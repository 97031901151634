import React, { useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { ErrorCode } from '@golden/gdk-admin'

const PlayerReportTransferCheckButton: React.FC = () => {
  const classes = useCommonStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [serialNumber, setSerialNumber] = useState<string>('')
  const [color, setColor] = useState<string>('#72d476')
  const [result, setResult] = useState<string | null>(null)
  const { t } = useT()
  const gdk = useGDK()
  const [debouncedCheck] = useDebouncedCallback(useCallback(() => {
    gdk.history.checkTransfer(serialNumber).subscribe({
      next: (response) => {
        setColor(response.result ? '#72d476' : '#f44336')
        setResult(response.result ? t('common.checkTransferSuccess') : t('common.checkTransferFail'))
      },
      error: (error) => {
        setColor('#f44336')
        setResult(error.code === ErrorCode.MODEL_NOT_FOUND ? t('common.thereIsNoThisOrderInAdmin') : error.message)
      }
    })
  }, [gdk, serialNumber]), 200)
  return (
    <React.Fragment>
      <Button
        className={classes.purpleGradualButton}
        onClick={useCallback(() => { setOpen(true) }, [])}
      >
        {t('common.checkTransferTitle')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={classes.dialogPinkHeader}>
          {t('common.checkTransferTitle')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={t('common.transactionSerial')}
                      value={serialNumber}
                      onChange={useCallback((event) => {
                        setSerialNumber(event.target.value)
                        setResult(null)
                      }, [])}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className={classes.purpleGradualButton}
                      onClick={useCallback(() => { debouncedCheck() }, [debouncedCheck])}
                    >
                      {t('common.confirm')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {result !== null && (
                  <Typography
                    style={{ color }}
                  >
                    {result}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box padding={3} display="flex" justifyContent="center">
          <Button
            className={classes.greyButton}
            onClick={useCallback(() => {
              setSerialNumber('')
              setResult(null)
              setOpen(false)
            }, [])}
          >
            {t('common.close')}
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(PlayerReportTransferCheckButton)
