import React, { useMemo, useCallback, ReactNode, useRef, useLayoutEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiTextField from '@material-ui/core/TextField'
import MuiChip from '@material-ui/core/Chip'
import MuiCheckbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import useT from '../../../i18ns/admin/useT'
import { Typography } from '@material-ui/core/'

const useStyles = makeStyles(() => ({
  chip: {},
  icon: {},
  popper: {
    zIndex: 1200
  },
  commaTags: {
    position: 'static',
    height: '1.1876em',
    boxSizing: 'content-box',
    padding: '6px 0'
  },
  commaTagsText: {
    position: 'absolute',
    bottom: '7px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '50%',
    overflow: 'hidden',
    lineHeight: 1
  },
  commaInput: {
    minWidth: 'auto !important',
    padding: '7px 0 5px !important'
  }
}))

export interface PropTypes {
  label?: string
  placeholder?: string
  options: Array<{
    name: string
    value: string | number
  }>
  value?: Array<string | number>
  defaultValue?: Array<string | number>
  onChange?: (value: Array<string | number>) => void
  disabled?: boolean
  fullWidth?: boolean
  onBlur?: () => void
  onFocus?: () => void
  className?: string
  classes?: Partial<Record<'chip' | 'icon', string>>
  disableCloseOnSelect?: boolean
  allValue?: number | string
  tagType?: 'chip' | 'comma'
  disableClearable?: boolean
  limitTags?: number
  getLimitTagsText?: (more: number) => ReactNode
  openOnFocus?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
}

const icon = (<CheckBoxOutlineBlankIcon />)
const checkedIcon = (<CheckBoxIcon />)
const deleteIcon = (<CancelRoundedIcon />)

const TextField = React.memo(MuiTextField)
const Chip = React.memo(MuiChip)
const Checkbox: React.FC<{ selected: boolean, name: string }> = React.memo((props) => {
  const { selected, name } = props
  return (
    <Box paddingRight={2}>
      <MuiCheckbox
        color="primary"
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
      />
      {name}
    </Box>
  )
})

const MultipleSelector: React.FC<PropTypes> = (props) => {
  const { openOnFocus, getLimitTagsText, disableClearable, limitTags, allValue, tagType, label, options, placeholder, defaultValue, value, onChange, onBlur, onFocus, fullWidth, disabled, className, classes: classesProps, disableCloseOnSelect = true, required = false, error, helperText } = props
  const { t } = useT()
  const classes = useStyles()
  const all = useMemo(() => ({ value: allValue ?? -1, name: t('common.all') }), [allValue, t]) as {
    name: string
    value: string | number
  }
  const showOptions = useMemo(() => {
    return [all].concat(options as any)
  }, [all, options])
  const defaultData = useMemo(() => {
    const data = defaultValue?.map((id) => options.find((option) => option.value === id)).filter((item) => item !== undefined)
    const sortedData = options.filter((option) => (data?.find((selected) => (selected?.value === option.value))))
    if (sortedData?.length === options.length && sortedData.length !== 0) return [all, ...sortedData]
    return sortedData
  }, [all, options, defaultValue])
  const data = useMemo(() => {
    const data = value?.map((id) => options.find((option) => option.value === id)).filter((item) => item !== undefined)
    const sortedData = options.filter((option) => (data?.find((selected) => (selected?.value === option.value))))
    if (sortedData?.length === options.length && sortedData.length !== 0) return [all, ...sortedData]
    return sortedData
  }, [all, options, value])

  const tagsText = useRef<HTMLHeadingElement>(null)
  const [commaTagsWidth, setCommaTagsWidth] = useState<string>('')

  const baseClass = useMemo(() => (tagType ?? 'chip') === 'comma' ? { input: classes.commaInput, popper: classes.popper } : { popper: classes.popper }, [tagType, classes])

  useLayoutEffect(() => {
    setCommaTagsWidth(`${tagsText?.current?.clientWidth as number}px`)
  }, [data, tagsText?.current?.clientWidth])

  return (
    <Autocomplete<any, boolean, boolean, boolean>
      className={className}
      multiple
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      options={showOptions}
      onFocusCapture={useCallback(() => { setCommaTagsWidth(`${tagsText?.current?.clientWidth as number}px`) }, [])}
      getOptionLabel={useCallback((item) => item.name, [])}
      defaultValue={defaultData}
      value={data}
      disableClearable={disableClearable}
      limitTags={limitTags}
      openOnFocus={(tagType ?? 'chip') === 'comma' ? true : openOnFocus}
      getLimitTagsText={(tagType ?? 'chip') === 'comma' ? () => '...' : getLimitTagsText}
      onChange={useCallback((_, value, reason: 'select-option' | 'remove-option' | 'blur' | 'clear' | 'create-option') => {
        const allIds = options.map((item) => item.value)
        const isAllSelected = allIds.every((id) => value.find((selected: { value: string | number, label: string }) => selected.value === id))
        let newValue = value.map((item: { value: string | number, label: string }) => item.value)
        if (value[value.length - 1]?.value === all.value && reason === 'select-option') newValue = allIds
        if (value[value.length - 1]?.value !== all.value && isAllSelected && reason === 'remove-option') newValue = []
        newValue = newValue.filter((item: number) => item !== all.value)
        if (onChange) onChange(newValue)
      }, [all.value, options, onChange])}
      onBlur={onBlur}
      onFocus={onFocus}
      classes={baseClass}
      renderInput={useCallback((params) => (
        <TextField
          {...params}
          label={`${label ?? ''}${required ? ' *' : ''}`}
          placeholder={placeholder}
          fullWidth={fullWidth}
          error={error}
          helperText={helperText}
        />
      ),
      [label, required, placeholder, fullWidth, error, helperText])}
      renderTags={useCallback((value: Array<{ value: string | number, name: string }>, getTagProps) => {
        return (tagType ?? 'chip') === 'chip'
          ? value.filter((data) => (value.find((item) => ((item.value === all.value))) ? (data.value === all.value) : true)).map((option, index) => (
            <Chip
              classes={{ root: clsx(classes.chip, classesProps?.chip), deleteIcon: clsx(classes.icon, classesProps?.icon) }}
              label={option.name}
              deleteIcon={deleteIcon}
              {...getTagProps({ index })}
            />
          ))
          : (<Box className={classes.commaTags} width={commaTagsWidth}>
            <Typography className={classes.commaTagsText} ref={tagsText}>
              {value.map((option) => option?.name).join(', ')}
            </Typography>
          </Box>)
      }, [tagType, classes, classesProps, tagsText, commaTagsWidth, all])}
      renderOption={useCallback((option, { selected }) => (
        <Checkbox selected={selected} name={option.name} />
      ), [])}
    />
  )
}

export default React.memo(MultipleSelector)
