import { ActivityStatusType, RecommendFriendStateType, ActivityNewMemberBonusStateType, ActivityDailySignInStateType, ActivityNewYear2023StatusType, ActivityDragonBoatFestival2023StatusType, ActivityNewYear2024StatusType, ActivityDepositBonusStatusType, ActivityUefaBuildingUpStateType, ActivityUefaStarsStatusType, ActivityWeeklyBonusDrawStatusType, ActivityDailyBonusDrawStatusType, ActivityNewYear2025DrawStatusType, ActivityNewYear2025RedEnvelopeStatusType } from '@golden/gdk-campaign'
import TKeyType from '../../i18ns/admin/TKeyType'

export const activityStatusName: { [key in ActivityStatusType]: TKeyType } = {
  [ActivityStatusType.SUCCESS]: 'activityStatus.success',
  [ActivityStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityStatusType.SENT]: 'activityStatus.sent',
  [ActivityStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityStatusType.CANCELED]: 'activityStatus.canceled'
}

export const recommendFriendStatusName: { [key in RecommendFriendStateType]: TKeyType } = {
  [RecommendFriendStateType.IN_PROGRESS]: 'activityStatus.success',
  [RecommendFriendStateType.RECEIVED]: 'activityStatus.sent',
  [RecommendFriendStateType.EXPIRED]: 'activityStatus.expired',
  [RecommendFriendStateType.CANCELED]: 'activityStatus.canceled'
}

export const newMemberBonusStatusName: { [key in ActivityNewMemberBonusStateType]: TKeyType } = {
  [ActivityNewMemberBonusStateType.JOINED]: 'activityStatus.success',
  [ActivityNewMemberBonusStateType.BONUS_RECEIVED]: 'activityStatus.sent',
  [ActivityNewMemberBonusStateType.BONUS_EXPIRED]: 'activityStatus.expired',
  [ActivityNewMemberBonusStateType.CANCELED]: 'activityStatus.canceled'
}

export const dailySignInStateName: { [key in ActivityDailySignInStateType]: TKeyType } = {
  [ActivityDailySignInStateType.APPLIED]: 'activityStatus.success',
  [ActivityDailySignInStateType.RECEIVED]: 'activityStatus.sent',
  [ActivityDailySignInStateType.EXPIRED]: 'activityStatus.expired',
  [ActivityDailySignInStateType.CANCELED]: 'activityStatus.canceled'
}

export const newYear2023StatusName: { [key in ActivityNewYear2023StatusType]: TKeyType } = {
  [ActivityNewYear2023StatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityNewYear2023StatusType.RECEIVED]: 'activityStatus.win',
  [ActivityNewYear2023StatusType.EXPIRED]: 'activityStatus.expired'
}

export const dragonBoatFestival2023StatusName: { [key in ActivityDragonBoatFestival2023StatusType]: TKeyType } = {
  [ActivityDragonBoatFestival2023StatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityDragonBoatFestival2023StatusType.RECEIVED]: 'activityStatus.win',
  [ActivityDragonBoatFestival2023StatusType.LOST]: 'activityStatus.lost',
  [ActivityDragonBoatFestival2023StatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityDragonBoatFestival2023StatusType.CANCELED]: 'activityStatus.canceled'
}

export const newYear2024StatusName: { [key in ActivityNewYear2024StatusType]: TKeyType } = {
  [ActivityNewYear2024StatusType.RECEIVED]: 'activityStatus.win',
  [ActivityNewYear2024StatusType.LOST]: 'activityStatus.lost'
}

export const newYear2025DrawStatusName: { [key in ActivityNewYear2025DrawStatusType]: TKeyType } = {
  [ActivityNewYear2025DrawStatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityNewYear2025DrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived',
  [ActivityNewYear2025DrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityNewYear2025DrawStatusType.LOSE]: 'activityStatus.lost',
  [ActivityNewYear2025DrawStatusType.EXPIRED]: 'activityStatus.expiredSimple'
}

export const newYear2025RedEnvelopeStatusName: { [key in ActivityNewYear2025RedEnvelopeStatusType]: TKeyType } = {
  [ActivityNewYear2025DrawStatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityNewYear2025DrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived',
  [ActivityNewYear2025DrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityNewYear2025DrawStatusType.EXPIRED]: 'activityStatus.expiredSimple'
}

export const depositBonusStatusName: { [key in ActivityDepositBonusStatusType]: TKeyType } = {
  [ActivityDepositBonusStatusType.RECEIVED]: 'activityStatus.sent'
}

export const uefaBuildingUpStatusName: { [key in ActivityUefaBuildingUpStateType]: TKeyType } = {
  [ActivityUefaBuildingUpStateType.APPLIED]: 'activityStatus.success',
  [ActivityUefaBuildingUpStateType.RECEIVED]: 'activityStatus.sent',
  [ActivityUefaBuildingUpStateType.EXPIRED]: 'activityStatus.expired',
  [ActivityUefaBuildingUpStateType.CANCELED]: 'activityStatus.canceled'
}

export const uefaStarsStatusName: { [key in ActivityUefaStarsStatusType]: TKeyType } = {
  [ActivityUefaStarsStatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityUefaStarsStatusType.WIN]: 'activityStatus.win',
  [ActivityUefaStarsStatusType.LOST]: 'activityStatus.lost',
  [ActivityUefaStarsStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityUefaStarsStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityUefaStarsStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}

export const weeklyBonusDrawStatusName: { [key in ActivityWeeklyBonusDrawStatusType]: TKeyType } = {
  [ActivityWeeklyBonusDrawStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityWeeklyBonusDrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityWeeklyBonusDrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}

export const dailyBonusDrawStatusName: { [key in ActivityDailyBonusDrawStatusType]: TKeyType } = {
  [ActivityDailyBonusDrawStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityDailyBonusDrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityDailyBonusDrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}

export default activityStatusName
