import React, { useEffect, useCallback, useState, ReactText } from 'react'
import { startOfDay, endOfDay, getTime } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useGDK from '../gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../../../components/admin/route/route'
import { getSnackbarMessage } from '../../../utils/admin/AlertHelper'
import useGDKStore from '../gdk/useGDKStore'
import { Alert, AlertInfo } from '@golden/gdk-admin'

const useStyles = makeStyles(() => ({
  button: {
    color: '#b15fff'
  }
}))

const Action: React.FC<{ id: ReactText, createdAt: Date, info: AlertInfo }> = React.memo((props) => {
  const { id, createdAt, info } = props
  const classes = useStyles()
  const { t } = useT()
  const navigate = useNavigate()
  const { closeSnackbar } = useSnackbar()
  const handleClick = useCallback(() => {
    navigate(
      allRoute.journalEvent.encodePath({
        search: { start_at: getTime(startOfDay(createdAt)), end_at: getTime(endOfDay(createdAt)), info, page: 1 },
        param: {}
      })
    )
    closeSnackbar()
  }, [navigate, closeSnackbar, createdAt])
  const handleClose = useCallback(() => {
    closeSnackbar(id)
  }, [closeSnackbar, id])
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        <Button
          className={classes.button}
          size="small"
          onClick={handleClick}
        >
          {t('common.detailInformation')}
        </Button>
      </Grid>
      <Grid item>
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
})

const EventHandler: React.FC = () => {
  const [notices, setNotices] = useState<Alert[]>([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const gdk = useGDK()
  const isLoggedIn = useGDKStore.auth.isLoggedIn()
  const { t } = useT()
  useEffect(() => {
    const subscripiton = gdk.alert.recentAlerts.subscribe((res) => {
      setNotices(res)
    })
    return () => { subscripiton.unsubscribe() }
  }, [gdk, t])

  useEffect(() => {
    if (notices.length) {
      const notice = notices[0]
      if ([AlertInfo.DEPOSIT_WAY_FULL_CASH_REACHED, AlertInfo.NO_TRANSFER_LOG_IN_THIRD_PARTY, AlertInfo.TRANSFER_STATUS_NOT_MATCH].includes(notice.info)) {
        enqueueSnackbar(getSnackbarMessage(notice.info, notice.subject, t), {
          action: (key) => (<Action id={key} info={notice.info} createdAt={notice.created_at || new Date()} />),
          autoHideDuration: 3 * 60 * 1000,
          onExited: () => {
            setNotices((notices) => {
              return notices.slice(1)
            })
          }
        })
      }
    }
  }, [notices, t, enqueueSnackbar])

  useEffect(() => {
    if (!isLoggedIn) closeSnackbar()
  }, [isLoggedIn, closeSnackbar])
  return null
}

export default React.memo(EventHandler)
