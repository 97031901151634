import React, { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute, { Path } from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { SearchToRequestFunc, useChangeUrlSubmit, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'
import { TextField } from '@material-ui/core'
import { acceptUndefined, convertEmptyToUndefined, guaranteeBetween, parseInt, pipe } from '../../../utils/default/FormHelper'
import { useInternValue } from '../../../utils/default/FormHook'
import { isUndefined, omitBy } from '@golden/utils'

export interface CourierFormType {
  page: number
  name: string
  is_active: '--' | 0 | 1
}
interface RequestType {
  name?: string
  is_active?: 0 | 1
}

export const searchToRequest: SearchToRequestFunc<CourierFormType> = (search) => {
  const converted = {
    ...search,
    page: acceptUndefined(
      search.page,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
      )
    ) || 1,
    is_active: acceptUndefined(
      search.is_active,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, 0, 1)
      )
    )
  } as CourierFormType
  return omitBy(converted, isUndefined) as CourierFormType
}

const FinanceCourierToolbar: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()

  useEffect(() => {
    gdk.trunk.trigger([Branch.FORWARDERS])
  }, [gdk])

  const options = useMemo(() => {
    return [
      { name: t('common.allStatus'), value: '--' },
      { name: t('common.toggleOn'), value: 1 },
      { name: t('common.toggleOff'), value: 0 }
    ]
  }, [t])

  const [name, setName] = useInternValue<string>('', request?.name ?? '')
  const handleNameSubmit = useChangeUrlSubmit<{}, RequestType>({
    formToRequest: () => {
      return omitBy({
        is_active: request?.is_active,
        name: convertEmptyToUndefined(name),
        page: 1
      }, isUndefined) as RequestType
    },
    encodePath: allRoute.FinanceCourier.encodePath,
    toAddNowTimestamp: true
  })
  const handleStatusChange = useChangeUrlSubmit<{ is_active: '--' | 0 | 1 }, RequestType>({
    formToRequest: (form) => omitBy({
      is_active: form.is_active === '--' ? undefined : form.is_active,
      name: convertEmptyToUndefined(name),
      page: 1
    }, isUndefined) as RequestType,
    encodePath: allRoute.FinanceCourier.encodePath,
    toAddNowTimestamp: true
  })

  const writable = useChecker()
  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('common.courierName')}
          placeholder={t('common.fuzzySearch')}
          value={name}
          onChange={useCallback((event) => {
            setName(event.target.value)
          }, [])}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.purpleGradualButton}
          onClick={handleNameSubmit}
        >
          <Box paddingY={0.5}>
            {t('common.search')}
          </Box>
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={classes.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={request?.is_active ?? '--'}
          onChange={useCallback((event) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const is_active = event.target.value
            handleStatusChange({ is_active })
          }, [handleStatusChange])}
          options={options}
        />
      </Grid>
      {writable && (
        <Grid item>
          <Button
            disabled={!writable}
            component={Link}
            to={Path.FINANCE_COURIER_CREATE}
            className={classes.purpleGradualButton}
            classes={{ disabled: classes.disabledButton }}
          >
            <Box paddingY={0.5}>
              {t('common.createCourierShort')}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(FinanceCourierToolbar)
