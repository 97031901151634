import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import PlayerReportFeedbackForm from '../../../components/admin/playerReport/feedback/PlayerReportFeedbackForm'
import PlayerReportFeedbackLockTable from '../../../components/admin/playerReport/feedback/PlayerReportFeedbackLockTable'
import PlayerReportFeedbackMemberTable from '../../../components/admin/playerReport/feedback/PlayerReportFeedbackMemberTable'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { Branch } from '@golden/gdk-admin'

const PlayerReportFeedbackHistoryPage: React.FC = () => {
  const gdk = useGDK()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS, Branch.PUBLISHERS])
  }, [gdk])
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <PlayerReportFeedbackForm />
      </Box>
      <Box paddingTop={3}>
        <PlayerReportFeedbackLockTable />
      </Box>
      <Box paddingTop={3}>
        <PlayerReportFeedbackMemberTable />
      </Box>
    </Box>
  )
}

export default React.memo(PlayerReportFeedbackHistoryPage)
