import React, { useContext, useCallback, useState, useEffect, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { getServerUrl } from '../../../utils/default/StageHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { getTargetURL } from '@golden/gdk-campaign'

export interface Payload {
  onSubmit: (imageId: number, imagePath: string) => void
  onCancel: () => void
  channel: string
  defaultImageId: number
  defaultImagePath: string
  open: boolean
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  icon: {
    fontSize: 48
  },
  input: {
    display: 'none'
  },
  dragSection: {
    cursor: 'pointer'
  },
  button: {
    color: theme.custom.palette.blue.main
  }
}))

interface PropTypes {
  context: React.Context<[
    Payload,
    Dispatch<SetStateAction<Payload>>
  ]>
}

const ExternalGameChannelImageDialog: React.FC<PropTypes> = (props) => {
  const { context } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [payload] = useContext(context)
  const {
    open,
    defaultImageId,
    defaultImagePath,
    channel,
    onSubmit,
    onCancel
  } = payload
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow()
  const [imageId, setImageId] = useState<number>(defaultImageId)
  useEffect(() => {
    setImageId(defaultImageId)
  }, [defaultImageId])
  const [imagePath, setImagePath] = useState<string>(defaultImagePath)
  useEffect(() => {
    setImagePath(defaultImagePath)
  }, [defaultImagePath])
  const gdk = useGDK()
  const handleCancel = useCallback(() => {
    setImageId(0)
    setImagePath('')
    onCancel()
  }, [onCancel])
  const handleFileUpload = useCallback((file: File | undefined) => {
    if (file) {
      pageFlow.setLoadingStart()
      gdk.platform.uploadImage(file).subscribe({
        next: (image) => {
          setImageId(image.id)
          setImagePath(image.url)
          pageFlow.setContentShow()
        },
        error: (error) => {
          pageFlow.setContentShow()
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: 'imageError',
            isOK: false,
            value: true
          })
        }
      })
    }
  }, [gdk])
  const handleDrop = useCallback((files) => {
    const file = files[0]
    handleFileUpload(file)
  }, [handleFileUpload])
  const handleUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    handleFileUpload(file)
  }, [handleFileUpload])
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop })
  const handleSubmit = useCallback(() => {
    onSubmit(imageId, imagePath)
    setImageId(0)
    setImagePath('')
  }, [onSubmit, imageId, imagePath])
  const handleRemove = useCallback(() => {
    setImageId(0)
    setImagePath('')
  }, [])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {channel}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <div className={clsx(commonClasses.grayFrameContainer, classes.dragSection)} {...getRootProps()}>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className={classes.input}
                onChange={handleUpload}
                {...getInputProps()}
              />
              {imagePath !== ''
                ? (
                <img src={getTargetURL(getServerUrl('image'), imagePath)} alt="" />
                  )
                : (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <CloudUploadIcon className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <p>{t('common.dropOrClick')} <span className={commonClasses.anchor}>{t('common.chooseFile')}</span> {t('common.uploadImage')}</p>
                  </Grid>
                </Grid>
                  )}
            </div>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Button className={classes.button} onClick={handleRemove}>
                {t('common.removeImage')}
              </Button>
            </Box>
          </LoadingAndErrorFrame>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box padding={3}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={handleSubmit}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ExternalGameChannelImageDialog)
