import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import useT from '../../../../i18ns/admin/useT'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useGDKFuncHandleSubmit } from '../../../../utils/default/ComplexFlowHook'
import { useDialogStyles, useCommonStyles } from '../../../../utils/admin/StyleHook'
import CustomThemeType from '../../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import Autocomplete, { PropTypes as AutocompleteProps } from '../../../../components/default/form/Autocomplete'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import { FormContext } from '../../../../views/admin/activity/ActivityWalletCreatePage'
import { ErrorCode } from '@golden/gdk-admin'

interface FormType {
  title: string
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  },
  error: {
    color: theme.palette.error.main
  }
}))

const ActivityWalletAddActivityTitleDialog: React.FC<{ open: boolean, close: () => void, reload: () => void }> = ({ open, close, reload }) => {
  const gdk = useGDK()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const classes = useStyles()
  const globalDialog = useGlobalDialog()
  const writable = useChecker()
  const { dispatch } = useContext(FormContext)

  const [form, setForm] = useState<FormType>({ title: '' })
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<Array<{ name: string, value: number }>>([])
  const [error, setError] = useState('')
  const autocompleteProps = useMemo(() => {
    return {
      loading,
      freeSolo: true,
      disableClearable: true,
      disableListWrap: true,
      options: options.map(item => item.name)
    }
  }, [loading, options])

  const duplicated = useMemo(() => autocompleteProps.options.includes(form.title), [form.title, autocompleteProps.options])
  const disabled = useMemo(() => {
    if (duplicated) return true
    if (error) return true
    if (loading) return true
    return writable && form.title === ''
  }, [duplicated, loading, error, writable, form.title])

  const handleChange = useCallback((value: any) => {
    setForm({ title: value })
  }, [])

  const handleAutoSelect = useCallback(() => {
    const value = options.find(item => form.title === item.name)?.value
    if (value === undefined) return
    dispatch({ type: 'change', label: 'activityWalletTitleId', value })
    close()
  }, [dispatch, options, form.title, close])

  useEffect(() => {
    setError('')
    if (form.title !== '') {
      setLoading(true)
      const subscription = gdk.activity.getActivityWalletActivityTitle({ title: form.title }).subscribe({
        next: (res) => {
          setLoading(false)
          setOptions(
            res.map((item) => {
              return { name: item.title, value: item.id }
            })
          )
        },
        error: () => {
          setLoading(false)
        }
      })
      return () => {
        subscription.unsubscribe()
        setLoading(false)
      }
    }
  }, [gdk, form.title, t])

  const formToRequest = (form: FormType) => {
    return {
      title: form.title
    }
  }

  useEffect(() => {
    if (open) setForm({ title: '' })
  }, [open])

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.activity.updateActivityWalletActivityTitle(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      close()
      reload()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.updateSuccess')
      }))
      globalDialog.setOpen({
        id: 'updateAddActivityTitleSuccess',
        value: true,
        isOK: false
      })
    },
    onError: (error) => {
      if (error.code === ErrorCode.ACTIVITY_WALLET_TITLE_CONTAIN_TRADITIONAL_CHINESE) {
        setError(error.message)
        return
      }
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'updateAddActivityTitleError', value: true, isOK: false })
    }
  })

  const onSubmit = useCallback(() => {
    if (writable) {
      handleSubmit(form)
    } else {
      close()
    }
  }, [form, handleSubmit, close, writable])

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.title}>{t('common.addActivityName')}</DialogTitle>
      <DialogContent>
        <Box padding={3} paddingTop={1}>
          <Autocomplete
            autocompleteProps={autocompleteProps}
            inputProps={{
              placeholder: t('placeholder.inputAddActivityTitle')
            }}
            value={form.title}
            onChange={handleChange}
            multiline
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
          />
          {
            duplicated && (
              <Box>
                <div className={classes.error}>{t('error.duplicatedActivityTitle')} <span className={commonClasses.anchor} onClick={handleAutoSelect}>{t('common.autoSelect')}</span></div>
              </Box>
            )
          }
          {
            error && (
              <Box>
                <div className={classes.error}>{error}</div>
              </Box>
            )
          }
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item>
            {
              writable && (
                <Button
                  classes={{ root: dialogClasses.cancelButton }}
                  onClick={close}
                >
                  {t('common.cancel')}
                </Button>
              )
            }
          </Grid>
          <Grid item>
            <Button
              classes={{ root: dialogClasses.okButton }}
              onClick={onSubmit}
              disabled={ disabled }
            >
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActivityWalletAddActivityTitleDialog)
