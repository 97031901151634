import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GDKError } from '@golden/gdk-admin'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'
import { cloneDeep, isUndefined, omitBy } from '@golden/utils'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  item: {
    border: '1px solid #e9e9e9',
    '&:not(:first-child)': {
      marginTop: '-1px'
    },
    height: '54px'
  },
  button: {
    color: theme.custom.palette.blue.main
  }
}))

interface tagPriorityPayload {
  id: number
  priority: number
  title?: string
}

const ActivityItem: React.FC<{ id: number, index: number, title: string, editIndex: number, setEditIndex: (index: number) => void, handleEdit: (index: number, value: string) => void }> = (props) => {
  const { id, index, title, editIndex, setEditIndex, handleEdit } = props
  const [newTitle, setTitle] = useState(title)
  const classes = useStyles()
  const { t } = useT()

  return (
    <Draggable draggableId={`activity-${id}`} index={index}>
      {
        (provided) => {
          const { draggableProps, dragHandleProps, innerRef } = provided
          return (
            <ListItem
              divider
              ref={innerRef}
              className={classes.item}
              {...draggableProps}
            >
              <ListItemIcon {...dragHandleProps}>
                { editIndex === -1 ? (<DragHandleIcon />) : null }
              </ListItemIcon>
              { editIndex === index
                ? (
                <TextField
                  fullWidth
                  value={newTitle}
                  onChange={(event) => {
                    const value = event.target.value
                    setTitle(value)
                  }}
                />
                  )
                : (<ListItemText primary={title} />)
              }
              {
                editIndex === -1
                  ? (
                  <Button className={classes.button} onClick={() => { setEditIndex(index) }}> { t('common.edit') } </Button>)
                  : (editIndex === index
                      ? (
                    <Button className={classes.button} onClick={() => { handleEdit(index, newTitle) }}>{ t('common.finish') }</Button>
                        )
                      : null)
              }
            </ListItem>
          )
        }
      }
    </Draggable>
  )
}

const ActivityManagementTagsDialog: React.FC<{ open: boolean, onClose: () => void, reload: () => void }> = (props) => {
  const { open, onClose, reload } = props
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const [tags, setTags] = useState<Array<{ id: number, title: string, priority: number, isEdit: boolean }>>([])
  const [editIndex, setEditIndex] = useState<number>(-1)
  const pageFlow = usePageFlow()

  useGetData({
    gdkFunc: () => gdk.activity.getActivityTagsPriority(),
    gdkFuncDependencies: [gdk, open],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setTags(res.map((item) => ({ ...item, isEdit: false })))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: open
  })

  const [handleSubmitDebounced] = useDebouncedCallback(useCallback(() => {
    onClose()
    gdk.activity.updateActivityTagsPriority(tags.map(({ id, isEdit, title }, index) => (omitBy({ id, priority: index, title: isEdit ? title : undefined } as tagPriorityPayload, isUndefined) as tagPriorityPayload))).subscribe({
      next: () => {
        setTags([])
        reload()
      },
      error: (error: GDKError) => {
        setTags([])
        reload()
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'sortPriorityError',
          value: false,
          isOK: false
        })
      }
    })
  }, [gdk, onClose, tags]), 200)
  const handleClose = useCallback(() => {
    onClose()
    setTags([])
  }, [onClose])
  const handleSubmit = useCallback(handleSubmitDebounced, [])
  const handleSorting = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    const { droppableId: sourceId, index: sourceIndex } = source
    const { droppableId: targetId, index: targetIndex } = destination
    if (sourceId === targetId && sourceIndex === targetIndex) return

    const newTags = cloneDeep(tags)

    const [target] = newTags.splice(sourceIndex, 1)
    newTags.splice(targetIndex, 0, target)

    setTags(newTags)
  }
  const handleEdit = (index: number, value: string) => {
    const newTags = cloneDeep(tags)

    const [target] = newTags.splice(index, 1)
    if (target.title !== value) {
      target.isEdit = true
      target.title = value
      newTags.splice(index, 0, target)
      setTags(newTags)
    }

    setEditIndex(-1)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.activityTag')}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <DragDropContext onDragEnd={handleSorting}>
            <Droppable droppableId='droppable-1'>
              {
                (provided) => {
                  const { droppableProps, innerRef, placeholder } = provided
                  return (
                    <List
                      disablePadding
                      ref={innerRef}
                      {...droppableProps}
                    >
                      {
                        tags.map((item, index) => (
                          <ActivityItem
                            {...item}
                            key={item.id}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                            index={index}
                            handleEdit={handleEdit}
                          />
                        ))
                      }
                      {placeholder}
                    </List>
                  )
                }
              }
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={handleClose}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={commonClasses.purpleGradualButton}
              onClick={handleSubmit}
              disabled={editIndex !== -1}
            >
              {t('common.saveSetting')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActivityManagementTagsDialog)
