import { ActivityWalletStatus } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

const activityWalletStatusTypeName: { [key in ActivityWalletStatus]: TKeyType } = {
  [ActivityWalletStatus.CAN_RECEIVE]: 'activityWalletStatus.canReceive',
  [ActivityWalletStatus.TRANSFERRING]: 'activityWalletStatus.transferring',
  [ActivityWalletStatus.RECEIVED]: 'activityWalletStatus.received',
  [ActivityWalletStatus.ERROR]: 'activityWalletStatus.error',
  [ActivityWalletStatus.EXPIRED]: 'activityWalletStatus.expired',
  [ActivityWalletStatus.REVOKED]: 'activityWalletStatus.revoke'
}

export default activityWalletStatusTypeName
