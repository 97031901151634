import React, { useMemo, useContext, useCallback, createContext } from 'react'
import { startOfDay, endOfDay, subYears, startOfYear, getTime, isAfter, isBefore, addMonths, subSeconds } from 'date-fns'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, SearchToRequestFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, pipe, parseInt, parseArray, getTimeFromDateInputValue, getValueFromValue, guaranteeBetween } from '../../../utils/default/FormHelper'
import { AdminReportInAndOutReport } from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { createShouldDisableDate, setYesterday, setLastMonth, setThisMonth, setToday } from '../../../utils/default/TimeHelper'
import allRoute from '../route/route'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../default/form/MultipleSelector'

export interface AdminReportInAndOutRequest {
  start_at: number
  end_at: number
  reports: AdminReportInAndOutReport[]
}

export interface AdminReportInAndOutFormType {
  time: DateInputValue
  reports: AdminReportInAndOutReport[]
}

export const initialForm: InitialFormFunc<AdminReportInAndOutFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  reports: [],
  ...defaultForm
})

export const searchToRequest: SearchToRequestFunc<AdminReportInAndOutRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const converted = {
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    reports: pipe(
      guaranteeNotUndefined,
      parseArray,
      (value: string[]) => value.filter((item) => item !== '')
    )(search.reports)
  }
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return converted
}

const getValueFromEvent: ValueGetter<AdminReportInAndOutFormType> = {
  time: getTimeFromDateInputValue,
  reports: getValueFromValue
}

const getChangedForm: ChangedFormGetter<AdminReportInAndOutFormType> = {
  time: (value, form) => {
    const startTimeAddTwoMonths = subSeconds(addMonths(value.start ?? new Date(), 2), 1)
    if (value.start && value.end && (isAfter(value.end, endOfDay(startTimeAddTwoMonths)) || isBefore(value.end, value.start))) {
      return { ...form, time: { start: value.start, end: endOfDay(startTimeAddTwoMonths) } }
    }
    return { ...form, time: value }
  }
}

const formToRequest = (form: AdminReportInAndOutFormType): AdminReportInAndOutRequest => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  start_at: getTime(form.time.start!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  end_at: getTime(form.time.end!),
  reports: form.reports
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    const startTimeAddTwoMonths = subSeconds(addMonths(value.time.start ?? new Date(), 2), 1)
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo, startTimeAddTwoMonths)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

const AdminReportInAndOutForm: React.FC = () => {
  const { t } = useT()
  const classes = useCommonStyles()
  const request = useRequestFromSearch({ searchToRequest })
  const validation = useMemo(() => {
    return {
      search_type: [],
      time: [],
      reports: []
    } as FormValidation<AdminReportInAndOutFormType>
  }, [])
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        reports: request.reports
      })
    }
    return initialForm()
  }, [request])
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportInAndOut.encodePath
  })
  const options = useMemo(() => {
    return [
      { name: t('common.platformStatistic'), value: AdminReportInAndOutReport.PLATFORM },
      { name: t('common.betRevenueStatistic'), value: AdminReportInAndOutReport.REVENUE },
      { name: t('common.depositWithdrawStatistic'), value: AdminReportInAndOutReport.DEPOSIT_AND_WITHDRAW },
      { name: t('common.transferStatistic'), value: AdminReportInAndOutReport.TRANSFER }
    ]
  }, [t])
  return (
    <Paper>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {t('page.inAndOut')}
        </Typography>
      </Box>
      <Box padding={4}>
        <FormStateProvider
          context={FormContext}
          defaultValue={defaultForm}
          onSubmit={handleSubmit}
          validation={validation}
          getValueFromEvent={getValueFromEvent}
          getChangedForm={getChangedForm}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <FormField<AdminReportInAndOutFormType, MultipleSelectorProps>
                name="reports"
                context={FormContext}
                component={MultipleSelector}
                options={options}
                label={t('common.statisticType')}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormStateProvider>
      </Box>
    </Paper>
  )
}

export default React.memo(AdminReportInAndOutForm)
