import React, { useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { captureMessage } from '@golden/sentry-react'
import { useCommonStyles, useDialogStyles } from '../../../../utils/admin/StyleHook'
import { GDKError, TransferHistory, WalletType } from '@golden/gdk-admin'
import useT from '../../../../i18ns/admin/useT'
import { formatMoney } from '../../../../utils/default/TableHelper'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { mainWalletName } from '../../../../utils/default/PlatformHelper'
import { isBefore, subMonths } from 'date-fns'

const TransferErrorStateActions: React.FC<{
  data: TransferHistory
  reload: () => void
}> = (props) => {
  const { data, reload } = props
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()

  const [open, setOpen] = useState(false)
  const [isConfirm, setIsConfirm] = useState(true)
  const [loading, setLoading] = useState(false)
  const isUpdateStatusTimeInvalid = useMemo(() => isBefore(data.created_at, subMonths(new Date(), 1)), [data])
  const handleTimeInvalid = () => {
    globalDialog.setConfig(createGlobalDialogConfig({
      variant: 'error',
      showCancel: false,
      message: t('dialog.transferUpdateStatusTimeInvalid')
    }))
    globalDialog.setOpen({ id: 'transferUpdateStatusTimeInvalid', value: true, isOK: false })
  }

  const handleConfirm = () => {
    if (isUpdateStatusTimeInvalid) {
      handleTimeInvalid()
      return
    }
    setIsConfirm(true)
    setOpen(true)
  }
  const handleCancel = () => {
    if (isUpdateStatusTimeInvalid) {
      handleTimeInvalid()
      return
    }
    setIsConfirm(false)
    setOpen(true)
  }
  const handleSubmit = () => {
    setLoading(true)
    captureMessage('轉賬確認')
    if (isConfirm) {
      gdk.history.confirmTransfer(data.id).subscribe({
        next: () => {
          setLoading(false)
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.updateSuccess')
          }))
          globalDialog.setOpen({ id: `confirmTransferErrorSuccess-${data.id}`, value: true, isOK: false })
          reload()
        },
        error: (error: GDKError) => {
          setLoading(false)
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({ id: 'confirmTransferErrorFailed', value: true, isOK: false })
        }
      })
    } else {
      gdk.history.cancelTransfer(data.id).subscribe({
        next: () => {
          setLoading(false)
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.updateSuccess')
          }))
          globalDialog.setOpen({ id: `cancelTransferErrorSuccess-${data.id}`, value: true, isOK: false })
          reload()
        },
        error: (error: GDKError) => {
          setLoading(false)
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({ id: 'cancelTransferErrorFailed', value: true, isOK: false })
        }
      })
    }
  }
  return (
    <React.Fragment>
      <Grid container item wrap="nowrap" spacing={1}>
        <Grid item xs={6}>
          <Button
            onClick={handleConfirm}
            size="small"
            className={commonClasses.blueGradualOutlineButton}
          >
            {t('common.success')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleCancel}
            size="small"
            className={commonClasses.pinkGradualOutlineButton}
          >
            {t('common.failure')}
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="sm">
        <DialogContent>
          <Box padding={3}>
            <Typography variant="h5" className={dialogClasses.text}>
              {t('common.checkVariationBelow')}
            </Typography>
            <Typography className={commonClasses.pre}>
              {`${t('common.orderNumber')} : [${data.transfer_number}]\n` +
                `${t('common.transactionStatus')} : ${isConfirm ? t('common.success') : t('common.failure')}\n` +
                `${data.from === WalletType.ACTIVITY ? t('common.activityWallet') : mainWalletName} : +${
                  (isConfirm && data.to === WalletType.CENTER) || (!isConfirm && data.from === WalletType.CENTER)
                    ? formatMoney(data.cash)
                    : '0'}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                disabled={loading}
                className={dialogClasses.cancelButton}
                onClick={() => {
                  setOpen(false)
                }}
              >
                {loading
                  ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={24} />
                  </Box>
                    )
                  : t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                className={dialogClasses.okButton}
                onClick={handleSubmit}
              >
                {loading
                  ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={24} />
                  </Box>
                    )
                  : t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(TransferErrorStateActions)
