import { useState, useEffect } from 'react'
import { NowTime } from '@golden/gdk-admin'
import { map, distinctUntilChanged, switchMap, tap, takeWhile, retryWhen, delay, take, Subject, timer, throwError, Observable, of } from 'rxjs'
import { differenceInSeconds } from 'date-fns'

const useTimeBasedValue = <D extends { expired_at: Date | null }>(
  api: () => Observable<D>,
  defaultValue: Omit<D, 'expired_at'> & { min: string, sec: string, loading: boolean }
) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    const flow$ = new Subject<void>()
    const refresh = () => { flow$.next() }
    const subscription = flow$.pipe(
      switchMap(() =>
        api().pipe(
          switchMap((data) => {
            const expiredAt = data.expired_at
            if (!expiredAt) return of({ ...data, second: 0 })
            if (differenceInSeconds(expiredAt, NowTime.get()) <= 0) {
              return throwError(() => new Error('expireTime is past'))
            }

            return timer(0, 1000).pipe(
              map(() => ({
                second: differenceInSeconds(expiredAt, NowTime.get()),
                ...data
              })),
              distinctUntilChanged(),
              tap(({ second }) => {
                if (second <= 0) refresh()
              }),
              takeWhile(({ second }) => second >= 0)
            )
          }),
          retryWhen((error) => error.pipe(delay(500), take(5))),
          map((data) => {
            const min = (Math.floor(data.second / 60)).toString().padStart(2, '0')
            const sec = (data.second % 60).toString().padStart(2, '0')
            return {
              ...data,
              min,
              sec,
              loading: false
            }
          })
        )
      )
    ).subscribe((data) => { setValue(data) })

    refresh()

    return () => { subscription.unsubscribe() }
  }, [api])

  return value
}

export default useTimeBasedValue
