import React, { useCallback, useContext, useMemo, useState } from 'react'
import { TextContentType, Editor, BubbleMenu } from '@golden/tiptap-react'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import AddsectionIcon from '@material-ui/icons/Crop169'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import FormatColorTextIcon from '@material-ui/icons/FormatColorText'
import FormatClearIcon from '@material-ui/icons/FormatClear'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import SvgIcon from '@material-ui/core/SvgIcon'
import LinkIcon from '@material-ui/icons/Link'
import IconButton from '@material-ui/core/IconButton'
import RemixSvgSprite from 'remixicon/fonts/remixicon.symbol.svg'
import 'remixicon/fonts/remixicon.css'
import DropDown from '../DropDown'
import useT from '../../../../i18ns/admin/useT'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import * as materialColors from '@material-ui/core/colors'
import Popper from '@material-ui/core/Popper'
import { PropTypes, EditorContext } from './TextEditor'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { uniqueId } from '@golden/utils'
import TKeyType from '../../../../i18ns/admin/TKeyType'
import { TOptions } from 'i18next'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  },
  menuBar: {
    padding: '6px 12px',
    boxShadow: 'inset 0 -1px 0 0 rgb(0 0 0 / 20%)',
    position: 'relative',
    zIndex: 2
  },
  textTypeDropDownBox: {
    width: '100px',
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    backgroundColor: '#e0e0e0'
  },
  button: {
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}))

export interface ExtendControlType {
  key: string
  type: 'button' | 'icon-button'
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  children: JSX.Element
  hint?: string
  className?: string
}
export type BuiltInControlType = 'textContentType' | 'table' | 'block' | 'textColor' | 'bold' | 'table' | 'redo' | 'undo' | 'clearFormat' | 'ol' | 'ul' | 'divider' | 'link'
export type ControlType = ExtendControlType | BuiltInControlType

const defaultColors = [
  materialColors.red['500'],
  materialColors.pink['500'],
  materialColors.purple['500'],
  materialColors.deepPurple['500'],
  materialColors.indigo['500'],
  materialColors.blue['500'],
  materialColors.lightBlue['500'],
  materialColors.cyan['500'],
  materialColors.teal['500'],
  materialColors.green['500'],
  materialColors.lightGreen['500'],
  materialColors.lime['500'],
  materialColors.yellow['500'],
  materialColors.amber['500'],
  materialColors.orange['500'],
  materialColors.deepOrange['500'],
  materialColors.brown['500'],
  materialColors.blueGrey['500']
]

const TextContentTypeDropDown: React.FC<{
  textContentType?: TextContentType[]
  disabled?: boolean
}> = (props) => {
  const { editor } = useContext(EditorContext)
  const { textContentType = [], disabled = false } = props
  const { t } = useT()

  const { textTypeDropDownBox } = useStyles(props)

  const value = editor?.getAttributes('heading')?.level ?? TextContentType.PARAGRAPH
  const options = useMemo(
    () => textContentType.map((type) => ({
      name: type === TextContentType.PARAGRAPH ? t('common.contentText') : `${t('common.title')} ${type}`,
      value: type
    })) ?? [],
    [t, textContentType]
  )

  return (
    <Box className={textTypeDropDownBox}>
      <DropDown
        hideLabel
        value={value}
        onChange={useCallback((event) => {
          if (event.target.value === TextContentType.PARAGRAPH) {
            // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
            editor?.chain().focus().setParagraph().run()
          } else {
            // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
            editor?.chain().focus().setHeading({ level: event.target.value }).run()
          }
        }, [editor])}
        options={options}
        fullWidth
        disabled={disabled}
      />
    </Box>
  )
}

const RemixIcon: React.FC<{ icon: string }> = (props) => {
  return (
    <SvgIcon {...props}>
      <use href={`${RemixSvgSprite}#${props.icon}`}/>
    </SvgIcon>
  )
}

const MenuButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>
  hint?: string
  disabled?: boolean
  className?: string
}> = (props) => {
  const { onClick, children, hint, disabled, className } = props
  const classes = useStyles(props)

  return (
    hint && !disabled
      ? <Tooltip title={hint} arrow>
        <span>
          <Button
            onClick={onClick}
            disabled={disabled}
            className={className}
            classes={{
              root: classes.button
            }}
          >
            { children }
          </Button>
        </span>
      </Tooltip>
      : <Button
        onClick={onClick}
        disabled={disabled}
        className={className}
        classes={{
          root: classes.button
        }}
      >
        { children }
      </Button>
  )
}

const TextColorDropDown: React.FC<{
  colors?: string[]
  disabled?: boolean
}> = (props) => {
  const { editor } = useContext(EditorContext)
  const { colors, disabled } = props
  const { t } = useT()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleColor = (color?: string) => {
    if (color) {
      editor?.chain().focus().setTextColor(color).run()
    } else {
      editor?.chain().focus().unsetTextColor().run()
    }
    setOpen(false)
  }

  return (
    <>
      <MenuButton onClick={handleOpen} className={open ? 'active' : ''} hint={t('common.textColor')} disabled={disabled}>
        <FormatColorTextIcon style={{ color: !disabled ? editor?.getAttributes('textStyle').textColor as string : '' }}/>
      </MenuButton>
      <Popper open={open} anchorEl={anchorEl} placement={'bottom-start'} disablePortal>
        <ClickAwayListener onClickAway={() => { setOpen(false) }}>
          <Paper
            elevation={3}
            style={{ maxWidth: 220, padding: 8 }}
          >
            <Grid container spacing={1}>
              { (colors ?? defaultColors).map((color, index) =>
                <Grid item key={index}>
                  <Box>
                    <IconButton
                      style={{ backgroundColor: color, width: 30, height: 30 }}
                      onClick={() => { handleColor(color) }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box>
                  <IconButton
                    style={{ padding: 0, width: 30, height: 30 }}
                    onClick={() => { handleColor() }}
                  >
                    <HighlightOffIcon fontSize="large"/>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const getControlComponent = (
  type: ControlType,
  t: (key: TKeyType, options?: TOptions | undefined) => string,
  editor: Editor,
  textContentType: TextContentType[] | undefined,
  colors: string[] | undefined,
  classes: ClassNameMap<'divider'>,
  handleOpen: (v: boolean) => void,
  handleUrl: (v: string) => void,
  disabled?: boolean
) => {
  const controlMap: { [key in BuiltInControlType]: JSX.Element } = {
    textContentType: (<TextContentTypeDropDown textContentType={textContentType} key={uniqueId('text-content-type-')} disabled={disabled} />),
    table: (
      <MenuButton
      // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
        key={uniqueId('add-table-')}
        hint={t('common.addTable')}
        disabled={disabled}
      >
        <RemixIcon icon="ri-table-line" />
      </MenuButton>
    ),
    block: (
      <MenuButton
        onClick={() => editor?.chain().focus().wrapIn('section').run()}
        key={uniqueId('section-')}
        hint={t('common.block')}
        disabled={disabled}
      >
        <AddsectionIcon />
      </MenuButton>
    ),
    bold: (
      <MenuButton
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().toggleBold().run()}
        className={editor?.isActive('bold') ? 'active' : ''}
        key={uniqueId('bold-')}
        hint={t('common.bold')}
        disabled={disabled}
      >
        <FormatBoldIcon />
      </MenuButton>
    ),
    ul: (
      <MenuButton
      // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        className={editor?.isActive('bulletList') ? 'active' : ''}
        key={uniqueId('ul-')}
        hint={t('common.ul')}
        disabled={disabled}
      >
        <FormatListBulletedIcon />
      </MenuButton>
    ),
    ol: (
      <MenuButton
      // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        className={editor?.isActive('orderedList') ? 'active' : ''}
        key={uniqueId('ol-')}
        hint={t('common.ol')}
        disabled={disabled}
      >
        <FormatListNumberedIcon />
      </MenuButton>
    ),
    textColor: (
      <TextColorDropDown colors={colors} key={uniqueId('text-color-')} disabled={disabled}/>
    ),
    clearFormat: (
      <MenuButton
        onClick={() => editor?.chain().focus().clearNodes().unsetAllMarks().run()}
        key={uniqueId('clear-format-')}
        hint={t('common.clearFormat')}
        disabled={disabled}
      >
        <FormatClearIcon />
      </MenuButton>
    ),
    undo: (
      <MenuButton
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().undo().run()}
        key={uniqueId('undo-')}
        disabled={disabled}
      >
        <UndoIcon />
      </MenuButton>
    ),
    redo: (
      <MenuButton
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick={() => editor?.chain().focus().redo().run()}
        key={uniqueId('redo-')}
        disabled={disabled}
      >
        <RedoIcon />
      </MenuButton>
    ),
    divider: (
      <Divider orientation="vertical" flexItem className={classes.divider} key={uniqueId('divider-')}/>
    ),
    link: (
      <MenuButton
        onClick={() => {
          const previousUrl = editor?.getAttributes('link').href
          if (previousUrl) {
            (editor?.chain().focus().extendMarkRange('link') as any).unsetLink().run()
          } else {
            handleUrl(previousUrl)
            handleOpen(true)
          }
        }}
        key={uniqueId('link-')}
      >
        <LinkIcon />
      </MenuButton>
    )
  }

  if (typeof type === 'string') {
    return controlMap[type]
  } else {
    return (
      <MenuButton onClick={type.onClick} disabled={type?.disabled} key={type.key} hint={type.hint}>
        { type.children }
      </MenuButton>
    )
  }
}

const TableBubbleMenu: React.FC<PropTypes & { editor: Editor }> = (props) => {
  const { editor } = props
  const classes = useStyles(props)
  const { t } = useT()

  const controls = useMemo((): Array<'divider' | ExtendControlType> => {
    if (!editor.isActive('table')) return []
    return [
      {
        type: 'button',
        key: 'add-table',
        children: (<RemixIcon icon="ri-table-line"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
        hint: t('common.addTable')
      },
      {
        type: 'button',
        key: 'delete-table',
        children: (<RemixIcon icon="ri-delete-bin-2-line"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().deleteTable().run(),
        hint: t('common.deleteTable')
      },
      'divider',
      {
        type: 'button',
        key: 'add-column-left',
        children: (<RemixIcon icon="ri-insert-column-left"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().addColumnBefore().run(),
        hint: t('common.addColumnLeft')
      },
      {
        type: 'button',
        key: 'add-column-right',
        children: (<RemixIcon icon="ri-insert-column-right"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().addColumnAfter().run(),
        hint: t('common.addColumnRight')
      },
      {
        type: 'button',
        key: 'delete-column',
        children: (<RemixIcon icon="ri-delete-column"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().deleteColumn().run(),
        hint: t('common.deleteColumn')
      },
      'divider',
      {
        type: 'button',
        key: 'add-row-top',
        children: (<RemixIcon icon="ri-insert-row-top"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().addRowBefore().run(),
        hint: t('common.addRowTop')
      },
      {
        type: 'button',
        key: 'add-row-bottom',
        children: (<RemixIcon icon="ri-insert-row-bottom"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().addRowAfter().run(),
        hint: t('common.addRowBottom')
      },
      {
        type: 'button',
        key: 'delete-row',
        children: (<RemixIcon icon="ri-delete-row"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().deleteRow().run(),
        hint: t('common.deleteRow')
      },
      'divider',
      {
        type: 'button',
        key: 'merge-cells',
        children: (<RemixIcon icon="ri-merge-cells-horizontal"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().mergeCells().run(),
        hint: t('common.mergeCells'),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        disabled: !editor.can().mergeCells()
      },
      {
        type: 'button',
        key: 'split-cell',
        children: (<RemixIcon icon="ri-split-cells-horizontal"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().splitCell().run(),
        hint: t('common.splitCell'),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        disabled: !editor.can().splitCell()
      },
      'divider',
      {
        type: 'button',
        key: 'toggle-heading',
        children: (<RemixIcon icon="ri-heading"/>),
        // @ts-expect-error 動態載入 Extension 無法得知 function 的 type
        onClick: () => editor.chain().focus().toggleHeaderCell().run(),
        hint: t('common.toggleHeaderCell'),
        className: editor?.isActive('tableHeader') ? 'active' : ''
      }
    ]
  },
  [editor, t, editor.state.selection])

  return (
    <BubbleMenu editor={props.editor} type="table" tippyOptions={{ maxWidth: 'none', offset: [0, 0], zIndex: 1 }}>
      <Paper>
        <Grid container alignItems="center">
          {controls.map((control) => control === 'divider'
            ? (<Divider orientation="vertical" flexItem className={classes.divider} key={uniqueId('divider-')}/>)
            : (
              <MenuButton
                onClick={control.onClick}
                key={control.key}
                hint={control.hint}
                className={control.className}
                disabled={control.disabled}
              >
                {control.children}
              </MenuButton>
              ))}
        </Grid>
      </Paper>
    </BubbleMenu>
  )
}

const TextEditorUrlDialog: React.FC<{
  open: boolean
  url: string
  handleOpen: (value: boolean) => void
  handleUrl: (url: string) => void
  editor: Editor | null
}> = (props) => {
  const { open, handleOpen, url, handleUrl, editor } = props
  const { t } = useT()

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={(v, reason) => { reason && handleOpen(false) }}>
      <DialogContent>
        <Input
          autoFocus
          type="text"
          placeholder={t('common.enterURLAndClickEnter')}
          fullWidth
          value={url}
          onChange={useCallback((e) => { handleUrl(e.target.value) }, [handleUrl])}
          onKeyPress={useCallback((e) => {
            if (e.key !== 'Enter' || !url) return
            (editor?.chain().focus().extendMarkRange('link') as any).setLink({ href: url }).run()
            handleOpen(false)
          }, [editor, handleOpen, url])}
        />
      </DialogContent>
    </Dialog>
  )
}

const TextEditorToolbar: React.FC<PropTypes> = (props) => {
  const { controls, extendControls, textContentType, colors, disabled = false } = props
  const classes = useStyles(props)
  const { editor } = useContext(EditorContext)
  const { t } = useT()
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')
  const handleOpen = useCallback((value: boolean) => { setOpen(value) }, [])
  const handleUrl = useCallback((url: string) => { setUrl(url) }, [])
  const controlsList = useMemo(() => controls.map(type => editor ? getControlComponent(type, t, editor, textContentType, colors, classes, handleOpen, handleUrl, disabled) : null), [t, controls, classes, editor, textContentType, colors, disabled, handleOpen, handleUrl])
  const extendControlsList = useMemo(() => extendControls?.map(type => editor ? getControlComponent(type, t, editor, textContentType, colors, classes, handleOpen, handleUrl, disabled) : null), [t, extendControls, classes, editor, textContentType, colors, disabled, handleOpen, handleUrl])

  return (
    <>
      <TextEditorUrlDialog
        open={open}
        url={url}
        handleOpen={handleOpen}
        handleUrl={handleUrl}
        editor={editor}
      />
      <Grid
        container
        direction="row"
        className={classes.menuBar}
      >
        { controlsList }
        { extendControlsList }
      </Grid>
      { editor && <TableBubbleMenu editor={editor} {...props} /> }
    </>
  )
}

export default React.memo(TextEditorToolbar)
