import React, { useState, useEffect, useCallback, useMemo, useContext, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormField from '../../default/form/FormField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import FormStateProvider from '../../default/form/FormStateProvider'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty, createValidateRange } from '../../../utils/default/Validator'
import { Observable } from 'rxjs'
import { newYear2025CategoryType } from '@golden/gdk-campaign'
import activityNewYear2025CategoryName from '../../../constants/admin/activityNewYear2025DrawCategoryName'

export interface FormType {
  account: string
  category: newYear2025CategoryType
  ticket_count: number
  memo: string
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))
const initialForm: InitialFormFunc<FormType> = (initialValue) => ({
  account: '',
  category: newYear2025CategoryType.LIVE,
  ticket_count: 1,
  memo: '',
  ...initialValue
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const AccountInput: React.FC<{ getCount: (data: { account: string, category: number }) => Observable<{ count: number }> }> = (props) => {
  const [count, setCount] = useState<string | number>('-')
  const { value } = useContext(FormContext)
  const { getCount } = props
  const { t } = useT()

  useEffect(() => {
    if (value.account !== '') {
      const validationSubscription = getCount({ account: value.account, category: value.category })
        .subscribe({
          next: (res) => {
            setCount(res.count)
          },
          error: () => {
            setCount('-')
          }
        })

      return () => {
        validationSubscription.unsubscribe()
      }
    } else {
      setCount('-')
    }
  }, [value.account, getCount, value.category])

  return (
    <FormField<FormType, TextFieldProps>
      context={FormContext}
      component={TextField}
      name="account"
      fullWidth
      required
      label={t('common.playerAccount')}
      placeholder={t('placeholder.inputPlayerAccount')}
      helperText={`${t('common.currentNoUseCount')}${count}`}
    />
  )
}

const ActivityNewYear2025SentDialog: React.FC<{ open: boolean, onClose: () => void, onSubmit: (form: FormType) => FormType, getCount: (data: { account: string, category: number }) => Observable<{ count: number }> }> = (props) => {
  const { open, onClose, onSubmit, getCount } = props
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const validation: FormValidation<FormType> = useMemo(() => {
    return {
      account: [{
        func: createValidateNotEmpty('account', t),
        when: ['beforeClickSubmit']
      }],
      category: [],
      ticket_count: [
        {
          func: createValidateRange('ticket_count', (value: unknown) => (value as number), 'moreThan', 0, t('error.sentLimit', { count: 4 })),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange('ticket_count', (value: unknown) => (value as number), 'lessEqual', 4, t('error.sentLimit', { count: 4 })),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: []
    }
  }, [t])

  const getValueFromEvent: ValueGetter<FormType> = {
    account: getValueFromChangeEvent,
    category: getValueFromChangeEvent,
    ticket_count: getValueFromChangeEvent,
    memo: getValueFromChangeEvent
  }
  const defaultForm = useMemo(() => initialForm(), [])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const categoryOptions = useMemo(() => ([
    { name: t(activityNewYear2025CategoryName[newYear2025CategoryType.LIVE]), value: newYear2025CategoryType.LIVE },
    { name: t(activityNewYear2025CategoryName[newYear2025CategoryType.VIDEO_AND_CARD]), value: newYear2025CategoryType.VIDEO_AND_CARD },
    { name: t(activityNewYear2025CategoryName[newYear2025CategoryType.SPORT_AND_ESPORT]), value: newYear2025CategoryType.SPORT_AND_ESPORT }
  ]), [t])

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('common.sentByHand')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <AccountInput getCount={getCount} />
              </Grid>
              <Grid item>
                <FormField<FormType, DropDownProps>
                  context={FormContext}
                  component={DropDown}
                  name="category"
                  options={categoryOptions}
                  label={t('common.gameCategory')}
                  fullWidth
                />
              </Grid>
              <Grid item></Grid>
              <Grid item>
                <FormField<FormType, DropDownProps>
                  context={FormContext}
                  component={DropDown}
                  name="ticket_count"
                  options={[{ name: '1', value: 1 }, { name: '2', value: 2 }, { name: '3', value: 3 }, { name: '4', value: 4 }]}
                  label={t('common.sentCount')}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormField<FormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="memo"
                  fullWidth
                  label={t('common.memo')}
                  placeholder={t('placeholder.inputSentByHandMemo')}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                variant="contained"
                color="secondary"
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {t('common.confirm')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(ActivityNewYear2025SentDialog)
