import React, { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import FetchBtiDialog from './FetchBtiDialog'

interface PropTypes {
  disabled?: boolean
}

const FetchBtiButton: React.FC<PropTypes> = (props) => {
  const { disabled } = props
  const [open, setOpen] = useState<boolean>(false)
  const classes = useCommonStyles()
  const { t } = useT()
  const handleOpen = useCallback(() => { setOpen(true) }, [])
  const handleClose = useCallback(() => { setOpen(false) }, [])
  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        className={classes.blueGradualOutlineButton}
        classes={{
          disabled: classes.blueGradualOutlineDisabledButton
        }}
        onClick={handleOpen}
      >
        {t('common.purchaseId')}
      </Button>
      <FetchBtiDialog open={open} onClose={handleClose} />
    </React.Fragment>
  )
}

export default React.memo(FetchBtiButton)
