import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import ManagerPermissionEditTable, { PropTypes as ManagerPermissionEditTableProps } from './ManagerPermissionEditTable'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { FormPropType, getValueFromChangeEvent, getValueFromValue, FormError } from '../../../utils/default/FormHelper'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty, createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import RequiredText from '../../default/form/RequiredText'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { Path } from '../route/route'
import { isEqual } from '@golden/utils'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { PageFlowType, usePageFlow } from '../../../utils/default/PageFlowHook'
import { PermissionType, RoleTreeItem } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export interface RoleFormType {
  name: string
  parentId: number
  permissions: PermissionType[]
}

export const initialForm: InitialFormFunc<RoleFormType> = (defaultForm) => ({
  name: '',
  parentId: 0,
  permissions: [],
  ...defaultForm
})

const getValueFromEvent: ValueGetter<RoleFormType> = {
  name: getValueFromChangeEvent,
  parentId: getValueFromChangeEvent,
  permissions: getValueFromValue
}

const inputProps = {
  maxLength: 10
}

interface PropTypes extends FormPropType<RoleFormType> {
  roleId?: number
  titleText: string
  okText: string
  formError?: Partial<FormError<RoleFormType>>
  isUpdate?: boolean
}

export const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const ParentIdDropdown: React.FC<{
  roleId?: number
  pageFlow: PageFlowType
  disabled?: boolean
}> = (props) => {
  const { roleId, pageFlow, disabled = false } = props
  const { t } = useT()
  const gdk = useGDK()
  const { value: form, dispatch } = useContext(FormContext)
  const globalDialog = useGlobalDialog()
  const dialogClasses = useDialogStyles()
  const me = useGDKStore.admin.me()

  const [nodes, setNodes] = useState<RoleTreeItem[]>([])
  useGetDataByPayload({
    canLoadData: !!me,
    payload: me?.role_id ?? 0,
    gdkFunc: (payload) => gdk.permissionRole.getRoleTree(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: RoleTreeItem[]) => {
      setNodes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const parentOptions = useMemo(() => nodes
    .filter((el) => el.id !== roleId)
    .map((el) => ({ name: el.name, value: el.id })), [nodes, roleId])

  const [parentId, setParentId] = useState(form.parentId)
  const handleChange = useCallback((event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    setParentId(event.target.value as number)
    if (!form.permissions.length) {
      dispatch({ type: 'set', value: { ...form, parentId: event.target.value as number } })
      return true
    }
    globalDialog.setConfig(createGlobalDialogConfig({
      message: (<Grid container direction="column" alignItems="center">
      <span className={dialogClasses.title}>{t('dialog.confirmChangeSupervisorTitle')}</span>
      <span className={dialogClasses.text}>{t('dialog.confirmChangeSupervisorContent')}</span>
    </Grid>),
      showCancel: true,
      showIcon: false
    }))
    globalDialog.setOpen({
      id: 'confirmChangeSupervisor',
      value: true,
      isOK: true
    })
    return false
  }, [dialogClasses.text, dialogClasses.title, form.permissions.length, globalDialog, parentId, t])
  useDialogFlow(globalDialog, 'confirmChangeSupervisor', () => {
    if (!isEqual(form, { ...form, parentId })) dispatch({ type: 'set', value: { ...form, parentId } })
  }, [form, parentId])

  return (
    <FormField<RoleFormType, DropDownProps>
      context={FormContext}
      component={DropDown}
      name="parentId"
      options={parentOptions}
      label={t('common.immediateSupervisor')}
      fullWidth
      onChange={handleChange}
      disabled={disabled}
    />
  )
}

const EditRoleForm: React.FC<PropTypes> = (props) => {
  const { roleId, defaultValue, onSubmit, titleText, okText, formError, isUpdate = false } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const formErrorName = useMemo(() => formError?.name, [formError])

  const validation: FormValidation<RoleFormType> = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty<RoleFormType>('name', t),
          when: ['change', 'blur', 'beforeClickSubmit']
        },
        {
          func: (value, _, lastSubmitForm, error) => {
            if (formErrorName && (value as string) === lastSubmitForm.name) {
              const message = formErrorName.replace('name ', '')
              return createErrorResult('name', message)
            }
            if (formErrorName === error?.name) {
              return createCorrectResult('name')
            }
            if (error) return error
            return {}
          },
          when: ['rerender', 'blur']
        },
        {
          func: (value: string, form) => {
            if (isEqual(form.permissions.sort((a, b) => a - b), defaultValue.permissions.sort((a, b) => a - b)) && value === defaultValue.name) {
              return createErrorResult('permissions', '')
            }
            return createCorrectResult('permissions')
          },
          when: ['beforeClickSubmit']
        }
      ],
      parentId: [],
      permissions: [
        {
          func: (value: number[]) => {
            if (value.length === 0) {
              return createErrorResult('permissions', t('error.mustNotEmpty'))
            }
            return createCorrectResult('permissions')
          },
          when: ['change', 'blur', 'beforeClickSubmit']
        },
        {
          func: (value: number[], form) => {
            if (isEqual(value.sort((a, b) => a - b), defaultValue.permissions.sort((a, b) => a - b)) && form.name === defaultValue.name) {
              return createErrorResult('permissions', '')
            }
            return createCorrectResult('permissions')
          },
          when: ['beforeClickSubmit']
        }
      ]
    } as FormValidation<RoleFormType>
  }, [t, formErrorName, defaultValue])

  if (!defaultValue.parentId) return null
  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <ScrollablePaper marginX={5}>
          <Box padding={4} width="100%">
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {titleText}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <FormField<RoleFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="name"
                  label={t('common.role')}
                  inputProps={inputProps}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <ParentIdDropdown roleId={roleId} pageFlow={pageFlow} disabled={isUpdate}/>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <FormField<RoleFormType, ManagerPermissionEditTableProps>
                  context={FormContext}
                  component={ManagerPermissionEditTable}
                  name="permissions"
                />
              </Grid>
            </Grid>
            <RequiredText />
          </Box>
        </ScrollablePaper>
        <Box paddingTop={3} marginX={5}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <MuiButton
                component={Link}
                className={classes.greyButton}
                to={Path.ROLE_MANAGEMENT}
              >
                {t('common.cancel')}
              </MuiButton>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={FormContext}
                component={Button}
                className={classes.purpleGradualButton}
                type="submit"
              >
                {okText}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Box>
      </FormStateProvider>
    </LoadingAndErrorFrame>
  )
}

export default React.memo(EditRoleForm)
