import React, { createContext, useState, useMemo, useContext } from 'react'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { ManualDepositType, ManualDepositForm, EffectiveCash, GDKError } from '@golden/gdk-admin'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromCheckboxEvent, getCashInputProps, getCodeInputProps } from '../../../utils/default/FormHelper'
import { RequestContext } from '../../../views/admin/manual/ManualDepositCreatePage'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney } from '../../../utils/default/TableHelper'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createCorrectResult, createErrorResult, createValidateCash, createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../default/form/FormField'
import NumberInput from '../../default/form/NumberInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import manualDepositName from '../../../constants/admin/manualDepositName'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { BcMath } from '@golden/bcmath'

export interface FormType {
  id: number
  account: string
  type: ManualDepositType
  cash: string
  effectiveCash: string
  memo: string
  code: string
  isExcludingSpecifiedGames: boolean
  multiple: string
  bindingDeposits: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  id: 0,
  account: '',
  type: ManualDepositType.EVENT,
  cash: '',
  effectiveCash: '',
  memo: '',
  code: '',
  isExcludingSpecifiedGames: true,
  multiple: '3',
  bindingDeposits: '',
  ...defaultForm
})

export const formToRequest = (form: FormType): ManualDepositForm => ({
  user_id: form.id,
  deposit_type: form.type,
  cash: Number(form.cash),
  multiple: Number(form.multiple),
  effective_cash_needed: form.effectiveCash,
  memo: form.memo,
  code: form.code,
  is_excluding_specified_games: form.isExcludingSpecifiedGames,
  binding_deposits: form.bindingDeposits
})

const getValueFromEvent: ValueGetter<FormType> = {
  id: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  type: getValueFromChangeEvent,
  cash: getValueFromChangeEvent,
  effectiveCash: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  code: getValueFromChangeEvent,
  isExcludingSpecifiedGames: getValueFromCheckboxEvent,
  multiple: getValueFromChangeEvent,
  bindingDeposits: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<FormType> = {
  account: (value, form) => ({
    ...initialForm(),
    id: form.id,
    account: value
  }),
  type: (value, form) => ({
    ...form,
    type: value,
    multiple: (value === ManualDepositType.EVENT) ? '3' : '1',
    isExcludingSpecifiedGames: (value === ManualDepositType.EVENT),
    bindingDeposits: ''
  }),
  cash: (value, form) => ({
    ...form,
    cash: value,
    effectiveCash: (value && form.multiple) ? BcMath.base(value).mul(form.multiple).get() : ''
  }),
  multiple: (value, form) => ({
    ...form,
    multiple: value,
    effectiveCash: (value && form.cash) ? BcMath.base(value).mul(form.cash).get() : ''
  })
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const cashBetInputProps = {
  ...getCashInputProps(),
  decimalScale: 2,
  maxLength: undefined
}

const multipleInputProps = {
  allowNegative: false,
  decimalScale: 0,
  maxLength: undefined
}

const codeInputProps = getCodeInputProps()

const memoFormHelperTextProps = { error: true }

const NeededEffectiveCashInput: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, error } = useContext(FormContext)

  return (
    <NumberInput
      label={`${t('common.create')}${t('common.deltaEffectiveCash')}`}
      value={value.effectiveCash}
      inputProps={ { ...cashBetInputProps, decimalScale: 4 } }
      error={error.effectiveCash !== null}
      helperText={error.effectiveCash ?? ''}
      fullWidth
      required
      disabled
    />
  )
})

const BindingDepositsInput: React.FC<{ result: EffectiveCash }> = React.memo((props) => {
  const { t } = useT()
  const classes = useCommonStyles()
  const { result } = props
  const bindingDepositsInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      placeholder: t('placeholder.inputBindingDeposits')
    }
  }), [classes.memoField, t])
  const { value } = useContext(FormContext)

  return value.type === ManualDepositType.EVENT
    ? (<Grid item>
    <Box style={{ color: 'grey', opacity: 0.6 }} marginBottom={2}>
      {t('common.exchangedDepositOrderHelperText')}
    </Box>
    <Typography>{`${t('common.exchangedDepositOrderNo')}`}</Typography>
    <FormField<FormType, TextFieldProps>
      context={FormContext}
      component={TextField}
      name="bindingDeposits"
      multiline
      fullWidth
      type="text"
      margin="normal"
      variant="outlined"
      InputProps={bindingDepositsInputProps}
      disabled={result.is_suspended}
    />

  </Grid>)
    : null
})

interface PropTypes {
  handleSubmit: (form: FormType) => FormType
  handleCancel: () => void
  loading: boolean
}

const ManualDepositCreateForm: React.FC<PropTypes> = (props) => {
  const { handleSubmit, handleCancel, loading } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const [context] = useContext(RequestContext)
  const [result, setResult] = useState<EffectiveCash>({
    user_id: 0,
    account: '',
    cash: '0.0000',
    buckets: [],
    is_suspended: false
  })
  const defaultForm = useMemo(() => initialForm({ account: context.account, id: result.user_id }), [context.account, result.user_id])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const payload = useMemo(() => ({ account: context.account }), [context])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.withdraw.getEffectiveCash(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: EffectiveCash) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: (error: GDKError) => {
      pageFlow.setGDKError(error)
    },
    canLoadData: !!payload.account
  })
  const validation = useMemo(() => {
    return {
      id: [],
      account: [],
      type: [
        {
          func: createValidateNotEmpty('type', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      cash: [
        {
          func: createValidateNotEmpty('cash', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('cash', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value) => {
            if (Number(value) === 0) return createErrorResult('cash', t('error.mustNotZero'))
            return createCorrectResult('cash')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      multiple: [
        {
          func: createValidateNotEmpty('multiple', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value) => {
            if (Number(value) === 0) return createErrorResult('multiple', t('error.mustNotZero'))
            if (!Number.isInteger(Number(value))) return createErrorResult('multiple', t('error.mustBeInt'))
            return createCorrectResult('multiple')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      effectiveCash: [
        {
          func: createValidateNotEmpty('effectiveCash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [
        {
          func: createValidateNotEmpty('memo', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isExcludingSpecifiedGames: [],
      bindingDeposits: []
    } as FormValidation<FormType>
  }, [t])
  const memoInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      placeholder: t('placeholder.inputPlayerMemo')
    }
  }), [classes.memoField, t])

  const options = useMemo(() => {
    return Object.keys(manualDepositName)
      .map((key) => ({ name: t(manualDepositName[key as ManualDepositType]), value: key }))
  }, [t])
  if (!payload.account || context.isBatch) return null
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={5}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.playerInformation')}
                  </Typography>
                </Box>
              </Grid>
              {result.account
                ? (
                <React.Fragment>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          label={t('common.playerAccount')}
                          value={result.account}
                          disabled
                          fullWidth
                          error={result.is_suspended}
                          helperText={result.is_suspended ? t('error.isSuspended') : ''}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          label={t('common.mainAccount')}
                          value={formatMoney(result.cash)}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, DropDownProps>
                          context={FormContext}
                          component={DropDown}
                          name="type"
                          label={t('common.depositObject')}
                          options={options}
                          fullWidth
                          required={!result.is_suspended}
                          disabled={result.is_suspended}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="cash"
                          label={t('common.depositCash')}
                          inputProps={cashBetInputProps}
                          fullWidth
                          required={!result.is_suspended}
                          disabled={result.is_suspended}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={1}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="multiple"
                          label={`${t('common.deltaEffectiveCash')}${t('common.multiple')}`}
                          inputProps={multipleInputProps}
                          FormHelperTextProps={memoFormHelperTextProps}
                          fullWidth
                          required={!result.is_suspended}
                          disabled={result.is_suspended}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <NeededEffectiveCashInput />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>{`${t('common.playerMemo')}*`}</Typography>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="memo"
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="outlined"
                      InputProps={memoInputProps}
                      disabled={result.is_suspended}
                    />
                  </Grid>
                  <BindingDepositsInput result={result} />
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="code"
                          fullWidth
                          required={!result.is_suspended}
                          inputProps={codeInputProps}
                          label={t('common.googleCode')}
                          placeholder={t('placeholder.inputGoogleCode2')}
                          disabled={result.is_suspended}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <RequiredText />
                  </Grid>
                </React.Fragment>
                  )
                : (
                <Box display="flex" justifyContent="center" padding={1} overflow="auto">
                  <Typography variant="h3">
                    {t('common.noData')}
                  </Typography>
                </Box>
                  )}
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
      <Box paddingTop={2}>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleCancel}
              className={classes.greyButton}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              disabled={loading}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {loading
                ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={24} />
                </Box>
                  )
                : t('common.confirmCreate')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(ManualDepositCreateForm)
