import React, { useCallback, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { DialogPayloadContext } from '../../../views/admin/player/PlayerJumpShipPage'
import { PlayerJumpShipItem } from '@golden/gdk-admin'

interface PropTypes {
  item: PlayerJumpShipItem
}

const JumpShipCancelButton: React.FC<PropTypes> = (props) => {
  const { item } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const [, setDialog] = useContext(DialogPayloadContext)
  const handleClick = useCallback(() => {
    setDialog({
      open: true,
      item
    })
  }, [item])
  return (
    <Button
      className={classes.blueGradualOutlineButton}
      onClick={handleClick}
    >
      {t('common.cancel')}
    </Button>
  )
}

export default React.memo(JumpShipCancelButton)
