import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ActivityManagementSortDialog from './ActivityManagementSortDialog'
import ActivityManagementTagsDialog from './ActivityManagementTagsDialog'

const AnnouncementListToolbar: React.FC<{ reload: () => void }> = (props) => {
  const { reload } = props
  const [tagsDialogOpen, setTagsDialogOpen] = useState<boolean>(false)
  const [sortDialogOpen, setSortDialogOpen] = useState<boolean>(false)
  const handleTagsDialogClose = useCallback(() => { setTagsDialogOpen(false) }, [])
  const handleSortDialogClose = useCallback(() => { setSortDialogOpen(false) }, [])

  const classes = useCommonStyles()
  const { t } = useT()

  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)

  const writable = useChecker()

  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2}>
      {writable && (
        <React.Fragment>
          <Grid item>
            <Button
              onClick={() => { setTagsDialogOpen(true) }}
              className={classes.purpleGradualOutlineButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingX={2.5} paddingY={0.5}>
                {t('common.tag')}
              </Box>
            </Button>
            <ActivityManagementTagsDialog
              reload={reload}
              open={tagsDialogOpen}
              onClose={handleTagsDialogClose}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => { setSortDialogOpen(true) }}
              className={classes.purpleGradualOutlineButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingX={2.5} paddingY={0.5}>
                {t('common.activitySort')}
              </Box>
            </Button>
            <ActivityManagementSortDialog
              reload={reload}
              open={sortDialogOpen}
              onClose={handleSortDialogClose}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={(event) => { setMenuEl(event.currentTarget) }}
              className={classes.purpleGradualButton}
              classes={{ disabled: classes.disabledButton }}
            >
              <Box paddingX={2.5} paddingY={0.5}>
                {t('common.create')}
              </Box>
            </Button>
            <Menu
              anchorEl={menuEl}
              keepMounted
              open={Boolean(menuEl)}
              onClose={() => { setMenuEl(null) }}
            >
              <MenuItem
                component={Link}
                to={allRoute.activityManagementLayoutCreate.encodePath({ search: {}, param: {} })}
              >
                <Box paddingX={2.5} paddingY={0.5}>
                  { t('activity.activity') }
                </Box>
              </MenuItem>
              <MenuItem
                component={Link}
                to={allRoute.activityManagementBannerCreate.encodePath({ search: {}, param: {} })}
              >
                <Box paddingX={2.5} paddingY={0.5}>
                  { t('activity.banner') }
                </Box>
              </MenuItem>
            </Menu>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

export default React.memo(AnnouncementListToolbar)
