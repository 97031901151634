import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GDKError, PlayerRankType, PlayerRankUser } from '@golden/gdk-admin'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import DropDown from '../../default/form/DropDown'
import RequiredText from '../../default/form/RequiredText'
import playerRankName from '../../../constants/default/playerRankName'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

interface PropTypes {
  reload: (account: string) => void
}

const PlayerRankSetButton: React.FC<PropTypes> = (props) => {
  const { reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const [open, setOpen] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [account, setAccount] = useState<string>('')
  const [rank, setRank] = useState<PlayerRankType>(PlayerRankType.SILVER)
  const [data, setData] = useState<PlayerRankUser | null>(null)
  const [error, setError] = useState<string | null>(null)
  const rankOptions = useMemo(() => {
    return Object.keys(playerRankName).map((item) => ({
      name: t(playerRankName[item as PlayerRankType]),
      value: item
    }))
  }, [t])
  const handleAccountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => { setAccount(event.target.value) }, [])
  const handleLayerIdChange = useCallback((event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => { setRank(event.target.value as PlayerRankType) }, [])
  const handleClose = useCallback(() => {
    setAccount('')
    setRank(PlayerRankType.SILVER)
    setData(null)
    setError(null)
    setLoadingData(false)
    setOpen(false)
  }, [])
  useEffect(() => {
    if (account !== '') {
      setData(null)
      setLoadingData(true)
      const subscription = gdk.player.getPlayerRankUser(account).subscribe({
        next: (res) => {
          setLoadingData(false)
          setData(res)
          setRank(res.rank)
          setError(null)
        },
        error: (err: GDKError) => {
          setLoadingData(false)
          setError(err.message)
        }
      })
      return () => { subscription.unsubscribe() }
    }
  }, [account, gdk])
  const payload = useMemo(() => ({ id: data?.id ?? 0, rank }), [data, rank])
  const handleSubmit = useDialogHandleClick({
    dialogId: 'setRank',
    globalDialog,
    payload,
    gdkFunc: (payload) => {
      setOpen(false)
      return gdk.player.setPlayerRank(payload.id, payload.rank)
    },
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmSetPlayerRank')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.setPlayerRankSuccess')
    }),
    getFailDialogConfig: (error) => {
      handleClose()
      return createGlobalDialogConfig({
        variant: 'error',
        showIcon: true,
        showCancel: false,
        message: error
      })
    },
    afterSuccessDialog: () => {
      reload(account)
      handleClose()
    },
    afterSuccessDialogDependencies: [account]
  })
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        className={commonClasses.purpleGradualButton}
        onClick={useCallback(() => { setOpen(true) }, [])}
      >
        {t('common.setPlayerRank')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('common.setPlayerRank')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label={t('common.playerAccount')}
                  placeholder={t('placeholder.inputPlayerAccount')}
                  value={account}
                  onChange={handleAccountChange}
                  helperText={error ?? ''}
                  error={error !== null}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <DropDown
                  label={t('common.playerRank')}
                  options={rankOptions}
                  disabled={data === null}
                  value={rank}
                  onChange={handleLayerIdChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                disabled={loadingData || data === null}
                onClick={handleSubmit}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {loadingData && (<LinearProgress />)}
      </Dialog>
    </Box>
  )
}

export default React.memo(PlayerRankSetButton)
