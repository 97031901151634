import React, { useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetDataByPayload, usePaginationClickAndChangeUrl, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest } from '../../../views/admin/forestage/AnnouncementPersonalPage'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AnnouncementPersonal, PaginationRes } from '@golden/gdk-admin'
import { createDefaultPaginationData, createTableData, formatDateTime } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import { renderContent } from '@golden/tiptap-react'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    width: 360,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  content: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: 'solid 1px #e7e7e7',
    width: '500px'
  }
}))

interface RowType {
  id: number
  sentAt: React.ReactElement
  user: string | React.ReactElement
  title: string
  content: React.ReactElement
  messagePeople: React.ReactElement
  operator: string
}

const UserListTable: React.FC<{ id: number }> = ({ id }) => {
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<PaginationRes<Array<{ created_at: Date, receiver: string }>>>(createDefaultPaginationData([]))
  const handlePagination = useCallback((_, page) => { setPage(page) }, [])

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  useGetDataByPayload({
    payload: page,
    gdkFunc: (page) => gdk.notification.getPersonalNotificationUserList(id, { page }),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<{ created_at: Date, receiver: string }>>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.receiver,
        receiver: item.receiver,
        createdAt: formatDateTime(item.created_at)
      }
    })
  }, [list])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        receiver: {
          label: t('common.playerAccount'),
          value: 'receiver',
          align: 'center'
        },
        createdAt: {
          label: t('common.sendAt'),
          value: 'createdAt',
          align: 'center'
        }
      },
      [
        'createdAt',
        'receiver'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <Box width={500}>
        <CoreTable
          classes={tableClasses}
          data={data}
          total={list.total}
          showPagination
          page={page}
          onChangePage={handlePagination}
        />
      </Box>
    </LoadingAndErrorFrame>
  )
}

const AnnouncementPersonalTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const [list, setList] = useState<PaginationRes<AnnouncementPersonal[]>>(createDefaultPaginationData([]))
  const globalDialog = useGlobalDialog()

  const openReceiverDetailDialog = useCallback((id: number) => {
    globalDialog.setConfig(createGlobalDialogConfig({
      notUseTypo: true,
      message: <UserListTable id={id} />,
      showIcon: false,
      showCancel: false
    }))
    globalDialog.setOpen({
      id: `openUserList-${id}`,
      value: true,
      isOK: false
    })
  }, [globalDialog])

  const openContentDialog = useCallback((payload: { id: number, message: React.ReactElement }) => {
    const { id, message } = payload
    globalDialog.setConfig(createGlobalDialogConfig({
      message,
      showIcon: false,
      showCancel: false
    }))
    globalDialog.setOpen({
      id: `openContent-${id}`,
      value: true,
      isOK: false
    })
  }, [globalDialog])

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.notification.getPersonalNotificationList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AnnouncementPersonal[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    },
    canLoadData: request !== undefined
  })
  const [rows, setRows] = useState<RowType[]>([])
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(list.data.map(async (entry) => {
      const content = await renderContent(entry.content)
      return {
        id: entry.id,
        sentAt: (<DateTime time={entry.created_at} />),
        user: entry.has_more
          ? (
          <Box className={commonClasses.anchor} onClick={() => { openReceiverDetailDialog(entry.id) }}>
            { entry.receiver }
          </Box>
            )
          : entry.receiver,
        title: entry.title,
        content: (
          <Box
            className={clsx(commonClasses.anchor, classes.ellipsis)}
            onClick={() => {
              openContentDialog({
                id: entry.id,
                message: (
              <React.Fragment>
                <Box>
                  <Typography variant="h4" align='center'>{entry.title}</Typography>
                </Box>
                <Box className={classes.content} style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: content }}></Box>
              </React.Fragment>)
              })
            }}
          >
            { content.replace(/<[^>]*>/g, '') }
          </Box>
        ),
        messagePeople: (
          <Box>{ (entry.send_count === null && entry.read_count === null) ? '-' : `${entry.read_count ?? 0}/${entry.send_count ?? 0}`}</Box>
        ),
        operator: entry.updated_by
      } as RowType
    })))
  }, [list.data, commonClasses.anchor, classes.ellipsis, classes.content, openReceiverDetailDialog, openContentDialog])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        sentAt: {
          label: t('common.sentAt'),
          value: 'sentAt',
          align: 'center'
        },
        user: {
          label: t('common.sendObject'),
          value: 'user',
          align: 'center'
        },
        title: {
          label: t('common.title'),
          value: 'title',
          align: 'left'
        },
        content: {
          label: t('common.content'),
          value: 'content',
          align: 'left',
          width: 360
        },
        messagePeople: {
          label: t('common.siteMessagePeople'),
          value: 'messagePeople',
          align: 'center'
        },
        operator: {
          label: t('common.updateBy'),
          value: 'operator',
          align: 'center'
        }
      },
      [
        'sentAt',
        'user',
        'title',
        'content',
        'messagePeople',
        'operator'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestagePersonal.encodePath
  })
  if (request === undefined) return null
  return (
    <ScrollablePaper marginX={6}>
      <Box padding={4}>
        <Box paddingY={2}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
              loading={pageFlow.status.loading}
            />
          </LoadingAndErrorFrame>
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AnnouncementPersonalTable)
