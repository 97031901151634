import React from 'react'
import clsx from 'clsx'
import { InternalWithdrawAccount } from '@golden/gdk-admin'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const ButtonsBase: React.FC<{
  handleDelete: () => void
}> = React.memo((props) => {
  const {
    handleDelete
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Button
      size="small"
      className={classes.pinkGradualOutlineButton}
      onClick={handleDelete}
    >
      {t('common.delete')}
    </Button>
  )
})

interface PropTypes {
  item: InternalWithdrawAccount
  reload: () => void
}

const InternalWithdrawAccountButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteInternalWithdraw-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text} align="center">{t('dialog.confirmDeleteInternalWithdraw')}</Typography>
          <Typography className={clsx(classes.purpleWord, dialogClasses.text)} align="center">
            [{item.real_name}] [{item.bank_account}]
          </Typography>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteInternalWithdrawSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.deleteInternalWithdraw(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  return (
    <ButtonsBase
      handleDelete={handleDelete}
    />
  )
}

export default React.memo(InternalWithdrawAccountButtons)
