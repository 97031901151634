import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { PaginationReq, AgentWalletsHistoryQuery, AgentWalletTransactionType, AgentAccountType, AgentWalletTransferType } from '@golden/gdk-admin'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { endOfDay, getTime, isAfter, isBefore, startOfDay } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import NumberInput from '../../default/form/NumberInput'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../default/form/MultipleSelector'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, acceptUndefined, convertEmptyToUndefined, getTimeFromDateInputValue, getValueFromChangeEvent, getValueFromValue, parseFloat, getCashInputProps, parseArray } from '../../../utils/default/FormHelper'
import allRoutes, { Path } from '../../../components/admin/route/route'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { setToday, setYesterday, setThisMonth, setLastMonth } from '../../../utils/default/TimeHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import agentAccountTypeName from '../../../constants/default/agentAccountTypeName'
import agentWalletTransactionTypeName from '../../../constants/default/agentWalletTransactionTypeName'
import { isUndefined, omitBy } from '@golden/utils'
import agentWalletTransferTypeName from '../../../constants/default/agentWalletTransferTypeName'

export type AgentWalletsHistoryRequest = PaginationReq & AgentWalletsHistoryQuery

export interface AccountAgentWalletsFormType {
  time: DateInputValue
  account: string
  accountType: AgentAccountType | '--'
  transferType: AgentWalletTransferType[]
  transactionType: AgentWalletTransactionType[]
  transferNumber: string
  minAmount: string
  maxAmount: string
}

export const searchToRequest: SearchToRequestFunc<AgentWalletsHistoryRequest> = (search) => {
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt
    )),
    account_type: acceptUndefined(search.account_type, pipe()),
    transaction_type: acceptUndefined(search.transaction_type, pipe(
      parseArray
    )),
    transfer_type: acceptUndefined(search.transfer_type, pipe(
      parseArray
    )),
    min_amount: acceptUndefined(search.min_amount, parseFloat),
    max_amount: acceptUndefined(search.max_amount, parseFloat)
  } as AgentWalletsHistoryRequest
  return omitBy(converted, isUndefined) as AgentWalletsHistoryRequest
}

export const initialForm: InitialFormFunc<AccountAgentWalletsFormType> = (defaultForm) => ({
  time: {
    start: null,
    end: null
  },
  account: '',
  accountType: '--',
  transactionType: [],
  transferType: [],
  transferNumber: '',
  minAmount: '',
  maxAmount: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<AccountAgentWalletsFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  accountType: getValueFromChangeEvent,
  transactionType: getValueFromValue,
  transferType: getValueFromValue,
  transferNumber: getValueFromChangeEvent,
  minAmount: getValueFromChangeEvent,
  maxAmount: getValueFromChangeEvent
}

const formToRequest = (form: AccountAgentWalletsFormType): AgentWalletsHistoryRequest => {
  const converted = {
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    account: convertEmptyToUndefined(form.account),
    account_type: form.accountType === '--' ? undefined : form.accountType,
    transaction_type: form.transactionType.length ? form.transactionType : undefined,
    transfer_type: form.transferType.length ? form.transferType : undefined,
    transfer_number: convertEmptyToUndefined(form.transferNumber),
    min_amount: convertEmptyToUndefined(form.minAmount),
    max_amount: convertEmptyToUndefined(form.maxAmount),
    page: 1
  } as AgentWalletsHistoryRequest
  return omitBy(converted, isUndefined) as AgentWalletsHistoryRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)
const cashInputProps = getCashInputProps()

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const shouldDisableStartDate = (day: Date | null): boolean => {
      if (day === null) return true
      return false
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (value.time.start === null) return false
      return isBefore(day, startOfDay(value.time.start))
    }

    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [value.time])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AccountAgentWalletsForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const writable = useChecker()

  const request = useRequestFromSearch({ searchToRequest })
  const defaultValue = useMemo(() => initialForm({
    time: {
      start: request?.start_at !== undefined ? new Date(request.start_at) : null,
      end: request?.end_at !== undefined ? new Date(request.end_at) : null
    },
    accountType: request?.account_type ?? '--',
    transactionType: request?.transaction_type ?? [],
    transferType: request?.transfer_type ?? [],
    transferNumber: request?.transfer_number ?? '',
    minAmount: request?.min_amount?.toString() ?? '',
    maxAmount: request?.max_amount?.toString() ?? '',
    ...request
  }), [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.agentWalletHistory.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    account: [],
    accountType: [],
    transactionType: [],
    transferType: [],
    transferNumber: [],
    minAmount: [],
    maxAmount: []
  } as FormValidation<AccountAgentWalletsFormType>), [t])

  const accountTypeOptions = useMemo(() => [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(agentAccountTypeName).map((key) => ({ name: t(agentAccountTypeName[key as AgentAccountType]), value: key }))
  ), [t])

  const transactionTypeOptions = useMemo(() => [
    {
      name: t(agentWalletTransactionTypeName[AgentWalletTransactionType.RECHARGE]),
      value: AgentWalletTransactionType.RECHARGE
    },
    {
      name: t('common.agentWalletTransfer'),
      value: 'transfer'
    },
    {
      name: t(agentWalletTransactionTypeName[AgentWalletTransactionType.REVOKE]),
      value: AgentWalletTransactionType.REVOKE
    },
    {
      name: t(agentWalletTransactionTypeName[AgentWalletTransactionType.DEPOSIT]),
      value: AgentWalletTransactionType.DEPOSIT
    }
  ], [t])

  const transferTypeOptions = useMemo(() => Object.keys(agentWalletTransferTypeName)
    .map((key) => ({ name: t(agentWalletTransferTypeName[key as AgentWalletTransferType]), value: key })), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          {writable && (
            <Box paddingBottom={2}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    component={Link}
                    to={Path.ACCOUNT_AGENT_WALLET_REVOKE}
                    className={classes.purpleGradualButton}
                  >
                    {t('page.createRevoke')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    component={Link}
                    to={Path.ACCOUNT_AGENT_WALLET_RECHARGE}
                    className={classes.purpleGradualButton}
                  >
                    {t('page.createRecharge')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.agentWallet')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.account')}
                      placeholder={t('placeholder.inputAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="accountType"
                      label={t('common.accountType')}
                      options={accountTypeOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, MultipleSelectorProps>
                      context={FormContext}
                      component={MultipleSelector}
                      name="transactionType"
                      label={t('common.transactionType')}
                      options={transactionTypeOptions}
                      fullWidth
                    />
                  </Grid>
                  {/* TODO 轉帳類型 */}
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, MultipleSelectorProps>
                      context={FormContext}
                      component={MultipleSelector}
                      name="transferType"
                      label={t('common.transferType')}
                      options={transferTypeOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="transferNumber"
                      label={t('common.serialNumber')}
                      placeholder={t('placeholder.inputSerialNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="minAmount"
                      label={t('common.minAmount')}
                      placeholder={t('placeholder.inputMaxAmount')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AccountAgentWalletsFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="maxAmount"
                      label={t('common.maxAmount')}
                      placeholder={t('placeholder.inputMaxAmount')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AccountAgentWalletsForm)
