import { ChangeEvent } from 'react'
import { startOfDay, endOfDay } from 'date-fns'
import { DateInputValue } from '../../components/default/form/DateInput'
import { FileTextFieldValue } from '../../components/default/form/FileTextField'

export type FormError<T> = { [key in keyof T]: string | null }

export interface FormPropType <FormType> {
  defaultValue: FormType
  onSubmit: (form: FormType) => FormType
}

export function getValueFromChangeEvent<Value> (event: unknown) {
  return (event as ChangeEvent<{ value: Value }>).target.value
}

export function getValueFromCheckboxEvent (event: unknown) {
  return (event as ChangeEvent<HTMLInputElement>).target.checked
}

export function getNumberValueFromCheckboxEvent (event: unknown) {
  return (event as ChangeEvent<HTMLInputElement>).target.checked ? 1 : 0
}

export function getValueFromValue<Value> (event: unknown) {
  return event as Value
}

export function getTimeFromDateInputValue (event: unknown): DateInputValue {
  const start = (event as DateInputValue)?.start
  const end = (event as DateInputValue)?.end
  return {
    start: start ?? null,
    end: end ?? null
  }
}

export function getDateFromDateInputValue (event: unknown): DateInputValue {
  const start = (event as DateInputValue)?.start
  const end = (event as DateInputValue)?.end
  return {
    start: start ? startOfDay(start) : null,
    end: end ? endOfDay(end) : null
  }
}

export function getContentFromFile (event: unknown): string {
  return (event as FileTextFieldValue).content
}

export function createHandleChange<EventType extends { value: unknown }, FormData> (
  onFormChange: (changed: FormData) => void,
  otherValue: FormData,
  setError?: (error: FormError<FormData>) => void,
  error?: FormError<FormData>
) {
  return (label: keyof FormData) => (event: ChangeEvent<EventType>) => {
    if (setError && error?.[label] !== null) setError({ ...error, [label]: null } as FormError<FormData>)
    onFormChange({ ...otherValue, [label]: event.target.value })
  }
}

export function toggleOrder (
  sort: {
    sort_by: string
    order: 'desc' | 'asc'
  },
  column: string
): string {
  let order = sort.order
  if (column === sort.sort_by && sort.order === 'desc') order = 'asc'
  else if (column === sort.sort_by && sort.order === 'asc') order = 'desc'
  return order
}

export function pipe (...fns: Array<(...params: any[]) => any>) {
  return (x: any) => fns.reduce((y, fn) => fn(y), x)
}

export function convertEmptyToUndefined (value: string) {
  const str = value.trim()
  return str === '' ? undefined : str
}

export function convertZeroToUndefined (value: number) {
  return value === 0 ? undefined : value
}

export function convertFalseToUndefined (value: boolean) {
  return value || undefined
}

export function convertUndefinedToDefault (value: string, defaultValue: string) {
  return value ?? defaultValue
}

export function guaranteeNotUndefined<T> (value: T | undefined): T | never {
  if (value === undefined) throw new Error(`${String(value)} is undefined`)
  return value
}

export function guaranteeBetween (value: number, min: number, max: number): number | never {
  if (value < min || value > max) throw new Error(`${value} out of bound`)
  return value
}

export function guaranteeBeOneOf<T> (value: T, array: T[]): T | never {
  if (!array.includes(value)) throw new Error(`${String(value)} is not correct`)
  return value
}

export function guaranteeBeKey<K> (value: keyof K, array: Array<keyof K>): keyof K | never {
  if (!array.includes(value)) throw new Error(`${String(value)} is not correct`)
  return value
}

export function guaranteeBeOrder (value: string): string | never {
  return guaranteeBeOneOf(value, ['desc', 'asc'])
}

export function acceptUndefined<T> (value: string | undefined, func: (param: string) => T): T | undefined {
  if (value === undefined) return value
  return func(value)
}

export function parseInt (value: string | undefined): number | never {
  if (value === undefined) throw new Error(`${String(value)} is undefined`)
  const result = Number.parseInt(value)
  if (isNaN(result)) throw new Error(`${value} can't parse integer`)
  return result
}

export function parseBoolean (value: string | undefined): boolean | never {
  if (value === undefined) throw new Error(`${String(value)} is undefined`)
  if (value !== 'true' && value !== 'false') throw new Error(`${value} can't parse boolean`)
  return value === 'true'
}

export function parseFloat (value: string | undefined): number | never {
  if (value === undefined) throw new Error(`${String(value)} is undefined`)
  const result = Number.parseFloat(value)
  if (isNaN(result)) throw new Error(`${value} can't parse float`)
  return result
}

export function parseArray (value: string | undefined, separator: string = ','): string[] | never {
  if (value === undefined) throw new Error(`${String(value)} is undefined`)
  return value.split(separator)
}

export function getRandomPassword () {
  const randomNumber = () => Math.floor(Math.random() * 9) + 48
  const randomLowerCase = () => Math.floor(Math.random() * 26) + 97
  const randomUpperCase = () => Math.floor(Math.random() * 26) + 65
  const passwordChars = []
  const sites = [0, 1, 2, 3, 4, 5, 6, 7]
  for (let cnt = 0; cnt < 8; cnt++) {
    const index = Math.floor(Math.random() * sites.length)
    const site = sites.splice(index, 1)[0]
    let type = Math.floor(Math.random() * 3) + 1
    if (cnt === 0) type = 1
    if (cnt === 1) type = Math.floor(Math.random() * 2) + 2
    switch (type) {
      case 1:
        passwordChars[site] = randomNumber()
        break
      case 2:
        passwordChars[site] = randomUpperCase()
        break
      case 3:
        passwordChars[site] = randomLowerCase()
        break
    }
  }
  const password = passwordChars.map((char) => String.fromCharCode(char)).join('')
  return password
}

export function getCashInputProps () {
  return {
    thousandSeparator: true,
    decimalScale: 0,
    allowNegative: false,
    fixedDecimalScale: true
  }
}

export function getCodeInputProps () {
  return {
    allowLeadingZeros: true,
    decimalScale: 0,
    fixedDecimalScale: true,
    allowNegative: false,
    format: '### ###'
  }
}

export function getBankAccountInputProps () {
  return {
    allowLeadingZeros: true,
    decimalScale: 0,
    fixedDecimalScale: true,
    allowNegative: false,
    maxLength: 20
  }
}
