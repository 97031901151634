import React, { useMemo, useCallback } from 'react'
import { Bank } from '@golden/gdk-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiTextField from '@material-ui/core/TextField'
import MuiChip from '@material-ui/core/Chip'
import MuiCheckbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import useT from '../../i18ns/admin/useT'

export interface PropTypes {
  label?: string
  placeholder?: string
  defaultValue?: number[]
  value?: number[]
  onChange?: (value: number[]) => void
  disabled?: boolean
  fullWidth?: boolean
  onBlur?: () => void
  onFocus?: () => void
}

const icon = (<CheckBoxOutlineBlankIcon />)
const checkedIcon = (<CheckBoxIcon />)
const deleteIcon = (<CancelRoundedIcon />)

const TextField = React.memo(MuiTextField)
const Chip = React.memo(MuiChip)
const Checkbox: React.FC<{ selected: boolean, name: string }> = React.memo((props) => {
  const { selected, name } = props
  return (
    <Box paddingRight={2}>
      <MuiCheckbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
      />
      {name}
    </Box>
  )
})

const BanksInput: React.FC<PropTypes> = (props) => {
  const { label, placeholder, defaultValue, value, onChange, onBlur, onFocus, fullWidth, disabled } = props
  const { t } = useT()
  const banks = useGDKStore.finance.supportBanks()
  const allBank: Bank = useMemo(() => ({ id: -1, name: t('common.all'), simple_code: 'all' }), [t])
  const defaultData = useMemo(() => {
    const data = defaultValue?.map((id) => banks.find((bank) => bank.id === id)).filter((item) => item !== undefined)
    if (data?.length === banks.length && data.length !== 0) return [allBank, ...data]
    return data
  }, [allBank, banks, defaultValue])
  const data = useMemo(() => {
    const data = value?.map((id) => banks.find((bank) => bank.id === id)).filter((item) => item !== undefined)
    if (data?.length === banks.length && data.length !== 0) return [allBank, ...data]
    return data
  }, [allBank, banks, value])
  const options = useMemo(() => {
    return [allBank].concat(banks)
  }, [allBank, banks])
  return (
    <Autocomplete<any, boolean, boolean, boolean>
      id="banks"
      multiple
      disableCloseOnSelect
      disabled={disabled}
      options={options}
      getOptionLabel={useCallback((item: Bank) => item.name, [])}
      defaultValue={defaultData}
      value={data}
      onChange={useCallback((_, value: Bank[], reason: 'select-option' | 'remove-option' | 'blur' | 'clear' | 'create-option') => {
        const allBankIds = banks.map((item) => item.id)
        const isAllSelected = allBankIds.every((id) => value.find((selected) => selected.id === id))
        let newValue = value.map((item) => item.id)
        if (value[value.length - 1]?.id === allBank.id && reason === 'select-option') newValue = allBankIds
        if (value[value.length - 1]?.id !== allBank.id && isAllSelected && reason === 'remove-option') newValue = []
        if (onChange) onChange(newValue)
      }, [allBank.id, banks, onChange])}
      onBlur={onBlur}
      onFocus={onFocus}
      renderInput={useCallback((params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth={fullWidth}
        />
      ), [fullWidth, label, placeholder])}
      renderTags={useCallback((value: Bank[], getTagProps) => {
        return value.map((bank, index) => (
          <Chip label={bank.name} deleteIcon={deleteIcon} {...getTagProps({ index })} />
        ))
      }, [])}
      renderOption={useCallback((option, { selected }) => (
        <Checkbox selected={selected} name={option.name} />
      ), [])}
    />
  )
}

export default React.memo(BanksInput)
