import TKeyType from '../../i18ns/admin/TKeyType'
import { newYear2025CategoryType } from '@golden/gdk-campaign'

export const activityNewYear2025CategoryName: { [key in newYear2025CategoryType]: TKeyType } = {
  [newYear2025CategoryType.LIVE]: 'gameCategory.short.liveGame',
  [newYear2025CategoryType.VIDEO_AND_CARD]: 'gameCategory.2025.videoCard',
  [newYear2025CategoryType.SPORT_AND_ESPORT]: 'gameCategory.2025.sportEsport'
}

export default activityNewYear2025CategoryName
