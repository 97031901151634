import React, { useState, useCallback } from 'react'
import DropDown from '../../default/form/DropDown'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { searchToRequest, RequestType } from '../../../views/admin/finance/FinanceBankCodePage'
import { useRequestFromSearch, useChangeUrlSubmit, useGetData, useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import useT from '../../../i18ns/admin/useT'
import { BankMappingForwarderInfo } from '@golden/gdk-admin'
import { Path } from '../../../components/admin/route/route'
import { PageFlowType } from '../../../utils/default/PageFlowHook'

const FinanceBankCodeToolbar: React.FC<{
  list: Array<BankMappingForwarderInfo & { edit_code: string }>
  pageFlow: PageFlowType
}> = (props) => {
  const request = useRequestFromSearch({ searchToRequest })
  const [option, setOption] = useState<Array<{ name: string, value: string }>>([])
  const [nextSlug, setNextSlug] = useState('')
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const { list, pageFlow } = props
  const [handleDebouncedBack] = useRedirectHandleBack({
    path: Path.FINANCE_BANK
  })

  useGetData({
    gdkFunc: () => gdk.finance.getBanksMappingForwarderList(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res) => {
      setOption(res.map(item => ({ name: item.name, value: item.slug })))
      if (res.length === 0) {
        pageFlow.setContentShow()
      }
      if (res.length && request?.slug === undefined) {
        handleChangeSubmit({ slug: res[0].slug })
      }
    },
    canLoadData: option.length === 0
  })

  const handleChangeSubmit = useChangeUrlSubmit<{ slug: string }, RequestType>({
    formToRequest: (form) => ({
      slug: form.slug
    }) as RequestType,
    encodePath: allRoute.financeBankCode.encodePath,
    toAddNowTimestamp: true
  })

  useDialogFlow(globalDialog, 'willChangeSlug', () => {
    handleChangeSubmit({ slug: nextSlug })
  }, [nextSlug])

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Box width={300}>
          <DropDown
            className={commonClasses.outlineDropDown}
            fullWidth
            variant="outlined"
            label=""
            value={request?.slug ?? ''}
            onChange={useCallback((event) => {
              const value = event.target.value as string
              if (list.some(item => item.forwarder_bank_code !== item.edit_code)) {
                setNextSlug(value)
                globalDialog.setConfig(createGlobalDialogConfig({
                  message: t('dialog.confirmChangeBeforeSave'),
                  showIcon: false
                }))
                globalDialog.setOpen({
                  id: 'willChangeSlug',
                  value: true,
                  isOK: false
                })
                return
              }
              handleChangeSubmit({ slug: value })
            }, [list, handleChangeSubmit, globalDialog, t])}
            options={option}
            disabled={option.length === 0}
            placeholder={(option.length === 0 && !pageFlow.status.loading) ? t('placeholder.noUsableWithdrawWay') : ''}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button
          className={commonClasses.greyButton}
          onClick={handleDebouncedBack}
        >
          <Box paddingY={0.5}>
            {t('common.back')}
          </Box>
        </Button>
      </Grid>
    </Grid>
  )
}

export default React.memo(FinanceBankCodeToolbar)
