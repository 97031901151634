import React, { useState, useContext, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { SmsResendDialogContext } from '../../../views/admin/forestage/AnnouncementSmsPage'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { CountryCallingCode, GDKError, SmsProviderRes, SmsTriggerType } from '@golden/gdk-admin'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import smsTriggerNname from '../../../constants/admin/smsTriggerName'
import DropDown from '../../default/form/DropDown'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  head: {
    width: 120
  },
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

interface PropTypes {
  providersStatus: SmsProviderRes | null
}

export interface Payload {
  id: number
  open: boolean
  reload: () => void
  onClose: () => void
  account: string
  iddCode: CountryCallingCode
  phone: string
  trigger: SmsTriggerType
  originProvider: string
}

export const initialPayload = (): Payload => ({
  id: 0,
  open: false,
  reload: () => {},
  onClose: () => {},
  account: '',
  iddCode: CountryCallingCode.CN,
  phone: '',
  trigger: SmsTriggerType.REGISTER,
  originProvider: ''
})

const AnnouncementSmsResendDialog: React.FC<PropTypes> = (props) => {
  const { providersStatus } = props
  const [payload] = useContext(SmsResendDialogContext)
  const { id, iddCode, open, onClose, reload, account, phone, trigger, originProvider } = payload
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const [provider, setProvider] = useState<string>('--')
  const [handleSubmitDebounced] = useDebouncedCallback(useCallback(() => {
    onClose()
    gdk.sms.resendSms(id, provider).subscribe({
      next: () => {
        setProvider('--')
        reload()
      },
      error: (error: GDKError) => {
        setProvider('--')
        reload()
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'resendSmsError',
          value: false,
          isOK: false
        })
      }
    })
  }, [gdk, onClose, id, provider]), 200)
  const handleClose = useCallback(() => {
    onClose()
    setProvider('--')
  }, [onClose])
  const handleSubmit = useCallback(handleSubmitDebounced, [])
  const providerOptions = useMemo(() => {
    if (!providersStatus) return []
    return providersStatus[iddCode].providers.map((item) => ({
      name: item.name,
      value: item.code
    }))
  }, [providersStatus, iddCode])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.resendSms')}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <table className={detailClasses.table}>
            <tbody>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.sendTarget')}</td>
                <td className={detailClasses.cell}>{account}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.cellphone')}</td>
                <td className={detailClasses.cell}>{phone}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.triggerSence')}</td>
                <td className={detailClasses.cell}>{t(smsTriggerNname[trigger])}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.originSmsProvider')}</td>
                <td className={detailClasses.cell}>{originProvider}</td>
              </tr>
              <tr>
                <td className={clsx(detailClasses.head, classes.head)}>{t('common.resendSmsProvider')}</td>
                <td className={detailClasses.cell}>
                  <Box paddingRight={2}>
                    <DropDown
                      fullWidth
                      label={t('common.smsProvider')}
                      value={provider}
                      onChange={useCallback((event) => {
                        setProvider(event.target.value)
                      }, [])}
                      options={providerOptions}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={handleClose}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={provider === '--'}
              className={commonClasses.purpleGradualButton}
              onClick={handleSubmit}
            >
              {t('common.send')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AnnouncementSmsResendDialog)
