import React, { useContext, useCallback, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import useT from '../../../i18ns/admin/useT'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { Payload } from './ExternalGameChannelImageDialog'
import { getTargetURL } from '@golden/gdk-campaign'

const useStyles = makeStyles(() => ({
  img: {
    objectFit: 'contain'
  }
}))

interface PropTypes {
  name: string
  imageId: number
  imagePath: string
  onChange: (imageId: number, imagePath: string) => void
  context: React.Context<[
    Payload,
    Dispatch<SetStateAction<Payload>>
  ]>
  size: number
}

const ExternalGameChannelImageButton: React.FC<PropTypes> = (props) => {
  const { name, imageId, imagePath, onChange, context, size } = props
  const writable = useChecker()
  const classes = useStyles()
  const { t } = useT()
  const [, setPayload] = useContext(context)
  const handleChange = useCallback((imageId: number, imagePath: string) => {
    onChange(imageId, imagePath)
    setPayload((payload) => ({ ...payload, open: false }))
  }, [onChange])
  const handleCancel = useCallback(() => {
    setPayload((payload) => ({
      ...payload,
      defaultImageId: 0,
      defaultImagePath: '',
      open: false
    }))
  }, [])
  const handleClick = useCallback(() => {
    setPayload((payload) => ({
      ...payload,
      onSubmit: handleChange,
      onCancel: handleCancel,
      open: true,
      defaultImageId: imageId,
      defaultImagePath: imagePath,
      channel: name
    }))
  }, [imageId, imagePath, name, handleChange])
  if (!writable) {
    if (imagePath !== '') {
      return (<img src={getTargetURL(getServerUrl('image'), imagePath)} alt="" />)
    }
    return (<p>{t('common.noImage')}</p>)
  }
  if (imagePath !== '') {
    return (
      <ButtonBase onClick={handleClick}>
        <img className={classes.img} width={size} src={getTargetURL(getServerUrl('image'), imagePath)} alt="" />
      </ButtonBase>
    )
  }
  return (
    <Button
      variant="contained"
      disableElevation
      onClick={handleClick}
    >
      {t('common.upload')}
    </Button>
  )
}

export default React.memo(ExternalGameChannelImageButton)
