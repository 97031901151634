import React, { useCallback } from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { EditIpWhitelistFormType } from './IpWhitelistFormStateProvider'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  onBack: () => void
  values: EditIpWhitelistFormType[]
  disabled: boolean
}

const EditIpWhitelistFormSubmitButton: React.FC<PropTypes> = (props) => {
  const { onBack, disabled, values } = props
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()

  const handleClick = useDialogHandleClick({
    dialogId: 'createWhitelist',
    globalDialog,
    payload: values,
    gdkFunc: (payload) => gdk.parameter.createIpWhitelist(payload),
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={dialogClasses.text}>{t('dialog.confirmAddIpWhitelist')}</Typography>
          {values.length > 1 && (<Typography className={dialogClasses.text}>{t('dialog.filterIp')}</Typography>)}
          {values.map((item) => (<Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>[{item.ip}]</Typography>))}
        </Box>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.addIpWhitelistSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    afterSuccessDialog: () => { onBack() }
  })

  return (
    <Button
      className={commonClasses.purpleGradualButton}
      disabled={disabled}
      onClick={handleClick}
    >
      {t('common.confirmCreate')}
    </Button>
  )
}

export default React.memo(EditIpWhitelistFormSubmitButton)
