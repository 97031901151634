import React, { createContext, useMemo, useState, useContext } from 'react'
import { ActivityDurationType, ActivityFrequencyType, ActivityLayoutReq, ActivityModuleType, ActivityType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import DateTimePicker, { PropTypes as TimePickerProps } from '../../default/form/DateTimePicker'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../default/form/MultipleSelector'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import RequiredText from '../../default/form/RequiredText'
import { ValueGetter, FormValidation, createDefaultFormState, ChangedFormGetter } from '../../../utils/default/FormHook'
import {
  FormPropType,
  getValueFromChangeEvent,
  getValueFromCheckboxEvent,
  getValueFromValue
} from '../../../utils/default/FormHelper'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createValidateNotEmpty, createValidateRange, createValidateDate, createErrorResult, createCorrectResult } from '../../../utils/default/Validator'
import useT from '../../../i18ns/admin/useT'
import TextEditor, { PropTypes as TextEditorProps } from '../../default/form/textEditor/TextEditor'
import { getCore, getRenderExtensions, defaultEditorState, EditorState, TextContentType, isEmpty } from '@golden/tiptap-react'
import FileImageField, { PropTypes as FileImageFieldProps } from '../../default/form/FileImageField'
import { isAfter, getUnixTime, isValid, isBefore } from 'date-fns'
import useGDK from '../../../providers/admin/gdk/useGDK'
import ActivityManagementEditLayoutSettingForm, {
  EditLayoutSettingFormType,
  initialForm as initialSettingForm,
  formToRequest as settingFormToRequest,
  getValueFromEvent as settingGetValueFromEvent
} from './ActivityManagementEditLayoutSettingForm'
import { GameFormType, GameSportsFormType } from './ActivityManagementEditLayoutGameSettingForm'
import ActivityManagementPreviewDialog from './ActivityManagementPreviewDialog'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  fileField: {
    backgroundColor: '#fff',
    padding: '6px 12px 12px',
    marginBottom: 12
  },
  tabs: {
    maxWidth: '69vw'
  },
  tab: {
    fontSize: theme.typography.h6.fontSize,
    minWidth: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  tabSelected: {
    backgroundColor: '#f3f3f3',
    color: theme.custom.palette.secondary
  },
  tabContent: {
    backgroundColor: '#f3f3f3',
    padding: 24
  },
  indicator: {
    height: 0
  }
}))

export type ActivityManagementEditLayoutFormType = {
  isUpdate?: boolean
  activity_id?: number
  type: string
  title: string
  startAt: Date | null
  endAt: Date | null
  isNoEndAt: boolean
  showStartAt: Date | null
  showEndAt: Date | null
  isNoShowEndAt: boolean
  content: EditorState
  pure_content: string
  desktopHomeImage: { id?: number, url: string } | null
  mobileHomeImage: { id?: number, url: string } | null
  couponImage: { id?: number, url: string } | null
  agentLongImage: { id?: number, url: string } | null
  isCarousel: boolean
  isGuest: boolean
  isMy: boolean
  isGeneralRule: boolean
  tagIds: number[]
} & EditLayoutSettingFormType

export const initialForm: InitialFormFunc<ActivityManagementEditLayoutFormType> = (form) => ({
  isUpdate: false,
  title: '',
  type: '',
  startAt: null,
  endAt: null,
  isNoEndAt: false,
  showStartAt: null,
  showEndAt: null,
  isNoShowEndAt: false,
  content: defaultEditorState,
  pure_content: '',
  desktopHomeImage: null,
  mobileHomeImage: null,
  couponImage: null,
  agentLongImage: null,
  isCarousel: false,
  isGuest: false,
  isMy: false,
  isGeneralRule: false,
  tagIds: [],
  ...initialSettingForm(form),
  ...form
})

export const formToRequest = (data: ActivityManagementEditLayoutFormType): Partial<ActivityLayoutReq<number>> & { activity_id?: number, type?: string } => ({
  activity_id: data.activity_id,
  title: data.title,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  start_at: getUnixTime(data.startAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  end_at: data.isNoEndAt ? null : getUnixTime(data.endAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  show_start_at: getUnixTime(data.showStartAt!),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  show_end_at: data.isNoShowEndAt ? null : getUnixTime(data.showEndAt!),
  content: JSON.stringify(data.content.content),
  pure_content: data.pure_content,
  desktop_home_image_id: data.desktopHomeImage?.id,
  mobile_home_image_id: data.mobileHomeImage?.id,
  coupon_image_id: data.couponImage?.id,
  agent_long_image_id: data.agentLongImage ? data.agentLongImage?.id : null,
  is_carousel: data.isCarousel,
  is_guest: data.isGuest,
  is_my: data.isMy,
  is_general_rule: data.isGeneralRule,
  tag_ids: data.tagIds,
  ...settingFormToRequest(data)
})

interface PropTypes extends FormPropType<ActivityManagementEditLayoutFormType> {
  isUpdate?: boolean
  okText: string
  onBack: () => void
  isLook?: boolean
}

const getValueFromEvent: ValueGetter<ActivityManagementEditLayoutFormType> = {
  isUpdate: getValueFromCheckboxEvent,
  title: getValueFromChangeEvent,
  type: getValueFromValue,
  startAt: getValueFromValue,
  endAt: getValueFromValue,
  isNoEndAt: getValueFromCheckboxEvent,
  showStartAt: getValueFromValue,
  showEndAt: getValueFromValue,
  isNoShowEndAt: getValueFromCheckboxEvent,
  content: getValueFromValue,
  pure_content: getValueFromValue,
  desktopHomeImage: getValueFromValue,
  mobileHomeImage: getValueFromValue,
  couponImage: getValueFromValue,
  agentLongImage: getValueFromValue,
  isCarousel: getValueFromCheckboxEvent,
  isGuest: getValueFromCheckboxEvent,
  isMy: getValueFromCheckboxEvent,
  isGeneralRule: getValueFromCheckboxEvent,
  tagIds: getValueFromValue,
  ...settingGetValueFromEvent
}

export const ActivityManagementFormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC<{ isShowDate: boolean, disabled: boolean }> = React.memo((props) => {
  const { isShowDate, disabled } = props
  const { t } = useT()
  const { value, dispatch } = useContext(ActivityManagementFormContext)

  return (
    <Grid item container direction="row" spacing={2}>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={isShowDate ? 'showStartAt' : 'startAt'}
          label={isShowDate ? t('common.startDisplayTime') : t('common.activityStartTime')}
          placeholder={isShowDate ? t('placeholder.activityStartAtTip') : t('placeholder.activityShowStartAtTip')}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField<ActivityManagementEditLayoutFormType, TimePickerProps>
          component={DateTimePicker}
          context={ActivityManagementFormContext}
          name={isShowDate ? 'showEndAt' : 'endAt'}
          label={isShowDate ? t('common.endDisplayTime') : t('common.activityEndTime')}
          placeholder={isShowDate ? t('placeholder.activityEndAtTip') : t('placeholder.activityShowEndAtTip')}
          required={isShowDate ? !value.isNoShowEndAt : !value.isNoEndAt}
          disabled={disabled || (isShowDate ? value.isNoShowEndAt : value.isNoEndAt)}
        />
        <OnOffCheckbox
          label={isShowDate ? t('common.longtermDisplay') : t('common.longtermActivity')}
          value={isShowDate ? value.isNoShowEndAt : value.isNoEndAt}
          onChange={(event) => {
            const checked = event.target.checked
            if (isShowDate) {
              dispatch({ type: 'change', label: 'isNoShowEndAt', value: checked })
              dispatch({ type: 'change', label: 'showEndAt', value: checked ? null : value.showEndAt })
            } else {
              dispatch({ type: 'change', label: 'isNoEndAt', value: checked })
              dispatch({ type: 'change', label: 'endAt', value: checked ? null : value.endAt })
            }
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const PreviewButton: React.FC = () => {
  const [openPreview, setOpenPreview] = useState(false)
  const commonClasses = useCommonStyles()
  const { t } = useT()
  return (<>
    <Button
      className={commonClasses.purpleGradualButton}
      onClick={() => { setOpenPreview(true) }}
    >
      {t('common.preview')}
    </Button>
    { openPreview && (
      <ActivityManagementPreviewDialog
        type={ActivityType.LAYOUT}
        onConfirm={() => { setOpenPreview(false) }}
      />
    )}
  </>)
}

const ActivityManagementEditLayoutForm: React.FC<PropTypes> = (props) => {
  const { defaultValue, onSubmit, okText, onBack, isLook = false } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [tags, setTags] = useState<Array<{ value: number, name: string }>>([])

  const fileSizeBytesLimitation = 2 * (2 ** 20) // 2MB in bytes
  const fileSizeLimitedText = '2 MB'
  const imageFieldGrids = [
    {
      name: 'desktopHomeImage',
      text: `${t('client.desktop')}${t('common.image')}`,
      isRequired: true
    },
    {
      name: 'mobileHomeImage',
      text: `${t('client.mobile')}${t('common.image')}`,
      isRequired: true
    },
    {
      name: 'couponImage',
      text: t('common.couponImage'),
      isRequired: true
    },
    {
      name: 'agentLongImage',
      text: t('common.agentLongImage'),
      isRequired: false
    }
  ]

  const [getChangedForm, setGetChangedForm] = useState<ChangedFormGetter<ActivityManagementEditLayoutFormType>>({})

  useAsyncEffect(async (mounted) => {
    const core = await getCore()
    const extensions = await getRenderExtensions(core)
    if (!mounted) return
    setGetChangedForm({
      singleDepositDuration: (value, form) => ({
        ...form,
        singleDepositDuration: value,
        singleBetDuration: value,
        singleFrequency: value === ActivityDurationType.CUSTOM ? ActivityFrequencyType.ONE_TIME : '-'
      }),
      singleBetDuration: (value, form) => ({
        ...form,
        singleDepositDuration: value,
        singleBetDuration: value,
        singleFrequency: value === ActivityDurationType.CUSTOM ? ActivityFrequencyType.ONE_TIME : '-'
      }),
      singleDepositCustomStartAt: (value, form) => ({
        ...form,
        singleDepositCustomStartAt: value,
        singleBetCustomStartAt: value
      }),
      singleDepositCustomEndAt: (value, form) => ({
        ...form,
        singleDepositCustomEndAt: value,
        singleBetCustomEndAt: value
      }),
      singleBetCustomStartAt: (value, form) => ({
        ...form,
        singleDepositCustomStartAt: value,
        singleBetCustomStartAt: value
      }),
      singleBetCustomEndAt: (value, form) => ({
        ...form,
        singleDepositCustomEndAt: value,
        singleBetCustomEndAt: value
      }),
      content: (value, form) => {
        return {
          ...form,
          content: value,
          pure_content: core.generateHTML(value.content, extensions).replace(/(<([^>]+)>)/ig, '')
        }
      }
    })
  }, [])

  useGetData({
    gdkFunc: () => gdk.activity.getActivityTagsPriority(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res) => {
      setTags(res.map(item => ({ value: item.id, name: item.title })))
    }
  })

  const textFieldInputProps = useMemo(() => {
    return {
      maxLength: 50,
      placeholder: t('placeholder.activityTitleTip')
    }
  }, [t])

  const validation: FormValidation<ActivityManagementEditLayoutFormType> = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('title', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<ActivityManagementEditLayoutFormType>(
            'title', (value) => (value as string).length, 'lessEqual', 50, t('error.mustLessEqualFiftyWords')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      type: [],
      startAt: [
        {
          func: (value, form) => {
            if (!value) return createErrorResult('startAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('startAt', t('error.dateError'))
            if (!form.endAt) {
              return { isPass: true, stop: false, newError: { startAt: null } }
            }
            if (!!form.endAt && isAfter(value as Date, form.endAt)) {
              return { isPass: false, stop: true, newError: { startAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { startAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      endAt: [
        {
          func: (value, form) => {
            if (!value && !form.isNoEndAt) return createErrorResult('endAt', t('error.mustNotEmpty'))
            if (!isValid(value) && !form.isNoEndAt) {
              return { isPass: false, stop: true, newError: { endAt: t('error.dateError') } }
            }
            if (!!form.startAt && !!value && isAfter(form.startAt, value as Date)) {
              return { isPass: false, stop: true, newError: { endAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { startAt: null, endAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isNoEndAt: [],
      showStartAt: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateDate<ActivityManagementEditLayoutFormType>('showStartAt', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form) => {
            if (!form.showEndAt) {
              return { isPass: true, stop: false, newError: { showStartAt: null } }
            }
            if (!!form.showEndAt && isAfter(value as Date, form.showEndAt)) {
              return { isPass: false, stop: true, newError: { showStartAt: t('error.startMustBeforeEnd') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      showEndAt: [
        {
          func: (value, form) => {
            if (!form.showStartAt) {
              return { isPass: true, stop: false, newError: { showEndAt: null } }
            }
            if (!value && !form.isNoShowEndAt) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.mustNotEmpty') } }
            }
            if (!!value && !isValid(value)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.dateError') } }
            }
            if (!!value && isAfter(form.showStartAt, value as Date)) {
              return { isPass: false, stop: true, newError: { showEndAt: t('error.endMustAfterStart') } }
            }
            return { isPass: true, stop: false, newError: { showStartAt: null, showEndAt: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isNoShowEndAt: [],
      content: [
        {
          func: (value) => {
            if (isEmpty((value as EditorState).content)) {
              return {
                isPass: false,
                stop: true,
                newError: { content: t('error.mustNotEmpty') }
              }
            }
            return { isPass: true, stop: false, newError: { content: null } }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      pure_content: [],
      desktopHomeImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('desktopHomeImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      mobileHomeImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('mobileHomeImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      couponImage: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('couponImage', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      agentLongImage: [],
      isCarousel: [],
      isGuest: [],
      isMy: [],
      isGeneralRule: [],
      tagIds: [
        {
          func: createValidateNotEmpty<ActivityManagementEditLayoutFormType>('tagIds', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      moduleType: [],
      hasSetting: [],
      singleHasDepositCondition: [],
      singleDepositDuration: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasDepositCondition) return createCorrectResult('singleDepositDuration')
            if (value === '-') return createErrorResult('singleDepositDuration', t('error.mustNotEmpty'))
            return createCorrectResult('singleDepositDuration')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleBetDuration', 'change:singleHasDepositCondition', 'change', 'beforeClickSubmit']
        }
      ],
      singleDepositCustomStartAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || form.singleDepositDuration !== ActivityDurationType.CUSTOM) return createCorrectResult('singleDepositCustomStartAt')
            if (!value) return createErrorResult('singleDepositCustomStartAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('singleDepositCustomStartAt', t('error.dateError'))
            if (form.singleDepositCustomEndAt && isAfter(value as Date, form.singleDepositCustomEndAt)) return createErrorResult('singleDepositCustomStartAt', t('error.startMustBeforeEnd'))
            return createCorrectResult('singleDepositCustomStartAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleDepositCustomEndAt', 'change:singleDepositDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleDepositCustomEndAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || form.singleDepositDuration !== ActivityDurationType.CUSTOM) return createCorrectResult('singleDepositCustomEndAt')
            if (!value) return createErrorResult('singleDepositCustomEndAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('singleDepositCustomEndAt', t('error.dateError'))
            if (form.singleDepositCustomStartAt && isAfter(form.singleDepositCustomStartAt, value as Date)) return createErrorResult('singleDepositCustomEndAt', t('error.endMustAfterStart'))
            return createCorrectResult('singleDepositCustomEndAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleDepositCustomStartAt', 'change:singleDepositDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleDepositType: [],
      singleDepositThreshold: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasDepositCondition) return createCorrectResult('singleDepositThreshold')
            if (!value) return createErrorResult('singleDepositThreshold', t('error.mustNotEmpty'))
            if (Number(value) === 0) return createErrorResult('singleDepositThreshold', t('error.mustMoreThanZero'))
            return createCorrectResult('singleDepositThreshold')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleHasDepositCondition', 'change', 'beforeClickSubmit']
        }
      ],
      singleHasBetCondition: [],
      singleBetDuration: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasBetCondition) return createCorrectResult('singleBetDuration')
            if (value === '-') return createErrorResult('singleBetDuration', t('error.mustNotEmpty'))
            return createCorrectResult('singleBetDuration')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleHasBetCondition', 'change:singleDepositDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetCustomStartAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || form.singleBetDuration !== ActivityDurationType.CUSTOM) return createCorrectResult('singleBetCustomStartAt')
            if (!value) return createErrorResult('singleBetCustomStartAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('singleBetCustomStartAt', t('error.dateError'))
            if (form.singleBetCustomEndAt && isAfter(value as Date, form.singleBetCustomEndAt)) return createErrorResult('singleBetCustomStartAt', t('error.startMustBeforeEnd'))
            return createCorrectResult('singleBetCustomStartAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleBetCustomEndAt', 'change:singleBetDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetCustomEndAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || form.singleBetDuration !== ActivityDurationType.CUSTOM) return createCorrectResult('singleBetCustomEndAt')
            if (!value) return createErrorResult('singleBetCustomEndAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('singleBetCustomEndAt', t('error.dateError'))
            if (form.singleBetCustomStartAt && isAfter(form.singleBetCustomStartAt, value as Date)) return createErrorResult('singleBetCustomEndAt', t('error.endMustAfterStart'))
            return createCorrectResult('singleBetCustomEndAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleBetCustomStartAt', 'change:singleBetDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetType: [],
      singleBetThreshold: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasBetCondition) return createCorrectResult('singleBetThreshold')
            if (!value) return createErrorResult('singleBetThreshold', t('error.mustNotEmpty'))
            if (Number(value) === 0) return createErrorResult('singleBetThreshold', t('error.mustMoreThanZero'))
            return createCorrectResult('singleBetThreshold')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleHasBetCondition', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetGameType: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasBetCondition) return createCorrectResult('singleBetGameType')
            if (!value) return createErrorResult('singleBetGameType', t('error.mustNotEmpty'))
            return createCorrectResult('singleBetGameType')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleHasBetCondition', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetSportGames: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasBetCondition || form.singleBetGameType !== 'sportGames') return createCorrectResult('singleBetSportGames')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('singleBetSportGames', t('error.mustNotEmpty'))
            for (const game of value as GameSportsFormType[]) {
              if (!game.game_id || !(game.tournament_id?.trim())) return createErrorResult('singleBetSportGames', t('error.mustNotEmpty'))
            }
            return createCorrectResult('singleBetSportGames')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleBetGameType', 'change:singleHasBetCondition', 'change:singleBetGameType', 'change', 'beforeClickSubmit']
        }
      ],
      singleBetGameIds: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE || !form.singleHasBetCondition || form.singleBetGameType !== 'gameId') return createCorrectResult('singleBetGameIds')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('singleBetGameIds', t('error.mustNotEmpty'))
            return createCorrectResult('singleBetGameIds')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleHasBetCondition', 'change:singleBetGameType', 'change', 'beforeClickSubmit']
        }
      ],
      singleFrequency: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE) return createCorrectResult('singleFrequency')
            if (value === '-') return createErrorResult('singleFrequency', t('error.mustNotEmpty'))
            return createCorrectResult('singleFrequency')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleDepositDuration', 'change:singleBetDuration', 'change', 'beforeClickSubmit']
        }
      ],
      singleStartAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE) return createCorrectResult('singleStartAt')
            if (!value) return createErrorResult('singleStartAt', t('error.mustNotEmpty'))
            if (!isValid(value)) return createErrorResult('singleStartAt', t('error.dateError'))
            if (form.singleEndAt && isAfter(value as Date, form.singleEndAt)) return createErrorResult('singleStartAt', t('error.startMustBeforeEnd'))
            return createCorrectResult('singleStartAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleEndAt', 'change', 'beforeClickSubmit']
        }
      ],
      singleEndAt: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.SINGLE) return createCorrectResult('singleEndAt')
            if (!value) return createErrorResult('singleEndAt', t('error.mustNotEmpty'))
            if (!form.isNoEndAt && !isValid(value)) return createErrorResult('singleEndAt', t('error.dateError'))
            if (!!form.singleStartAt && !!value && isAfter(form.singleStartAt, value as Date)) return createErrorResult('singleEndAt', t('error.endMustAfterStart'))
            return createCorrectResult('singleEndAt')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:singleStartAt', 'change', 'beforeClickSubmit']
        }
      ],
      gameHasDepositCondition: [],
      gameDepositType: [],
      gameDepositDuration: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasDepositCondition) return createCorrectResult('gameDepositDuration')
            if (value === '-') return createErrorResult('gameDepositDuration', t('error.mustNotEmpty'))
            return createCorrectResult('gameDepositDuration')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameHasDepositCondition', 'change', 'beforeClickSubmit']
        }
      ],
      gameDepositThreshold: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasDepositCondition) return createCorrectResult('gameDepositThreshold')
            if (!value) return createErrorResult('gameDepositThreshold', t('error.mustNotEmpty'))
            if (Number(value) === 0) return createErrorResult('gameDepositThreshold', t('error.mustMoreThanZero'))
            return createCorrectResult('gameDepositThreshold')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameHasDepositCondition', 'change', 'beforeClickSubmit']
        }
      ],
      gameHasBetCondition: [],
      gameBetType: [],
      gameBetThreshold: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasBetCondition) return createCorrectResult('gameBetThreshold')
            if (!value) return createErrorResult('gameBetThreshold', t('error.mustNotEmpty'))
            if (Number(value) === 0) return createErrorResult('gameBetThreshold', t('error.mustMoreThanZero'))
            return createCorrectResult('gameBetThreshold')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameHasBetCondition', 'change', 'beforeClickSubmit']
        }
      ],
      gameBetGameType: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasBetCondition) return createCorrectResult('gameBetGameType')
            if (!value) return createErrorResult('gameBetGameType', t('error.mustNotEmpty'))
            return createCorrectResult('gameBetGameType')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameHasBetCondition', 'change', 'beforeClickSubmit']
        }
      ],
      gameBetSportGames: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasBetCondition || form.gameBetGameType !== 'sportGames') return createCorrectResult('gameBetSportGames')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('gameBetSportGames', t('error.mustNotEmpty'))
            for (const game of value as GameSportsFormType[]) {
              if (!game.game_id || !(game.tournament_id?.trim())) return createErrorResult('gameBetSportGames', t('error.mustNotEmpty'))
            }
            return createCorrectResult('gameBetSportGames')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameBetGameType', 'change:gameHasBetCondition', 'change:gameBetGameType', 'change', 'beforeClickSubmit']
        }
      ],
      gameBetGameIds: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME || !form.gameHasBetCondition || form.gameBetGameType !== 'gameId') return createCorrectResult('gameBetGameIds')
            if (Array.isArray(value) && value.length === 0) return createErrorResult('gameBetGameIds', t('error.mustNotEmpty'))
            return createCorrectResult('gameBetGameIds')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change:gameHasBetCondition', 'change:gameBetGameType', 'change', 'beforeClickSubmit']
        }
      ],
      gameGames: [
        {
          func: (value, form) => {
            if (!form.hasSetting || form.moduleType !== ActivityModuleType.GAME) return createCorrectResult('gameGames')
            for (const game of value as GameFormType[]) {
              if (!game.league || !game.game || !game.game_start_at || !game.start_at || !game.end_at) return createErrorResult('gameGames', t('error.mustNotEmpty'))
              if (!isValid(game.game_start_at) || !isValid(game.start_at) || !isValid(game.end_at)) return createErrorResult('gameGames', t('error.dateError'))
              if (isBefore(game.end_at, game.start_at)) return createErrorResult('gameGames', t('error.startMustBeforeEnd'))
              if (form.gameHasBetCondition) {
                if (!game.bet_start_at || !game.bet_end_at) return createErrorResult('gameGames', t('error.mustNotEmpty'))
                if (!isValid(game.bet_start_at) || !isValid(game.bet_end_at)) return createErrorResult('gameGames', t('error.dateError'))
                if (isBefore(game.bet_end_at, game.bet_start_at)) return createErrorResult('gameGames', t('error.startMustBeforeEnd'))
              }
            }
            return createCorrectResult('gameGames')
          },
          when: ['change:hasSetting', 'change:moduleType', 'change', 'beforeClickSubmit']
        }
      ],
      isUpdate: []
    }
  }, [t])

  return (
    <FormStateProvider<ActivityManagementEditLayoutFormType>
      context={ActivityManagementFormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
      getChangedForm={getChangedForm}
    >
      <Grid container direction="column" spacing={3} wrap="nowrap">
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<ActivityManagementEditLayoutFormType, TextFieldProps>
              component={TextField}
              context={ActivityManagementFormContext}
              name="title"
              label={t('common.title')}
              inputProps={textFieldInputProps}
              autoFocus
              fullWidth
              required
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<ActivityManagementEditLayoutFormType, MultipleSelectorProps>
              component={MultipleSelector}
              context={ActivityManagementFormContext}
              name="tagIds"
              label={t('common.tag')}
              options={tags}
              fullWidth
              disabled={isLook}
            />
          </Grid>
        </Grid>
        <DateInput isShowDate={true} disabled={isLook} />
        <DateInput isShowDate={false} disabled={isLook} />
        <Grid item>
          <ActivityManagementEditLayoutSettingForm />
        </Grid>
        <Grid item>
          <Box className={commonClasses.pinkTitleBar}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Tabs
                classes={{ root: classes.tabs, indicator: classes.indicator }}
                value={'tab'}
              >
                <Tab
                  value={'tab'}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  label={t('common.content')}
                />
              </Tabs>
              <Box display="flex" flexGrow={1} />
            </Box>
          </Box>
          <Box className={classes.tabContent}>
            <Grid container direction='row' spacing={2} className={classes.fileField}>
              {imageFieldGrids.map(({ name, text, isRequired }, index) => (
                <Grid item xs={12} md={6} key={`${name}-${index}`}>
                  <span>{text}</span>
                  <FormField<ActivityManagementEditLayoutFormType, FileImageFieldProps>
                    context={ActivityManagementFormContext}
                    component={FileImageField}
                    name={name as keyof(ActivityManagementEditLayoutFormType)}
                    label={t('common.image')}
                    helperText={t('helperText.bannerTip')}
                    buttonText={t('common.uploadImage')}
                    accept=".png, .jpg"
                    fullWidth
                    fileSizeBytesLimitation={fileSizeBytesLimitation}
                    fileSizeLimitedText={fileSizeLimitedText}
                    required={isRequired}
                    disabled={isLook}
                  />
                </Grid>
              ))}
            </Grid>
            <FormField<ActivityManagementEditLayoutFormType, TextEditorProps>
              component={TextEditor}
              context={ActivityManagementFormContext}
              name="content"
              controls={['textContentType', 'block', 'table', 'bold', 'ol', 'ul', 'textColor', 'clearFormat']}
              textContentType={[TextContentType.HEADING2, TextContentType.PARAGRAPH]}
              placeholder={`${t('placeholder.inputContent')}*`}
              colors={['#BA9F72']}
              disabled={isLook}
            />
            <FormField<ActivityManagementEditLayoutFormType, OnOffCheckboxProps>
              component={OnOffCheckbox}
              context={ActivityManagementFormContext}
              name="isGeneralRule"
              label={t('common.generalRule')}
              disabled={isLook}
            />
          </Box>
        </Grid>
        <Grid item container direction="row">
          <FormField<ActivityManagementEditLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isCarousel"
            label={t('common.homeCarousel')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isGuest"
            label={t('common.showBeforeLogin')}
            disabled={isLook}
          />
          <FormField<ActivityManagementEditLayoutFormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={ActivityManagementFormContext}
            name="isMy"
            label={t('common.my')}
            disabled={isLook}
          />
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={onBack}
            >
              {!isLook ? t('common.cancel') : t('common.back')}
            </Button>
          </Grid>
          <Grid item>
            <PreviewButton />
          </Grid>
          {!isLook && (<Grid item>
            <FormSubmitButton
              component={Button}
              context={ActivityManagementFormContext}
              className={commonClasses.purpleGradualButton}
              type="submit"
            >
              {okText}
            </FormSubmitButton>
          </Grid>)}
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(ActivityManagementEditLayoutForm)
