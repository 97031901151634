import React, { useContext, useCallback, useMemo } from 'react'
import { PlatformType } from '@golden/gdk-admin'
import Button from '@material-ui/core/Button'
import { DialogPayloadContext } from '../../../views/admin/playerReport/PlayerReportFetchTaskPage'
import { formToRequest } from './FetchTaskDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'

interface PropTypes {
  disabled?: boolean
  platform: PlatformType
  reload: () => void
}

const FetchTaskButton: React.FC<PropTypes> = (props) => {
  const { disabled, platform, reload } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(DialogPayloadContext)
  const formToRequestFunc = useMemo(() => {
    return formToRequest(platform)
  }, [platform])
  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest: formToRequestFunc,
    gdkFunc: (payload) => gdk.platform.dispatchFetchTask(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setPayload((payload) => ({
        ...payload,
        open: false
      }))
      reload()
    },
    onError: (error) => {
      setPayload((payload) => ({
        ...payload,
        open: false
      }))
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: 'updateDrawDateFailure',
        value: true,
        isOK: false
      })
    }
  })
  const handleCancel = useCallback(() => { setPayload((payload) => ({ ...payload, open: false })) }, [])
  return (
    <Button
      disabled={disabled}
      className={classes.blueGradualOutlineButton}
      classes={{
        disabled: classes.blueGradualOutlineDisabledButton
      }}
      onClick={useCallback(() => {
        setPayload((payload) => ({
          ...payload,
          onSubmit: handleSubmit,
          onCancel: handleCancel,
          platform,
          open: true
        }))
      }, [handleSubmit, platform])}
    >
      {t('common.updateToRerun')}
    </Button>
  )
}

export default React.memo(FetchTaskButton)
