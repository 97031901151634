import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, PaginationReq, ActivityType, ActivityNewYear2025RedEnvelope, ActivityNewYear2025RedEnvelopeQuery, ActivityNewYear2025RedEnvelopeSum, ActivityNewYear2025RedEnvelopeStatusType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useLocation } from 'react-router-dom'
import { newYear2025RedEnvelopeStatusName } from '../../../constants/default/activityStatusName'
import { GameCategoryType, newYear2025CategoryType } from '@golden/gdk-campaign'

interface SumRowType1 {
  id: number
  ticket_5: string
  ticket_6: string
  ticket_7: string
  ticket_8: string
}

interface SumRowType2 {
  id: number
  total: number
  noUse: number
  notReceived: number
  received: number
  expired: number
}

interface SumRowType3 {
  id: number
  sport: number
  live: number
  card: number
  eSport: number
  video: number
}

interface RowType {
  id: number
  serialNumber: string
  createdAt: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement | string
  bonus: string
  updatedAt: React.ReactElement | string
  updatedBy: string
  memo: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordNewYear2025RedEnvelopeTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityNewYear2025RedEnvelope[]> & ActivityNewYear2025RedEnvelopeSum>({
    ...createDefaultPaginationData([]),
    summary: {
      ticket: {
        5: '0/0',
        6: '0/0',
        7: '0/0',
        8: '0/0'
      },
      status: {
        total: 0,
        no_use: 0,
        not_received: 0,
        received: 0,
        expired: 0
      },
      game_category: {
        [GameCategoryType.SPORT_LOTTERY]: 0,
        [GameCategoryType.LIVE_GAME]: 0,
        [GameCategoryType.CARD_GAME]: 0,
        [GameCategoryType.VIDEO_GAME]: 0,
        [GameCategoryType.ESPORT]: 0
      }
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityNewYear2025RedEnvelopeQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordNewYear2025RedEnvelope(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityNewYear2025RedEnvelope[]> & ActivityNewYear2025RedEnvelopeSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      ticket_5: list.summary.ticket[5],
      ticket_6: list.summary.ticket[6],
      ticket_7: list.summary.ticket[7],
      ticket_8: list.summary.ticket[8]
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        ticket_5: {
          label: t('common.ticketCountUnit', { counts: 5 }),
          value: 'ticket_5',
          align: 'center'
        },
        ticket_6: {
          label: t('common.ticketCountUnit', { counts: 6 }),
          value: 'ticket_6',
          align: 'center'
        },
        ticket_7: {
          label: t('common.ticketCountUnit', { counts: 7 }),
          value: 'ticket_7',
          align: 'center'
        },
        ticket_8: {
          label: t('common.ticketCountUnit', { counts: 8 }),
          value: 'ticket_8',
          align: 'center'
        }
      },
      [
        'ticket_5',
        'ticket_6',
        'ticket_7',
        'ticket_8'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])

  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      total: list.summary.status.total,
      noUse: list.summary.status.no_use,
      notReceived: list.summary.status.not_received,
      received: list.summary.status.received,
      expired: list.summary.status.expired
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        total: {
          label: t('common.totalCount2'),
          value: 'total',
          align: 'center'
        },
        noUse: {
          label: t('activityStatus.noUse'),
          value: 'total',
          align: 'center'
        },
        notReceived: {
          label: t('activityStatus.notReceived'),
          value: 'total',
          align: 'center'
        },
        received: {
          label: t('activityStatus.received'),
          value: 'total',
          align: 'center'
        },
        expired: {
          label: t('activityStatus.expiredSimple'),
          value: 'total',
          align: 'center'
        }
      },
      [
        'total',
        'noUse',
        'notReceived',
        'received',
        'expired'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])

  const sumRow3 = useMemo(() => {
    return [{
      id: 1,
      sport: list.summary.game_category[GameCategoryType.SPORT_LOTTERY],
      live: list.summary.game_category[GameCategoryType.LIVE_GAME],
      card: list.summary.game_category[GameCategoryType.CARD_GAME],
      eSport: list.summary.game_category[GameCategoryType.ESPORT],
      video: list.summary.game_category[GameCategoryType.VIDEO_GAME]
    }] as SumRowType3[]
  }, [list])
  const sumData3 = useMemo(() => {
    return createTableData<SumRowType3>(
      {
        id: {
          label: '',
          value: 'id'
        },
        sport: {
          label: t('gameCategory.short.sportLottery'),
          value: 'sport',
          align: 'center'
        },
        live: {
          label: t('gameCategory.short.liveGame'),
          value: 'live',
          align: 'center'
        },
        card: {
          label: t('gameCategory.short.chessGame'),
          value: 'card',
          align: 'center'
        },
        eSport: {
          label: t('gameCategory.short.esport'),
          value: 'eSport',
          align: 'center'
        },
        video: {
          label: t('gameCategory.short.videoGame'),
          value: 'video',
          align: 'center'
        }
      },
      [
        'sport',
        'live',
        'card',
        'eSport',
        'video'
      ],
      sumRow3,
      'id'
    )
  }, [t, sumRow3])

  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        serialNumber: item.serial_number,
        createdAt: (<DateTime time={item.created_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: t(newYear2025RedEnvelopeStatusName[item.status]),
        bonus: formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : ('--'),
        updatedBy: item.updated_by,
        memo: item.memo
      } as RowType
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serialNumber: {
          label: t('common.activitySerial'),
          value: 'serialNumber',
          align: 'center'
        },
        createdAt: {
          label: t('common.applyTime'),
          value: 'createdAt',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'serialNumber',
        'createdAt',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'updatedBy',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.NEW_YEAR_2025_RED_ENVELOPE)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.playerCountStatistic')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.countStatisticResultShort')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.transferInToCategoryStatistic')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData3}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordNewYear2025RedEnvelopeTable)
