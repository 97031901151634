import React, { useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GDKError, PermissionType } from '@golden/gdk-admin'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface PropType {
  reload: () => void
}

const PlayerLayerSystemButton: React.FC<PropType> = (props) => {
  const { reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const [resetDisabled, setResetDisabled] = useState<boolean>(false)
  const [stratifyDisabled, setStratifyDisabled] = useState<boolean>(false)
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const writableZero = useChecker([PermissionType.PLAYER_LAYER_STATISTIC_ZERO])
  const writableResetLayer = useChecker([PermissionType.PLAYER_LAYER_RESET_LAYER])

  const [handleDebouncedStratify] = useDebouncedCallback(useCallback(() => {
    gdk.player.stratifyLayer()
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            notUseTypo: true,
            message: (
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Typography align="center" className={clsx(dialogClasses.title, commonClasses.pre)}>
                      {t('dialog.systemProcessTitle')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Typography align="center" className={clsx(dialogClasses.text, commonClasses.pre)}>
                      {t('dialog.systemProcessContent')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )
          }))
          globalDialog.setOpen({
            id: 'stratifySuceess',
            value: true,
            isOK: false
          })
          setStratifyDisabled(true)
        },
        error: (error: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: 'stratifyFailure',
            value: true,
            isOK: false
          })
        }
      })
  }, [gdk, commonClasses, dialogClasses, t]), 200)

  const handleStratify = useCallback(handleDebouncedStratify, [])

  const handleResetLayerStartAt = useDialogHandleClick({
    dialogId: 'resetLayerStartAt',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.title, commonClasses.pre)}>
                {t('dialog.resetLayerStartAtTitle')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.text, commonClasses.pre)}>
                {t('dialog.resetLayerStartAtContent')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      notUseTypo: true,
      message: (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.title, commonClasses.pre)}>
                {t('dialog.systemProcessTitle')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.text, commonClasses.pre)}>
                {t('dialog.systemProcessContent')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      showIcon: true,
      showCancel: false,
      message: error
    }),
    payload: null,
    gdkFunc: () => gdk.player.resetLayerStartAt(),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      reload()
      setResetDisabled(true)
    }
  })

  return (
    <Grid container spacing={2}>
      { writableZero && (<Grid item>
        <Button
          onClick={handleResetLayerStartAt}
          className={commonClasses.purpleGradualButton}
          disabled={resetDisabled}
        >
          {resetDisabled ? t('common.resetLayerStartAtProcessing') : t('common.resetLayerStartAt')}
        </Button>
      </Grid>) }
      { writableResetLayer && (<Grid item>
        <Button
          onClick={handleStratify}
          className={commonClasses.purpleGradualButton}
          disabled={stratifyDisabled}
        >
          {stratifyDisabled ? t('common.stratifyLayerProcessing') : t('common.stratifyLayer')}
        </Button>
      </Grid>) }
    </Grid>
  )
}

export default React.memo(PlayerLayerSystemButton)
