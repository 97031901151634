import React, { useMemo, useState, useContext } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { PiggyBank, DepositMethodType, PaginationRes } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import depositTypeName from '../../../constants/default/depositTypeName'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import TableStatus from '../../default/present/TableStatus'
import { FinancePiggyBankFormContext } from '../../../views/admin/finance/FinancePiggyBankPage'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import FinancePiggyBankButtons from './FinancePiggyBankButtons'
import clientTypeName from '../../../constants/admin/clientTypeName'
import { Box, makeStyles } from '@material-ui/core'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import CustomThemeType from '../../../themes/default/CustomThemeType'

interface RowType {
  id: number
  layer: React.ReactElement
  bank: string
  account: React.ReactElement
  way: string
  min: string
  max: string
  receiver: string
  memo: string
  client: React.ReactElement
  status: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  functions: React.ReactElement
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.custom.palette.blue.main
  }
}))

const FinancePiggyBankTable: React.FC = () => {
  const [form, setForm] = useContext(FinancePiggyBankFormContext)
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const [list, setList] = useState<PaginationRes<PiggyBank[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()
  const { reload, reloadFlag } = useReload()
  const payload = useMemo(() => {
    const payload = omitBy({ account: convertEmptyToUndefined(form.account), real_name: convertEmptyToUndefined(form.receiver) }, isUndefined)
    if (form.status === '--') return { ...payload, page: form.page }
    return { ...payload, is_active: Number(form.status) as 0 | 1, page: form.page }
  }, [form])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getPiggyBankList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<PiggyBank[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellBody: commonClasses.nowrap,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])
  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        layer: (
          <React.Fragment>
            {item.layers.map((layer) => (<p key={`layer-${layer.id}`}>{layer.name}</p>))}
            {item.is_for_proxy_deposit ? <p>{t('common.staffDeposit')}</p> : null}
          </React.Fragment>
        ),
        bank: item.name,
        account: (
          <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="center">
            {item.is_recommended && (<ThumbUpIcon className={classes.icon} />)}
            {item.account}
          </Box>
        ),
        way: t(depositTypeName[DepositMethodType.ONLINE]),
        min: formatMoney(item.cash_min),
        max: formatMoney(item.cash_max),
        receiver: item.real_name,
        memo: item.memo,
        client: (
          <React.Fragment>
            {item.clients.map((client) => (
              <p key={`client-${client}`}>{t(clientTypeName[client])}</p>
            ))}
          </React.Fragment>
        ),
        status: (<TableStatus status={item.is_active} />),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        functions: (
          <FinancePiggyBankButtons
            item={item}
            reload={reload}
          />
        )
      } as RowType
    })
  }, [t, list, reload, classes])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        layer: {
          label: t('common.openLayer'),
          value: 'layer',
          align: 'center'
        },
        bank: {
          label: t('common.bankName'),
          value: 'bank',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        way: {
          label: t('common.financeDepositWay'),
          value: 'way',
          align: 'center'
        },
        min: {
          label: t('common.minDepositMoney'),
          value: 'min',
          align: 'right'
        },
        max: {
          label: t('common.maxDepositMoney'),
          value: 'max',
          align: 'right'
        },
        receiver: {
          label: t('common.playerSideReceiver'),
          value: 'receiver',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        client: {
          label: t('common.platformVersion'),
          value: 'client',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'layer',
            'bank',
            'account',
            'way',
            'min',
            'max',
            'receiver',
            'memo',
            'client',
            'status',
            'updatedAt',
            'updatedBy',
            'functions'
          ]
        : [
            'layer',
            'bank',
            'account',
            'way',
            'min',
            'max',
            'receiver',
            'memo',
            'client',
            'status',
            'updatedAt',
            'updatedBy'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        showPagination
        total={list.total}
        page={form.page}
        onChangePage={(_, page) => { setForm((form) => ({ ...form, page })) }}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FinancePiggyBankTable)
