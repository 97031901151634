import React, { useState, useMemo, useCallback, createContext, Dispatch, SetStateAction } from 'react'
import { PaginationRes, PaginationReq, BannerNotificationQuery } from '@golden/gdk-admin'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import Paper from '@material-ui/core/Paper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl, useGetData } from '../../../utils/default/ComplexFlowHook'
import {
  Cell,
  createTableData,
  createDefaultPaginationData,
  formatDateTime
} from '../../../utils/default/TableHelper'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import { searchToRequest } from '../../../views/admin/forestage/BannerNotificationPage'
import allRoute from '../route/route'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'
import { BannerNotification } from '@golden/gdk-admin/dist/interface/BannerNotification'
import bannerNotificationTargetTypeName from '../../../constants/admin/bannerNotificationTargetTypeName'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import BannerNotificationTableStatus from './BannerNotificationTableStatus'
import BannerNotificationTableButtons from './BannerNotificationTableButtons'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import MemoTextWithContext from '../../default/memo/MemoTextWithContext'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  text: {
    width: 160
  },
  title: {
    textAlign: 'center'
  }
}))

const LinkMemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

interface RowType extends Cell {
  target: string
  receiver_text: React.ReactElement
  titles: React.ReactElement
  content: React.ReactElement
  time: React.ReactElement
  status: React.ReactElement
  counts: React.ReactElement
  created_at: React.ReactElement
  created_by: string
  buttons: React.ReactElement
}

const ReceiverListDialog: React.FC<{ open: boolean, close: () => void, id: number }> = (props) => {
  const { open, id, close } = props
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<PaginationRes<string[]>>(createDefaultPaginationData([]))

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  useGetData({
    gdkFunc: () => gdk.notification.getBannerNotificationReceiverList(id, { page, per_page: 25 }),
    gdkFuncDependencies: [gdk, id, page],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: open
  })

  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item
      }
    })
  }, [list.data])

  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'left'
        }
      },
      [
        'account'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = useCallback((_, page) => { setPage(page) }, [])

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={clsx(classes.title, dialogClasses.title)}>{t('common.nameList')}</DialogTitle>
      <DialogContent dividers>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={list.total}
            showPagination
            page={page}
            perPage={list.per_page}
            onChangePage={handlePagination}
          />
        </LoadingAndErrorFrame>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button onClick={close} classes={{ root: dialogClasses.okButton }}>
            {t('common.close')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const BannerNotificationTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [list, setList] = useState<PaginationRes<Array<BannerNotification<Date>>>>(
    createDefaultPaginationData([] as Array<BannerNotification<Date>>)
  )
  const [reloadFlag, setReloadFlag] = useState<boolean>(true)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [dialogId, setDialogId] = useState<number>(0)

  const payload = useMemo(() => {
    const payload: BannerNotificationQuery & PaginationReq = {
      ...request,
      page: request?.page ?? 1
    }
    return payload
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.notification.getBannerNotifications(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<BannerNotification<Date>>>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const handleReload = useCallback(() => { setReloadFlag((reloadFlag) => !reloadFlag) }, [])

  const [rows, setRows] = useState<RowType[]>([])
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(list.data.map(async (entry) => {
      return {
        id: entry.id,
        target: t(bannerNotificationTargetTypeName[entry.target]),
        receiver_text: (entry.receiver_text.includes(' ~ '))
          ? (
              <span
                className={commonClasses.anchor}
                onClick={() => {
                  setDialogId(entry.id)
                  setOpenDialog(true)
                }}
              >
                {entry.receiver_text}
              </span>
            )
          : (<span>{entry.receiver_text}</span>),
        titles: (
          <Box>
            { entry.title }
            <br />
            <span style={{ color: '#ccc' }} >{ entry.sub_title }</span>
          </Box>
        ),
        content: (
          <Box>
            { isNaN(Number(entry.content))
              ? (
                  <MemoTextWithContext
                    memoPopoverContext={LinkMemoContext}
                    memo={entry.content}
                    classes={{ text: classes.text }}
                  >
                    <a href={entry.content} target="_blank" rel="noopener noreferrer">{entry.content}</a>
                  </MemoTextWithContext>
                )
              : entry.content
            }
          </Box>),
        time: (<Box>
          {formatDateTime(entry.start_at)}
          <br />
          { t('common.to') }
          <br />
          {formatDateTime(entry.end_at)}
        </Box>),
        status: (
          <BannerNotificationTableStatus
            id={entry.id}
            isActive={entry.is_active}
            endAt={entry.end_at}
            reload={handleReload}
          />
        ),
        counts: (<Box>{`${entry.click_count} / ${entry.notification_count}`}</Box>),
        created_at: (<DateTime time={entry.created_at} />),
        created_by: entry.created_by,
        buttons: (
          <BannerNotificationTableButtons
            id={entry.id}
            target={entry.target}
            reload={handleReload}
          />
        )
      }
    })))
  }, [list.data, t, handleReload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        target: {
          label: t('common.type'),
          value: 'target',
          align: 'center',
          width: 80
        },
        receiver_text: {
          label: t('common.sendTarget'),
          value: 'receiver_text',
          align: 'center',
          width: 120
        },
        titles: {
          label: `${t('common.title')}/${t('common.subTitle')}`,
          value: 'titles',
          align: 'center',
          width: 160
        },
        content: {
          label: `${t('common.surveyId')}/${t('common.link')}`,
          value: 'content',
          align: 'center',
          width: 160
        },
        time: {
          label: t('common.displayTime'),
          value: 'time',
          align: 'center',
          width: 160
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 160
        },
        counts: {
          label: `${t('common.clickCount')}/${t('common.sendCount')}`,
          value: 'counts',
          align: 'center',
          width: 100
        },
        created_at: {
          label: t('common.createdAt2'),
          value: 'created_at',
          align: 'center',
          width: 120
        },
        created_by: {
          label: t('common.createdBy'),
          value: 'created_by',
          align: 'center',
          width: 120
        },
        buttons: {
          label: t('common.function'),
          value: 'buttons',
          align: 'center',
          width: 60
        }
      },
      [
        'target',
        'receiver_text',
        'titles',
        'content',
        'time',
        'status',
        'counts',
        'created_at',
        'created_by',
        'buttons'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestageBannerNotification.encodePath,
    pageFlow
  })

  return (
    <MemoPopoverWithContext memoPopoverContext={LinkMemoContext}>
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <CoreTable
              classes={{
                head: commonClasses.pinkTableHead,
                cellHead: commonClasses.tableCellHead,
                row: commonClasses.tableRow
              }}
              data={data}
              total={list.total}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
              loading={pageFlow.status.loading}
            />
            <ReceiverListDialog id={dialogId} open={openDialog} close={() => { setOpenDialog(false) }} />
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(BannerNotificationTable)
