import React, { createContext, useContext, useMemo, useEffect } from 'react'
import { AdminDivisionsOfChina } from '@golden/pccode-china'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { getBankAccountInputProps, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import NumberInput from '../../default/form/NumberInput'
import useT from '../../../i18ns/admin/useT'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { createValidateNotEmpty, createErrorResult, createCorrectResult, createValidateRange } from '../../../utils/default/Validator'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

const bankAccountInputProps = getBankAccountInputProps()
const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

export interface CardFormType {
  name: string
  account: string
  bank: number | '--'
  province: number | '--'
  city: number | '--'
}

export const initialCardForm: InitialFormFunc<CardFormType> = (defaultForm) => ({
  name: '',
  account: '',
  bank: '--',
  province: '--',
  city: '--',
  ...defaultForm
})

const getValueFromCardForm: ValueGetter<CardFormType> = {
  name: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  bank: getValueFromChangeEvent,
  province: getValueFromChangeEvent,
  city: getValueFromChangeEvent
}

const CardFormContext = createContext(createDefaultFormState(initialCardForm()))

const AccountNumberInput: React.FC<{ originalAccount: string }> = React.memo((props) => {
  const { originalAccount } = props
  const gdk = useGDK()
  const { value, error, handleChange, dispatch, setError } = useContext(CardFormContext)
  const { t } = useT()

  useEffect(() => {
    if (value.account !== '' && value.account.length >= 16 && originalAccount !== value.account) {
      const validationSubscription = gdk.player.validatePlayerDebitCard({ bank_account: value.account })
        .subscribe({
          next: (res) => {
            if (!res.result) {
              setError((error) => ({ ...error, account: t('error.cardMustNotRepeat') }))
            }
          }
        })
      const infoSubscription = gdk.finance.getDebitCardInfo({ bank_account: value.account })
        .subscribe({
          next: (res) => {
            try {
              AdminDivisionsOfChina.getProvinceCities(Number(res.province))
              // city code 有可能為0，用特例許可，不為 0 時才檢查相應城市編碼是否存在
              if (res.city !== 0) {
                AdminDivisionsOfChina.getCityInProvince(Number(res.city))
              }
              dispatch({ type: 'set', value: { ...value, bank: res.bank, province: res.province, city: res.city } })
            } catch (error) {
              dispatch({ type: 'set', value: { ...value, bank: '--', province: '--', city: '--' } })
            }
          },
          error: () => {
            dispatch({ type: 'set', value: { ...value, bank: '--', province: '--', city: '--' } })
          }
        })

      return () => {
        validationSubscription.unsubscribe()
        infoSubscription.unsubscribe()
      }
    } else {
      dispatch({ type: 'set', value: { ...value, province: '--', city: '--' } })
    }
  }, [value.account, gdk, originalAccount])
  const onChange = handleChange('account')
  return (
    <React.Fragment>
      <NumberInput
        fullWidth
        label={t('common.bankAccount')}
        inputProps={bankAccountInputProps}
        value={value.account}
        onChange={onChange}
        error={error.account !== null}
        helperText={error.account ?? ''}
      />
    </React.Fragment>
  )
})

interface PropTypes {
  open: boolean
  title: string
  disableName?: boolean
  onClose: () => void
  onSubmit: (form: CardFormType) => CardFormType
  defaultValue: CardFormType
}

const DebitCardInfoDialog: React.FC<PropTypes> = (props) => {
  const { open, title, disableName, onClose, onSubmit, defaultValue } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const supportBanks = useGDKStore.finance.supportBanks()
  const validation = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange('account', (value: unknown) => (value as string).length, 'moreEqual', 16, t('error.invalidDebitCard')),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      bank: [
        {
          func: (value) => {
            if (value as string === '--') {
              return createErrorResult<CardFormType>('bank', t('error.mustNotEmpty'))
            }
            return createCorrectResult<CardFormType>('bank')
          },
          when: ['blur', 'beforeClickSubmit']
        }
      ],
      province: [],
      city: []
    } as FormValidation<CardFormType>
  }, [t])
  const bankOptions = useMemo(
    () => [{ name: '--', value: '--' }].concat(supportBanks.map((bank) => ({ name: bank.name, value: bank.id })) as any),
    [supportBanks]
  )

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {title}
      </Box>
      <FormStateProvider
        context={CardFormContext}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromCardForm}
        validation={validation}
      >
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormField<CardFormType, TextFieldProps>
                  context={CardFormContext}
                  component={TextField}
                  name="name"
                  label={t('common.realName')}
                  disabled={disableName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountNumberInput originalAccount={defaultValue.account} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField<CardFormType, DropDownProps>
                  context={CardFormContext}
                  component={DropDown}
                  name="bank"
                  options={bankOptions}
                  label={t('common.bank')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={CardFormContext}
                component={Button}
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {t('common.confirm')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(DebitCardInfoDialog)
