import React, { useReducer, useCallback, useState, useMemo } from 'react'
import { Platform, PlatformType, GameCategoryType, GameStatus } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '../../../components/admin/externalGame/Switch'
import StatusLabel from '../../../components/admin/StatusLabel'
import GameButton from '../../../components/admin/externalGame/GameButton'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetData, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import GameSaveButton from '../../../components/admin/externalGame/GameSaveButton'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { StorePlatform, getWalletName, getStorePlatform } from '../../../utils/default/PlatformHelper'
import { cloneDeep } from '@golden/utils'

interface ExternalGameForm {
  platforms: StorePlatform[]
  selected: {
    platform: PlatformType
    game: number
  }
}

interface SetAction {
  type: 'set'
  payload: ExternalGameForm
}

interface SelectPlatformAction {
  type: 'selectPlatform'
  platformId: number
}

interface SwitchSelectedPlatformAction {
  type: 'switchSelectedPlatform'
}

interface SelectGameAction {
  type: 'selectGame'
  gameId: number
}

interface ChangeGameStateAction {
  type: 'changeGameState'
  gameId: number
  state: GameStatus
}

interface ChangeCategoryAction {
  type: 'changeCategory'
  gameId: number
  category: GameCategoryType
}

interface changeMemoAction {
  type: 'changeMemo'
  gameId: number
  memo: string
}

type Actions = SetAction
| SelectPlatformAction
| SwitchSelectedPlatformAction
| SelectGameAction
| ChangeGameStateAction
| ChangeCategoryAction
| changeMemoAction

function reducer (
  state: ExternalGameForm,
  action: Actions
): ExternalGameForm {
  const { type } = action
  const { platforms, selected } = state

  if (type === 'set') {
    const { payload } = action
    return payload
  }

  if (type === 'selectPlatform') {
    const { platformId } = action
    return {
      platforms,
      selected: {
        ...selected,
        platform: platformId
      }
    }
  }

  if (type === 'switchSelectedPlatform') {
    if (!platforms.map((platform) => platform.id).includes(selected.platform)) return state
    const result = cloneDeep(platforms)
    const selectedPlateform = result.find((platform) => platform.id === selected.platform)
    if (selectedPlateform === undefined) return state
    const isActive = !selectedPlateform.is_active
    selectedPlateform.is_active = isActive
    selectedPlateform.games.forEach((game) => {
      game.status = isActive ? GameStatus.ONLINE : GameStatus.STOP_SALE
    })
    return {
      platforms: result,
      selected
    }
  }

  if (type === 'selectGame') {
    const { gameId } = action
    return {
      platforms,
      selected: {
        ...selected,
        game: gameId
      }
    }
  }

  if (type === 'changeGameState') {
    const { gameId, state: status } = action
    const result = cloneDeep(platforms)
    const selectedPlatform = result.find((platform) => platform.id === selected.platform)
    if (selectedPlatform === undefined) return state
    const targetGame = selectedPlatform.games.find((game) => game.id === gameId)
    if (targetGame === undefined) return state
    targetGame.status = (status as number)

    const isActive = selectedPlatform.games.some((game) => ![GameStatus.STOP_SALE].includes(game.status))
    selectedPlatform.is_active = isActive
    return {
      platforms: result,
      selected
    }
  }

  if (type === 'changeCategory') {
    const { gameId, category } = action
    const result = cloneDeep(platforms)
    const selectedPlatform = result.find((platform) => platform.id === selected.platform)
    if (selectedPlatform === undefined) return state
    const targetGame = selectedPlatform.games.find((game) => game.id === gameId)
    if (targetGame === undefined) return state
    targetGame.category = category as number
    return {
      platforms: result,
      selected
    }
  }

  if (type === 'changeMemo') {
    const { gameId, memo } = action
    const result = cloneDeep(platforms)
    const selectedPlatform = result.find((platform) => platform.id === selected.platform)
    if (selectedPlatform === undefined) return state
    const targetGame = selectedPlatform.games.find((game) => game.id === gameId)
    if (targetGame === undefined) return state
    targetGame.memo = memo

    return {
      platforms: result,
      selected
    }
  }

  return state
}

const ExternalGamePage: React.FC = () => {
  const classes = useCommonStyles()
  const { reload, reloadFlag } = useReload()
  const [original, setOriginal] = useState<Platform[]>([])
  const [state, dispatch] = useReducer(reducer, {
    platforms: [] as StorePlatform[],
    selected: {
      platform: 1,
      game: 1
    }
  })
  const { platforms, selected } = state
  const pageFlow = usePageFlow(true, false)
  const gdk = useGDK()
  const { t } = useT()

  useGetData({
    gdkFunc: () => gdk.platform.getPlatformExcludeGolden(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Platform[]) => {
      setOriginal(res)
      dispatch({
        type: 'set',
        payload: {
          platforms: getStorePlatform(res, t).filter((platform) => (platform.games.some((game) => (game.status !== GameStatus.REMOVED)))),
          selected: {
            platform: res[0]?.id || 1,
            game: res[0]?.games[0]?.id || 1
          }
        }
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const selectedPlatform = useMemo(() => platforms.find((platform) => platform.id === selected.platform), [platforms, selected.platform])
  const forbidTransfer = useMemo(() => selectedPlatform?.games.every((game) => [GameStatus.MAINTENANCE, GameStatus.STOP_SALE].includes(game.status)) ?? false, [selectedPlatform?.games])

  const handlePlatformSelect = useCallback((id: number) => { dispatch({ type: 'selectPlatform', platformId: id }) }, [])
  const handleSelectedPlatformSwitch = useCallback(() => { dispatch({ type: 'switchSelectedPlatform' }) }, [])
  const handleGameSelect = useCallback((id: number) => { dispatch({ type: 'selectGame', gameId: id }) }, [])
  const handleGameStateChange = useCallback(
    (id: number, state: GameStatus) => { dispatch({ type: 'changeGameState', gameId: id, state }) },
    []
  )
  const handleMemoChange = useCallback((id: number, memo: string) => { dispatch({ type: 'changeMemo', gameId: id, memo }) }, [])

  const writable = useChecker()

  return (
    <Box paddingY={4}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <ScrollablePaper marginX={4}>
              <Box padding={4}>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  minWidth={150}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.gameProviderOption')}
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Grid container spacing={2} direction="row">
                    {platforms.map((platform) => (
                      <Grid item xl={3} lg={4} md={6} sm={12} key={platform.id}>
                        <StatusLabel
                          id={platform.id}
                          onClick={handlePlatformSelect}
                          selected={selected.platform === platform.id}
                          isActive={platform.is_active}
                          text={`${platform.instance.walletName}${platform.is_warned && import.meta.env.VITE_APP_STAGE !== 'prod' ? ' ▲' : ''}`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </ScrollablePaper>
          </Grid>
          <Grid item>
            <ScrollablePaper marginX={4}>
              <Box padding={4}>
                <Box
                  display="flex"
                  flexDirection="row"
                  minWidth={300}
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {getWalletName(selected.platform, platforms)}
                  </Typography>
                  <Box display="flex" flexGrow={1} />
                  <Switch
                    disabled={!writable}
                    checked={selectedPlatform?.is_active ?? false}
                    onClick={handleSelectedPlatformSwitch}
                  />
                </Box>
                <Box paddingY={2}>
                  <Grid container spacing={2} direction="row">
                    {selectedPlatform?.games.map((game) => (
                      <Grid item xl={3} lg={4} md={6} sm={12} key={game.id}>
                        <GameButton
                          disabled={!writable}
                          gameId={game.id}
                          text={game.instance?.name ?? ''}
                          state={game.status}
                          selected={game.id === selected.game}
                          onClick={handleGameSelect}
                          onStateChange={handleGameStateChange}
                          category={(game.category)}
                          memo={(game.memo ?? '')}
                          forbidTransfer={forbidTransfer}
                          onMemoChange={handleMemoChange}
                        />
                      </Grid>
                    )) ?? null}
                  </Grid>
                </Box>
              </Box>
            </ScrollablePaper>
          </Grid>
          {writable && (
            <Grid item>
              <Box display="flex" justifyContent="flex-end" marginX={4}>
                <GameSaveButton reload={reload} platforms={platforms} original={original} />
              </Box>
            </Grid>
          )}
        </Grid>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(ExternalGamePage)
