import React, { useCallback, useMemo } from 'react'
import { DepositWay } from '@golden/gdk-admin'
import { useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest } from '../../../views/admin/finance/FinanceDepositWayPage'
import depositTypeName from '../../../constants/default/depositTypeName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const ButtonsBase: React.FC<{
  status: boolean
  handleStart: () => void
  handleStop: () => void
  handleDelete: () => void
  handleUpdate: () => void
}> = React.memo((props) => {
  const {
    status,
    handleStart,
    handleStop,
    handleDelete,
    handleUpdate
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justifyContent="center">
      {
        !status &&
          (
            <Grid item>
              <Button
                onClick={handleStart}
                size="small"
                className={classes.greenGradualOutlineButton}
              >
                {t('common.toggleOn')}
              </Button>
            </Grid>
          )
      }
      {
        status &&
          (
            <Grid item>
              <Button
                onClick={handleStop}
                size="small"
                className={classes.greyOutlineButton}
              >
                {t('common.toggleOff')}
              </Button>
            </Grid>
          )
      }
      <Grid item>
        <Button
          size="small"
          className={classes.blueGradualOutlineButton}
          onClick={handleUpdate}
        >
          {t('common.update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleDelete}
          size="small"
          className={classes.pinkGradualOutlineButton}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
})

interface PropTypes {
  item: DepositWay
  reload: () => void
}

const FinanceDepositWayButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const navigate = useNavigate()
  const request = useRequestFromSearch({ searchToRequest })
  const forwarders = useGDKStore.finance.forwarders()

  const startPayload = useMemo(() => ({
    forwarder_id: item.forwarder_id,
    category: item.category,
    method: item.method,
    cash_min: Number(item.cash_min),
    cash_max: Number(item.cash_max),
    cash_full: Number(item.cash_full),
    fee_rate: item.fee_rate,
    layer_ids: item.layers.map((item) => item.id),
    agent_types: item.agent_types,
    is_active: true,
    is_recommended: item.is_recommended,
    is_for_proxy_deposit: item.is_for_proxy_deposit,
    clients: item.clients
  }), [item])
  const handleStart = useDialogHandleClick({
    dialogId: `startDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmSartDepositWay')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.startDepositWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: startPayload,
    gdkFunc: (payload) => gdk.finance.updateDepositWay(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  const stopPayload = useMemo(() => ({
    forwarder_id: item.forwarder_id,
    category: item.category,
    method: item.method,
    cash_min: Number(item.cash_min),
    cash_max: Number(item.cash_max),
    cash_full: Number(item.cash_full),
    fee_rate: item.fee_rate,
    layer_ids: item.layers.map((item) => item.id),
    agent_types: item.agent_types,
    is_active: false,
    is_recommended: item.is_recommended,
    is_for_proxy_deposit: item.is_for_proxy_deposit,
    clients: item.clients
  }), [item])
  const handleStop = useDialogHandleClick({
    dialogId: `stopDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmStopDepositWay')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.stopDepositWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: stopPayload,
    gdkFunc: (payload) => gdk.finance.updateDepositWay(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: `${t('dialog.confirmDeleteDepositWay')} [${item.forwarder_name}] [${t(depositTypeName[item.method])}]`
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteDepositWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: item.id,
    gdkFunc: (payload) => gdk.finance.deleteDepositWay(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  const handleUpdate = useCallback(() => {
    if (forwarders[item.slug]?.forwarders.some((forwarder) => forwarder.id === item.forwarder_id)) {
      navigate(allRoute.financeDepositWayUpdate.encodePath({ search: { ...request }, param: { id: item.id } }))
      return
    }
    globalDialog.setConfig(createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: t('error.pleaseEnableForwarder'),
      showCancel: false
    }))
    globalDialog.setOpen({
      id: `updateWithdrawWay-${item.id}`,
      value: true,
      isOK: false
    })
  }, [forwarders, globalDialog, item.forwarder_id, item.id, item.slug, navigate, request, t])

  return (
    <ButtonsBase
      status={item.is_active}
      handleStart={handleStart}
      handleStop={handleStop}
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
    />
  )
}

export default React.memo(FinanceDepositWayButtons)
