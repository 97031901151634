import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, PaginationReq, ActivityType, ActivityNewYear2025Draw, ActivityNewYear2025DrawQuery, ActivityNewYear2025DrawSum, ActivityNewYear2025DrawStatusType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useLocation } from 'react-router-dom'
import { newYear2025DrawStatusName } from '../../../constants/default/activityStatusName'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import type { FormType } from './ActivityNewYear2025DrawSentDialog'
import ActivityNewYear2025DrawSentDialog from './ActivityNewYear2025DrawSentDialog'
import activityNewYear2025CategoryName from '../../../constants/admin/activityNewYear2025DrawCategoryName'
import { newYear2025CategoryType } from '@golden/gdk-campaign'

interface SumRowType1 {
  id: number
  total: number
  noUse: number
  notReceived: number
  received: number
  lose: number
  expired: number
}

interface SumRowType2 {
  id: number
  totalBonus: string
  bonus888: string
  bonus88: string
  bonus58: string
  bonus18: string
  bonus8: string
  bonus0: string
}

interface SumRowType3 {
  id: number
  live: string
  videoCard: string
  sportEsport: string
}

interface RowType {
  id: number
  serialNumber: string
  createdAt: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement | string
  bonus: string
  updatedAt: React.ReactElement | string
  updatedBy: string
  memo: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const Button = React.memo(MuiButton)

const SentButton: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [open, setOpen] = useState(false)
  const activities = useGDKStore.activity.activities()

  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest: (form: FormType) => form,
    globalDialog,
    dialogId: 'sentByHandDialog',
    gdkFunc: (payload) => gdk.activity.postActivityRecordNewYear2025DrawTicket(payload),
    gdkFuncDependencies: [gdk],
    getChangeDialogConfig: (form) => createGlobalDialogConfig({
      showIcon: false,
      message: (<div className={commonClasses.pre}>
        {
          `${t('dialog.confirmSentTicket')}
[${activities.find((item) => item.type === ActivityType.NEW_YEAR_2025_DRAW)?.name ?? ''}]
[${form.account}]
[${t(activityNewYear2025CategoryName[form.category])}${t('common.ticket')}]
[${form.ticket_count} ${t('common.countShort')}]
${t('common.memo')}: ${form.memo}
          `
        }
      </div>)
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('common.hasSent')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    onSuccess: () => {
      setOpen(false)
    }
  })
  return (
    <React.Fragment>
      <Button className={commonClasses.purpleGradualButton} onClick={() => { setOpen(true) }}>{t('common.sentByHand')}</Button>
      <ActivityNewYear2025DrawSentDialog open={open} onClose={() => { setOpen(false) }} onSubmit={handleSubmit} getCount={(data: { account: string, category: number }) => gdk.activity.getActivityRecordNewYear2025DrawTicket(data)} />
    </React.Fragment>
  )
}

const ActivityRecordNewYear2025DrawTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityNewYear2025Draw[]> & ActivityNewYear2025DrawSum>({
    ...createDefaultPaginationData([]),
    summary: {
      status: {
        total: 0,
        no_use: 0,
        not_received: 0,
        received: 0,
        lose: 0,
        expired: 0
      },
      bonus: {
        0: '0.0000',
        8: '0.0000',
        18: '0.0000',
        58: '0.0000',
        88: '0.0000',
        888: '0.0000',
        total: '0.0000'
      },
      ticket: {
        1: '0/0',
        2: '0/0',
        3: '0/0'
      }
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    payload: request as ActivityNewYear2025DrawQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordNewYear2025Draw(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityNewYear2025Draw[]> & ActivityNewYear2025DrawSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])

  const sumRow1 = useMemo(() => {
    return [{
      id: 1,
      total: list.summary.status.total,
      noUse: list.summary.status.no_use,
      notReceived: list.summary.status.not_received,
      received: list.summary.status.received,
      lose: list.summary.status.lose,
      expired: list.summary.status.expired
    }] as SumRowType1[]
  }, [list])
  const sumData1 = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        total: {
          label: t('common.totalCount2'),
          value: 'total',
          align: 'center'
        },
        noUse: {
          label: t('activityStatus.noUse'),
          value: 'total',
          align: 'center'
        },
        notReceived: {
          label: t('activityStatus.notReceived'),
          value: 'total',
          align: 'center'
        },
        received: {
          label: t('activityStatus.received'),
          value: 'total',
          align: 'center'
        },
        lose: {
          label: t('activityStatus.lost'),
          value: 'total',
          align: 'center'
        },
        expired: {
          label: t('activityStatus.expiredSimple'),
          value: 'total',
          align: 'center'
        }
      },
      [
        'total',
        'noUse',
        'notReceived',
        'received',
        'lose',
        'expired'
      ],
      sumRow1,
      'id'
    )
  }, [t, sumRow1])

  const sumRow2 = useMemo(() => {
    return [{
      id: 1,
      totalBonus: list.summary.bonus.total,
      bonus888: list.summary.bonus[888],
      bonus88: list.summary.bonus[88],
      bonus58: list.summary.bonus[58],
      bonus18: list.summary.bonus[18],
      bonus8: list.summary.bonus[8],
      bonus0: list.summary.bonus[0]
    }] as SumRowType2[]
  }, [list])
  const sumData2 = useMemo(() => {
    return createTableData<SumRowType2>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalBonus: {
          label: t('common.totalActivityBonus'),
          value: 'totalBonus',
          align: 'center'
        },
        bonus888: {
          label: '888',
          value: 'bonus888',
          align: 'center'
        },
        bonus88: {
          label: '88',
          value: 'bonus88',
          align: 'center'
        },
        bonus58: {
          label: '58',
          value: 'bonus58',
          align: 'center'
        },
        bonus18: {
          label: '18',
          value: 'bonus18',
          align: 'center'
        },
        bonus8: {
          label: '8',
          value: 'bonus8',
          align: 'center'
        },
        bonus0: {
          label: '0',
          value: 'bonus0',
          align: 'center'
        }
      },
      [
        'totalBonus',
        'bonus888',
        'bonus88',
        'bonus58',
        'bonus18',
        'bonus8',
        'bonus0'
      ],
      sumRow2,
      'id'
    )
  }, [t, sumRow2])

  const sumRow3 = useMemo(() => {
    return [{
      id: 1,
      live: list.summary.ticket[1],
      videoCard: list.summary.ticket[2],
      sportEsport: list.summary.ticket[3]
    }] as SumRowType3[]
  }, [list])
  const sumData3 = useMemo(() => {
    return createTableData<SumRowType3>(
      {
        id: {
          label: '',
          value: 'id'
        },
        live: {
          label: t(activityNewYear2025CategoryName[newYear2025CategoryType.LIVE]),
          value: 'live',
          align: 'center'
        },
        videoCard: {
          label: t(activityNewYear2025CategoryName[newYear2025CategoryType.VIDEO_AND_CARD]),
          value: 'videoCard',
          align: 'center'
        },
        sportEsport: {
          label: t(activityNewYear2025CategoryName[newYear2025CategoryType.SPORT_AND_ESPORT]),
          value: 'sportEsport',
          align: 'center'
        }
      },
      [
        'live',
        'videoCard',
        'sportEsport'
      ],
      sumRow3,
      'id'
    )
  }, [t, sumRow3])

  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        serialNumber: item.serial_number,
        createdAt: (<DateTime time={item.created_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: t(newYear2025DrawStatusName[item.status]),
        bonus: (item.status === ActivityNewYear2025DrawStatusType.EXPIRED || item.status === ActivityNewYear2025DrawStatusType.NO_USE) ? '-' : formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : ('--'),
        updatedBy: item.updated_by,
        memo: item.memo
      } as RowType
    })
  }, [list.data, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serialNumber: {
          label: t('common.activitySerial'),
          value: 'serialNumber',
          align: 'center'
        },
        createdAt: {
          label: t('common.applyTime'),
          value: 'createdAt',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'serialNumber',
        'createdAt',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'updatedBy',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Grid container justify='space-between'>
                  <Grid item>
                    <Typography variant="h5">
                      {activities.find((item) => item.type === ActivityType.NEW_YEAR_2025_DRAW)?.name ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SentButton />
                  </Grid>
                </Grid>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.stautsStatistic')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData1}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.prizeStatistic')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData2}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.ticketStatistic')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData3}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(ActivityRecordNewYear2025DrawTable)
