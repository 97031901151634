import React, { useMemo, createContext, useState, useContext } from 'react'
import { PaginationReq, DepositCrawlerQuery, ForwarderType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { isAfter, startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, parseFloat, convertEmptyToUndefined, getValueFromValue, getCashInputProps } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createValidateDate, createValidateCash, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import DateTimePicker from '../../default/form/DateTimePicker'
import RequiredText from '../../default/form/RequiredText'
import NumberInput from '../../default/form/NumberInput'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { createShouldDisableDate } from '../../../utils/default/TimeHelper'

export type CrawlerRequest = PaginationReq & DepositCrawlerQuery

export interface CrawlerFormType {
  account: string
  name: string
  bankAccount: string
  bank: string
  note: string
  createdAtStart: Date | null
  createdAtEnd: Date | null
  min: string
  max: string
}

export const searchToRequest: SearchToRequestFunc<CrawlerRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    bank_code: acceptUndefined(search.bank_code, parseInt),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat)
  } as CrawlerRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  if (converted.cash_start && converted.cash_end && converted.cash_end < converted.cash_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as CrawlerRequest
}

export const initialForm: InitialFormFunc<CrawlerFormType> = (defaultForm) => ({
  account: '',
  name: '',
  bankAccount: '',
  bank: '--',
  note: '',
  createdAtStart: startOfDay(new Date()),
  createdAtEnd: endOfDay(new Date()),
  min: '',
  max: '',
  ...defaultForm
})

const formToRequest = (form: CrawlerFormType): CrawlerRequest => {
  const converted = {
    player_bank_account: convertEmptyToUndefined(form.account),
    player_real_name: convertEmptyToUndefined(form.name),
    golden_bank_account: convertEmptyToUndefined(form.bankAccount),
    slug: form.bank === '--' ? undefined : form.bank.split('-')[0],
    bank_code: form.bank === '--' ? undefined : (form.bank.split('-')[1] === '0' ? undefined : form.bank.split('-')[1]),
    note: convertEmptyToUndefined(form.note),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.createdAtStart!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.createdAtEnd!),
    cash_start: convertEmptyToUndefined(form.min),
    cash_end: convertEmptyToUndefined(form.max),
    page: 1
  } as CrawlerRequest
  return omitBy(converted, isUndefined) as CrawlerRequest
}

const getValueFromEvent: ValueGetter<CrawlerFormType> = {
  account: getValueFromChangeEvent,
  name: getValueFromChangeEvent,
  bankAccount: getValueFromChangeEvent,
  bank: getValueFromChangeEvent,
  note: getValueFromChangeEvent,
  createdAtStart: getValueFromValue,
  createdAtEnd: getValueFromValue,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const cashInputProps = getCashInputProps()

const DateRow: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const {
    shouldDisableStartDate: shouldDisableCreateStartDate,
    shouldDisableEndDate: shouldDisableCreateEndDate
  } = useMemo(() => {
    const past = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.createdAtStart, value.createdAtEnd, past)
  }, [value.createdAtStart, value.createdAtEnd])
  return (
    <Grid item>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
          <DateTimePicker
            value={value.createdAtStart}
            onChange={handleChange('createdAtStart')}
            label={`${t('common.bankHandle')}${t('common.beginAt')}`}
            shouldDisableDate={shouldDisableCreateStartDate}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePicker
            value={value.createdAtEnd}
            onChange={handleChange('createdAtEnd')}
            label={`${t('common.bankHandle')}${t('common.endAt')}`}
            shouldDisableDate={shouldDisableCreateEndDate}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

const DepositCrawlerForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const [banks, setBanks] = useState<Array<{ slug: ForwarderType.GOLDEN | ForwarderType.GOLDEN_ALIPAY | ForwarderType.GOLDEN_WECHAT, bank_code: number, bank_name: string }>>([])

  useGetData({
    gdkFunc: () => gdk.deposit.getDepositCrawlerBanks(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setBanks(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        account: request.player_bank_account,
        name: request.player_real_name,
        bankAccount: request.golden_bank_account,
        bank: request.slug === undefined ? '--' : `${request.slug}-${request.bank_code ?? 0}`,
        note: request.note,
        createdAtStart: new Date(request.start_at),
        createdAtEnd: new Date(request.end_at),
        min: request.cash_start?.toString() ?? '',
        max: request.cash_end?.toString() ?? ''
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.depositCrawler.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    name: [],
    bankAccount: [],
    bank: [],
    note: [],
    createdAtStart: [
      {
        func: createValidateDate('createdAtStart', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: (value, form) => {
          if (form.createdAtEnd === null) {
            return { isPass: true, stop: false, newError: { createdAtStart: null } }
          }
          if (isAfter(value as Date, form.createdAtEnd)) {
            return { isPass: false, stop: true, newError: { createdAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    createdAtEnd: [
      {
        func: createValidateDate('createdAtEnd', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: (value, form) => {
          if (form.createdAtStart === null) {
            return { isPass: true, stop: false, newError: { createdAtEnd: null } }
          }
          if (isAfter(form.createdAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { createdAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    min: [
      {
        func: createValidateCash('min', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: createValidateMin('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    max: [
      {
        func: createValidateCash('max', t),
        when: ['change', 'beforeClickSubmit']
      },
      {
        func: createValidateMax('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<CrawlerFormType>), [t])

  const bankOptions = [{ name: t('common.all'), value: '--' }].concat(
    banks.map((courier) => {
      let name = courier.bank_name
      if (courier.slug === ForwarderType.GOLDEN_ALIPAY) name = t('forwarder.goldenAlipay')
      if (courier.slug === ForwarderType.GOLDEN_WECHAT) name = t('forwarder.goldenWechat')
      return {
        name,
        value: `${courier.slug}-${courier.bank_code}`
      }
    }) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.depositCrawler')}
                  </Typography>
                </Box>
              </Grid>
              <DateRow />
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="name"
                      label={t('common.depositName')}
                      placeholder={t('placeholder.inputDepositName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.senderAccount')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="bankAccount"
                      label={t('common.depositAccount')}
                      placeholder={t('placeholder.inputDepositAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="bank"
                      options={bankOptions}
                      label={t('common.depositBank')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="note"
                      label={t('common.depositNote')}
                      placeholder={t('placeholder.inputDepositNote')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="min"
                      label={t('common.minOrderMoney')}
                      placeholder={t('placeholder.inputMinOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<CrawlerFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="max"
                      label={t('common.maxOrderMoney')}
                      placeholder={t('placeholder.inputMaxOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(DepositCrawlerForm)
