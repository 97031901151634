import React, { useMemo, useState, useCallback, useContext } from 'react'
import { PaginationRes, PaginationReq, InternalWithdrawAccount } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, createDefaultPaginationData, formatMoney } from '../../../utils/default/TableHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DateTime from '../../default/present/DateTime'
import { RequestContext } from '../../../views/admin/finance/FinanceInternalWithdrawAccountPage'
import { convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import InternalWithdrawAccountButtons from './InternalWithdrawAccountButtons'

interface RowTyep {
  id: number
  bank: string
  name: string
  account: string
  min: string
  max: string
  createdAt: React.ReactElement
  updatedBy: string
  functions: React.ReactElement
}

const InternalWithdrawAccountTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { reload, reloadFlag } = useReload()
  const [request] = useContext(RequestContext)
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<PaginationRes<InternalWithdrawAccount[]>>(
    createDefaultPaginationData([])
  )
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const writable = useChecker()
  const { t } = useT()
  const payload = useMemo(() => {
    let payload: { bank_name?: string } & PaginationReq = { page }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const bank_name = convertEmptyToUndefined(request.bank)
    if (bank_name) payload = { ...payload, bank_name }
    return payload
  }, [request, page])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getInternalWithdrawAccountList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<InternalWithdrawAccount[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        bank: item.bank_name,
        name: item.real_name,
        account: item.bank_account,
        min: item.cash_min ? formatMoney(item.cash_min) : '--',
        max: item.cash_max ? formatMoney(item.cash_max) : '--',
        createdAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        updatedBy: item.updated_by,
        functions: (
          <InternalWithdrawAccountButtons item={item} reload={reload} />
        )
      } as RowTyep
    })
  }, [list, reload])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        bank: {
          label: t('common.bankName'),
          value: 'bank',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        min: {
          label: t('common.minGetCash'),
          value: 'min',
          align: 'center'
        },
        max: {
          label: t('common.maxGetCash'),
          value: 'max',
          align: 'center'
        },
        createdAt: {
          label: t('common.createdAt'),
          value: 'createdAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'bank',
            'name',
            'account',
            'min',
            'max',
            'createdAt',
            'updatedBy',
            'functions'
          ]
        : [
            'bank',
            'name',
            'account',
            'min',
            'max',
            'createdAt',
            'updatedBy'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  const handlePagination = useCallback((_, page) => { setPage(page) }, [])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        showPagination
        page={page}
        onChangePage={handlePagination}
      />

    </LoadingAndErrorFrame>
  )
}

export default React.memo(InternalWithdrawAccountTable)
