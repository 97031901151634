import React, { createContext, useMemo, useCallback, useContext, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FourElementForm, DebitCardValidationProviderType, FourElementResult, GDKError, DebitCardValidationResultType } from '@golden/gdk-admin'
import RequiredText from '../../default/form/RequiredText'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty, createValidateRange } from '../../../utils/default/Validator'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import StateProvider from '../../../providers/default/StateProvider'
import debitcardValidationProviderName from '../../../constants/admin/debitCardValidationProviderName'
import { useDebouncedCallback } from 'use-debounce/lib'
import debitcardValidationResultName from '../../../constants/admin/debitCardValidationResultName'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  dialogContent: {
    overflow: 'hidden'
  }
}))

enum StepType {
  INFO = 0,
  VALI = 1
}

interface ValidationFormType {
  identity: string
  realName: string
  account: string
  phone: string
}

interface PropTypes {
  title: string
  confirmText: string
  id: number
  open: boolean
  onClose: () => void
  onSubmit: (token: string) => void
  defaultValue: ValidationFormType
  loading: boolean
}

export const initialValidationForm: InitialFormFunc<ValidationFormType> = (defaultForm) => ({
  identity: '',
  realName: '',
  account: '',
  phone: '',
  ...defaultForm
})

const ValidationFormContext = createContext(createDefaultFormState(initialValidationForm()))
const ProgressContext = createContext<[StepType, Dispatch<SetStateAction<StepType>>]>([StepType.INFO, () => {}])
const LoadingContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([false, () => {}])
const ValidationResultContext = createContext<[FourElementResult | null, Dispatch<SetStateAction<FourElementResult | null>>]>([null, () => {}])

const getValueFromValidationForm: ValueGetter<ValidationFormType> = {
  identity: getValueFromChangeEvent,
  realName: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  phone: getValueFromChangeEvent
}

const validationFormToRequest = (id: number) => (form: ValidationFormType): FourElementForm => ({
  user_id: id,
  id_number: form.identity,
  real_name: form.realName,
  bank_account: form.account,
  phone: form.phone
})

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const Progress: React.FC = React.memo(() => {
  const { t } = useT()
  const [activeStep] = useContext(ProgressContext)
  const labels: { [key in StepType]: string } = useMemo(() => {
    return {
      [StepType.INFO]: t('common.bankInformation'),
      [StepType.VALI]: t('common.bindDebitCard')
    }
  }, [t])
  return (
    <Stepper activeStep={activeStep}>
      {Object.keys(labels).map((key) => Number(key)).sort((a, b) => a - b).map((key) => (
        <Step key={key}>
          <StepLabel>
            {labels[key as StepType]}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
})

const InfoContent: React.FC = React.memo(() => {
  const { t } = useT()
  const [activeStep] = useContext(ProgressContext)
  const disabled = useMemo(() => activeStep !== StepType.INFO, [activeStep])
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormField<ValidationFormType, TextFieldProps>
          context={ValidationFormContext}
          component={TextField}
          name="identity"
          label={t('common.identity')}
          placeholder={t('placeholder.inputIdentity')}
          disabled={disabled}
          fullWidth
          required
        />
      </Grid>
      <Grid item>
        <FormField<ValidationFormType, TextFieldProps>
          context={ValidationFormContext}
          component={TextField}
          name="realName"
          label={t('common.realName')}
          placeholder={t('placeholder.inputRealName')}
          fullWidth
          required
          disabled
        />
      </Grid>
      <Grid item>
        <FormField<ValidationFormType, TextFieldProps>
          context={ValidationFormContext}
          component={TextField}
          name="account"
          label={t('common.bankAccount')}
          placeholder={t('placeholder.inputBankAccount')}
          fullWidth
          required
          disabled
        />
      </Grid>
      <Grid item>
        <FormField<ValidationFormType, TextFieldProps>
          context={ValidationFormContext}
          component={TextField}
          name="phone"
          label={t('common.cellphone')}
          placeholder={t('placeholder.inputPhoneNumber')}
          disabled={disabled}
          helperText={t('helperText.onlyChinaPhoneTip')}
          fullWidth
          required
        />
      </Grid>
      <Grid item>
        <RequiredText />
      </Grid>
    </Grid>
  )
})

const NextButton: React.FC<PropTypes> = React.memo((props) => {
  const { id } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const { disableSubmit } = useContext(ValidationFormContext)
  const [, setActiveStep] = useContext(ProgressContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const [, setResult] = useContext(ValidationResultContext)
  const { value } = useContext(ValidationFormContext)
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebounceSubmit] = useDebouncedCallback(useCallback(() => {
    setLoading(true)
    gdk.player.validateIdentity(validationFormToRequest(id)(value)).subscribe({
      next: (res) => {
        setResult(res)
        setActiveStep(StepType.VALI)
        setLoading(false)
      },
      error: (error: GDKError) => {
        setLoading(false)
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'validateIdentittyFail',
          isOK: false,
          value: true
        })
      }
    })
  }, [gdk, id, value]), 200)

  const handleSubmit = useCallback(handleDebounceSubmit, [])
  return (
    <Button
      disabled={disableSubmit || loading}
      className={commonClasses.purpleGradualButton}
      onClick={handleSubmit}
    >
      {loading
        ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
          )
        : t('common.nextStep')}
    </Button>
  )
})

const color: { [key in DebitCardValidationResultType]: string } = {
  [DebitCardValidationResultType.SUCCESS]: '#72d476',
  [DebitCardValidationResultType.FAIL]: '#ff5f5f',
  [DebitCardValidationResultType.THIRD_PARTY_EXCEPTION]: '#808080',
  [DebitCardValidationResultType.SYSTEM_EXCEPTION]: '#808080'
}

const ValiContent: React.FC = React.memo(() => {
  const { t } = useT()
  const [result] = useContext(ValidationResultContext)
  return (
    <Grid container direction="column" spacing={2}>
      {Object.keys(debitcardValidationProviderName).map((key) => {
        const item = result?.data.find((result) => result.name === key)
        return (
          <Grid item key={key}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography variant="h5">
                  {t(debitcardValidationProviderName[key as DebitCardValidationProviderType])}
                </Typography>
              </Grid>
              {item && (
                <Grid item>
                  <Typography variant="h5" style={{ color: color[item.result] }}>
                    {t(debitcardValidationResultName[item.result])}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
})

const SubmitButton: React.FC<PropTypes> = React.memo((props) => {
  const { onSubmit, confirmText, loading } = props
  const commonClasses = useCommonStyles()
  const [result] = useContext(ValidationResultContext)
  const [handleDebounceSubmit] = useDebouncedCallback(useCallback(() => {
    onSubmit(result?.token ?? '')
  }, [onSubmit, result]), 200)

  const handleSubmit = useCallback(handleDebounceSubmit, [])
  return (
    <Button
      className={commonClasses.purpleGradualButton}
      disabled={loading}
      onClick={handleSubmit}
    >
      {loading
        ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
          )
        : confirmText}
    </Button>
  )
})

const button: { [key in StepType]: React.FC<PropTypes> } = {
  [StepType.INFO]: NextButton,
  [StepType.VALI]: SubmitButton
}

const StepButton: React.FC<PropTypes> = React.memo((props) => {
  const [activeStep] = useContext(ProgressContext)
  const Component = button[activeStep]
  return (
    <Component {...props} />
  )
})

const DebitCardValidationDialog: React.FC<PropTypes> = (props) => {
  const { open, title } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const classes = useStyles()
  const { onClose, defaultValue } = props
  const validation = useMemo(() => {
    return {
      identity: [
        {
          func: createValidateNotEmpty('identity', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      realName: [
        {
          func: createValidateNotEmpty('realName', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange('account', (value: unknown) => (value as string).length, 'moreEqual', 16, t('error.invalidDebitCard')),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      phone: [
        {
          func: createValidateNotEmpty('phone', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<ValidationFormType>
  }, [t])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {title}
      </Box>
      <StateProvider
        context={ProgressContext}
        defaultValue={StepType.INFO}
      >
        <Progress />
        <FormStateProvider
          context={ValidationFormContext}
          defaultValue={defaultValue}
          onSubmit={useCallback((form: ValidationFormType) => form, [])}
          getValueFromEvent={getValueFromValidationForm}
          validation={validation}
        >
          <StateProvider
            context={LoadingContext}
            defaultValue={false}
          >
            <StateProvider
              context={ValidationResultContext}
              defaultValue={null}
            >
              <DialogContent className={classes.dialogContent}>
                <Grid container direction="row" spacing={6} alignItems="flex-start">
                  <Grid item xs={6}>
                    <InfoContent />
                  </Grid>
                  <Grid item xs={6}>
                    <ValiContent />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Grid container direction="row" spacing={2} justifyContent="center">
                  <Grid item>
                    <Button
                      className={commonClasses.greyButton}
                      onClick={onClose}
                    >
                      {t('common.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <StepButton {...props} />
                  </Grid>
                </Grid>
              </DialogActions>
            </StateProvider>
          </StateProvider>
        </FormStateProvider>
      </StateProvider>
    </Dialog>
  )
}

export default React.memo(DebitCardValidationDialog)
