import React, { useMemo } from 'react'
import { getTime } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import { ActivityType, ActivityNewYear2025DrawStatusType } from '@golden/gdk-admin'
import Grid from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import { FormState, ValueGetter } from '../../../utils/default/FormHook'
import { ActivityFormType, ActivityRequest } from './ActivityRecordDetailForm'
import useT from '../../../i18ns/admin/useT'
import { getValueFromChangeEvent, convertEmptyToUndefined, acceptUndefined, pipe, guaranteeBeKey } from '../../../utils/default/FormHelper'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { newYear2025DrawStatusName } from '../../../constants/default/activityStatusName'

export interface ActivityNewYear2025DrawFormType {
  account: string
  agent_account: string
  newYear2025DrawStatus: ActivityNewYear2025DrawStatusType | '--'
}

export const initialForm = (): ActivityNewYear2025DrawFormType => ({
  account: '',
  agent_account: '',
  newYear2025DrawStatus: '--'
})

export const getValueFromEvent: ValueGetter<ActivityNewYear2025DrawFormType> = {
  account: getValueFromChangeEvent,
  agent_account: getValueFromChangeEvent,
  newYear2025DrawStatus: getValueFromChangeEvent
}

export const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: ActivityType.NEW_YEAR_2025_DRAW,
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    status: form.newYear2025DrawStatus === '--' ? undefined : form.newYear2025DrawStatus,
    type: form.type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  return omitBy(converted, isUndefined) as ActivityRequest
}

export const searchToRequest: SearchToRequestFunc<{
  account: string | undefined
  agent_account: string | undefined
  status: string | undefined
}> = (search) => {
  const converted = {
    account: search.account,
    agent_account: search.agent_account,
    newYear2025DrawStatus: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(newYear2025DrawStatusName))
    ))
  }
  return omitBy(converted, isUndefined) as any
}

interface PropTypes {
  context: React.Context<FormState<ActivityFormType>>
}

const TextField = React.memo(MuiTextField)

const ActivityNewYear2025DrawForm: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { t } = useT()

  const stateOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(newYear2025DrawStatusName)
          .map((key) => ({ name: t(newYear2025DrawStatusName[key as ActivityNewYear2025DrawStatusType]), value: key }))
      )
  }, [t])

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="account"
          label={t('common.playerAccount')}
          placeholder={t('placeholder.inputPlayerAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, TextFieldProps>
          context={context}
          component={TextField}
          name="agent_account"
          label={t('common.agentAccount')}
          placeholder={t('placeholder.inputAgentAccount')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormField<ActivityFormType, DropDownProps>
          context={context}
          component={DropDown}
          name="newYear2025DrawStatus"
          label={t('common.status')}
          options={stateOptions}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityNewYear2025DrawForm)
