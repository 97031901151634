import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { AdminPlayerDebitCard } from '@golden/gdk-admin'
import { AdminDivisionsOfChina } from '@golden/pccode-china'
import { useCommonStyles, useDialogStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

const Button = React.memo(MuiButton)

interface PropTypes {
  index: number
  id: number
  card: AdminPlayerDebitCard
  reload: () => void
}

const DebitCardDeleteButton: React.FC<PropTypes> = (props) => {
  const { index, id, card, reload } = props
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    setOpen(false)
    gdk.player.deletePlayerDebitCard(id, card.id)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.deleteCardSuccess')
          }))
          globalDialog.setOpen({
            id: `deleteDebitCardSuccess-${card.id}`,
            value: true,
            isOK: false
          })
          reload()
        },
        error: (error) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: `deleteDebitCardFail-${card.id}`,
            value: true,
            isOK: false
          })
        }
      })
  }, [gdk, id, card.id]), 200)
  const onClick = useCallback(handleDebouncedOK, [])
  return (
    <React.Fragment>
      <Button
        disabled={index === 0}
        onClick={useCallback(() => { setOpen(true) }, [])}
        className={commonClasses.pinkGradualOutlineButton}
      >
        {t('common.delete')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('common.deleteDebitCard')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Typography align="center" className={dialogClasses.text}>{t('dialog.confirmDeleteCard')}</Typography>
            <table className={detailClasses.table}>
              <tbody>
                <tr>
                  <td className={detailClasses.head}>{t('common.realName')}</td>
                  <td className={detailClasses.cell}>{card.real_name}</td>
                </tr>
                <tr>
                  <td className={detailClasses.head}>{t('common.bankAccount')}</td>
                  <td className={detailClasses.cell}>{card.bank_account}</td>
                </tr>
                <tr>
                  <td className={detailClasses.head}>{t('common.bank')}</td>
                  <td className={detailClasses.cell}>{card.bank_name}</td>
                </tr>
                <tr>
                  <td className={detailClasses.head}>{t('common.province')}</td>
                  <td className={detailClasses.cell}>{AdminDivisionsOfChina.getProvinces().find((province) => province.code === card.province)?.name ?? '--'}</td>
                </tr>
                <tr>
                  <td className={detailClasses.head}>{t('common.city')}</td>
                  <td className={detailClasses.cell}>{AdminDivisionsOfChina.getCities().find((city) => city.code === card.city)?.name ?? '--'}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={useCallback(() => { setOpen(false) }, [])}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={onClick}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(DebitCardDeleteButton)
