import React, { useState, useMemo, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { PaginationRes, PaginationReq, Greeting } from '@golden/gdk-admin'
import { useCommonStyles } from '../../utils/admin/StyleHook'
import useGDK from '../../providers/admin/gdk/useGDK'
import useT from '../../i18ns/admin/useT'
import GreetingTable from '../../components/admin/greeting/GreetingTable'
import GreetingDialog from '../../components/admin/greeting/GreetingDialog'
import GreetingForm, { GreetingFormType, ActionType, initialForm } from '../../components/admin/greeting/GreetingForm'
import LoadingAndErrorFrame from '../../components/default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload } from '../../utils/default/ComplexFlowHook'
import { createDefaultPaginationData } from '../../utils/default/TableHelper'
import { usePageFlow } from '../../utils/default/PageFlowHook'
import ScrollablePaper from '../../components/default/present/ScrollablePaper'

export interface GreetingPageState {
  isOpen: boolean
  dialogTitle: string
  defaultValue: GreetingFormType
  action: ActionType
}

const initialState: GreetingPageState = {
  action: 'CREATE',
  isOpen: false,
  dialogTitle: '',
  defaultValue: initialForm()
}

const GreetingPage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()

  const [state, setState] = useState<GreetingPageState>(initialState)
  const [page, setPage] = useState<number>(1)
  const [greetingList, setGreetingList] = useState<PaginationRes<Array<Greeting<Date>>>>(
    createDefaultPaginationData([] as Array<Greeting<Date>>)
  )
  const [reloadFlag, setReloadFlag] = useState<boolean>(true)

  const handleChangePage = useCallback((_, page) => { setPage(page) }, [])
  const toggleDialog = useCallback(() => { setState(prev => ({ ...prev, isOpen: !prev.isOpen })) }, [])
  const handleReload = useCallback(() => { setReloadFlag((reloadFlag) => !reloadFlag) }, [])

  const payload = useMemo(() => {
    const payload: PaginationReq = {
      page
    }
    return payload
  }, [page])

  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.greeting.getGreetingList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<Greeting<Date>>>) => {
      setGreetingList(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const handleCreate = useCallback(() => {
    setState({
      action: 'CREATE',
      dialogTitle: t('common.createGreeting'),
      defaultValue: initialForm(),
      isOpen: true
    })
  }, [t])
  const handleClick = useCallback((id: number, action: ActionType) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = greetingList.data.find((el) => el.id === id)!
    setState({
      action,
      dialogTitle: action === 'UPDATE' ? t('common.updateGreeting') : t('common.deleteGreeting'),
      isOpen: true,
      defaultValue: {
        id,
        title: data.title,
        content: data.content,
        isActive: data.is_active
      }
    })
  }, [greetingList, t])

  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={4}>
        <Box padding={4}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className={classes.purpleGradualButton}
              onClick={handleCreate}
            >
              <Box paddingX={2.5}>
                {t('common.createGreeting')}
              </Box>
            </Button>
          </Box>
          <Box paddingY={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <GreetingTable
                page={page}
                greetingList={greetingList}
                onClick={handleClick}
                onChangePage={handleChangePage}
                reload={handleReload}
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>

      <GreetingDialog
        title={state.dialogTitle}
        isOpen={state.isOpen}
        content={
          <GreetingForm
            action={state.action}
            defaultValue={state.defaultValue}
            onClose={toggleDialog}
            reload={handleReload}
          />
        }
      />
    </Box>
  )
}

export default React.memo(GreetingPage)
