import React, { createContext, useEffect, useMemo } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import { makeStyles } from '@material-ui/core/styles'
import { AdminLoginReq, GDKError, ErrorCode } from '@golden/gdk-admin'
import { Navigate } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import LockIcon from '@material-ui/icons/Lock'
import ButtonBase from '../../components/default/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormStateProvider from '../../components/default/form/FormStateProvider'
import FormField from '../../components/default/form/FormField'
import FormSubmitButton from '../../components/default/form/FormSubmitButton'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../utils/default/FormHook'
import { getValueFromChangeEvent, convertEmptyToUndefined } from '../../utils/default/FormHelper'
import { createValidateNotEmpty } from '../../utils/default/Validator'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import useGDK from '../../providers/admin/gdk/useGDK'
import { useGDKFuncHandleSubmit } from '../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../providers/admin/dialog/useGlobalDialog'
import { Path } from '../../components/admin/route/route'
import { createGlobalDialogConfig } from '../../utils/default/DialogHelper'
import CustomThemeType from '../../themes/admin/CustomThemeType'
import useT from '../../i18ns/admin/useT'
import useGreetingDisplayStatus from '../../providers/admin/greeting/useGreetingDisplayStatus'
import NumberInput from '../../components/default/form/NumberInput'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  root: {
    height: `calc(100vh - ${Number(theme.mixins.toolbar.minHeight) + theme.spacing(1)}px)`,
    minHeight: 700
  },
  toolbar: {
    display: 'flex',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    ...theme.mixins.toolbar
  },
  paper: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(1),
    minHeight: 600,
    boxShadow: theme.shadows[2]
  },
  lock: {
    width: 64,
    height: 64,
    background: 'linear-gradient(175deg, #da8cff, #9a55ff)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    color: theme.palette.common.white,
    transform: 'translateY(-50%)'
  },
  lockIcon: {
    fontSize: 24
  },
  submitButton: {
    height: 48,
    background: theme.custom.palette.button.pink,
    '&:hover': {
      background: theme.custom.palette.button.pink,
      opacity: theme.custom.palette.opacity.hover,
      color: theme.palette.common.white
    }
  },
  disabledButton: {
    background: theme.custom.palette.button.disabled,
    color: `${theme.palette.text.disabled} !important`
  },
  sublink: {
    height: 48,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800]
    }
  }
}))

export interface LoginFormType {
  account: string
  password: string
  code: string
}

const codeInputProps = {
  allowLeadingZeros: true,
  decimalScale: 0,
  fixedDecimalScale: true,
  allowNegative: false,
  format: '### ###'
}

const getValueFromEvent: ValueGetter<LoginFormType> = {
  account: getValueFromChangeEvent,
  password: getValueFromChangeEvent,
  code: getValueFromChangeEvent
}

const defaultValue = {
  account: '',
  password: '',
  code: ''
}

const LoginFormContext = createContext(createDefaultFormState(defaultValue))

const TextField = React.memo(MuiTextField)

const Button = React.memo(ButtonBase)

const LoginPageBase: React.FC<{ handleSubmit: (form: LoginFormType) => LoginFormType, loading: boolean }> = React.memo((props) => {
  const { handleSubmit, loading } = props
  const classes = useStyles()
  const { t } = useT()

  const validation: FormValidation<LoginFormType> = useMemo(() => {
    return {
      account: [
        {
          func: createValidateNotEmpty<LoginFormType>('account', t),
          when: ['beforeClickSubmit']
        }
      ],
      password: [
        {
          func: createValidateNotEmpty<LoginFormType>('password', t),
          when: ['beforeClickSubmit']
        }
      ],
      code: []
    }
  }, [t])

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box minWidth={467}>
        <Paper className={classes.paper}>
          <Grid
            className={classes.lock}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <LockIcon className={classes.lockIcon} />
          </Grid>
          <Box paddingBottom={2}>
            <Typography variant="h1">{t('common.backPlateformLoginForm')}</Typography>
          </Box>
          <Box paddingY={2}>
            <Typography variant="h5">{t('common.inputAccountAndPasswordToLogin')}</Typography>
          </Box>
          <FormStateProvider<LoginFormType>
            context={LoginFormContext}
            defaultValue={defaultValue}
            onSubmit={handleSubmit}
            getValueFromEvent={getValueFromEvent}
            validation={validation}
          >
            <Box paddingBottom={2}>
              <FormField<LoginFormType, TextFieldProps>
                component={TextField}
                context={LoginFormContext}
                name="account"
                autoFocus
                fullWidth
                variant="outlined"
                placeholder={t('placeholder.inputAccount')}
              />
            </Box>
            <Box paddingBottom={2}>
              <FormField<LoginFormType, TextFieldProps>
                component={TextField}
                context={LoginFormContext}
                name="password"
                fullWidth
                variant="outlined"
                placeholder={t('placeholder.inputPassword')}
                type="password"
              />
            </Box>
            <Box paddingBottom={2}>
              <FormField<LoginFormType, TextFieldProps>
                component={NumberInput}
                context={LoginFormContext}
                inputProps={codeInputProps}
                name="code"
                fullWidth
                variant="outlined"
                placeholder={t('placeholder.inputGoogleCode')}
                helperText={t('helperText.skipIfNotBind')}
              />
            </Box>
            <Box paddingBottom={2} paddingTop={2}>
              <FormSubmitButton
                disabled={loading}
                component={Button}
                context={LoginFormContext}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                classes={{
                  root: classes.submitButton,
                  disabled: classes.disabledButton
                }}
              >
                {loading
                  ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Box>
                    )
                  : t('common.login')}
              </FormSubmitButton>
            </Box>
          </FormStateProvider>
        </Paper>
      </Box>
    </Grid>
  )
})

const LoginPage: React.FC = () => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const isLoggedIn = useGDKStore.auth.isLoggedIn()
  const { setDisplayed } = useGreetingDisplayStatus()

  const { handleSubmit, loading } = useGDKFuncHandleSubmit({
    formToRequest: (form: LoginFormType) => {
      const converted = {
        ...form,
        code: convertEmptyToUndefined(form.code)
      } as AdminLoginReq
      return omitBy(converted, isUndefined) as AdminLoginReq
    },
    gdkFunc: (payload) => gdk.auth.login(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setDisplayed(false)
    },
    onError: (error: GDKError) => {
      if (error.code === ErrorCode.AUTHENTICATOR_CODE_INVALID || error.code === ErrorCode.NOT_LOGINABLE) {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
      } else {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: t('error.accountAndPasswordError'),
          showCancel: false
        }))
      }
      globalDialog.setOpen({ id: 'loginFail', value: true, isOK: false })
    },
    onErrorDependencies: [t]
  })

  useEffect(() => {
    if (isLoggedIn) {
      setDisplayed(false)
    }
  }, [])

  if (isLoggedIn) return (<Navigate to={Path.HOME} />)

  return (<LoginPageBase loading={loading} handleSubmit={handleSubmit} />)
}

export default React.memo(LoginPage)
