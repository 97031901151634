import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import LoadingAndErrorFrame from '../../components/default/frames/LoadingAndErrorFrame'
import BaseDataChangeDataSection from '../../components/admin/baseData/BaseDataChangeDataSection'
import BaseDataGoogleAuthenticatorSection from '../../components/admin/baseData/BaseDataGoogleAuthenticatorSection'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import useGlobalDialog from '../../providers/admin/dialog/useGlobalDialog'
import useT from '../../i18ns/admin/useT'
import { createGlobalDialogConfig } from '../../utils/default/DialogHelper'

const BaseDataPage: React.FC = () => {
  const [show, setShow] = useState<boolean>(true)
  const globalDialog = useGlobalDialog()
  const me = useGDKStore.admin.me()
  const { t } = useT()

  useEffect(() => {
    if (me === null) return () => {}
    const messageBoundGoogle = !me.is_need_authenticator && me.is_need_update_password ? t('dialog.googleBindSuccess') : ''
    const messageFirstLogin = me.is_need_update_password ? t('dialog.firstLogin') : ''
    const messageNotBindGoogle = me.is_need_authenticator ? t('dialog.notBindGoogle') : ''
    const list = [messageBoundGoogle, messageFirstLogin, messageNotBindGoogle].filter((item) => item !== '')
    if (list.length !== 0 && show) {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: (
          <React.Fragment>
            {list.map((item, index) => (
              <React.Fragment key={index}>
                <span key={index}>{item}</span>
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        )
      }))
      globalDialog.setOpen({ id: 'lockToBaseData', value: true, isOK: false })
      setShow(false)
    }
  }, [me, t])

  return (
    <Box padding={5}>
      <LoadingAndErrorFrame error={null} loading={me === null} showContent={me !== null}>
        <BaseDataChangeDataSection setShow={setShow} />
        <Box paddingTop={3}>
          <BaseDataGoogleAuthenticatorSection setShow={setShow} />
        </Box>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(BaseDataPage)
