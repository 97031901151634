import React from 'react'
import Box from '@material-ui/core/Box'
import { formatMoney } from '../../../utils/default/TableHelper'

const getColor = (n: number) => {
  if (n === 0) return '#808080'
  if (n > 0) return '#55B559'
  return '#f44336'
}

interface PointCellPropTypes {
  point: string
  isColored?: boolean
  isHighlighted?: boolean
  showPlusOnPositive?: boolean
}

const PointCell: React.FC<PointCellPropTypes> = (props) => {
  const { point, isColored, isHighlighted, showPlusOnPositive } = props
  return (
    <Box style={{ color: getColor((isColored ? Number(point) : 0)) }}>
      {isHighlighted && '**'}
      {(showPlusOnPositive ? (Number(point) > 0 ? '+' : '') : '')}{formatMoney(point)}
    </Box>
  )
}

export default React.memo(PointCell)
