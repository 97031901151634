import React, { useMemo, useCallback } from 'react'
import { PlayerRankType } from '@golden/gdk-admin'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import playerRankName from '../../../constants/default/playerRankName'
import FeedbackInput from './FeedbackInput'
import { createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import CoreTable from '../../default/present/CoreTable'

type RowType = Record<string, React.ReactElement> & {
  id: number
}

export interface PropTypes {
  disabled?: boolean
  value?: Array<{ rank: PlayerRankType, feedback: string }>
  onChange?: (value: Array<{ rank: PlayerRankType, feedback: string }>) => void
}

const RankInput: React.FC<{
  rank: PlayerRankType
  value: string
  onChange: (rank: PlayerRankType, value: string) => void
  disabled?: boolean
}> = React.memo((props) => {
  const { rank, value, onChange, disabled } = props
  const handleChange = useCallback((value: string) => { onChange(rank, value) }, [onChange, rank])
  return (
    <FeedbackInput
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  )
})

const FeedbackRankInput: React.FC<PropTypes> = (props) => {
  const { value, onChange, disabled } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.blackTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const handleChange = useCallback((rank: PlayerRankType, newValue: string) => {
    if (value && onChange) {
      onChange(value.filter((item) => item.rank !== rank).concat({ rank, feedback: newValue }))
    }
  }, [value, onChange])
  const rows = useMemo(() => {
    const rankDataObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        const target = value?.find((item) => item.rank === key)
        return {
          [key.toString()]: (
            <RankInput
              rank={key}
              value={target?.feedback ?? ''}
              onChange={handleChange}
              disabled={disabled}
            />
          )
        }
      })
      .reduce((item, obj) => Object.assign({}, obj, item), {})
    return [
      { id: 1, ...rankDataObject }
    ] as RowType[]
  }, [value, disabled, handleChange])
  const data = useMemo(() => {
    const rankColumnObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        return {
          [key.toString()]: {
            label: `${t(playerRankName[key])}*`,
            value: key.toString(),
            align: 'center'
          }
        }
      })
      .reduce((item, obj) => Object.assign({}, item, obj), {})
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        ...rankColumnObject
      },
      Object.keys(playerRankName),
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <CoreTable
      dense
      data={data}
      classes={tableClasses}
      total={1}
    />
  )
}

export default React.memo(FeedbackRankInput)
