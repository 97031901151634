import React, { useState, useMemo } from 'react'
import { IpWhitelist } from '@golden/gdk-admin'
import Button from '@material-ui/core/Button'
import CoreTable from '../../default/present/CoreTable'
import DateTime from '../../default/present/DateTime'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData } from '../../../utils/default/TableHelper'
import { searchToRequest } from './SystemIpWhitelistForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, useReload, useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface RowType {
  id: number
  ip: string
  memo: string
  updateAt: React.ReactElement
  updateBy: string
  function: React.ReactElement
}

const RemoveButton: React.FC<{ id: number, ip: string, reload: () => void }> = React.memo((props) => {
  const { id, ip, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const handleDelete = useDialogHandleClick({
    dialogId: `deleteIp-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmRemoveIpWhitelist')}
          <span className={commonClasses.purpleWord}>[{ip}]</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.removeWhitelistSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: id,
    gdkFunc: (payload) => gdk.parameter.deleteIpWhitelist(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  return (
    <Button
      className={commonClasses.pinkGradualOutlineButton}
      onClick={handleDelete}
    >
      {t('common.delete')}
    </Button>
  )
})

const SystemIpWhitelistTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const writable = useChecker()
  const [list, setList] = useState<IpWhitelist[]>([])
  const pageFlow = usePageFlow()
  const { reload, reloadFlag } = useReload()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.parameter.getIpWhitelistList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: IpWhitelist[]) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.map((item) => {
      return {
        id: item.id,
        ip: item.ip,
        memo: item.memo,
        updateAt: (<DateTime time={item.created_at} />),
        updateBy: item.updated_by,
        function: (<RemoveButton id={item.id} ip={item.ip} reload={reload} />)
      }
    })
  }, [list, reload])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        ip: {
          label: t('common.ipAddress'),
          value: 'ip',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        updateAt: {
          label: t('common.updateAt'),
          value: 'updateAt',
          align: 'center'
        },
        updateBy: {
          label: t('common.updateBy'),
          value: 'updateBy',
          align: 'center'
        },
        function: {
          label: '',
          value: 'function',
          align: 'center'
        }
      },
      writable
        ? [
            'ip',
            'memo',
            'updateAt',
            'updateBy',
            'function'
          ]
        : [
            'ip',
            'memo',
            'updateAt',
            'updateBy'
          ],
      rows,
      'id'
    )
  }, [rows, writable, t])

  if (request === undefined) return null

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.length}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(SystemIpWhitelistTable)
