import React, { useCallback, useMemo } from 'react'
import { Platform, Branch } from '@golden/gdk-admin'
import Button from '@material-ui/core/Button'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  platforms: Platform[]
  original: Platform[]
  reload: () => void
}

const GameSaveButton: React.FC<PropTypes> = (props) => {
  const { platforms, original, reload } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const payload = useMemo(() => {
    return platforms.filter((platform) => {
      const target = original.find((item) => item.id === platform.id)
      if (target === undefined) return false
      return platform.games.some((game) => {
        const targetGame = target.games.find((targetGameItem) => targetGameItem.id === game.id)
        if (targetGame === undefined) return false
        return targetGame.status !== game.status || targetGame.category !== game.category || targetGame.memo !== game.memo
      })
    })
  }, [original, platforms])
  const handleSave = useDialogHandleClick({
    dialogId: 'updateExternalGame',
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmUpdateGameSetting')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.updateGameSettingSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: useCallback((error: string) => createGlobalDialogConfig({
      message: error,
      showIcon: true,
      variant: 'error',
      showCancel: false
    }), []),
    payload,
    gdkFunc: (payload) => gdk.platform.updatePlatform(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { gdk.trunk.trigger([Branch.PLATFORMS]) },
    onSuccessDependencies: [gdk],
    afterSuccessDialog: reload
  })
  return (
    <Button className={classes.purpleGradualButton} onClick={handleSave}>
      {t('common.saveSetting')}
    </Button>
  )
}

export default React.memo(GameSaveButton)
