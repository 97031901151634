import { getServerUrl } from './../default/StageHelper'
import { interval, switchMap, take, map, catchError, filter, tap } from 'rxjs'
import thisVersion, { getLatestVersionParams } from 'virtual:golden/version'
import { useState, useEffect } from 'react'
import { AdminModule } from '@golden/gdk-admin'

export const useUpdateVersion = (gdk: AdminModule) => {
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    const subs = interval(60 * 1000).pipe(
      switchMap(() => gdk.api.fetch(...getLatestVersionParams(getServerUrl('main'))).pipe(
        map(({ latestVersion }) => latestVersion === thisVersion)
      )),
      filter((value) => !value),
      take(1),
      catchError((_error, source) => source)
    ).pipe(
      tap(() => {
        setUpdated(true)
      })
    ).subscribe()
    return () => { subs.unsubscribe() }
  })

  return {
    updated
  }
}
