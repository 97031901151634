import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { CryptoWallets, PlayerCGPayDetailRes } from '@golden/gdk-admin'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

const Button = React.memo(MuiButton)

interface PropTypes {
  id: number
  wallet: CryptoWallets
}

const CGPayDetailButton: React.FC<PropTypes> = (props) => {
  const { id, wallet } = props
  const [open, setOpen] = useState<boolean>(false)
  const [res, setRes] = useState<PlayerCGPayDetailRes>({
    real_name: '',
    address: '',
    usdt_address: ''
  })
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    gdk.player.getPlayerCGPayDetail(id.toString())
      .subscribe({
        next: (res) => { setRes(res) }
      })
  }, [gdk, id, wallet.id]), 200)
  const onClick = useCallback(handleDebouncedOK, [])

  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => {
          onClick()
          setOpen(true)
        }, [onClick])}
        className={commonClasses.greyOutlineButton}
      >
        {t('common.betInfo')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('common.detail')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Grid container direction='column' spacing={5}>
              <Grid item xs={12}>{t('dialog.CGPayBankCardName', { data: res.real_name })}</Grid>
              <Grid item xs={12}>{t('dialog.CGPayAddress', { data: res.address })}</Grid>
              <Grid item xs={12}>{t('dialog.CGPayUsdtAddress', { data: res.usdt_address })}</Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction='row' justifyContent='center' spacing={5}>
            <Grid item xs={4}>
              <Button
                fullWidth
                className={commonClasses.blueGradualOutlineButton}
                onClick={() => { setOpen(false) }}
              >{t('common.confirm')}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(CGPayDetailButton)
