import React, { createContext, useMemo, useContext, useCallback, useState, Dispatch, SetStateAction } from 'react'
import { getTime, startOfDay, endOfDay } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce/lib'
import { makeStyles } from '@material-ui/core/styles'
import ReplayIcon from '@material-ui/icons/Replay'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRedirectHandleBack, useGetDataByParams, useReload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCodeInputProps, getValueFromValue } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import RequiredText from '../../../components/default/form/RequiredText'
import FormField from '../../../components/default/form/FormField'
import NumberInput from '../../../components/default/form/NumberInput'
import allRoute, { Path } from '../../../components/admin/route/route'
import { createGlobalDialogConfig, OpenPayload, GloablDialogConfig } from '../../../utils/default/DialogHelper'
import { InternalWithdrawSlip, InternalWithdrawInfo } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { createValidateNotEmpty, createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import { formatMoney, createTableData } from '../../../utils/default/TableHelper'
import CoreDialog from '../../../components/default/dialogs/CoreDialog'
import FinanceInternalWithdrawExecuteTable, { PropTypes as ExecuteTableProps } from '../../../components/admin/finance/FinanceInternalWithdrawExecuteTable'
import CoreTable from '../../../components/default/present/CoreTable'
import { useLocation } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import StateProvider from '../../../providers/default/StateProvider'

const useStyles = makeStyles(() => ({
  icon: {
    cursor: 'pointer'
  }
}))

export const StateContext = createContext<[Array<{ id: number, fee: string }>, Dispatch<SetStateAction<Array<{ id: number, fee: string }>>>]>([
  [],
  () => {}
])

export interface FormType {
  accounts: InternalWithdrawSlip['accounts']
  memo: string
  code: string
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  accounts: [],
  memo: '',
  code: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<FormType> = {
  accounts: getValueFromValue,
  memo: getValueFromChangeEvent,
  code: getValueFromChangeEvent
}

const memoFormHelperTextProps = { error: true }

const codeInputProps = getCodeInputProps()

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

export const formToRequest = (form: FormType): InternalWithdrawSlip => ({
  accounts: form.accounts,
  memo: form.memo,
  code: form.code
})

const SummaryRow: React.FC<{ wallet: string }> = React.memo((props) => {
  const { wallet } = props
  const { t } = useT()
  const { value } = useContext(FormContext)
  const sum = value.accounts.reduce((summary, item) => summary + item.cash, 0)
  const balance = Number(wallet) - sum
  const error: string | null = balance < 0 ? t('common.cashExceedForwarderBalance') : null
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          label={t('common.internalWithdrawTotalCash')}
          disabled
          fullWidth
          value={formatMoney(sum)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          label={t('common.waitForAssginCash')}
          disabled
          fullWidth
          value={formatMoney(balance)}
          error={error !== null}
          helperText={error ?? ''}
        />
      </Grid>
    </Grid>
  )
})

interface RowType {
  id: number
  bank: string
  name: string
  account: string
  cash: number
}

const SubmitButton: React.FC<{
  id: number
  accounts: InternalWithdrawInfo['accounts']
  onBack: () => void
  onSuccess: () => void
}> = React.memo((props) => {
  const { id, accounts, onBack, onSuccess } = props
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const [open, setOpen] = useState<OpenPayload>({ id: '', value: false, isOK: false })
  const [config, setConfig] = useState<GloablDialogConfig>(createGlobalDialogConfig())
  const { t } = useT()
  const { value, error, disableSubmit } = useContext(FormContext)
  const sum = useMemo(() => {
    return value.accounts.reduce((summary, item) => summary + item.cash, 0)
  }, [value.accounts])
  const rows: RowType[] = useMemo(() => {
    return value.accounts.map((account) => {
      const target = accounts.find((item) => item.id === account.id)
      return {
        id: account.id,
        bank: target?.bank_name ?? '',
        name: target?.real_name ?? '',
        account: target?.bank_account ?? '',
        cash: account.cash
      } as RowType
    })
  }, [value.accounts, accounts])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bank: {
          label: t('common.bankName'),
          value: 'bank',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        cash: {
          label: t('common.internalWithdrawCashAssign'),
          value: 'cash',
          align: 'right'
        }
      },
      [
        'bank',
        'name',
        'account',
        'cash'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const [handleDebouncedSubmit] = useDebouncedCallback(useCallback(() => {
    setConfig(createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text} align="center">{t('dialog.confirmExecuteInternalWithdraw')}</Typography>
          <Box>
            <CoreTable
              dense
              noScroll
              data={data}
              total={data.rows.length}
              classes={{
                head: commonClasses.blackTableHead,
                cellHead: commonClasses.tableCellHead,
                row: commonClasses.borderTableRow
              }}
            />
            <Box paddingTop={2}>
              <Typography className={dialogClasses.text} align="right">{t('common.internalWithdrawTotalCash')} {sum}</Typography>
            </Box>
          </Box>
        </React.Fragment>
      )
    }))
    setOpen({ id: 'send', value: true, isOK: false })
  }, [dialogClasses, commonClasses, data, sum]), 200)
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') {
      setOpen({ id: 'send', value: false, isOK: true })
      gdk.finance.executeInternalWithdraw(id, formToRequest(value)).subscribe({
        next: () => {
          setConfig(createGlobalDialogConfig({
            showIcon: false,
            notUseTypo: true,
            message: (
              <Box whiteSpace="pre">
                <Typography className={dialogClasses.text} align="center">
                  {t('dialog.executeInternalWithdraw')}
                </Typography>
              </Box>
            ),
            okText: t('common.lookInternalWithdrawHistory'),
            cancelText: t('common.confirm')
          }))
          setOpen({ id: 'success', value: true, isOK: false })
        },
        error: (error) => {
          setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            message: error.message,
            showCancel: false
          }))
          setOpen({ id: 'error', value: true, isOK: false })
        }
      })
    }
    if (open.id === 'success') onSuccess()
    if (open.id === 'error') onBack()
  }, [dialogClasses, gdk, open.id, t, value, id, onSuccess, onBack]), 200)
  const [handleDebouncedCancel] = useDebouncedCallback(useCallback(() => {
    if (open.id === 'send') setOpen({ id: 'send', value: false, isOK: false })
    if (open.id === 'success') onBack()
  }, [open, onBack]), 200)
  return (
    <React.Fragment>
      <Button
        className={commonClasses.purpleGradualButton}
        disabled={disableSubmit || Object.values(error).some((item) => item !== null)}
        onClick={handleDebouncedSubmit}
      >
        {t('common.confirmInternalWithdraw')}
      </Button>
      <CoreDialog
        notUseTypo={config.notUseTypo}
        showOK={config.showOK}
        okText={config.okText}
        showCancel={config.showCancel}
        cancelText={config.cancelText}
        showIcon={config.showIcon}
        open={open.value}
        variant={config.variant}
        message={config.message}
        onOK={handleDebouncedOK}
        onCancel={handleDebouncedCancel}
        okButtonClasses={{
          root: dialogClasses.okButton
        }}
        cancelButtonClasses={{
          root: open.id === 'success' ? dialogClasses.okButton : dialogClasses.cancelButton
        }}
      />
    </React.Fragment>
  )
})

const FinanceInternalWithdrawExecutePage: React.FC = () => {
  const goldenBankPageFlow = usePageFlow()
  const courierPageFlow = usePageFlow()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()
  const { reload, reloadFlag } = useReload()
  const location = useLocation()
  const id = useMemo(() => {
    return Number((parsePath(location.search, location.pathname, Path.FINANCE_INTERNAL_WITHDRAW_EXECUTE).param as { id: string }).id)
  }, [location.search, location.pathname])
  const [info, setInfo] = useState<InternalWithdrawInfo | null>(null)
  const [balance, setBalance] = useState<string>('0.0000')
  const validation = useMemo(() => {
    return {
      accounts: [
        {
          func: (value: unknown) => {
            const result = (value as InternalWithdrawSlip['accounts']).every((item) => {
              return !(info && item.cash && item.cash < Number(info.cash_min))
            })
            if (result) {
              return createCorrectResult('accounts')
            }
            return createErrorResult('accounts', '')
          },
          when: ['beforeClickSubmit']
        },
        {
          func: (value: unknown) => {
            const sum = (value as InternalWithdrawSlip['accounts']).reduce((summary, item) => summary + item.cash, 0)
            if (Number(balance) < sum) {
              return createErrorResult('accounts', '')
            }
            return createCorrectResult('accounts')
          },
          when: ['beforeClickSubmit']
        }
      ],
      memo: [],
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t, balance, info])
  const defaultValue = useMemo(() => {
    return initialForm()
  }, [])
  useGetDataByParams({
    path: Path.FINANCE_INTERNAL_WITHDRAW_EXECUTE,
    gdkFunc: (param: { id: string }) => gdk.finance.getInternalWithdrawInfo(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: goldenBankPageFlow.setLoadingStart,
    onSuccess: (res) => {
      setInfo(res)
      setBalance(res.balance)
      goldenBankPageFlow.setContentShow()
    },
    onError: goldenBankPageFlow.setGDKError
  })
  useGetDataByParams({
    path: Path.FINANCE_INTERNAL_WITHDRAW_EXECUTE,
    gdkFunc: (param: { id: string }) => gdk.finance.getInternalWithdrawCourierBalance(Number(param.id)),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: (res: { balance: string }) => { setBalance(res.balance) }
  })
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({ path: Path.FINANCE_INTERNAL_WITHDRAW })
  const [handleSuccess] = useRedirectHandleBack({ path: allRoute.financeInternalWithdrawHistory.encodePath({ search: { courier_id: id, start_at: getTime(startOfDay(new Date())), end_at: getTime(endOfDay(new Date())), page: 1 }, param: {} }) })
  const memoInputProps = useMemo(() => ({
    classes: { input: commonClasses.memoField },
    inputProps: {
      placeholder: t('placeholder.inputMemo')
    }
  }), [commonClasses.memoField, t])
  return (
    <StateProvider
      context={StateContext}
      defaultValue={[]}
    >
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultValue}
        onSubmit={(form) => form}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <Box padding={5}>
          <Paper>
            <Box padding={4}>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.thirdPartyInternalWithdraw')}
                </Typography>
              </Box>
              <Box paddingY={2}>
                <LoadingAndErrorFrame
                  showContent={goldenBankPageFlow.status.showContent && courierPageFlow.status.showContent}
                  loading={goldenBankPageFlow.status.loading || courierPageFlow.status.loading}
                  error={goldenBankPageFlow.status.error ?? courierPageFlow.status.error}
                >
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Box display="flex" alignItems="center">
                            <Box display="flex" flexGrow={1}>
                              <TextField
                                fullWidth
                                disabled
                                label={t('common.withdrawWay')}
                                value={`${info?.forwarder_name ?? ''} (${t('common.balanceWithColon')} ${formatMoney(balance)})`}
                              />
                            </Box>
                            <Box display="flex">
                              <ReplayIcon
                                className={classes.icon}
                                onClick={reload}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            disabled
                            label={t('common.minWithdrawCash2')}
                            value={info?.cash_min ? formatMoney(info.cash_min) : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            disabled
                            label={t('common.maxWithdrawalMoney2')}
                            value={info?.cash_max ? formatMoney(info.cash_max) : ''}
                            helperText={t('common.splitOrderWhenExceedMax')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormField<FormType, ExecuteTableProps>
                        context={FormContext}
                        component={FinanceInternalWithdrawExecuteTable}
                        name="accounts"
                        accounts={info?.accounts ?? []}
                        minCash={info?.cash_min ?? '0.0000'}
                      />
                    </Grid>
                    <Grid item>
                      <SummaryRow wallet={balance} />
                    </Grid>
                    <Grid item>
                      <FormField<FormType, TextFieldProps>
                        context={FormContext}
                        component={TextField}
                        name="memo"
                        multiline
                        fullWidth
                        type="text"
                        margin="normal"
                        variant="outlined"
                        InputProps={memoInputProps}
                        FormHelperTextProps={memoFormHelperTextProps}
                      />
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <FormField<FormType, TextFieldProps>
                            context={FormContext}
                            component={NumberInput}
                            name="code"
                            fullWidth
                            required
                            inputProps={codeInputProps}
                            label={t('common.googleCode')}
                            placeholder={t('placeholder.inputGoogleCode2')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <RequiredText />
                    </Grid>
                  </Grid>
                </LoadingAndErrorFrame>
              </Box>
            </Box>
          </Paper>
          <Box marginTop={2}>
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  className={commonClasses.greyButton}
                  onClick={handleDebouncedBack}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <SubmitButton
                  id={id}
                  accounts={info?.accounts ?? []}
                  onSuccess={handleSuccess}
                  onBack={handleBack}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormStateProvider>
    </StateProvider>
  )
}

export default React.memo(FinanceInternalWithdrawExecutePage)
