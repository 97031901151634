import React, { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { MemoPopoverPayload } from './MemoPopover'
import { MemoDialogPayload } from './MemoDialog'
import MemoButton from './MemoButton'

interface PropTypes {
  memoPopoverContext: React.Context<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>
  memoDialogContext: React.Context<[MemoDialogPayload, Dispatch<SetStateAction<MemoDialogPayload>>]>
  showIconInSuccess?: boolean
  disabled?: boolean
  onlyRead?: boolean
  memo: string
  account: string
  nickname: string
  id: number
  type: 'admin' | 'agent' | 'user'
}

const MemoButtonWithContext: React.FC<PropTypes> = (props) => {
  const {
    memoPopoverContext: MemoPopoverContext,
    memoDialogContext: MemoDialogContext,
    showIconInSuccess = true,
    disabled,
    onlyRead,
    memo,
    account,
    nickname,
    id,
    type
  } = props

  const [, setMemoPopoverPayload] = useContext(MemoPopoverContext)
  const [, setMemoDialogPayload] = useContext(MemoDialogContext)

  const handleMouseEnter = useCallback((event) => {
    setMemoPopoverPayload({
      open: true,
      anchorEl: event.currentTarget,
      memo
    })
  }, [setMemoPopoverPayload, memo])

  const handleMouseLeave = useCallback(
    () => { setMemoPopoverPayload((memoPopoverPayload) => ({ ...memoPopoverPayload, open: false })) },
    [setMemoPopoverPayload]
  )

  const handleClick = useCallback(() => {
    setMemoDialogPayload({
      showIconInSuccess,
      open: true,
      account,
      nickname,
      id,
      type,
      memo
    })
  }, [account, nickname, id, type, memo, setMemoDialogPayload, showIconInSuccess])

  return (
    <MemoButton
      onlyRead={onlyRead}
      disabled={disabled}
      memo={memo}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    />
  )
}

export default React.memo(MemoButtonWithContext)
