import React, { useContext, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import DropDown from '../../default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useInternValue } from '../../../utils/default/FormHook'
import { FinancePiggyBankFormContext } from '../../../views/admin/finance/FinancePiggyBankPage'
import { Path } from '../route/route'

const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 14,
    paddingBottom: 14
  }
}))

const TextField = React.memo(MuiTextField)

const FinancePiggyBankToolbar: React.FC = () => {
  const [form, setForm] = useContext(FinancePiggyBankFormContext)
  const [account, setAccount] = useInternValue<string>('', form.account)
  const [receiver, setReceiver] = useInternValue<string>('', form.receiver)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const options = useMemo(() => {
    return [
      { name: t('common.allStatus'), value: '--' },
      { name: t('common.toggleOn'), value: 1 },
      { name: t('common.toggleOff'), value: 0 }
    ]
  }, [t])
  const writable = useChecker()
  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2}>
      <Grid item>
        <TextField
          variant="outlined"
          InputProps={{ classes: { input: classes.textField } }}
          placeholder={t('placeholder.inputBankAccount')}
          value={account}
          onChange={useCallback((event) => {
            const value = event.target.value
            setAccount(value)
          }, [])}
        />
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          InputProps={{ classes: { input: classes.textField } }}
          placeholder={t('placeholder.inputPlayerSideReceiver')}
          value={receiver}
          onChange={useCallback((event) => {
            const value = event.target.value
            setReceiver(value)
          }, [])}
        />
      </Grid>
      <Grid item>
        <Button
          className={commonClasses.purpleGradualButton}
          onClick={useCallback(() => {
            setForm((form) => ({ ...form, account, receiver, page: 1 }))
          }, [account, receiver])}
        >
          <Box paddingY={0.5}>
            {t('common.search')}
          </Box>
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <DropDown
          className={commonClasses.outlineDropDown}
          fullWidth
          variant="outlined"
          label=""
          value={form.status}
          onChange={useCallback((event) => {
            const status = event.target.value as (0 | 1)
            setForm((form) => ({ ...form, status, page: 1 }))
          }, [])}
          options={options}
        />
      </Grid>
      {writable && (
        <Grid item>
          <Button
            component={Link}
            to={Path.FINANCE_PIGGY_BANK_CREATE}
            className={commonClasses.purpleGradualButton}
            classes={{ disabled: commonClasses.disabledButton }}
          >
            <Box paddingY={0.5}>
              {t('page.createPiggyBank')}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(FinancePiggyBankToolbar)
