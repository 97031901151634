import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { endOfDay, getTime, isAfter, isBefore, startOfDay } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import NumberInput from '../../default/form/NumberInput'
import DateInputBase, { PropTypes as DateInputProps, DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import Autocomplete, { PropTypes as AutocompleteProps } from '../../default/form/Autocomplete'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { searchToRequest, Request } from '../../../views/admin/finance/FinanceBlacklistPage'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { convertEmptyToUndefined, getValueFromChangeEvent, getTimeFromDateInputValue, getValueFromValue, getBankAccountInputProps } from '../../../utils/default/FormHelper'
import allRoute, { Path } from '../route/route'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { createValidateRange, createCorrectResult, createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import blacklistMemoOptions from '../../../constants/admin/blacklistMemoOptions'

export interface FinanceBlacklistFormType {
  time: DateInputValue
  real_name: string
  bank_account: string
  bank_code: string | number
  player_account: string
  memo: string
}

const initialForm: InitialFormFunc<FinanceBlacklistFormType> = (defaultForm) => ({
  time: {
    start: null,
    end: null
  },
  real_name: '',
  bank_account: '',
  bank_code: '--',
  player_account: '',
  memo: '',
  ...defaultForm
})

const formToRequest = (form: FinanceBlacklistFormType): Request => {
  const converted = {
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    real_name: convertEmptyToUndefined(form.real_name),
    bank_account: convertEmptyToUndefined(form.bank_account),
    bank_code: form.bank_code === '--' ? undefined : form.bank_code,
    player_account: convertEmptyToUndefined(form.player_account),
    memo: convertEmptyToUndefined(form.memo),
    page: 1
  } as Request
  return omitBy(converted, isUndefined) as Request
}

const getValueFromEvent: ValueGetter<FinanceBlacklistFormType> = {
  time: getTimeFromDateInputValue,
  real_name: getValueFromChangeEvent,
  bank_account: getValueFromChangeEvent,
  bank_code: getValueFromChangeEvent,
  player_account: getValueFromChangeEvent,
  memo: getValueFromValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const accountNumberInputProps = getBankAccountInputProps()

const realNameInputProps = {
  maxLength: 20
}

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const shouldDisableStartDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (value.time.end === null) return false
      return isAfter(day, endOfDay(value.time.end))
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (value.time.start === null) return false
      return isBefore(day, startOfDay(value.time.start))
    }
    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [value.time])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      classes={dateClasses}
    />
  )
})

const FinanceBlacklistForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const supportBanks = useGDKStore.finance.supportBanks()
  const banks = useMemo(() => supportBanks.map((bank) => bank.id), [supportBanks])
  const request = useRequestFromSearch({
    searchToRequest: searchToRequest(banks)
  })
  const defaultValue = useMemo(() => initialForm({
    time: {
      start: request?.start_at !== undefined ? new Date(request.start_at) : null,
      end: request?.end_at !== undefined ? new Date(request.end_at) : null
    },
    ...request
  }), [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.financeBlacklist.encodePath
  })

  const bankOptions = useMemo(() => {
    return [{ name: t('common.pleaseChooseBank'), value: '--' }].concat(
      supportBanks.map((bank) => ({ name: bank.name, value: bank.id })) as any
    )
  }, [supportBanks, t])

  const formValidation: FormValidation<FinanceBlacklistFormType> = useMemo(() => {
    return {
      time: [
        {
          func: createValidateStartAtWithEndAt('time', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      real_name: [],
      bank_account: [
        {
          func: (value, form, lastSubmitForm) => {
            if ((value as string) === '') return createCorrectResult('bank_account')
            return createValidateRange(
              'bank_account',
              (value) => (value as string).length,
              'moreEqual',
              16,
              t('error.invalidDebitCard')
            )(value, form, lastSubmitForm, null)
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      bank_code: [],
      player_account: [],
      memo: []
    }
  }, [t])

  const memoAutocompleteProps = useMemo(() => {
    return {
      freeSolo: true,
      disableClearable: true,
      disableListWrap: true,
      options: blacklistMemoOptions.map((item) => t(item)),
      getOptionDisabled: (option: string) => option === t(blacklistMemoOptions[5])
    }
  }, [t])

  const writable = useChecker()

  return (
    <Box padding={4}>
      {writable && (
        <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
          <Button
            classes={{ disabled: classes.disabledButton }}
            className={classes.purpleGradualButton}
            component={Link}
            to={Path.FINANCE_BLACKLIST_CREATE}
          >
            {t('common.createDebitCardBlacklist')}
          </Button>
        </Box>
      )}
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {t('common.debitCardBlacklist')}
        </Typography>
      </Box>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultValue}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={formValidation}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DateInput />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={3}>
                <FormField<FinanceBlacklistFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="real_name"
                  label={t('common.realName')}
                  placeholder={t('placeholder.inputRealName')}
                  inputProps={realNameInputProps}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormField<FinanceBlacklistFormType, TextFieldProps>
                  context={FormContext}
                  component={NumberInput}
                  name="bank_account"
                  label={t('common.bankAccount')}
                  placeholder={t('placeholder.inputBankAccount')}
                  fullWidth
                  inputProps={accountNumberInputProps}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormField<FinanceBlacklistFormType, DropDownProps>
                  context={FormContext}
                  component={DropDown}
                  name="bank_code"
                  label={t('common.bank')}
                  fullWidth
                  options={bankOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={3}>
                <FormField<FinanceBlacklistFormType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="player_account"
                  label={t('common.playerAccount')}
                  placeholder={t('placeholder.inputPlayerAccount')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormField<FinanceBlacklistFormType, AutocompleteProps>
                  context={FormContext}
                  component={Autocomplete}
                  name="memo"
                  label={t('common.memo')}
                  placeholder={t('placeholder.inputMemo')}
                  fullWidth
                  autocompleteProps={memoAutocompleteProps}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end">
              <FormSubmitButton
                className={classes.purpleGradualButton}
                context={FormContext}
                type="submit"
                component={Button}
              >
                {t('common.search')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </FormStateProvider>
    </Box>
  )
}

export default React.memo(FinanceBlacklistForm)
