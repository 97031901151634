import React, { createContext, useMemo } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { searchToRequest, Request } from '../../../views/admin/forestage/BannerNotificationPage'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { convertEmptyToUndefined, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { BannerNotificationStatusType, BannerNotificationTargetType } from '@golden/gdk-admin'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import bannerNotificationTargetTypeName from '../../../constants/admin/bannerNotificationTargetTypeName'
import bannerNotificationStatusName from '../../../constants/admin/bannerNotificationStatusName'

export interface BannerNotificationFormType {
  target: BannerNotificationTargetType | '--'
  account: string
  title: string
  content: string
  status: BannerNotificationStatusType | '--'
}

const initialForm: InitialFormFunc<BannerNotificationFormType> = (defaultForm) => ({
  target: '--',
  account: '',
  title: '',
  content: '',
  status: '--',
  ...defaultForm
})

const formToRequest = (form: BannerNotificationFormType): Request => {
  const converted: Request = {
    target: form.target === '--' ? undefined : form.target,
    content: convertEmptyToUndefined(form.content),
    status: form.status === '--' ? undefined : form.status,
    account: convertEmptyToUndefined(form.account),
    title: convertEmptyToUndefined(form.title),
    page: 1
  }
  return omitBy(converted, isUndefined) as Request
}

const getValueFromEvent: ValueGetter<BannerNotificationFormType> = {
  target: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  title: getValueFromChangeEvent,
  content: getValueFromChangeEvent,
  status: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const BannerNotificationForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultValue = useMemo(() => {
    return initialForm(request)
  }, [request])
  const handleSubmit = useChangeUrlSubmit({
    formToRequest,
    encodePath: allRoute.forestageBannerNotification.encodePath
  })
  const formValidation: FormValidation<BannerNotificationFormType> = useMemo(() => {
    return {
      target: [],
      account: [],
      title: [],
      content: [],
      status: []
    }
  }, [])

  const targetOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(bannerNotificationTargetTypeName)
      .map((key) => key as BannerNotificationTargetType)
      .map((key) => ({
        name: t(bannerNotificationTargetTypeName[key]),
        value: key
      })) as any
  )

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(bannerNotificationStatusName)
      .map((key) => key as BannerNotificationStatusType)
      .map((key) => ({
        name: t(bannerNotificationStatusName[key]),
        value: key
      })) as any
  )

  return (
    <Paper>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          marginBottom={1}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('page.bannerNotification')}
          </Typography>
        </Box>
        <FormStateProvider
          context={FormContext}
          defaultValue={defaultValue}
          onSubmit={handleSubmit}
          getValueFromEvent={getValueFromEvent}
          validation={formValidation}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormField<BannerNotificationFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="target"
                    label={t('common.type')}
                    fullWidth
                    options={targetOptions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField<BannerNotificationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.nameList')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<BannerNotificationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="title"
                    label={t('common.title')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<BannerNotificationFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="content"
                    label={t('common.buttonLink')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormField<BannerNotificationFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="status"
                    label={t('common.status')}
                    fullWidth
                    options={statusOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end">
                <FormSubmitButton
                  className={classes.purpleGradualButton}
                  context={FormContext}
                  type="submit"
                  component={Button}
                >
                  {t('common.search')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </FormStateProvider>
      </Box>
    </Paper>
  )
}

export default React.memo(BannerNotificationForm)
