import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useT from '../../i18ns/admin/useT'
import Grid from '@material-ui/core/Grid'
import DropDown from '../default/form/DropDown'
import { GameCategoryType, GameType, PlatformType } from '@golden/gdk-admin'
import { useInternValue } from '../../utils/default/FormHook'
import { findGame } from '../../utils/default/PlatformHelper'
import MultipleSelector from '../default/form/MultipleSelector'
import { useGetData } from '../../utils/default/ComplexFlowHook'
import useGDK from '../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../default/frames/LoadingAndErrorFrame'
import GameIdInput from './GameIdInput'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import GameCategoryInput from './GameCategoryInput'

interface ValueType {
  game_category: GameCategoryType | 'all'
  game_id: GameType | 'noGame' | 'all'
  channel_id?: number[]
  lottery_channel_id?: number[]
}

interface ErrorType {
  game_category: string | null
  game_id: string | null
  channel_id: string | null
  lottery_channel_id: string | null
}

export interface PropTypes {
  value?: ValueType
  onChange?: (value: ValueType) => void
  error?: ErrorType
  FrontComponent?: React.ReactElement
  EndComponent?: React.ReactElement
  categoryNoAll?: true
}

const defaultValue: ValueType = {
  game_category: 'all',
  game_id: 'all',
  channel_id: [],
  lottery_channel_id: [-1]
}

interface ContextType {
  value: ValueType
  setValue: Dispatch<SetStateAction<ValueType>>
  error: ErrorType
  setError: Dispatch<SetStateAction<ErrorType>>
}

const defaultContext = {
  value: defaultValue,
  setValue: () => {},
  error: { game_category: null, game_id: null, channel_id: null, lottery_channel_id: null },
  setError: () => {}
}

const Context = createContext<ContextType>(defaultContext)

const ChannelInput: React.FC<{
  gameId: GameType
}> = React.memo((props) => {
  const { gameId } = props
  const gdk = useGDK()
  const aliveGames = useGDKStore.platform.aliveGames()
  const pageFlow = usePageFlow()
  const { t } = useT()
  const [items, setItems] = useState<Array<{ id: number, name: string }>>([])
  const { value, setValue } = useContext(Context)
  const game = useMemo(() => findGame(gameId, aliveGames), [gameId, aliveGames])
  const isLottery = useMemo(() => game?.category === GameCategoryType.LOTTERY, [game?.category])

  const lotteryChannelOptions = useMemo(() => [
    { name: t('common.all'), value: [-1] },
    ...items.map((item) => ({ name: item.name, value: [item.id] }))
  ], [items, t])
  const channelOptions = useMemo(() => {
    return items.map((item) => ({ name: item.name, value: item.id }))
  }, [items])

  useGetData({
    canLoadData: !!game,
    gdkFunc: () => gdk.platform.getChannelList(game?.instance.platform.id ?? PlatformType.GOLDEN, gameId),
    gdkFuncDependencies: [gdk, game?.instance?.platform, gameId],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Array<{ id: number, name: string }>) => {
      setItems(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      { isLottery && (
        <DropDown
        value={value.lottery_channel_id?.[0] ?? -1}
        onChange={(event) => {
          const val = event.target.value as number
          setValue({ ...value, lottery_channel_id: [val] })
        }}
        label={t('common.publisher')}
        options={lotteryChannelOptions}
        fullWidth
        />
      )}
      { !isLottery && (
        <MultipleSelector
          value={value.channel_id ?? []}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          onChange={(list) => {
            setValue({ ...value, channel_id: list as number[] })
          }}
          label={t('common.game')}
          options={channelOptions}
          tagType="comma"
          allValue={-100} // 有可能有選項是 -1，所以給 -100 以避免撞到
          disableClearable
          fullWidth
        />
      )}
    </LoadingAndErrorFrame>
  )
})

const GameInput: React.FC<PropTypes> = (props) => {
  const { value, onChange, FrontComponent, categoryNoAll = false, EndComponent } = props
  const [internValue, setInternValue] = useInternValue<ValueType>(value ?? defaultValue)
  const [error, setError] = useInternValue<ErrorType>({ game_category: null, game_id: null, channel_id: null, lottery_channel_id: null })
  useEffect(() => {
    if (onChange) onChange(internValue)
  }, [internValue])
  const showChannel = useMemo(() => !!value?.game_id && !!value?.channel_id && !!value.lottery_channel_id && value.game_id !== 'all' && value.game_id !== 'noGame', [value?.channel_id, value?.game_id, value?.lottery_channel_id])
  return (
    <Context.Provider value={{
      value: internValue,
      setValue: setInternValue,
      error,
      setError
    }}>
      <Grid container direction="row" spacing={2} wrap="wrap">
        {FrontComponent}
        <Grid item lg={3} md={6} xs={12}>
          <GameCategoryInput
            value={value?.game_category}
            onChange={useCallback((category) => {
              setInternValue({
                ...value,
                game_category: category,
                game_id: 'all',
                channel_id: !value?.channel_id ? undefined : [],
                lottery_channel_id: !value?.lottery_channel_id ? undefined : [-1]
              })
            }, [setInternValue, value])}
            noAll={categoryNoAll}
            fullWidth
            required
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <GameIdInput
            value={value?.game_id}
            onChange={useCallback((gameId) => {
              setInternValue({
                ...value,
                game_category: value?.game_category ?? 'all',
                game_id: gameId,
                channel_id: !value?.channel_id ? undefined : [],
                lottery_channel_id: !value?.lottery_channel_id ? undefined : [-1]
              })
            }, [setInternValue, value])}
            category={value?.game_category}
            fullWidth
            required
          />
        </Grid>
        { (!!showChannel || !!EndComponent) && (<>
          {showChannel && (<Grid item xs={12} md={6} lg={3}>
            <ChannelInput gameId={value?.game_id as GameType}/>
          </Grid>)}
          {EndComponent}
        </>) }
      </Grid>
    </Context.Provider>
  )
}

export default React.memo(GameInput)
