import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, NewPeopleStatisticQuery, ClientPlatStrType, Sort, NewPeopleStatistic } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getTimeFromDateInputValue, parseArray, getValueFromValue, getValueFromChangeEvent, guaranteeBeKey, guaranteeBeOrder, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth, setLastMonth } from '../../../utils/default/TimeHelper'
import clientPlatformName from '../../../constants/admin/clientPlatformName'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../default/form/MultipleSelector'
import { initialSort } from './AdminReportNewPeopleStatisticTable'

export type NewPeopleRequest = PaginationReq & NewPeopleStatisticQuery & Sort

export interface NewPeopleFormType {
  time: DateInputValue
  staff: string
  account: string
  client_platforms: ClientPlatStrType[]
}

export const searchToRequest: SearchToRequestFunc<NewPeopleRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )(search.end_at),
    client_platforms: acceptUndefined(search.client_platforms, pipe(
      parseArray,
      (values) => values.map((item: string) => item as ClientPlatStrType)
    )),
    sort_by: pipe(
      guaranteeNotUndefined,
      (value) => guaranteeBeKey<NewPeopleStatistic>(value, ['register_at', 'account'])
    )(search.sort_by),
    order: pipe(
      guaranteeNotUndefined,
      guaranteeBeOrder
    )(search.order)
  } as NewPeopleRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as NewPeopleRequest
}

export const initialForm: InitialFormFunc<NewPeopleFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  staff: '',
  account: '',
  client_platforms: [],
  ...defaultForm
})

const formToRequest = (form: NewPeopleFormType): NewPeopleRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    staff: convertEmptyToUndefined(form.staff),
    account: convertEmptyToUndefined(form.account),
    client_platforms: form.client_platforms.length === 0 ? undefined : form.client_platforms,
    ...initialSort(),
    page: 1
  } as NewPeopleRequest
  return omitBy(converted, isUndefined) as NewPeopleRequest
}

const getValueFromEvent: ValueGetter<NewPeopleFormType> = {
  time: getTimeFromDateInputValue,
  client_platforms: getValueFromValue,
  account: getValueFromChangeEvent,
  staff: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    const today = endOfDay(new Date())
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo, today)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AdminReportNewPeopleStatisticForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.adminReportNewPeopleStatistic.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    client_platforms: [],
    staff: [],
    account: []
  } as FormValidation<NewPeopleFormType>), [])

  const options = useMemo(() => {
    return Object.values(ClientPlatStrType)
      .map((key) => ({ name: t(clientPlatformName[key]), value: key }))
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.newPeopleStatistic')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="flex-end">
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<NewPeopleFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="staff"
                    fullWidth
                    label={t('common.belongStaffAccount')}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<NewPeopleFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormField<NewPeopleFormType, MultipleSelectorProps>
                    context={FormContext}
                    component={MultipleSelector}
                    name="client_platforms"
                    options={options}
                    label={t('common.device')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AdminReportNewPeopleStatisticForm)
