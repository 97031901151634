import React, { PropsWithChildren, useState, useEffect } from 'react'
import { Observable } from 'rxjs'

interface PropTypes<T> {
  context: React.Context<T>
  defaultValue: T
  inputFactory: () => Observable<T>
}

const GDKStateProvider = function<T> (props: PropsWithChildren<PropTypes<T>>) {
  const { context: Context, defaultValue, inputFactory, children } = props

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    const subscription = inputFactory().subscribe(setValue)
    return () => { subscription.unsubscribe() }
  }, [inputFactory])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default GDKStateProvider
