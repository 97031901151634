import React, { useState, useEffect, useMemo, useCallback, createContext, Dispatch, SetStateAction } from 'react'
import {
  PaginationRes,
  ActivityWalletItem,
  ActivityWalletLimitType,
  GDKError,
  PermissionType,
  getTargetURL,
  ActivityWalletStatus
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { searchToRequest } from '../../activity/wallet/ActivityWalletForm'
import CoreTable from '../../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../../default/memo/MemoPopoverWithContext'
import RadioGroup from '../../../default/form/RadioGroup'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload, useGDKFuncHandleClick, useDialogHandleSubmit } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney, formatCount } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles, useDialogStyles } from '../../../../utils/admin/StyleHook'
import allRoute from '../../route/route'
import DateTime from '../../../default/present/DateTime'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import useGlobalDialog from '../../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../../utils/default/DialogHelper'
import { getCategoryShortName, getGameName, getWalletNameByWalletId } from '../../../../utils/default/PlatformHelper'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../../default/memo/MemoPopover'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import activityWalletStatusColor from '../../../../constants/admin/activityWalletStatusColor'
import activityWalletStatusTypeName from '../../../../constants/admin/activityWalletStatusName'
import { useDebouncedCallback } from 'use-debounce/lib'
import { getServerUrl } from '../../../../utils/default/StageHelper'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import ActivityWalletBindingDepositsDialog from './ActivityWalletBindingDepositsDialog'
import { BcMath } from '@golden/bcmath'

interface SumRowType {
  id: number
  totalCount: string
  totalCash: string
}
interface RowType {
  id: number
  selected: React.ReactElement | null
  account: string
  createdAt: React.ReactElement
  expiredAt: React.ReactElement
  batchId: string
  activitySerial: string
  title: string
  content: React.ReactElement
  platform: React.ReactElement
  multiple: string
  cash: string
  status: React.ReactElement
  transferTo: string
  transferSerial: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  bindingDeposits: string | React.ReactElement
  operation: string | React.ReactElement
}

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: 'none'
  },
  white: {
    color: theme.palette.common.white
  },
  scroll: {
    width: 300,
    maxHeight: 220,
    overflowY: 'scroll'
  }
}))

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const Button = React.memo(MuiButton)

const BindingDepositButton: React.FC<{ list: string[], reload: () => void, id: number, title: string, account: string, cash: string, disabled: boolean }> = ({ id, list, reload, title, account, cash, disabled }) => {
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const text = useMemo(() => {
    if (list.length === 0) return '--'
    if (list.length > 1) return `${list.length} ${t('common.amountShort')}`
    return list[0]
  }, [t, list])

  return disabled
    ? (<span>{ text }</span>)
    : (
    <React.Fragment>
      <span
        className={commonClasses.anchor}
        onClick={() => { setOpen(true) }}
      >
        {text}
      </span>
      <ActivityWalletBindingDepositsDialog open={open} close={close} reload={reload} id={id} bindingDeposits={list} title={title} account={account} cash={cash} />
    </React.Fragment>
      )
}

const RevokeButton: React.FC<{ id: number, batchId: string, batchCount: number, batchCash: string, batchFinishedIds: string[], cash: string, reload: () => void }> = ({ id, batchId, batchCount, batchCash, batchFinishedIds, cash, reload }) => {
  const gdk = useGDK()
  const { t } = useT()
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const [value, setValue] = useState<'ids' | 'batch'>('ids')
  const info = useMemo(() => {
    return {
      ...value === 'ids'
        ? {
            count: 1,
            cash
          }
        : {
            count: batchCount,
            cash: batchCash
          }
    }
  }, [value, batchCash, batchCount, cash])

  const options = useMemo(() => [
    { value: 'ids', name: t('common.singleRevoke') },
    { value: 'batch', name: `${t('common.batchRevoke')}\n(ID: ${batchId})` }
  ], [t, batchId])

  const payload = useMemo(() => {
    return ({
      ...value === 'ids' ? { ids: [id] } : { batch_id: batchId, finished_ids: batchFinishedIds }
    })
  }, [id, batchId, value, batchFinishedIds])

  const { handleClick, loading: submitLoading } = useGDKFuncHandleClick({
    payload,
    gdkFunc: (payload) => gdk.activity.revokeActivityWallet(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: {
      success_count: number
      fail_count: number
      fail_serial_numbers: string[]
    }) => {
      setOpen(false)
      reload()
      globalDialog.setConfig(createGlobalDialogConfig({
        message: (
          <>
            <Box marginBottom={4}>
              <Typography className={dialogClasses.title} align="center">{t('dialog.revokeResult')}</Typography>
            </Box>
            <Box>
              {
                `${res.success_count} ${t('common.amountShort')}${t('common.success')} ， ${res.fail_count} ${t('common.amountShort')}${t('common.fail')}`
              }
            </Box>
            {
              res.fail_serial_numbers.length > 0 && (
                <Box className={classes.scroll} marginTop={2}>
                  <Table>
                    <TableHead>
                      <TableRow className={commonClasses.greyTableHead}>
                        <TableCell
                          key={'head'}
                          align="center"
                          className={commonClasses.tableCellHead}
                        >
                          {`${t('common.fail')}${t('common.orderNumber')}`}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {res.fail_serial_numbers.map((number) => (
                        <TableRow className={commonClasses.greyTableRow}>
                          <TableCell align="center">
                            {number}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )
            }
          </>
        ),
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `revokeSuccess-${id}`,
        value: true,
        isOK: false
      })
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        variant: 'error',
        message: error.message,
        showIcon: false,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: `revokeFailed-${id}`,
        value: true,
        isOK: false
      })
    }
  })

  return (
    <>
      <Button
        className={commonClasses.blueGradualOutlineButton}
        onClick={() => { setOpen(true) }}
      >
        {t('common.revoke2')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle className={commonClasses.dialogPinkHeader}>
          {t('dialog.confirmRevoke2')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems='center'>
            <Grid item>
              <Box height={32} paddingY={1}>
                <Typography align="center">{t('dialog.revokeActivityWallet', { count: info.count, cash: info.cash })}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <RadioGroup
                label=''
                value={value}
                options={options}
                onChange={(event) => {
                  setValue(event.target.value as 'ids' | 'batch')
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Box padding={2}>
          <DialogActions>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClick}
                  disabled={submitLoading}
                >
                  {t('common.confirm')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

const ActivityWalletTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const games = useGDKStore.platform.games()
  const storeCategories = useGDKStore.platform.categories()
  const globalDialog = useGlobalDialog()
  const [list, setList] = useState<PaginationRes<ActivityWalletItem[]> & { total_count: number, total_cash: string, batch_ids: Record<string, { count: number, cash: string, finished_ids: string[] }> }>({ ...createDefaultPaginationData([]), total_count: 0, total_cash: '0.0000', batch_ids: {} })
  const canRevokeList = useMemo(() => list.data.filter(item => item.created_by_admin && item.status === ActivityWalletStatus.CAN_RECEIVE), [list])
  const writableExport = useChecker([PermissionType.ACTIVITY_WALLET_EXPORT])
  const writableEdit = useChecker([PermissionType.ACTIVITY_WALLET])
  const { reload, reloadFlag } = useReload()

  const [selected, setSelected] = useState<Array<{ id: number, batchId: string, cash: string }>>([])
  const resetSelected = useCallback(() => {
    setSelected([])
  }, [])

  const handleSelect = useCallback((id: number, batchId: string, cash: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelected = checked ? [...selected, { id, batchId, cash }] : selected.filter((el) => el.id !== id)
    setSelected([...newSelected])
  }, [selected])
  const handleSelectAll = useCallback((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const listIds = canRevokeList.map((el) => ({ id: el.id, batchId: el.batch_id, cash: el.cash }))
    const newSelected = checked ? listIds : []
    setSelected([...newSelected])
  }, [canRevokeList])

  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.activity.getActivityWalletList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityWalletItem[]> & { total_count: number, total_cash: string, batch_ids: Record<string, { count: number, cash: string, finished_ids: string[] }> }) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const sumRow = useMemo(() => {
    return [{
      id: 1,
      totalCount: formatCount(list.total_count),
      totalCash: formatMoney(list.total_cash)
    }] as SumRowType[]
  }, [list])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        totalCount: {
          label: t('common.totalCount'),
          value: 'totalCount',
          align: 'center'
        },
        totalCash: {
          label: t('common.totalCash'),
          value: 'totalCash',
          align: 'center'
        }
      },
      [
        'totalCount',
        'totalCash'
      ],
      sumRow,
      'id'
    )
  }, [t, sumRow])

  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        selected: item.created_by_admin && item.status === ActivityWalletStatus.CAN_RECEIVE
          ? (
          <Box paddingLeft={2}>
            <Checkbox
              checked={selected.map((el) => el.id).includes(item.id)}
              onChange={handleSelect(item.id, item.batch_id, item.cash)}
            />
          </Box>
            )
          : '',
        account: item.account,
        expiredAt: (<DateTime time={item.expired_at}/>),
        createdAt: (<DateTime time={item.created_at} />),
        batchId: item.batch_id,
        activitySerial: item.serial_number,
        title: item.title,
        content: (<MemoTextWithTitleAndContext context={MemoContext} title={item.content ?? '-'} memo={item.content ?? '-'} />),
        platform: (<span className={commonClasses.pre}>{item.limit_type === ActivityWalletLimitType.GAME_CATEGORY ? item.limit_ids.map((id) => getCategoryShortName(id, storeCategories)).join(', ') : item.limit_ids.map((id) => getGameName(id, games)).join(', ')}</span>),
        multiple: `${item.multiple} ${t('common.multi')}`,
        cash: formatMoney(item.cash),
        status: (<span style={{ color: activityWalletStatusColor[item.status] }}>
          {t(activityWalletStatusTypeName[item.status])}
        </span>),
        transferTo: item.to === null ? '--' : getWalletNameByWalletId(item.to, platforms),
        transferSerial: item.transfer_logs.length === 0
          ? (<span>--</span>)
          : (
              item.transfer_logs.length === 1
                ? (<span>{ item.transfer_logs[0] }</span>)
                : (
                  <span
                    className={commonClasses.anchor}
                    onClick={() => {
                      globalDialog.setConfig(createGlobalDialogConfig({
                        showIcon: false,
                        showCancel: false,
                        message: item.transfer_logs.join('\n')
                      }))
                      globalDialog.setOpen({
                        id: `transferSerial-${item.id}`,
                        value: true,
                        isOK: false
                      })
                    }}
                  >
                    {item.transfer_logs.length} {t('common.amountShort')}
                  </span>
                  )
            ),
        updatedAt: (<DateTime time={item.updated_at} />),
        updatedBy: item.updated_by,
        bindingDeposits: ((<BindingDepositButton id={item.id} list={item.binding_deposits} reload={reload} title={item.title} account={item.account} cash={item.cash} disabled={item.status === ActivityWalletStatus.REVOKED} />)),
        operation: selected.length === 0 && item.created_by_admin && item.status === ActivityWalletStatus.CAN_RECEIVE ? (<RevokeButton id={item.id} batchId={item.batch_id} cash={item.cash} batchCount={list.batch_ids[item.batch_id]?.count} batchCash={list.batch_ids[item.batch_id]?.cash} batchFinishedIds={list.batch_ids[item.batch_id]?.finished_ids ?? []} reload={reload} />) : ''
      } as RowType
    })
  }, [list.data, commonClasses.anchor, commonClasses.pre, games, storeCategories, platforms, t, globalDialog, reload, selected, handleSelect, list.batch_ids])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        selected: {
          label: canRevokeList.length !== 0
            ? (
            <Box paddingLeft={2}>
              <Checkbox
                color="default"
                classes={{
                  root: classes.white,
                  checked: classes.white
                }}
                checked={canRevokeList.map((el) => el.id).every((el) => selected.map(item => item.id).includes(el))}
                onChange={handleSelectAll}
              />
            </Box>
              )
            : '',
          value: 'selected'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        createdAt: {
          label: t('common.sendDate'),
          value: 'createdAt',
          align: 'center',
          width: 100
        },
        expiredAt: {
          label: t('common.receivedExpiredTime'),
          value: 'expiredAt',
          align: 'center',
          width: 100
        },
        batchId: {
          label: t('common.batchNumberId'),
          value: 'batchId'
        },
        activitySerial: {
          label: t('common.activitySerial'),
          value: 'activitySerial',
          align: 'center'
        },
        title: {
          label: t('common.activityName'),
          value: 'title',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'center'
        },
        platform: {
          label: t('common.activityGamePlatform'),
          value: 'platform',
          align: 'center',
          width: 300
        },
        multiple: {
          label: t('common.searchingEffectiveCash'),
          value: 'multiple',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'right'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        transferTo: {
          label: t('common.transferIn'),
          value: 'transferTo',
          align: 'center'
        },
        transferSerial: {
          label: t('common.serialNumber'),
          value: 'transferSerial',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        bindingDeposits: {
          label: t('common.exchangedDepositOrderNo'),
          value: 'bindingDeposits',
          align: 'center'
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center',
          width: 40
        }
      },
      [
        ...writableEdit ? ['selected' as keyof RowType] : [],
        'account',
        'createdAt',
        'expiredAt',
        'batchId',
        'activitySerial',
        'title',
        'content',
        'platform',
        'multiple',
        'cash',
        'status',
        'transferTo',
        'transferSerial',
        'updatedAt',
        'updatedBy',
        'bindingDeposits',
        ...writableEdit ? ['operation' as keyof RowType] : []
      ],
      rows,
      'id'
    )
  }, [t, rows, classes.white, selected, handleSelectAll, canRevokeList, writableEdit])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.activityWallet.encodePath
  })

  const [handleDebouncedRevoke] = useDebouncedCallback(() => {
    handleRevoke({ ids: selected.map(item => item.id) })
  }, 200)

  const { handleSubmit: handleRevoke } = useDialogHandleSubmit<{ ids: number[] }, { ids: number[] }, { success_count: number, fail_count: number, fail_serial_numbers: string[] }>({
    dialogId: 'revokeActivityWallet',
    globalDialog,
    getChangeDialogConfig: useCallback(() => {
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <React.Fragment>
            <Typography className={dialogClasses.title} align="center">{t('dialog.confirmRevoke2')}</Typography>
            <Typography className={dialogClasses.text} align="center">
              {t('dialog.revokeActivityWallet', {
                count: selected.length,
                cash: formatMoney(selected.reduce((acc, curr) => {
                  acc = acc.add(curr.cash)
                  return acc
                }, BcMath.base(0)).get())
              }
              )}
            </Typography>
          </React.Fragment>
        )
      })
    }, [t, dialogClasses, selected]),
    getSuccessDialogConfig: (_, res) => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: (
        <>
          <Box marginBottom={4}>
            <Typography className={dialogClasses.title} align="center">{t('dialog.revokeResult')}</Typography>
          </Box>
          <Box>
            {
              `${res.success_count} ${t('common.amountShort')}${t('common.success')} ， ${res.fail_count} ${t('common.amountShort')}${t('common.fail')}`
            }
          </Box>
          {
            res.fail_serial_numbers.length > 0 && (
              <Box className={classes.scroll} marginTop={2}>
                <Table>
                  <TableHead>
                    <TableRow className={commonClasses.greyTableHead}>
                      <TableCell
                        key={'head'}
                        align="center"
                        className={commonClasses.tableCellHead}
                      >
                        {`${t('common.fail')}${t('common.orderNumber')}`}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {res.fail_serial_numbers.map((number) => (
                      <TableRow className={commonClasses.greyTableRow}>
                        <TableCell align="center">
                          {number}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )
            }
        </>
      )
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest: (form) => form,
    gdkFunc: (payload) => gdk.activity.revokeActivityWallet(payload),
    onSuccess: reload,
    onError: reload,
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: resetSelected
  })

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    resetSelected()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.activity.exportActivityWalletValidate(request!)
      .subscribe({
        next: (res) => {
          const link = document.createElement('a')
          link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        error: (err: GDKError) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            variant: 'error',
            message: err.message,
            showCancel: false
          }))
          globalDialog.setOpen({ id: 'exportActivityWalletFailed', value: true, isOK: false })
        }
      })
  }, 200)

  useEffect(() => {
    if (request) {
      resetSelected()
    }
  }, [request, resetSelected])

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <LoadingAndErrorFrame {...pageFlow.status}>

                <Box paddingTop={3}>
                  <CoreTable
                    data={sumData}
                    total={1}
                    classes={tableClasses}
                  />
                </Box>
              </LoadingAndErrorFrame>
            </Box>
          </ScrollablePaper>
        </Grid>
        <Grid item>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <LoadingAndErrorFrame {...pageFlow.status}>
              {!!list.total && (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>{selected.length > 0 && (writableEdit || writableExport) && t('common.nDataSelected', { amount: selected.length })}</Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft='auto'>
                    {
                      writableEdit && selected.length > 0 && (
                        <Box marginRight={1}>
                          <Button
                            className={commonClasses.purpleGradualButton}
                            onClick={handleDebouncedRevoke}
                          >
                              {t('common.batchRevoke2')}
                          </Button>
                        </Box>
                      )
                    }
                    {
                      writableExport && selected.length === 0 && (
                        <Box>
                          <Button
                            className={commonClasses.purpleGradualButton}
                            onClick={handleDebouncedExport}
                          >
                            {t('common.export')}
                          </Button>
                        </Box>
                      )
                    }
                  </Box>
                </Box>
              )}
                <Box paddingTop={3}>
                  <MemoPopoverWithContext memoPopoverContext={MemoContext}>
                    <CoreTable
                        classes={tableClasses}
                        data={data}
                        total={list.total}
                        showPagination
                        page={request.page}
                        perPage={list.per_page}
                        onChangePage={handlePagination}
                      />
                  </MemoPopoverWithContext>
                </Box>
              </LoadingAndErrorFrame>
            </Box>
          </ScrollablePaper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(ActivityWalletTable)
