import React, { createContext, useState, useMemo, useContext, useCallback, useEffect, ChangeEvent } from 'react'
import clsx from 'clsx'
import { omitBy, isUndefined } from '@golden/utils'
import { makeStyles } from '@material-ui/core/styles'
import {
  PlayerJumpShipReasonType,
  PlayerJumpShipForm,
  PlayerJumpShipValidation,
  PlayerJumpShipTimingType,
  PlayerJumpShipErrorType
} from '@golden/gdk-admin'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MuiStepper from '@material-ui/core/Stepper'
import MuiStep from '@material-ui/core/Step'
import MuiStepLabel from '@material-ui/core/StepLabel'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DropDown from '../../../components/default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../../components/default/form/OnOffCheckbox'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import FormField from '../../../components/default/form/FormField'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import { Path } from '../../../components/admin/route/route'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRedirectHandleBack, useDialogHandleSubmit, useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter, DisableFieldGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, convertEmptyToUndefined, getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import playerJumpShipReasonName from '../../../constants/admin/playerJumpShipReasonName'
import playerJumpShipTimingName from '../../../constants/admin/playerJumpShipTimingName'
import playerJumpShipErrorTypeName from '../../../constants/admin/playerJumpShipErrorTypeName'
import { createValidateNotEmpty, createCorrectResult, createValidateDropDownNotEmpty, createErrorResult, createValidateRange } from '../../../utils/default/Validator'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import RequiredText from '../../../components/default/form/RequiredText'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../../components/default/present/CoreTable'
import { useDialogFlow } from '../../../utils/default/DialogHook'

const useStyles = makeStyles(() => ({
  check: {
    color: 'green'
  }
}))

interface RuleRowType {
  id: PlayerJumpShipReasonType
  reason: string
  limit: React.ReactElement
  auto: string
  agent: string
  memo: string
}

interface ValidationRowType {
  id: number
  account: string
  accountValidation: React.ReactElement
  rule: React.ReactElement
  agentChain: React.ReactElement
}

interface FormType {
  reason: PlayerJumpShipReasonType | '--'
  description: string
  staff: string
  timing: PlayerJumpShipTimingType
  notCheckRule: boolean
  notCheckAgentChain: boolean
  account: string
}

const convertAccountToAccounts = (account: string): string[] => {
  return (account.split('\n')
    .map((account) => convertEmptyToUndefined(account))
    .filter((account) => account !== undefined)) as string[]
}

const filterAccounts = (accounts: string[], formValidation: PlayerJumpShipValidation | null) => {
  return accounts
    .filter((_, index) => {
      const account = formValidation?.account.find((item) => item.index === index)
      const rule = formValidation?.rule.find((item) => item.index === index)
      const agentChain = formValidation?.agent_chain.find((item) => item.index === index)
      return !account && !rule && !agentChain
    })
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  reason: '--',
  description: '',
  staff: '',
  timing: PlayerJumpShipTimingType.NOW,
  notCheckRule: false,
  notCheckAgentChain: false,
  account: '',
  ...defaultForm
})

const defaultForm = initialForm()

const getValueFromEvent: ValueGetter<FormType> = {
  reason: getValueFromChangeEvent,
  description: getValueFromChangeEvent,
  staff: getValueFromChangeEvent,
  timing: getValueFromValue,
  notCheckRule: getValueFromCheckboxEvent,
  notCheckAgentChain: getValueFromCheckboxEvent,
  account: getValueFromChangeEvent
}

const nowTimingReasons: Array<PlayerJumpShipReasonType | '--'> = [
  PlayerJumpShipReasonType.STUPID_PLAYER,
  PlayerJumpShipReasonType.LONG_TIME_NO_LOGIN
]

const getChangedForm: ChangedFormGetter<FormType> = {
  reason: (value, form) => {
    let newStaff = form.staff
    let newTiming = form.timing
    let newNotCheckRule = form.notCheckRule
    let newNotCheckAgentChain = form.notCheckAgentChain
    if (form.reason === PlayerJumpShipReasonType.PLAYER_TO_PLATFORM && value !== PlayerJumpShipReasonType.PLAYER_TO_PLATFORM) {
      newStaff = ''
      newNotCheckAgentChain = false
    }
    if (value === PlayerJumpShipReasonType.PLAYER_TO_PLATFORM) {
      newStaff = 'RMB'
      newNotCheckAgentChain = true
    }
    if (value === PlayerJumpShipReasonType.STUPID_PLAYER) {
      newNotCheckRule = false
    }
    if (nowTimingReasons.includes(value)) {
      newTiming = PlayerJumpShipTimingType.NOW
    } else {
      newTiming = PlayerJumpShipTimingType.END_OF_MONTH
    }
    return { ...form, reason: value, staff: newStaff, timing: newTiming, notCheckRule: newNotCheckRule, notCheckAgentChain: newNotCheckAgentChain }
  }
}

const formToRequest = (step: number, formValidation: PlayerJumpShipValidation | null) => (form: FormType): PlayerJumpShipForm => {
  return omitBy({
    reason: form.reason,
    description: convertEmptyToUndefined(form.description),
    staff: form.staff,
    accounts: step === 0 ? convertAccountToAccounts(form.account) : filterAccounts(convertAccountToAccounts(form.account), formValidation),
    timing: form.timing,
    not_check_rule: form.notCheckRule,
    not_check_agent_chain: form.notCheckAgentChain
  } as PlayerJumpShipForm, isUndefined) as PlayerJumpShipForm
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)
const Button = React.memo(MuiButton)
const Stepper = React.memo(MuiStepper)
const StepLabel = React.memo(MuiStepLabel)
const Step = React.memo(MuiStep)

const StepProgress: React.FC<{ step: number }> = React.memo((props) => {
  const { step } = props
  const { t } = useT()
  return (
    <Stepper activeStep={step} alternativeLabel>
      <Step><StepLabel>{t('common.playerList')}</StepLabel></Step>
      <Step><StepLabel>{t('common.validate')}</StepLabel></Step>
    </Stepper>
  )
})

const ReasonDropDown: React.FC = React.memo(() => {
  const { value, handleChange, error, disabled } = useContext(FormContext)
  const { t } = useT()
  const reasonOptions = useMemo(() => {
    return [{ name: t('placeholder.inputJumpShipReason'), value: '--', disabled: true }].concat(
      Object.keys(playerJumpShipReasonName)
        .map((key) => Number(key) as PlayerJumpShipReasonType)
        .map((key) => ({ name: t(playerJumpShipReasonName[key]), value: key })) as any
    )
  }, [t])
  const helperText = useMemo(() => {
    if (error.reason) {
      return error.reason
    }
    if (value.reason === PlayerJumpShipReasonType.STUPID_PLAYER) {
      return t('common.jumpShipTip18')
    }
    return ''
  }, [error.reason, value.reason, t])
  const onChange = useCallback(handleChange('reason'), [])
  return (
    <DropDown
      required
      fullWidth
      disabled={disabled.reason}
      options={reasonOptions}
      label={t('common.jumpShipReason')}
      value={value.reason}
      onChange={onChange}
      error={error.reason !== null}
      helperText={helperText}
    />
  )
})

const CustomInput: React.FC = React.memo(() => {
  const { value, handleChange, error } = useContext(FormContext)
  const { t } = useT()
  const onChange = useCallback(handleChange('description'), [])
  if (value.reason !== PlayerJumpShipReasonType.CUSTOM) return null
  return (
    <Grid item xs={12} md={6} lg={3}>
      <TextField
        fullWidth
        required
        label={t('common.customerReason')}
        placeholder={t('placeholder.inputReason')}
        value={value.description}
        onChange={onChange}
        error={error.description !== null}
        helperText={error.description}
      />
    </Grid>
  )
})

const StaffAccount: React.FC = React.memo(() => {
  const { value, handleChange, error, disabled, setError } = useContext(FormContext)
  const [dirty, setDirty] = useState<boolean>(false)
  const { t } = useT()
  const gdk = useGDK()
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true)
    handleChange('staff')(event)
  }, [])
  useEffect(() => {
    if (value.staff === '' && dirty) {
      setError((error) => ({ ...error, staff: t('error.mustNotEmpty') }))
    } else if (value.staff !== '') {
      setError((error) => ({ ...error, staff: null }))
      const subsciption = gdk.jumpShip.validateStaffAccount(value.staff).subscribe({
        next: (res) => {
          if (!res.result) setError((error) => ({ ...error, staff: t('common.staffNotExist') }))
        }
      })
      return () => { subsciption.unsubscribe() }
    }
  }, [value.staff, dirty, t, gdk])
  return (
    <TextField
      required
      fullWidth
      disabled={disabled.staff}
      label={t('common.newStaff')}
      value={value.staff}
      onChange={onChange}
      error={error.staff !== null}
      helperText={error.staff ?? ''}
    />
  )
})

const TimingDropDown: React.FC = React.memo(() => {
  const { value, handleChange, error, disabled } = useContext(FormContext)
  const { t } = useT()
  const [nextTiming, setNextTiming] = useState<string>(value.timing)
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const timingOptions = useMemo(() => {
    return Object.keys(playerJumpShipTimingName)
      .map((key) => ({ name: t(playerJumpShipTimingName[key as PlayerJumpShipTimingType]), value: key }))
  }, [t])
  const onChange = useCallback((event: React.ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    globalDialog.setConfig(createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Box whiteSpace="pre" textAlign="center" className={dialogClasses.text}>
          {t('dialog.changeJumpShipTiming')}
        </Box>
      )
    }))
    globalDialog.setOpen({
      id: 'changeTiming',
      value: true,
      isOK: false
    })
    setNextTiming(event.target.value as string)
  }, [])
  useDialogFlow(globalDialog, 'changeTiming', () => {
    handleChange('timing')(nextTiming)
  }, [nextTiming])
  return (
    <DropDown
      fullWidth
      disabled={disabled.timing}
      options={timingOptions}
      label={t('common.jumpShipTiming')}
      value={value.timing}
      onChange={onChange}
      error={error.timing !== null}
      helperText={error.timing ?? ''}
    />
  )
})

const ValidationTable: React.FC<{ validation: PlayerJumpShipValidation | null }> = React.memo((props) => {
  const { validation } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { value } = useContext(FormContext)
  const { t } = useT()
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.greyTableRow
    }
  }, [commonClasses])
  const validationRows = useMemo(() => {
    if (!validation) return []
    return convertAccountToAccounts(value.account)
      .map((account, index) => {
        const accountValidation = validation.account.find((item) => item.index === index)
        const rule = validation.rule.find((item) => item.index === index)
        const agentChain = validation.agent_chain.find((item) => item.index === index)
        return {
          id: index,
          account,
          accountValidation: accountValidation
            ? (
            <Typography color="error">
              {accountValidation.errors.map((item) => t(playerJumpShipErrorTypeName[item])).join('、')}
            </Typography>
              )
            : (
            <CheckCircleIcon className={classes.check} />
              ),
          rule: rule
            ? (
            <Typography color="error">
              {rule.errors.map((item) => t(playerJumpShipErrorTypeName[item])).join('、')}
            </Typography>
              )
            : (!accountValidation
                ? (
            <CheckCircleIcon className={classes.check} />
                  )
                : (<span></span>)),
          agentChain: agentChain
            ? (
            <Typography color="error">
              {
                agentChain.errors
                  .map((item) => {
                    if (item === PlayerJumpShipErrorType.CANT_CROSS_AGENT_CHAIN) {
                      return `${t(playerJumpShipErrorTypeName[item])}(${agentChain.staff})`
                    }
                    return t(playerJumpShipErrorTypeName[item])
                  })
                  .join('、')
              }
            </Typography>
              )
            : (!accountValidation
                ? (
            <CheckCircleIcon className={classes.check} />
                  )
                : (<span></span>))
        } as ValidationRowType
      })
  }, [validation, value.account, t, classes])
  const validationData = useMemo(() => {
    const columns: Array<keyof ValidationRowType> = [
      'account',
      'accountValidation'
    ]
    if (!value.notCheckRule) columns.push('rule')
    if (!value.notCheckAgentChain) columns.push('agentChain')
    return createTableData<ValidationRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.jumpShipAccount'),
          value: 'account'
        },
        accountValidation: {
          label: t('common.account'),
          value: 'accountValidation'
        },
        rule: {
          label: t('common.jumpShipRule'),
          value: 'rule'
        },
        agentChain: {
          label: t('common.crossAgentChain'),
          value: 'agentChain'
        }
      },
      columns,
      validationRows,
      'id'
    )
  }, [validationRows, t, value.notCheckRule, value.notCheckAgentChain])
  if (!validation) return null
  return (
    <CoreTable
      classes={tableClasses}
      data={validationData}
      total={convertAccountToAccounts(value.account).length}
      dense
    />
  )
})

const PlayerJumpShipCreatePage: React.FC = () => {
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const pageFlow = usePageFlow()
  const [step, setStep] = useState<number>(0)
  const [advanceIsOpen, setAdvanceIsOpen] = useState<boolean>(false)
  const [formValidation, setFormValidation] = useState<PlayerJumpShipValidation | null>(null)
  const validation = useMemo(() => {
    return {
      reason: [
        {
          func: createValidateDropDownNotEmpty<FormType>('reason', t),
          when: ['beforeClickSubmit']
        },
        {
          func: (value) => {
            if (value !== PlayerJumpShipReasonType.CUSTOM) {
              return createCorrectResult('description')
            }
            return { isPass: true, stop: false, newError: {} }
          },
          when: ['change']
        }
      ],
      description: [
        {
          func: (value, form, lastSubmitForm) => {
            if (form.reason === PlayerJumpShipReasonType.CUSTOM) {
              return createValidateNotEmpty<FormType>('description', t)(value, form, lastSubmitForm, null)
            }
            return createCorrectResult('description')
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastSubmitForm) => {
            if (form.reason === PlayerJumpShipReasonType.CUSTOM) {
              return createValidateRange<FormType>('description', (value) => (value as string).length, 'lessEqual', 20, t('error.mustLessEqualTwentyWords'))(value, form, lastSubmitForm, null)
            }
            return createCorrectResult('description')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      staff: [
        {
          func: createValidateNotEmpty<FormType>('account', t),
          when: ['beforeClickSubmit']
        }
      ],
      timing: [],
      notCheckRule: [],
      notCheckAgentChain: [],
      account: [
        {
          func: (value) => {
            if (step === 0) {
              if (convertAccountToAccounts(value as string).length === 0) {
                return createErrorResult('account', t('error.mustNotEmpty'))
              }
            }
            if (step === 1) {
              if (filterAccounts(convertAccountToAccounts(value as string), formValidation).length === 0) {
                return createErrorResult('account', t('error.mustNotEmpty'))
              }
            }
            return createCorrectResult('account')
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange('account', (value) => convertAccountToAccounts(value as string).length, 'lessEqual', 20, t('error.exceedUpperLimit')),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t, step, formValidation])
  const handleLastStep = useCallback(() => {
    setStep((step) => {
      if (step !== 0) return step - 1
      return 0
    })
  }, [])
  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.PLAYER_JUMP_SHIP
  })
  const { handleSubmit: handleValidation, loading: validationLoading } = useGDKFuncHandleSubmit({
    formToRequest: formToRequest(step, formValidation),
    gdkFunc: (data) => gdk.jumpShip.validate(data),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: PlayerJumpShipValidation) => {
      setFormValidation(res)
      setStep(1)
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        message: error.message,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: 'validateJumpShipError',
        value: true,
        isOK: false
      })
    }
  })
  const { handleSubmit: handleCreateJumpShip, loading: createLoading } = useDialogHandleSubmit({
    dialogId: 'createJumpShip',
    globalDialog,
    getChangeDialogConfig: useCallback((form: FormType) => createGlobalDialogConfig({
      showIcon: false,
      message: t('common.allJumpShip', {
        count: filterAccounts(convertAccountToAccounts(form.account), formValidation).length
      })
    }), [t, formValidation]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      notUseTypo: true,
      message: (
        <Box>
          <div className={clsx(dialogClasses.text)}>{t('dialog.jumpShipSuccess')}</div>
          <div className={clsx(dialogClasses.text)}>{t('dialog.wait1')}</div>
        </Box>
      )
    }), [dialogClasses.text, t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest: formToRequest(step, formValidation),
    gdkFunc: (payload) => gdk.jumpShip.createJumpShipRequisition(payload),
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  const getDisabled: DisableFieldGetter<FormType> = useMemo(() => {
    return {
      reason: () => validationLoading || step !== 0,
      description: () => validationLoading || step !== 0,
      staff: (_, form) => {
        return form.reason === PlayerJumpShipReasonType.PLAYER_TO_PLATFORM || validationLoading || step !== 0
      },
      timing: (_, form) => {
        return nowTimingReasons.includes(form.reason) || validationLoading || step !== 0
      },
      notCheckRule: (_, form) => validationLoading || form.reason === PlayerJumpShipReasonType.STUPID_PLAYER || step !== 0,
      notCheckAgentChain: (_, form) => form.reason === PlayerJumpShipReasonType.PLAYER_TO_PLATFORM || validationLoading || step !== 0,
      account: () => validationLoading || step !== 0
    }
  }, [validationLoading, step])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.greyTableRow
    }
  }, [commonClasses])
  const ruleRows = useMemo(() => {
    return [
      {
        id: PlayerJumpShipReasonType.STUPID_PLAYER,
        reason: t('playerJumpShipReason.stupidPlayer'),
        limit: (<p className={commonClasses.pre}>{t('common.jumpShipLimit1')}</p>),
        auto: t('common.now'),
        agent: t('common.okShort'),
        memo: t('common.jumpShipTip18')
      },
      {
        id: PlayerJumpShipReasonType.LONG_TIME_NO_LOGIN,
        reason: t('playerJumpShipReason.longTimeNoLogin'),
        limit: (<p className={commonClasses.pre}>{t('common.jumpShipTip2')}</p>),
        auto: t('common.now'),
        agent: t('common.okShort'),
        memo: ''
      },
      {
        id: PlayerJumpShipReasonType.PLAYER_TO_PLATFORM,
        reason: t('playerJumpShipReason.playerToPlatform'),
        limit: (<p>{t('common.none')}</p>),
        auto: t('common.endOfMonth'),
        agent: t('common.okShort'),
        memo: ''
      },
      {
        id: PlayerJumpShipReasonType.ABANDON_PLAYER,
        reason: t('playerJumpShipReason.abandonPlayer'),
        limit: (<p>{t('common.none')}</p>),
        auto: t('common.endOfMonth'),
        agent: t('common.notOKShort'),
        memo: ''
      },
      {
        id: PlayerJumpShipReasonType.CUSTOM,
        reason: t('common.custom'),
        limit: (<p>{t('common.none')}</p>),
        auto: t('common.endOfMonth'),
        agent: t('common.notOKShort'),
        memo: ''
      }
    ]
  }, [t, commonClasses])
  const ruleData = useMemo(() => {
    return createTableData<RuleRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        reason: {
          label: t('common.jumpShipReason'),
          value: 'reason',
          align: 'left'
        },
        limit: {
          label: t('common.jumpShipLimit'),
          value: 'limit',
          align: 'left'
        },
        auto: {
          label: t('common.jumpShipAutoTime'),
          value: 'auto',
          align: 'center'
        },
        agent: {
          label: t('common.jumpAgent'),
          value: 'agent',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'left'
        }
      },
      [
        'reason',
        'limit',
        'auto',
        'agent',
        'memo'
      ],
      ruleRows,
      'id'
    )
  }, [ruleRows, t])
  return (
    <Box paddingY={5}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <FormStateProvider
              context={FormContext}
              defaultValue={defaultForm}
              onSubmit={step === 0 ? handleValidation : handleCreateJumpShip}
              validation={validation}
              getValueFromEvent={getValueFromEvent}
              getChangedForm={getChangedForm}
              disableField={getDisabled}
            >
              <StepProgress step={step} />
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <ReasonDropDown />
                    </Grid>
                    <CustomInput />
                    <Grid item xs={12} md={6} lg={3}>
                      <StaffAccount />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {advanceIsOpen
                    ? (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TimingDropDown />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormField<FormType, OnOffCheckboxProps>
                              context={FormContext}
                              component={OnOffCheckbox}
                              name="notCheckRule"
                              label={t('common.notCheckJumpShipRule')}
                            />
                          </Grid>
                          <Grid item>
                            <FormField<FormType, OnOffCheckboxProps>
                              context={FormContext}
                              component={OnOffCheckbox}
                              name="notCheckAgentChain"
                              label={t('common.notCheckAgentChain')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      )
                    : (
                    <Button
                      className={commonClasses.purpleGradualButton}
                      variant="contained"
                      onClick={() => { setAdvanceIsOpen(true) }}
                    >
                      {t('common.advanceSetting')}
                    </Button>
                      )}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t('common.jumpShipAccount')} *</Typography>
                  {step === 0 && (
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      variant="outlined"
                      placeholder={t('common.jumpShipTip19')}
                      multiline
                      fullWidth
                      required
                      rows={20}
                      rowsMax={20}
                    />
                  )}
                  {step === 1 && (<ValidationTable validation={formValidation} />)}
                </Grid>
                <Grid item>
                  <RequiredText />
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        className={commonClasses.greyButton}
                        onClick={handleDebouncedBack}
                      >
                        {t('common.back')}
                      </Button>
                    </Grid>
                    {step > 0 && (
                      <Grid item>
                        <Button
                          className={commonClasses.purpleGradualOutlineButton}
                          onClick={handleLastStep}
                        >
                          {t('common.lastStep')}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      {step === 0 && (
                        <FormSubmitButton
                          type="submit"
                          context={FormContext}
                          component={Button}
                          className={commonClasses.purpleGradualButton}
                          disabled={validationLoading}
                        >
                          {validationLoading
                            ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <CircularProgress size={24} />
                            </Box>
                              )
                            : t('common.nextStep')}
                        </FormSubmitButton>
                      )}
                      {step === 1 && (
                        <FormSubmitButton
                          type="submit"
                          context={FormContext}
                          component={Button}
                          className={commonClasses.purpleGradualButton}
                          disabled={createLoading}
                        >
                          {createLoading
                            ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <CircularProgress size={24} />
                            </Box>
                              )
                            : t('common.confirmJumpShip')}
                        </FormSubmitButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormStateProvider>
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <Box paddingTop={2}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={7}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" className={commonClasses.bold}>
                      {t('common.jumpShipDescription')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CoreTable
                      classes={tableClasses}
                      data={ruleData}
                      total={5}
                      dense
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" className={commonClasses.bold}>
                      {t('common.jumpShipTip6')}
                    </Typography>
                    <ol>
                      <li>
                        <Typography>
                          {t('common.jumpShipTip7')}
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          {t('common.jumpShipTip8')}
                        </Typography>
                      </li>
                    </ol>
                  </Grid>
                  <Grid item>
                    <Typography className={commonClasses.bold}>
                      {t(playerJumpShipReasonName[PlayerJumpShipReasonType.STUPID_PLAYER])}
                    </Typography>
                    <ol>
                      <li>
                        {t('common.jumpShipTip9')}
                      </li>
                    </ol>
                  </Grid>
                  <Grid item>
                    <Typography className={commonClasses.bold}>
                      {[
                        PlayerJumpShipReasonType.LONG_TIME_NO_LOGIN,
                        PlayerJumpShipReasonType.PLAYER_TO_PLATFORM,
                        PlayerJumpShipReasonType.ABANDON_PLAYER,
                        PlayerJumpShipReasonType.CUSTOM
                      ]
                        .map((item) => t(playerJumpShipReasonName[item]))
                        .join('、')}
                    </Typography>
                    <ol>
                      <li>
                        {t('common.jumpShipTip24')}
                      </li>
                      <ul>
                        <li>
                          {t('common.jumpShipTip21')}
                        </li>
                      </ul>
                      <li>
                        {t('common.jumpShipTip11')}
                      </li>
                      <ul>
                        <li>
                          {t('common.jumpShipTip12')}
                        </li>
                        <li>
                          {t('common.jumpShipTip13')}
                        </li>
                      </ul>
                      <li>
                        {t('common.jumpShipTip23')}
                      </li>
                      <ul>
                        <li>
                          {t('common.jumpShipTip20')}
                        </li>
                      </ul>
                      <li>
                        {t('common.jumpShipTip15')}
                      </li>
                      <ul>
                        <li>
                          {t('common.jumpShipTip21')}
                        </li>
                      </ul>
                      <li>
                        {t('common.jumpShipTip22')}
                      </li>
                      <ul>
                        <li>
                          {t('common.jumpShipTip20')}
                        </li>
                      </ul>
                      <li>
                        {t('common.jumpShipTip25')}
                      </li>
                      <ul>
                        <li>
                        {t('common.jumpShipTip26')}
                        </li>
                        <li>
                        {t('common.jumpShipTip27')}
                        </li>
                      </ul>
                    </ol>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ScrollablePaper>
      </Box>
    </Box>
  )
}

export default React.memo(PlayerJumpShipCreatePage)
