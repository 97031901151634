import React, { useCallback, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CoreTable from '../../default/present/CoreTable'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { EditBlacklistFormType } from './BlacklistFormStateProvider'
import { NewFinanceBlacklist } from '@golden/gdk-admin'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { createTableData } from '../../../utils/default/TableHelper'

interface PropTypes {
  onBack: () => void
  values: EditBlacklistFormType[]
  disabled: boolean
}

const formToRequest: (form: EditBlacklistFormType) => NewFinanceBlacklist = (form) => ({
  real_name: form.realName === '' ? '--' : form.realName,
  bank_account: form.bankAccount,
  bank_code: Number(form.bank),
  memo: form.memo
})

interface RowType {
  id: number
  name: string
  account: string
}

const EditBlacklistFormSubmitButton: React.FC<PropTypes> = (props) => {
  const { onBack, disabled, values } = props
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()

  const payload = useMemo(() => ({ cards: values.map((value) => formToRequest(value)) }), [values])

  const rows: RowType[] = useMemo(() => {
    return values.map((value, index) => ({
      id: index,
      name: value.realName,
      account: value.bankAccount
    }))
  }, [values])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        }
      },
      [
        'name',
        'account'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handleClick = useDialogHandleClick({
    dialogId: 'createBlacklist',
    globalDialog,
    payload,
    gdkFunc: (payload) => gdk.finance.createBlacklist(payload),
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={dialogClasses.text}>{t('dialog.confirmCreateDebitCard')}</Typography>
          <CoreTable
            dense
            noScroll
            data={data}
            total={values.length}
            classes={{
              head: commonClasses.blackTableHead,
              cellHead: commonClasses.tableCellHead,
              row: commonClasses.borderTableRow
            }}
          />
        </Box>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createDebitCardSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    afterSuccessDialog: () => { onBack() }
  })

  return (
    <Button
      className={commonClasses.purpleGradualButton}
      disabled={disabled}
      onClick={handleClick}
    >
      {t('common.confirmCreate')}
    </Button>
  )
}

export default React.memo(EditBlacklistFormSubmitButton)
