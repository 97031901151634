import React, { useMemo, useState, createContext, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { PaginationRes, AdminBank } from '@golden/gdk-admin'
import { getBankImage } from '@golden/shared'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl, useReload, useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import MemoButton from '../../default/memo/MemoButton'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { searchToRequest } from '../../../views/admin/finance/FinanceBankPage'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const useStyles = makeStyles((theme) => ({
  checked: {
    color: '#72d476'
  },
  cancel: {
    color: '#ff5f5f'
  },
  error: {
    color: '#c41f46',
    margin: theme.spacing(0.5)
  },
  black: {
    color: theme.palette.common.black
  }
}))

export interface RowType {
  id: number
  detail: AdminBank
  logo: React.ReactElement
  name: string
  code: string
  short: string
  status: React.ReactElement
  maintain: React.ReactElement
  memo: React.ReactElement
  function: React.ReactElement
}

export const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([createDefaultMemoPopoverPayload(), () => {}])

const MaintainButton: React.FC<{ bank: AdminBank, reload: () => void }> = React.memo((props) => {
  const { bank, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const handleStart = useDialogHandleClick({
    dialogId: `startMaintain-${bank.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStartMaintain')}
          <span className={commonClasses.purpleWord}>{bank.name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.startMaintain')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: bank.id,
    gdkFunc: (payload) => gdk.finance.maintainBank(payload, true),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  const handleStop = useDialogHandleClick({
    dialogId: `stopMaintain-${bank.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStopMaintain')}
          <span className={commonClasses.purpleWord}>{bank.name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.stopMaintain')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: bank.id,
    gdkFunc: (payload) => gdk.finance.maintainBank(payload, false),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  if (bank.maintained) {
    return (
      <Button
        className={commonClasses.greyOutlineButton}
        onClick={handleStop}
      >
        {t('common.stopMaintain')}
      </Button>
    )
  }
  return (
    <Button
      className={commonClasses.greyOutlineButton}
      onClick={handleStart}
    >
      {t('common.startMaintain')}
    </Button>
  )
})

const FinanceBankTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { reload, reloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<AdminBank[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow(true)
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()
  useGetDataByPayload({
    payload: request,
    gdkFunc: (payload) => gdk.finance.getBankList(payload ?? { page: 1 }),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AdminBank[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellBody: commonClasses.nowrap,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])
  const rows = useMemo(() => {
    return list.data.map((item) => {
      let img = ''
      try {
        img = getBankImage(item.simple_code)
      } catch {}
      return {
        id: item.id,
        detail: item,
        logo: (item.url !== '' && item.url !== null)
          ? (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <img src={img} alt={item.simple_name} width={200} />
          </a>
            )
          : (<img src={img} alt={item.simple_name} width={200} />),
        name: item.name,
        code: item.simple_code,
        short: item.simple_name,
        status: item.is_showed ? (<CheckCircleIcon className={classes.checked} />) : (<CancelIcon className={classes.cancel} />),
        maintain: item.maintained ? (<Grid item><span className={commonClasses.chipText} style={{ backgroundColor: '#ff5f5f' }}>{t('common.maintaining')}</span></Grid>) : (<p></p>),
        memo: (item.memo !== '' && item.memo !== null)
          ? (
          <MemoPopoverContext.Consumer>
            {([, setMemoPopoverPayload]) => (
              <MemoButton
                memo={item.memo}
                onMouseEnter={(event) => {
                  setMemoPopoverPayload({
                    open: true,
                    anchorEl: event.currentTarget,
                    memo: item.memo
                  })
                }}
                onMouseLeave={() => { setMemoPopoverPayload((memoPopoverPayload) => ({ ...memoPopoverPayload, open: false })) }}
              />
            )}
          </MemoPopoverContext.Consumer>
            )
          : (<p></p>),
        function: (
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.blueGradualOutlineButton}
                component={Link}
                to={allRoute.financeBankUpdate.encodePath({ search: { ...request }, param: { id: item.id } })}
              >
                {t('common.update')}
              </Button>
            </Grid>
            <Grid item>
              <MaintainButton bank={item} reload={reload} />
            </Grid>
          </Grid>
        )
      } as RowType
    })
  }, [request, classes.cancel, classes.checked, commonClasses.blueGradualOutlineButton, commonClasses.chipText, list.data, t, reload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        detail: {
          label: '',
          value: 'detail'
        },
        logo: {
          label: t('common.bankLogo'),
          value: 'logo',
          align: 'center'
        },
        name: {
          label: t('common.bankName'),
          value: 'name',
          align: 'center'
        },
        code: {
          label: t('common.bankCode'),
          value: 'code',
          align: 'center'
        },
        short: {
          label: t('common.bankShortName'),
          value: 'short',
          align: 'center'
        },
        status: {
          label: t('common.playerCanSee'),
          value: 'status',
          align: 'center'
        },
        maintain: {
          label: t('common.withdrawMaintain'),
          value: 'maintain',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        }
      },
      writable
        ? [
            'name',
            'code',
            'short',
            'logo',
            'status',
            'maintain',
            'memo',
            'function'
          ]
        : [
            'name',
            'code',
            'short',
            'logo',
            'status',
            'maintain',
            'memo'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeBank.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <MemoPopoverWithContext memoPopoverContext={MemoPopoverContext}>
        <CoreTable
          classes={tableClasses}
          data={data}
          total={list.total}
          showPagination
          page={request?.page ?? 1}
          onChangePage={handlePagination}
          loading={pageFlow.status.loading}
        />
      </MemoPopoverWithContext>
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FinanceBankTable)
