import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  id: number
  name: string
  reload: () => void
  disabled?: boolean
}

const ButtonsBase: React.FC<{
  id: number
  handleDelete: () => void
  disabled?: boolean
}> = React.memo((props) => {
  const {
    id,
    handleDelete,
    disabled = false
  } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <Button
          component={Link}
          to={allRoute.roleManagementUpdate.encodePath({ search: {}, param: { id } })}
          size="small"
          className={classes.blueGradualOutlineButton}
          classes={{ disabled: classes.blueGradualOutlineDisabledButton }}
          disabled={disabled}
        >
          {t('common.update')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleDelete}
          size="small"
          className={classes.pinkGradualOutlineButton}
          classes={{ disabled: classes.pinkGradualOutlineDisabledButton }}
          disabled={disabled}
        >
          {t('common.delete')}
        </Button>
      </Grid>
    </Grid>
  )
})

const RoleManagementButtons: React.FC<PropTypes> = (props) => {
  const { id, name, reload, disabled = false } = props
  const classes = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { t } = useT()

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteRole-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <div className={classes.pre} style={{ textAlign: 'center' }}>
          {`${t('dialog.confirmDelete')}\n`}
          <span className={classes.purpleWord}>[{name}]</span>
        </div>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteFinish')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    payload: id,
    gdkFunc: (payload) => gdk.permissionRole.deleteRole(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  return (
    <ButtonsBase
      id={id}
      handleDelete={handleDelete}
      disabled={disabled}
    />
  )
}

export default React.memo(RoleManagementButtons)
