import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { NotificationChannelType, SystemNotificationForm, NotificationType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import RequiredText from '../../default/form/RequiredText'
import NotificationChannelCheckboxGroup, { PropTypes as NotificationChannelCheckboxGroupProps } from './NotificationChannelCheckboxGroup'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { FormPropType, getValueFromValue, getValueFromCheckboxEvent, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createValidateRange, createValidateEditorNotEmpty } from '../../../utils/default/Validator'
import notificationTypeName from '../../../constants/admin/notificationTypeName'
import { TextEditor } from '../../default/form/textEditor/TextEditor'
import { defaultEditorState, EditorState, insertText } from '@golden/tiptap-react'
import { ExtendControlType } from '../../default/form/textEditor/TextEditorToolbar'

export interface FormType {
  title: string
  content: EditorState
  channels: NotificationChannelType[]
  isActive: boolean
}

export const initialForm: InitialFormFunc<FormType> = (form) => ({
  title: '',
  content: defaultEditorState,
  channels: [],
  isActive: true,
  ...form
})

export const formToRequest = (data: FormType): SystemNotificationForm => ({
  title: data.title,
  content: JSON.stringify(data.content.content),
  channels: data.channels,
  is_active: data.isActive
})

interface PropTypes extends FormPropType<FormType> {
  notificationType: NotificationType
  okText: string
  onBack: () => void
}

const getValueFromEvent: ValueGetter<FormType> = {
  title: getValueFromChangeEvent,
  content: getValueFromValue,
  channels: getValueFromValue,
  isActive: getValueFromCheckboxEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const titleInputProps = {
  maxLength: 20
}

const Editor: React.FC<{
  notificationType: NotificationType
}> = React.memo((props) => {
  const { notificationType } = props
  const { t } = useT()
  const { value, handleChange, error } = useContext(FormContext)
  const insertLink = useCallback(async () => {
    handleChange('content')(await insertText(value.content, '{{link}}'))
  }, [value.content])
  const insertCard = useCallback(async () => {
    handleChange('content')(await insertText(value.content, '{{card}}'))
  }, [value.content])
  const extendControls = useMemo(() => {
    return [
      'separator',
      {
        key: 'insert-link',
        type: 'button',
        children: (
          <Box display="flex" flexDirection="row" alignItems="center">
            <MailOutlineIcon />
            <Box component="span" paddingLeft={0.5}>{t('common.insertEmailLink')}</Box>
          </Box>
        ),
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: insertLink,
        disabled: notificationType !== NotificationType.SEND_EMAIL_VERIFY_LINK
      },
      {
        key: 'insert-card',
        type: 'button',
        children: (
          <Box display="flex" flexDirection="row" alignItems="center">
            <CreditCardIcon />
            <Box component="span" paddingLeft={0.5}>{t('common.insertDebitCard')}</Box>
          </Box>
        ),
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: insertCard,
        disabled: ![
          NotificationType.DEBIT_CARD_REVIEW_SUCCESSFUL,
          NotificationType.DEBIT_CARD_REVIEW_FAIL,
          NotificationType.MODIFY_BANK_CARD_SUCCESS,
          NotificationType.MODIFY_BANK_CARD_FAILURE
        ].includes(notificationType)
      }
    ] as ExtendControlType[]
  }, [insertLink, insertCard, t, notificationType])
  return (
    <TextEditor
      value={value.content}
      onChange={handleChange('content')}
      error={error.content !== null}
      helperText={error.content ?? ''}
      placeholder={`${t('placeholder.inputContent')}*`}
      controls={['textColor', 'clearFormat']}
      extendControls={extendControls}
    />
  )
})

const EditSystemNotificationForm: React.FC<PropTypes> = (props) => {
  const { defaultValue, notificationType, onSubmit, okText, onBack } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const validation: FormValidation<FormType> = useMemo(() => {
    return {
      title: [],
      content: [
        {
          func: createValidateEditorNotEmpty('content', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      channels: [
        {
          func: createValidateRange(
            'channels',
            (value) => (value as NotificationChannelType[]).length,
            'moreThan',
            0,
            t('error.mustNotEmpty')
          ),
          when: ['beforeClickSubmit']
        }
      ],
      isActive: []
    }
  }, [t])
  return (
    <FormStateProvider<FormType>
      context={FormContext}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      getValueFromEvent={getValueFromEvent}
      validation={validation}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <TextField
              label={t('common.sendSituation')}
              value={t(notificationTypeName[notificationType])}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <FormField<FormType, TextFieldProps>
              component={TextField}
              context={FormContext}
              name="title"
              label={t('common.sendTitle')}
              placeholder={t('placeholder.inputTitle')}
              inputProps={titleInputProps}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <Editor notificationType={notificationType} />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <FormField<FormType, NotificationChannelCheckboxGroupProps>
            component={NotificationChannelCheckboxGroup}
            context={FormContext}
            name="channels"
            label={t('common.sendChannel')}
            notificationType={notificationType}
            required
          />
        </Grid>
        <Grid item container direction="row">
          <Grid item>
            <Typography>{t('common.isOpen')}</Typography>
            <FormField<FormType, OnOffCheckboxProps>
              component={OnOffCheckbox}
              context={FormContext}
              name="isActive"
              label={t('common.isOpen')}
            />
          </Grid>
        </Grid>
        <Grid item>
          <RequiredText />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={classes.greyButton}
              onClick={onBack}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              component={Button}
              context={FormContext}
              className={classes.purpleGradualButton}
              type="submit"
            >
              {okText}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(EditSystemNotificationForm)
