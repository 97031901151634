import React, { createContext, useMemo, useContext, useState, useCallback } from 'react'
import { ForwarderType, DepositMethodType, ClientType, DepositCategoryType, ForwarderConfig } from '@golden/gdk-admin'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import NumberInput from '../../default/form/NumberInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormPropType, getValueFromChangeEvent, getValueFromValue, getValueFromCheckboxEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty, createValidateRange, createErrorResult, createCorrectResult, createValidateCash, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import { createDefaultFormState, ValueGetter, FormValidation, ChangedFormGetter, DisableFieldGetter } from '../../../utils/default/FormHook'
import { InitialFormFunc, useGetData } from '../../../utils/default/ComplexFlowHook'
import depositTypeName from '../../../constants/default/depositTypeName'
import useGDK from '../../../providers/admin/gdk/useGDK'
import RequiredText from '../../default/form/RequiredText'
import clientTypeName from '../../../constants/admin/clientTypeName'
import OpenPlayerLayerInput, { PropTypes as OpenPlayerLayerInputProps, OpenPlayerLayerValue } from '../OpenPlayerLayerInput'
import OpenAgentLayerInput, { PropTypes as OpenAgentLayerInputProps, OpenAgentLayerValue } from '../OpenAgentLayerInput'
import CheckboxGroup, { PropTypes as CheckboxGroupProps } from '../../default/form/CheckboxGroup'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { DepositMethodFactory, OpenWindowAction } from '@golden/shared'

export interface EditFinanceDepositWayFormType {
  slug: ForwarderType | string
  courier: number | string
  method: [DepositCategoryType | '', DepositMethodType | '']
  min: string
  max: string
  full: string
  userFee: string
  playerLayer: OpenPlayerLayerValue
  agentLayers: OpenAgentLayerValue
  isActive: boolean
  isRecommended: boolean
  clients: ClientType[]
}

export const initialForm: InitialFormFunc<EditFinanceDepositWayFormType> = (defaultForm) => ({
  slug: '',
  courier: '',
  category: '',
  method: ['', ''],
  min: '',
  max: '',
  full: '',
  userFee: '',
  playerLayer: {
    layers: [],
    isForProxyDeposit: false
  },
  agentLayers: [],
  isActive: false,
  isRecommended: false,
  clients: [],
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditFinanceDepositWayFormType> = {
  slug: getValueFromChangeEvent,
  courier: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  full: getValueFromChangeEvent,
  userFee: getValueFromChangeEvent,
  playerLayer: getValueFromValue,
  agentLayers: getValueFromValue,
  isActive: getValueFromCheckboxEvent,
  isRecommended: getValueFromCheckboxEvent,
  clients: getValueFromValue
}

const getChangedForm: ChangedFormGetter<EditFinanceDepositWayFormType> = {
  slug: (value, form) => {
    return {
      ...form,
      slug: value,
      courier: '',
      method: ['', ''],
      way: '',
      min: '',
      max: ''
    }
  },
  courier: (value, form) => {
    return {
      ...form,
      courier: value,
      method: ['', ''],
      way: '',
      min: '',
      max: ''
    }
  }
}

interface PropTypes extends FormPropType<EditFinanceDepositWayFormType> {
  title: string
  okText: string
  onBack: () => void
  disableForwarder?: boolean
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const rateInputProps = {
  decimalScale: 1,
  allowNegative: false,
  suffix: '%'
}

const cashInputProps = getCashInputProps()

const Fields: React.FC = React.memo(() => {
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const { t } = useT()
  const gdk = useGDK()
  const forwarders = useGDKStore.finance.forwarders()

  const [configs, setConfigs] = useState<ForwarderConfig[]>([])
  useGetData({
    gdkFunc: () => gdk.finance.getForwarderConfig(),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: ForwarderConfig[]) => { setConfigs(res) }
  })

  const courierOptions = useMemo(() => {
    if (!(value.slug in forwarders)) return []
    return forwarders[value.slug].forwarders.map(({ id, name }) => ({ value: id, name }))
  }, [forwarders, value.slug])

  const forwarderOptions = useMemo(
    () => Object.entries(forwarders).map(([value, { name }]) => ({ value, name })),
    [forwarders]
  )

  const wayOptions = useMemo(() => {
    const config = configs.find((config) => config.slug === value.slug)
    if (config) {
      return config.deposit_methods.map((method) => ({ name: t(depositTypeName[method.method]), value: [method.category, method.method] }))
    }
    return []
  }, [configs, t, value.slug])

  const method = useMemo(() => {
    const config = configs.find((config) => config.slug === value.slug)
    if (config) {
      return config.deposit_methods.find((method) => method.method === value.method[1])
    }
    return undefined
  }, [configs, value.method, value.slug])

  const onCourierChange = useCallback(handleChange('courier'), [])
  const onCourierBlur = useCallback(handleOther('blur', 'courier'), [])
  const onMethodChange = useCallback(handleChange('method'), [])
  const onMethodBlur = useCallback(handleOther('blur', 'method'), [])
  const onMinChange = useCallback(handleChange('min'), [])
  const onMaxChange = useCallback(handleChange('max'), [])

  return (
    <React.Fragment>
      <Grid item>
        <Grid container direction="row" spacing={2} wrap="wrap">
          <Grid item xs={12} md={6} lg={3}>
            <FormField<EditFinanceDepositWayFormType, DropDownProps>
              context={FormContext}
              component={DropDown}
              name="slug"
              label={t('common.forwarder')}
              options={forwarderOptions}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DropDown
              fullWidth
              required
              label={t('common.courierName')}
              options={courierOptions}
              value={value.courier}
              onChange={onCourierChange}
              onBlur={onCourierBlur}
              helperText={error.courier ?? ''}
              error={error.courier !== null}
              disabled={courierOptions.length === 0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DropDown
              fullWidth
              required
              label={t('common.depositWay')}
              options={wayOptions}
              value={value.method}
              onChange={onMethodChange}
              onBlur={onMethodBlur}
              helperText={error.method ?? ''}
              error={error.method !== null}
              disabled={wayOptions.length === 0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2} wrap="wrap">
          <Grid item xs={12} md={3}>
            <NumberInput
              fullWidth
              required
              label={t('common.minDepositMoney')}
              placeholder={t('placeholder.inputMinDepositMoney')}
              inputProps={cashInputProps}
              value={value.min}
              onChange={onMinChange}
              helperText={error.min}
              error={error.min !== null}
              disabled={method === undefined}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumberInput
              fullWidth
              required
              label={t('common.maxDepositMoney')}
              placeholder={t('placeholder.inputMaxDepositMoney')}
              inputProps={cashInputProps}
              value={value.max}
              onChange={onMaxChange}
              helperText={error.max}
              error={error.max !== null}
              disabled={method === undefined}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField<EditFinanceDepositWayFormType, TextFieldProps>
              context={FormContext}
              component={NumberInput}
              name="full"
              label={t('common.stopMoney')}
              placeholder={t('placeholder.inputStopMoney')}
              fullWidth
              required
              inputProps={cashInputProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2} wrap="wrap">
          <Grid item xs={12} md={3}>
            <FormField<EditFinanceDepositWayFormType, TextFieldProps>
              context={FormContext}
              component={NumberInput}
              name="userFee"
              label={t('common.playerDepositFee')}
              fullWidth
              required
              inputProps={rateInputProps}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
})

const PlayerLayerInput: React.FC = () => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  const proxyDepositDisabled = useMemo(() => value.slug === ForwarderType.GOUBAO || (value.slug === ForwarderType.SF && value.method[1] === DepositMethodType.UNION_PAY_QUICK), [value.slug, value.method])

  return (<Grid item>
    <FormField<EditFinanceDepositWayFormType, OpenPlayerLayerInputProps>
      component={OpenPlayerLayerInput}
      context={FormContext}
      name="playerLayer"
      label={t('common.openPlayerLayer')}
      required
      proxyDepositDisabled={proxyDepositDisabled}
    />
  </Grid>)
}

const AgentLayerInput: React.FC = () => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  const openAgentLayer = useMemo(() => {
    if (value.method[1]) return DepositMethodFactory.create(value.method[1], (() => 1) as unknown as OpenWindowAction, () => '').openAgentLayer
    return false
  }, [value.method])

  return openAgentLayer
    ? (<Grid item>
    <FormField<EditFinanceDepositWayFormType, OpenAgentLayerInputProps>
      component={OpenAgentLayerInput}
      context={FormContext}
      name="agentLayers"
      label={t('common.openAgentLayer')}
      required
    />
  </Grid>)
    : null
}

const EditFinanceDepositWayForm: React.FC<PropTypes> = (props) => {
  const { title, okText, onBack, disableForwarder, onSubmit, defaultValue } = props
  const theme = useTheme()
  const classes = useCommonStyles()
  const { t } = useT()

  const disableField = useMemo(() => {
    return {
      slug: () => disableForwarder ?? false,
      fee: () => true
    } as DisableFieldGetter<EditFinanceDepositWayFormType>
  }, [disableForwarder])

  const clientOptions = useMemo(() => {
    return Object.keys(clientTypeName)
      .map((key) => Number(key) as ClientType)
      .map((key) => ({ name: t(clientTypeName[key]), value: key }))
  }, [t])

  const validation = useMemo(() => {
    return {
      slug: [
        {
          func: createValidateNotEmpty<EditFinanceDepositWayFormType>('slug', t),
          when: ['change', 'blur', 'beforeClickSubmit']
        }
      ],
      courier: [
        {
          func: createValidateNotEmpty<EditFinanceDepositWayFormType>('courier', t),
          when: ['change', 'blur', 'beforeClickSubmit']
        }
      ],
      method: [
        {
          func: (value, form, lastSubmitForm) => {
            return createValidateNotEmpty('method', t)((value as [DepositCategoryType | '', DepositMethodType | ''])[1], form, lastSubmitForm, null)
          },
          when: ['change', 'blur', 'beforeClickSubmit']
        }
      ],
      min: [
        {
          func: createValidateCash('min', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastSubmitForm) => {
            if (form.method[1] !== '') {
              return createValidateNotEmpty<EditFinanceDepositWayFormType>('min', t)(value, form, lastSubmitForm, null)
            }
            return createCorrectResult('min')
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMin('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      max: [
        {
          func: createValidateCash('max', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastSubmitForm) => {
            if (form.method[1] !== '') {
              return createValidateNotEmpty<EditFinanceDepositWayFormType>('max', t)(value, form, lastSubmitForm, null)
            }
            return createCorrectResult('max')
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateMax('min', 'max', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      full: [
        {
          func: createValidateCash('full', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateNotEmpty<EditFinanceDepositWayFormType>('full', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      fee: [],
      userFee: [
        {
          func: createValidateNotEmpty<EditFinanceDepositWayFormType>('userFee', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateRange<EditFinanceDepositWayFormType>(
            'userFee',
            (value) => Number(value),
            'lessEqual',
            100,
            t('error.rateError')
          ),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      playerLayer: [
        {
          func: (value, form) => {
            const playerLayer = (value as OpenPlayerLayerValue)
            if (playerLayer.layers.length === 0 && !playerLayer.isForProxyDeposit && form.agentLayers.length === 0) {
              return createErrorResult('playerLayer', t('error.mustNotEmpty'))
            }
            return createCorrectResult('playerLayer')
          },
          when: ['beforeClickSubmit']
        }
      ],
      agentLayers: [
        {
          func: (value, form) => {
            const agentLayers = (value as OpenAgentLayerValue)
            if (form.playerLayer.layers.length === 0 && !form.playerLayer.isForProxyDeposit && agentLayers.length === 0) {
              return createErrorResult('agentLayers', t('error.mustNotEmpty'))
            }
            return createCorrectResult('agentLayers')
          },
          when: ['beforeClickSubmit']
        }
      ],
      isActive: [],
      isRecommended: [],
      clients: [
        {
          func: createValidateRange('clients', (value) => (value as any[]).length, 'moreThan', 0, t('error.mustNotEmpty')),
          when: ['beforeClickSubmit']
        }
      ],
      isForProxyDeposit: []
    } as FormValidation<EditFinanceDepositWayFormType>
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
      disableField={disableField}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Fields />
            <Grid item>
              <Box
                marginTop={3}
                borderBottom={`4px solid ${theme.palette.grey[200]}`}
              />
            </Grid>
            <Grid item>
              <FormField<EditFinanceDepositWayFormType, CheckboxGroupProps>
                context={FormContext}
                component={CheckboxGroup}
                name="clients"
                label={t('common.platformVersion')}
                options={clientOptions}
                required
              />
            </Grid>
            <PlayerLayerInput />
            <AgentLayerInput />
            <Grid item>
              <Typography className={classes.bold}>{t('common.status')}</Typography>
              <FormField<EditFinanceDepositWayFormType, OnOffCheckboxProps>
                component={OnOffCheckbox}
                context={FormContext}
                name="isActive"
                label={t('common.isOpenDepositWay')}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.bold}>{t('common.suggestion')}</Typography>
              <FormField<EditFinanceDepositWayFormType, OnOffCheckboxProps>
                component={OnOffCheckbox}
                context={FormContext}
                name="isRecommended"
                label={t('common.isSuggestionWay')}
              />
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box paddingY={3} display="flex" flexDirection="row" justifyContent="flex-end">
        <Box paddingX={2}>
          <Button
            className={classes.greyButton}
            onClick={onBack}
          >
            {t('common.cancel')}
          </Button>
        </Box>
        <FormSubmitButton
          context={FormContext}
          component={Button}
          type="submit"
          className={classes.purpleGradualButton}
        >
          {okText}
        </FormSubmitButton>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(EditFinanceDepositWayForm)
