import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const useStyles = makeStyles(() => ({
  fileInput: {
    display: 'none'
  },
  button: {
    whiteSpace: 'nowrap'
  }
}))

export interface FileTextFieldValue {
  filename: string
  content: string
}

export interface PropTypes {
  label: string
  accept: string
  onChange?: (value: FileTextFieldValue) => void
  helperText?: string
  error?: boolean
  required?: boolean
  fullWidth?: boolean
}

const FileTextField: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { label, accept, onChange, required, error, helperText = '', fullWidth } = props
  const { t } = useT()
  const [internValue, setInternValue] = useState<FileTextFieldValue>({ filename: '', content: '' })
  const fileRef = React.createRef<HTMLInputElement>()
  const handleClick = useCallback(() => {
    if (fileRef.current) fileRef.current.click()
  }, [fileRef])
  const handleImportFile = useCallback((event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target?.result) return
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const newValue = { filename: file.name, content: e.target.result.toString() }
      setInternValue(newValue)
      if (onChange) onChange(newValue)
    }
    reader.readAsText(file)
  }, [onChange])
  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <TextField
        disabled
        label={label}
        value={internValue.filename}
        required={required}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
      />
      <Button
        onClick={handleClick}
        className={clsx(commonClasses.purpleGradualButton, classes.button)}
      >
        {t('common.importFile')}
      </Button>
      <input
        onChange={handleImportFile}
        className={classes.fileInput}
        type="file"
        accept={accept}
        ref={fileRef}
      />
    </Box>
  )
}

export default React.memo(FileTextField)
