import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ChannelFormStateProvider, {
  PropTypes as ChannelFormStateProviderProps,
  initialForm,
  EditChannelFormType
} from './ChannelFormStateProvider'
import FormField from '../../../components/default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../../components/default/form/OnOffCheckbox'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormState, createDefaultFormState } from '../../../utils/default/FormHook'
import { useRedirectHandleBack, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import CreateChannelFormSubmitButton from './CreateChannelFormSubmitButton'
import ExternalGameChannelImageButton from './ExternalGameChannelImageButton'
import ExternalGameChannelImageDialog, { Payload as ChannelImageDialogPayload } from './ExternalGameChannelImageDialog'
import { searchToRequest } from './ExternalGameChannelForm'
import { findGame } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { GameType } from '@golden/gdk-campaign'

const createDefaultRows = () => {
  const defaultFirstForm = initialForm()
  return [{
    id: 0,
    context: createContext(createDefaultFormState(defaultFirstForm)),
    defaultValue: defaultFirstForm
  }]
}

interface SubmitButtonContextType { id: number, disabled: boolean, isRepeat: boolean, value: EditChannelFormType }

const SubmitButtonContext = React.createContext<[
  SubmitButtonContextType[],
  Dispatch<SetStateAction<SubmitButtonContextType[]>>
]>([
  [],
  () => {}
])

const TextField = React.memo(MuiTextField)

const FormGameChangeParser: React.FC<{
  game: number
}> = React.memo((props) => {
  const { game } = props
  const [, setDisabled] = useContext(SubmitButtonContext)

  useEffect(() => {
    setDisabled([{
      id: 0,
      disabled: false,
      isRepeat: false,
      value: initialForm()
    }])
  }, [game])

  return null
})

const FormContextParser: React.FC<{
  id: number
  size: number
  context: React.Context<FormState<EditChannelFormType>>
  setDisableds: Dispatch<SetStateAction<SubmitButtonContextType[]>>
}> = React.memo((props) => {
  const { t } = useT()
  const { id, size, context, setDisableds } = props
  const { disableSubmit, value, setError } = useContext(context)
  const [isRepeat, setIsRepeat] = useState<boolean>(false)
  useEffect(() => {
    setDisableds((disableds) => {
      const codes = disableds
        .filter((item) => item.id !== id)
        .map((item) => item.value.code)
        .filter((item) => item !== '')
      if (codes.includes(value.code)) {
        const target = disableds.filter((item) => item.id !== id).find((item) => item.value.code === value.code)
        if (target) {
          setError((err) => ({ ...err, code: t('error.arrayRepeat', { number: (target.id + 1) }) }))
          setIsRepeat(true)
        }
      } else if (isRepeat) {
        setError((err) => ({ ...err, code: null }))
        setIsRepeat(false)
      }
      return disableds
    })
  }, [value, size])
  useEffect(() => {
    setDisableds((disableds) => {
      return disableds
        .filter((item) => item.id !== id)
        .concat({ id, disabled: disableSubmit, isRepeat, value })
    })
  }, [disableSubmit, value, id, isRepeat])
  return null
})

const defaultImagePayload = {
  onSubmit: () => {},
  onCancel: () => {},
  defaultImageId: 0,
  defaultImagePath: '',
  channel: '',
  open: false
}

const ImageDialogPayloadContext = createContext<[
  ChannelImageDialogPayload,
  Dispatch<SetStateAction<ChannelImageDialogPayload>>
]>([
  defaultImagePayload,
  () => {}
])

const ImageButtonField: React.FC<{
  context: React.Context<FormState<EditChannelFormType>>
}> = React.memo((props) => {
  const { context } = props
  const { value, handleChange } = useContext(context)

  return (
    <ExternalGameChannelImageButton
      name={value.name}
      imageId={value.image.id}
      imagePath={value.image.path}
      onChange={(imageId, imagePath) => { handleChange('image')({ id: imageId, path: imagePath }) }}
      context={ImageDialogPayloadContext}
      size={240}
    />
  )
})

const ExternalGameChannelCreateTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const games = useGDKStore.platform.games()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const game = useMemo(() => findGame(request!.gameId as GameType, games)?.instance ?? null, [games, request])

  const [rows, setRows] = useState<Array<{ id: number } & ChannelFormStateProviderProps>>(createDefaultRows())

  useEffect(() => {
    setRows(createDefaultRows())
  }, [game])

  const tableSetting = useMemo(() => {
    return game?.tableSetting ?? []
  }, [game])

  const columns = useMemo(() => {
    const columns = [{ name: '', width: 'auto' }]
    if (tableSetting.includes('code')) columns.push({ name: `${t('common.gameCode')}*`, width: 'auto' })
    if (tableSetting.includes('name')) columns.push({ name: `${t('common.name')}*`, width: 'auto' })
    if (tableSetting.includes('category')) columns.push({ name: t('common.class'), width: 'auto' })
    if (tableSetting.includes('agGciType')) columns.push({ name: 'GCI GameType', width: 'auto' })
    if (tableSetting.includes('isShow')) columns.push({ name: t('common.showInGameHall'), width: 'auto' })
    if (tableSetting.includes('image')) columns.push({ name: t('common.image'), width: 'auto' })
    columns.push({ name: t('common.function'), width: 'auto' })
    return columns
  }, [t, tableSetting])

  const categoryOptions = useMemo(() => {
    return (game?.channelCategories ?? []).map((el) => ({ name: el.text, value: el.id }))
  }, [game])

  const [handleDebouncedBatchCreate] = useDebouncedCallback(() => {
    setRows((rows) => {
      const defaultForm = initialForm()
      return rows.concat({
        id: rows[rows.length - 1].id + 1,
        context: createContext(createDefaultFormState(defaultForm)),
        defaultValue: defaultForm
      })
    })
  }, 500)

  const handleBatchCreate = useCallback(handleDebouncedBatchCreate, [])

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: allRoute.externalGameChannel.encodePath({ search: { gameId: game?.id, page: 1 }, param: {} })
  })

  if (!game) return null

  return (
    <StateProvider
      context={SubmitButtonContext}
      defaultValue={[]}
    >
      <StateProvider
        context={ImageDialogPayloadContext}
        defaultValue={defaultImagePayload}
      >
        <Box paddingY={5}>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
                <Button
                  className={classes.purpleGradualButton}
                  onClick={handleBatchCreate}
                >
                  {t('common.batchCreate')}
                </Button>
              </Box>
              <Box paddingY={3} overflow="auto">
                <Table size="small">
                  <TableHead className={classes.blackTableHead}>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          className={clsx(classes.tableCellHead, classes.nowrap)}
                          style={{ width: column.width }}
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <ChannelFormStateProvider
                        key={row.id}
                        context={row.context}
                        defaultValue={row.defaultValue}
                      >
                        <TableRow className={classes.greyTableRow}>
                          <TableCell align="center">
                            {row.id + 1}
                          </TableCell>
                          {tableSetting.includes('code') && (
                            <TableCell align="center">
                              <FormField<EditChannelFormType, TextFieldProps>
                                component={TextField}
                                context={row.context}
                                name="code"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                autoFocus
                                label=""
                              />
                            </TableCell>
                          )}
                          {tableSetting.includes('name') && (
                            <TableCell align="center">
                              <FormField<EditChannelFormType, TextFieldProps>
                                component={TextField}
                                context={row.context}
                                name="name"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label=""
                              />
                            </TableCell>
                          )}
                          {tableSetting.includes('category') && (
                            <TableCell align="center">
                              <FormField<EditChannelFormType, DropDownProps>
                                component={DropDown}
                                context={row.context}
                                name="category"
                                options={categoryOptions}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label=""
                              />
                            </TableCell>
                          )}
                          {tableSetting.includes('agGciType') && (
                            <TableCell align="center">
                              <FormField<EditChannelFormType, TextFieldProps>
                                component={TextField}
                                context={row.context}
                                name="agGciType"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label=""
                              />
                            </TableCell>
                          )}
                          {tableSetting.includes('isShow') && (
                            <TableCell align="center">
                              <FormField<EditChannelFormType, OnOffCheckboxProps>
                                component={OnOffCheckbox}
                                context={row.context}
                                name="isShow"
                                label=""
                              />
                            </TableCell>
                          )}
                          {tableSetting.includes('image') && (
                            <TableCell align="center">
                              <ImageButtonField context={row.context} />
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <SubmitButtonContext.Consumer>
                              {([, setDisaleds]) => (
                                <Button
                                  className={classes.pinkGradualOutlineButton}
                                  onClick={() => {
                                    setRows((rows) => rows.filter((item) => item.id !== row.id))
                                    setDisaleds((disableds) => disableds.filter((disabled) => disabled.id !== row.id))
                                  }}
                                  disabled={rows.length === 1}
                                >
                                  {t('common.delete')}
                                </Button>
                              )}
                            </SubmitButtonContext.Consumer>
                          </TableCell>
                        </TableRow>
                        <SubmitButtonContext.Consumer>
                          {([, setDisableds]) => (
                            <FormContextParser
                              id={row.id}
                              size={rows.length}
                              context={row.context}
                              setDisableds={setDisableds}
                            />
                          )}
                        </SubmitButtonContext.Consumer>
                      </ChannelFormStateProvider>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </ScrollablePaper>
          <Box paddingY={3} paddingX={5} display="flex" flexDirection="row" justifyContent="flex-end">
            <Box paddingX={2}>
              <Button
                className={classes.greyButton}
                onClick={handleDebouncedBack}
              >
                {t('common.cancel')}
              </Button>
            </Box>
            <FormGameChangeParser game={game.id} />
            <SubmitButtonContext.Consumer>
              {([payload]) => {
                const values = payload.map((item) => item.value)
                const disabled = payload.some((item) => item.disabled)
                return (
                  <CreateChannelFormSubmitButton
                    onBack={handleBack}
                    disabled={disabled}
                    values={values}
                  />
                )
              }}
            </SubmitButtonContext.Consumer>
          </Box>
          <ExternalGameChannelImageDialog context={ImageDialogPayloadContext} />
        </Box>
      </StateProvider>
    </StateProvider>
  )
}

export default React.memo(ExternalGameChannelCreateTable)
