import React, { createContext, Dispatch, SetStateAction, useMemo, useState, useCallback, useEffect, ChangeEventHandler } from 'react'
import clsx from 'clsx'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { AgentType, LayerStructure, AdminLayerInfo, PaginationRes, LoginStatus } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import Tree, { TreeNode } from '../../default/present/Tree'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import MemoTextWithContext from '../../default/memo/MemoTextWithContext'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import agentTypeName from '../../../constants/default/agentTypeName'
import { getTitleOfNextLayer } from '../../../utils/default/AgentAccountHelper'
import { formatCount, createTableData, ColumnCollection, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useRequestFromSearch, SearchToRequestFunc, useDialogHandleClick, useGetDataByParams, useGetDataByPayload, useReload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import allRoute, { Path } from '../route/route'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { acceptUndefined, parseInt } from '../../../utils/default/FormHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import Switch from '../Switch'

interface RowType {
  id: number
  account: string
  nickname: string
  createdAt: React.ReactElement
  registeredUser: string
  depositedUser: string
  depositCount: string
  depositMoney: React.ReactElement
  percentage: string
  isLoginable: React.ReactElement
  loginStatus: React.ReactElement
  children: string | React.ReactElement
  memo: React.ReactElement
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  isLoginableText: {
    width: theme.custom.shape.memoWidth
  },
  loginStatusString: {
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    backgroundColor: 'grey',
    color: 'white',
    textAlign: 'center'
  },
  stopLoginChildrenTip: {
    color: 'red'
  }
}))

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const LoginStatusText: React.FC<{
  isLoginable: boolean
  loginStatus: LoginStatus
  forbiddenAccounts: string[]
}> = React.memo((props) => {
  const classes = useStyles()
  const { t } = useT()
  const { isLoginable, loginStatus, forbiddenAccounts } = props
  return (
    <Box display="flex" justifyContent="center">
      <span className={classes.isLoginableText} style={{ color: ((!(forbiddenAccounts?.length) && isLoginable) ? 'green' : 'red') }}>{((isLoginable && loginStatus === LoginStatus.NORMAL) ? t('common.normal') : t('common.isNotLoginable'))}</span>
      {(!!(forbiddenAccounts?.length) && isLoginable) && (
        <Tooltip title={t('common.isNotLoginableFrom', { upperLayerAccount: forbiddenAccounts?.join(', ') })} placement="top">
          <div className={classes.loginStatusString}>?</div>
        </Tooltip>
      )}
    </Box>
  )
})

const SwitchConfirmDialog: React.FC<{
  isStart?: boolean
  type: AgentType
  account: string
  allChildrenCount?: number
  handleOnChange: ChangeEventHandler<HTMLInputElement>
}> = React.memo((props) => {
  const { isStart, type, account, allChildrenCount, handleOnChange } = props
  const classes = useCommonStyles()
  const customClasses = useStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()

  return (
    <React.Fragment>
      {isStart ? t('dialog.confirmStartAgentLoginable') : t('dialog.confirmStopAgentLoginable')}
      <Typography className={clsx(classes.purpleWord, dialogClasses.text)} align="center">[{t(agentTypeName[type])}] [{account}]</Typography>
      {(!isStart && type !== AgentType.STAFF) && <Typography className={clsx(customClasses.stopLoginChildrenTip, dialogClasses.text)} align="center">{t('dialog.confirmStopAgentLoginableChildrenTip', { children_count: allChildrenCount })}</Typography>}
      <div>
        {t('common.memo')}
        <input
          type='text'
          onChange={handleOnChange}
        />
      </div>
    </React.Fragment>
  )
})

const LoginableSwitch: React.FC<{
  id: number
  isLoginable: boolean
  type: AgentType
  account: string
  allChildrenCount: number
  reload: () => void
}> = React.memo((props) => {
  const { id, isLoginable, type, account, reload, allChildrenCount } = props
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const writable = useChecker()
  const [loginableMemo, setLoginableMemo] = useState<string>('')

  const dialogId = useMemo(() => `${isLoginable ? 'stop' : 'start'}AgentLoginable-${id}`, [id, isLoginable])
  const handleClick = useDialogHandleClick({
    dialogId,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <SwitchConfirmDialog
          isStart={!isLoginable}
          type={type}
          account={account}
          allChildrenCount={allChildrenCount}
          handleOnChange={useCallback((e) => { setLoginableMemo(e.target.value) }, [setLoginableMemo])}
        />
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: isLoginable ? t('dialog.stopAgentLoginableSuccess') : t('dialog.startAgentLoginableSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (id) => gdk.emperor.updateAgent(id, !isLoginable, loginableMemo),
    gdkFuncDependencies: [gdk, isLoginable, loginableMemo],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  return (
    <Switch
      value={isLoginable}
      activeLabel={t('common.yes')}
      inactiveLabel={t('common.no')}
      onChange={() => {
        handleClick()
        return false
      }}
      disabled={!writable}
    />
  )
})

const searchToRequest: SearchToRequestFunc<{ nodeId?: string, page: number }> = (search) => ({
  ...search,
  page: acceptUndefined(search.page, parseInt) ?? 1
})

const AccountEmperorLayerTable: React.FC = () => {
  const classes = useCommonStyles()
  const theme = useTheme()
  const gdk = useGDK()
  const { t } = useT()

  const layerPageFlow = usePageFlow()
  const [nodes, setNodes] = useState<LayerStructure[]>([])
  const [page, setPage] = useState(1)
  useGetDataByParams({
    path: Path.ACCOUNT_EMPEROR_LAYER,
    gdkFunc: (param: { id: string }) => gdk.emperor.getLayer(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: layerPageFlow.setLoadingStart,
    onSuccess: (res) => {
      setNodes(res)
      layerPageFlow.setContentShow()
    },
    onError: layerPageFlow.setGDKError
  })

  const request = useRequestFromSearch({ searchToRequest })

  const defaultExpanded: string[] = useMemo(() => {
    if (request?.nodeId) {
      let target = nodes.find((node) => `${node.id}` === request.nodeId)
      const seeds = [request.nodeId]
      while (target?.superior_id) {
        const superiorId = target.superior_id
        seeds.push(`${superiorId}`)
        target = nodes.find((node) => node.id === superiorId)
      }
      return seeds
    } else {
      const emperor = nodes.find((node) => node.superior_id === null)
      if (emperor) {
        const children = nodes.filter((node) => node.superior_id === emperor.id)
        return [`${emperor.id}`].concat(children.slice(0, 1).map((child) => `${child.id}`))
      }
    }
    return []
  }, [nodes, request])

  const [nodeId, setNodeId] = useState<string>('0')

  useEffect(() => {
    if (request?.nodeId) {
      setNodeId(request.nodeId)
    } else if (defaultExpanded[1]) {
      setNodeId((nodeId) => {
        if (nodeId === '0') {
          return defaultExpanded[1]
        }
        return nodeId
      })
    }
  }, [request, defaultExpanded])

  const nodePageFlow = usePageFlow(true, false)
  const { reload: infoReload, reloadFlag: infoReloadFlag } = useReload()
  const [info, setInfo] = useState<AdminLayerInfo | null>(null)
  useGetDataByPayload({
    payload: nodeId,
    gdkFunc: (payload) => gdk.emperor.getLayerInfo(Number(payload)),
    gdkFuncDependencies: [gdk, infoReloadFlag],
    onBeforeFetch: nodePageFlow.setLoadingStart,
    onSuccess: (res: AdminLayerInfo) => {
      setInfo(res)
      nodePageFlow.setContentShow()
    },
    onError: nodePageFlow.setGDKError,
    canLoadData: Number(nodeId) !== 0
  })

  const root: TreeNode = useMemo(() => {
    const getNode = (parentId: number | null): TreeNode => {
      const children = nodes.filter((node) => node.superior_id === parentId)
      return {
        positions: children.map((child) => `${child.id}`),
        values: children
          .map((child) => ({
            [`${child.id}`]: {
              label: child.account,
              leaves: getNode(child.id)
            }
          }))
          .reduce((collection, item) => Object.assign({}, collection, item), {})
      }
    }
    return getNode(null)
  }, [nodes])

  const columnData: ColumnCollection<RowType> = useMemo(() => {
    return {
      id: {
        label: '',
        value: 'id'
      },
      account: {
        label: t(agentTypeName[info?.type ?? AgentType.BOSS]),
        value: 'account',
        align: 'center'
      },
      nickname: {
        label: t('common.nickname'),
        value: 'nickname',
        align: 'center'
      },
      createdAt: {
        label: t('common.createdAt'),
        value: 'createdAt',
        align: 'center'
      },
      registeredUser: {
        label: t('common.registeredPlayerCount'),
        value: 'registeredUser',
        align: 'center'
      },
      depositedUser: {
        label: t('common.depositedPlayerCount'),
        value: 'depositedUser',
        align: 'center'
      },
      depositCount: {
        label: t('common.playerDepositCount'),
        value: 'depositCount',
        align: 'center'
      },
      depositMoney: {
        label: t('common.playerDepositMoney'),
        value: 'depositMoney',
        align: 'right'
      },
      isLoginable: {
        label: t('common.isLoginable'),
        value: 'isLoginable',
        align: 'center'
      },
      loginStatus: {
        label: t('common.status'),
        value: 'loginStatus',
        align: 'center'
      },
      percentage: {
        label: t('common.splitPercentage'),
        value: 'percentage',
        align: 'center'
      },
      children: {
        label: t(getTitleOfNextLayer(info?.type ?? AgentType.BOSS)),
        value: 'children',
        align: 'center'
      },
      memo: {
        label: t('common.memo'),
        value: 'memo',
        align: 'center'
      }
    }
  }, [info, t])

  const mainRows: RowType[] = useMemo(() => {
    if (info === null) return []
    return [{
      id: info.id,
      account: info.account,
      nickname: info.nickname,
      createdAt: (<DateTime time={info.created_at} />),
      registeredUser: formatCount(info.registered_user || 0),
      depositedUser: formatCount(info.deposited_user || 0),
      depositCount: formatCount(info.deposit_count || 0),
      depositMoney: (<PointsCell points={info.cash_deposit || 0} />),
      isLoginable: (<LoginableSwitch id={info.id} isLoginable={info.is_loginable} type={info.type} account={info.account} allChildrenCount={info.all_children_count} reload={infoReload} />),
      loginStatus: (<LoginStatusText isLoginable={info.is_loginable} loginStatus={info.login_status} forbiddenAccounts={info.forbidden_accounts} />),
      percentage: `${Number(info.percentage)}%`,
      children: formatCount(info.children_count),
      memo: (
        <MemoTextWithContext
          memoPopoverContext={MemoPopoverContext}
          memo={info.memo}
        />
      )
    }]
  }, [info, infoReload])

  const mainData = useMemo(() => {
    return createTableData(
      columnData,
      [
        'account',
        'nickname',
        'createdAt',
        'registeredUser',
        'depositedUser',
        'depositCount',
        'depositMoney',
        'percentage',
        'children',
        'memo',
        'loginStatus',
        'isLoginable'
      ],
      mainRows,
      'id'
    )
  }, [mainRows, columnData])

  const payload = useMemo(() => {
    return {
      nodeId,
      page: request?.page ?? 1
    }
  }, [request, nodeId])

  const childrenPageFlow = usePageFlow()
  const { reload: childrenReload, reloadFlag: childrenReloadFlag } = useReload()
  const [children, setChildren] = useState<PaginationRes<AdminLayerInfo[]>>(createDefaultPaginationData([]))
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.emperor.getLayerInfoChildren(Number(payload.nodeId), { page }),
    gdkFuncDependencies: [gdk, childrenReloadFlag, infoReloadFlag, page],
    onBeforeFetch: childrenPageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AdminLayerInfo[]>) => {
      setChildren(res)
      childrenPageFlow.setContentShow()
    },
    onError: childrenPageFlow.setGDKError,
    canLoadData: Number(payload.nodeId) !== 0
  })

  const subRows = useMemo(() => {
    return children.data.map((child) => ({
      id: child.id,
      account: child.account,
      nickname: child.nickname,
      createdAt: (<DateTime time={child.created_at} />),
      registeredUser: formatCount(child.registered_user || 0),
      depositedUser: formatCount(child.deposited_user || 0),
      depositCount: formatCount(child.deposit_count || 0),
      depositMoney: (<PointsCell points={child.cash_deposit || 0} />),
      isLoginable: (<LoginableSwitch id={child.id} isLoginable={child.is_loginable} type={child.type} account={child.account} allChildrenCount={child.all_children_count} reload={childrenReload} />),
      loginStatus: (<LoginStatusText isLoginable={child.is_loginable} loginStatus={child.login_status} forbiddenAccounts={child.forbidden_accounts} />),
      percentage: `${Number(child.percentage)}%`,
      children: child.type === AgentType.STAFF
        ? formatCount(child.children_count)
        : (
        <span className={classes.anchor} onClick={() => { setNodeId(child.id.toString()) }}>
          {formatCount(child.children_count)}
        </span>
          ),
      memo: (
        <MemoTextWithContext
          memoPopoverContext={MemoPopoverContext}
          memo={child.memo}
        />
      )
    })) as RowType[]
  }, [classes.anchor, children, childrenReload])

  const subData = useMemo(() => {
    const nextNodeType: AgentType = (info?.type ?? AgentType.EMPEROR) + 1
    return createTableData(
      {
        ...columnData,
        account: {
          label: t(agentTypeName[nextNodeType]),
          value: 'account',
          align: 'center'
        },
        children: {
          label: t(getTitleOfNextLayer(nextNodeType)),
          value: 'children',
          align: 'center'
        }
      },
      [
        'account',
        'nickname',
        'createdAt',
        'registeredUser',
        'depositedUser',
        'depositCount',
        'depositMoney',
        'percentage',
        'children',
        'memo',
        'loginStatus',
        'isLoginable'
      ],
      subRows,
      'id'
    )
  }, [subRows, columnData, info, t])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const handlePagination = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page)
  }, [])

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={6}>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12} md={2}>
            <Box
              height="100%"
              bgcolor={theme.palette.common.black}
              padding={2}
              overflow="auto"
            >
              <LoadingAndErrorFrame { ...layerPageFlow.status }>
                {defaultExpanded.length > 0 && (
                  <Tree
                    color={theme.palette.common.white}
                    root={root}
                    defaultExpanded={defaultExpanded}
                    onLabelClick={(nodeId: string) => { setNodeId(nodeId) }}
                  />
                )}
              </LoadingAndErrorFrame>
            </Box>
          </Grid>
          <MemoPopoverWithContext memoPopoverContext={MemoPopoverContext}>
            <Grid item xs={12} md={10}>
              <Box paddingBottom={6} width="100%">
                <LoadingAndErrorFrame { ...nodePageFlow.status }>
                  <CoreTable
                    classes={tableClasses}
                    data={mainData}
                    total={1}
                  />
                </LoadingAndErrorFrame>
              </Box>
              <LoadingAndErrorFrame
                loading={childrenPageFlow.status.loading}
                error={childrenPageFlow.status.error}
                showContent={childrenPageFlow.status.showContent && subRows.length !== 0 && info?.type !== AgentType.STAFF}
              >
                <CoreTable
                  classes={tableClasses}
                  data={subData}
                  total={children.total}
                  showPagination
                  page={page}
                  onChangePage={handlePagination}
                />
              </LoadingAndErrorFrame>
            </Grid>
          </MemoPopoverWithContext>
        </Grid>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AccountEmperorLayerTable)
