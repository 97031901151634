import React, { useMemo, useState, useCallback, ChangeEvent } from 'react'
import { isEqual, lightFormat } from 'date-fns'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { FrequencyType, CompanyStatisticQuery, PlayerStatistic } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LineChart, { PropTypes as LineChartProps } from '../../default/present/LineChart'
import { getDayArray, getWeekArray, getMonthArray } from '../../../utils/default/TimeHelper'
import NumberInput from '../../default/form/NumberInput'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useDebouncedCallback } from 'use-debounce/lib'

interface PropTypes {
  request: CompanyStatisticQuery
  result: PlayerStatistic
}

interface ChartType {
  date: string
  revenue?: number
  betCash?: number
}

interface FormType {
  interval: string
  showRevenue: boolean
  showBetCash: boolean
}

const AdminReportCompanyStatisticPlayerCash: React.FC<PropTypes> = (props) => {
  const { request, result } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const [form, setForm] = useState<FormType>({
    interval: '500',
    showRevenue: true,
    showBetCash: true
  })
  const data: ChartType[] = useMemo(() => {
    if (!request) return []
    const mappingData = (date: Date, formatDateString: string) => {
      const target = result.overview.find((item) => isEqual(item.date, date))
      const displayTime = lightFormat(date, formatDateString)
      if (target) {
        return { date: displayTime, revenue: Number(target.revenue), betCash: Number(target.bet_cash) }
      }
      return { date: displayTime }
    }
    if (request.period === FrequencyType.DAY) {
      return getDayArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.WEEK) {
      return getWeekArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.MONTH) {
      return getMonthArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'yyyy/MM'))
    }
    return []
  }, [request, result.overview])
  const lines = useMemo(() => {
    const lines = []
    if (form.showRevenue) lines.push({ name: t('common.playerRevenue'), dataKey: 'revenue', stroke: '#6236ff' } as LineChartProps<ChartType>['lines'][0])
    if (form.showBetCash) lines.push({ name: t('common.playerBetCash'), dataKey: 'betCash', stroke: '#fb9678' } as LineChartProps<ChartType>['lines'][0])
    return lines
  }, [form, t])
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.playerOverview')}
          </Typography>
        </Box>
        <Box paddingTop={2} display="flex" alignItems="center" flexDirection="row">
          <Box display="flex" marginRight={2}>
            <NumberInput
              label={t('common.cashInterval')}
              value={form.interval}
              onChange={useDebouncedCallback(useCallback((event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setForm((form) => ({ ...form, interval: value }))
              }, []), 200)[0]}
              helperText={t('helperText.interval')}
              FormHelperTextProps={{ error: true }}
              inputProps={{
                decimalScale: 0,
                fixedDecimalScale: true,
                allowNegative: false,
                maxLength: 6
              }}
            />
          </Box>
          <OnOffCheckbox
            label={t('common.playerRevenue')}
            value={form.showRevenue}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showRevenue: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.playerBetCash')}
            value={form.showBetCash}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showBetCash: value }))
            }, [])}
          />
        </Box>
        <Box paddingY={2}>
          <LineChart<ChartType>
            height={600}
            chartMarginRight={48}
            interval={Number(form.interval)}
            data={data}
            dataKey="date"
            xLabel={t('common.date')}
            yLabel={t('common.cash')}
            lines={lines}
          />
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportCompanyStatisticPlayerCash)
