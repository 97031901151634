import React from 'react'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'

interface PropTypes {
  id: number
  realName: string
  bankAccount: string
  reload: () => void
}

const FinanceBlacklistButtons: React.FC<PropTypes> = (props) => {
  const { id, realName, bankAccount, reload } = props
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const handleDelete = useDialogHandleClick({
    dialogId: `deleteBlacklist-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmDeleteDebitCard')}
          <span className={classes.purpleWord}>{realName} {bankAccount}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteDebitCardSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: id,
    gdkFunc: (payload) => gdk.finance.deleteBlacklist(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  return (
    <Button
      onClick={handleDelete}
      size="small"
      className={classes.pinkGradualOutlineButton}
    >
      {t('common.delete')}
    </Button>
  )
}

export default FinanceBlacklistButtons
