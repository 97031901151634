import { DependencyList, useEffect } from 'react'

export const useAsyncEffect = (
  func: (mounted: boolean) => Promise<unknown>,
  deps: DependencyList
) => {
  useEffect(() => {
    let mounted = true
    void func(mounted)
    return () => {
      mounted = false
    }
  }, deps)
}
