import React, { PropsWithChildren } from 'react'
import {
  useForm,
  ValueGetter,
  FormValidation,
  ChangedFormGetter,
  FormState,
  DisableFieldGetter
} from '../../../utils/default/FormHook'
import { FormPropType } from '../../../utils/default/FormHelper'

interface PropTypes<FormType> extends FormPropType<FormType> {
  context: React.Context<FormState<FormType>>
  getValueFromEvent: ValueGetter<FormType>
  validation: FormValidation<FormType>
  getChangedForm?: ChangedFormGetter<FormType>
  disableField?: DisableFieldGetter<FormType>
  beforeSubmit?: (form: FormType) => void
}

const FormStateProvider = function<FormType extends {}> (props: PropsWithChildren<PropTypes<FormType>>) {
  const {
    context: Context,
    defaultValue,
    onSubmit,
    beforeSubmit,
    getValueFromEvent,
    validation,
    getChangedForm,
    disableField,
    children
  } = props

  const formState = useForm<FormType>({
    defaultValue,
    onSubmit,
    beforeSubmit,
    getValueFromEvent,
    formValidation:
    validation,
    getChangedForm,
    disableField
  })

  const { handleSubmit } = formState

  return (
    <Context.Provider value={formState}>
      <form onSubmit={handleSubmit}>
        {children}
      </form>
    </Context.Provider>
  )
}

export default FormStateProvider
