import { useState, useCallback } from 'react'
import { GDKError } from '@golden/gdk-admin'
import { LoadingAndErrorPayload } from '../../components/default/frames/LoadingAndErrorFrame'

export const usePageFlow = (defaultLoading: boolean = false, defaultShowContent: boolean = true) => {
  const [status, setStatus] = useState<LoadingAndErrorPayload>({
    error: null,
    loading: defaultLoading,
    showContent: defaultShowContent
  })
  const setLoadingStart = useCallback(() => { setStatus({ error: null, loading: true, showContent: false }) }, [])
  const setContentShow = useCallback(() => { setStatus({ error: null, loading: false, showContent: true }) }, [])
  const setError = useCallback((error: string) => { setStatus({ error, loading: false, showContent: false }) }, [])
  const setGDKError = useCallback((error: GDKError) => { setStatus({ error: error.message, loading: false, showContent: false }) }, [])
  return {
    status,
    setLoadingStart,
    setContentShow,
    setError,
    setGDKError,
    setStatus
  }
}

export type PageFlowType = ReturnType<typeof usePageFlow>
