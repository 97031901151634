import React, { createContext, useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import CoreDialog from '../../../components/default/dialogs/CoreDialog'
import {
  GloablDialogConfig,
  OpenPayload,
  GlobalDialogType,
  createGlobalDialogConfig
} from '../../../utils/default/DialogHelper'
import { useDialogStyles } from '../../../utils/admin/StyleHook'

export const GlobalDialogContext = createContext<GlobalDialogType>({
  open: { id: '', value: false, isOK: false },
  setOpen: () => {},
  setConfig: () => {},
  clearState: () => {}
})

const GlobalDialogProvider: React.FC = (props) => {
  const { children } = props
  const classes = useDialogStyles()
  const [open, setOpen] = useState<OpenPayload>({ id: '', value: false, isOK: false })
  const [config, setConfig] = useState<GloablDialogConfig>(createGlobalDialogConfig())

  const handleOK = () => {
    setOpen((open) => {
      return { ...open, value: false, isOK: true }
    })
  }

  const [handleCancel] = useDebouncedCallback(
    () => { setOpen((open) => ({ ...open, value: false, isOK: false })) },
    200
  )

  const clearState = useCallback(() => {
    setOpen({ id: '', value: false, isOK: false })
  }, [])

  return (
    <React.Fragment>
      <GlobalDialogContext.Provider
        value={{
          open,
          setOpen,
          setConfig,
          clearState
        }}
      >
        {children}
      </GlobalDialogContext.Provider>
      <CoreDialog
        maxWidth={config.maxWidth}
        notUseTypo={config.notUseTypo}
        showOK={config.showOK}
        okText={config.okText}
        showCancel={config.showCancel}
        cancelText={config.cancelText}
        showIcon={config.showIcon}
        open={open.value}
        variant={config.variant}
        message={config.message}
        onOK={handleOK}
        onCancel={handleCancel}
        okButtonClasses={{
          root: classes.okButton
        }}
        cancelButtonClasses={{
          root: classes.cancelButton
        }}
      />
    </React.Fragment>
  )
}

export default GlobalDialogProvider
