import { Box, Button, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { memo, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useT from '../../../i18ns/admin/useT'
import Dialog from '@material-ui/core/Dialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { BarrierStepByStepRes, GameType, WithdrawPointTarget, barrierStepByStepSnapshotDefaultValue } from '@golden/gdk-admin'
import { useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest } from './EffectiveCashHistoryForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import withdrawPointTargetName from '../../../constants/admin/withdrawPointTargetName'
import { PropTypes as CalculateEffectiveCashProps, sortBuckets, useStyles as CalculateEffectiveCashStyles } from '../../../components/admin/manual/EffectiveCashSummaryTable'
import { getGameName } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import clsx from 'clsx'
import { formatDate, formatTime } from '../../../utils/default/TableHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import PointCell from '../../default/present/PointCell'
import NestedCell from '../../default/present/NestedCell'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  BoxPadding: {
    'padding-left': '27px'
  },
  baseColor: {
    color: '#000'
  },
  baseBackground: {
    background: '#f2f2f2'
  },
  buttonColor: {
    color: '#169BD5'
  },
  spacePreLine: {
    'white-space': 'pre-line'
  },
  ul: {
    paddingLeft: '20px',
    marginTop: '5px',
    marginBottom: '0'
  },
  tableCell: {
    position: 'relative'
  },
  guideLine: {
    position: 'absolute',
    top: '150px',
    left: '-25px',
    width: '10px',
    border: '2px solid #333',
    borderRight: '0',

    '&:after': {
      content: '""' as string,
      position: 'absolute',
      right: '-10px',
      bottom: '-5px',
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '10px solid #333',
      transform: 'rotate(90deg)'
    }
  },
  dialogNoteText: {
    color: theme.palette.text.primary
  },
  dialogNoteBox: {
    paddingTop: '8px'
  },
  dialogHeaderButton: {
    position: 'absolute',
    right: '12px',
    top: '8px'
  }
}))

const OriginEffectiveCashTable: React.FC<{
  barrierStepByStep: BarrierStepByStepRes | undefined
  mapName: (name: string, term: string, color?: string) => JSX.Element
}> = memo((props) => {
  const { barrierStepByStep, mapName } = props
  const classes = CalculateEffectiveCashStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const games = useGDKStore.platform.games()
  const tablePayloads: CalculateEffectiveCashProps['tablePayloads'] = useMemo(() => {
    if (barrierStepByStep?.snapshot.length) {
      const isIncludesGeneral = barrierStepByStep.snapshot.some(item => item.target === WithdrawPointTarget.GENERAL)
      if (!isIncludesGeneral) {
        barrierStepByStep.snapshot.push(barrierStepByStepSnapshotDefaultValue[WithdrawPointTarget.GENERAL])
      }
    }
    return [
      {
        name: t('common.type'),
        width: 80,
        isNested: true,
        value: (<NestedCell computedLine info={[
          mapName(t('common.delay'), 'delayed_withdraw_point', 'rgb(255, 95, 95)'),
          mapName(`+ ${t('common.hasReach')}`, 'withdraw_point', 'rgb(255, 95, 95)'),
          mapName(`- ${t('common.stillRequire')}`, 'barrier_sum', 'rgb(255, 95, 95)'),
          t('common.difference')]}
        />)
      },
      ...sortBuckets(barrierStepByStep?.snapshot ?? []).map(bucket => ({
        name: bucket.target === WithdrawPointTarget.GAME && bucket.target_id ? mapName(getGameName(bucket.target_id, games), `game_${bucket.target_id}`) : mapName(t(withdrawPointTargetName[bucket.target]), bucket.target),
        isNested: true,
        value: (
          <NestedCell computedLine info={[
            <PointCell point={bucket.delayed_withdraw_point} />,
            <PointCell point={bucket.withdraw_point} />,
            <PointCell point={bucket.barrier_sum} />,
            <PointCell point={bucket.gap} isColored />
          ]}/>
        )
      }))
    ]
  }, [barrierStepByStep, games, t, mapName])

  if (tablePayloads.length === 0) {
    return (
      <Box display="flex" justifyContent="center" padding={1} overflow="auto">
        <Typography variant="h3">
          {t('common.noData')}
        </Typography>
      </Box>
    )
  }

  return (
    <Table size='medium' className={`${classes.fourBorder} ${classes.tableBackground}`}>
      <TableHead className={clsx(classes.head, commonClasses.pinkTableHead, commonClasses.bold)}>
        <TableRow className={commonClasses.tableRow}>
          {tablePayloads.map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellHead, commonClasses.tableCellHead) }}
                className={clsx(classes.fourPadding, classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnName`}
                style={{ minWidth: el.width ?? 'auto' }}
              >
                {el.name}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          className={commonClasses.tableRow}
        >
          {tablePayloads.map(el => el.value).map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellBody, commonClasses.pre) }}
                className={clsx((tablePayloads[index].isNested ? classes.noPadding : classes.fourPadding), classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnContent`}
                style={{ minWidth: tablePayloads[index].width ?? 'auto' }}
              >
                {el}
              </TableCell>
            )
          })}
        </TableRow>
      </TableBody>
    </Table>
  )
})

const DelayEffectiveCashTable: React.FC<{
  barrierStepByStep: BarrierStepByStepRes | undefined
  mapName: (name: string, term: string, color?: string) => JSX.Element
  isShowTerm: boolean
  currentTableCellRef: MutableRefObject<HTMLElement | null>
}> = memo((props) => {
  const { barrierStepByStep, mapName, currentTableCellRef, isShowTerm } = props
  const { t } = useT()
  const classes = CalculateEffectiveCashStyles()
  const commonClasses = useCommonStyles()
  const games = useGDKStore.platform.games()
  const style = useStyles()

  const delayTableCellRef = useRef<HTMLElement | null>(null)
  const [guideHeight, setGuideHeight] = useState(0)

  const tablePayloads: CalculateEffectiveCashProps['tablePayloads'] = useMemo(() => {
    return [
      {
        name: t('common.type'),
        width: 80,
        isNested: true,
        value: (<NestedCell computedLine info={[
          mapName(t('dialog.catchAgainReached'), 'previous_withdraw_point', 'rgb(255, 95, 95)'),
          mapName(`- ${t('dialog.decreaseRequire')}`, 'decrease', 'rgb(255, 95, 95)'),
          mapName(`${t('common.delay')}`, 'delayed_withdraw_point', 'rgb(255, 95, 95)')]}
        />)
      },
      ...sortBuckets(barrierStepByStep?.snapshot ?? []).map(bucket => {
        const withdrawPoint = barrierStepByStep?.previous_withdraw_points.find(item => {
          if (item.target !== WithdrawPointTarget.GAME) {
            return bucket.target === item.target
          } else {
            return bucket.target_id === item.target_id
          }
        })

        const delayWithdrawPoint = barrierStepByStep?.delayed_withdraw_points.find(item => {
          if (item.target !== WithdrawPointTarget.GAME) {
            return bucket.target === item.target
          } else {
            return bucket.target_id === item.target_id
          }
        })
        const isHighlighted = bucket.is_removed
        return {
          name: bucket.target === WithdrawPointTarget.GAME && bucket.target_id ? mapName(getGameName(bucket.target_id, games), `game_${bucket.target_id}`) : mapName(t(withdrawPointTargetName[bucket.target]), bucket.target),
          isNested: true,
          value: (
            <NestedCell computedLine info={[
              <PointCell point={withdrawPoint?.withdraw_point ?? ''} />,
              <PointCell point={bucket.decrease} isHighlighted={isHighlighted} />,
              <PointCell point={delayWithdrawPoint?.delayed_withdraw_point ?? ''} isColored />
            ]}/>
          )
        }
      })
    ]
  }, [barrierStepByStep, games, t, mapName])

  useEffect(() => {
    setGuideHeight(() => {
      if (delayTableCellRef.current && currentTableCellRef.current) {
        return currentTableCellRef.current.getBoundingClientRect().top - delayTableCellRef.current.getBoundingClientRect().bottom + 60
      } else {
        return 0
      }
    })
  }, [isShowTerm])

  return (
    <Table size='medium' className={`${classes.fourBorder} ${classes.tableBackground}`}>
      <TableHead className={clsx(classes.head, commonClasses.pinkTableHead, commonClasses.bold)}>
        <TableRow className={commonClasses.tableRow}>
          {tablePayloads.map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellHead, commonClasses.tableCellHead) }}
                className={clsx(classes.fourPadding, classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnName`}
                style={{ minWidth: el.width ?? 'auto' }}
              >
                {el.name}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          className={commonClasses.tableRow}
        >
          {tablePayloads.map(el => el.value).map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellBody, commonClasses.pre) }}
                className={clsx((tablePayloads[index].isNested ? classes.noPadding : classes.fourPadding), classes.fourBorder, style.tableCell)}
                align='center'
                key={`the${index}-rdColumnContent`}
                style={{ minWidth: tablePayloads[index].width ?? 'auto' }}
                ref={delayTableCellRef}
              >
                {el}
                {index === 0 && <div className={style.guideLine} style={{ height: `${guideHeight}px` }} />}
              </TableCell>
            )
          })}
        </TableRow>
      </TableBody>
    </Table>
  )
})

const CurrentEffectiveCashTable: React.FC<{
  barrierStepByStep: BarrierStepByStepRes | undefined
  mapName: (name: string, term: string, color?: string) => JSX.Element
  currentTableCellRef: MutableRefObject<HTMLElement | null>
}> = memo((props) => {
  const { barrierStepByStep, mapName, currentTableCellRef } = props
  const { t } = useT()
  const classes = CalculateEffectiveCashStyles()
  const commonClasses = useCommonStyles()
  const games = useGDKStore.platform.games()

  const tablePayloads: CalculateEffectiveCashProps['tablePayloads'] = useMemo(() => {
    return [
      {
        name: t('common.type'),
        width: 80,
        isNested: true,
        value: (<NestedCell computedLine info={[
          mapName(t('common.delay'), 'delayed_withdraw_point', 'rgb(255, 95, 95)'),
          mapName(`+ ${t('common.hasReach')}`, 'withdraw_point', 'rgb(255, 95, 95)'),
          mapName(`- ${t('common.stillRequire')}`, 'barrier_sum', 'rgb(255, 95, 95)'),
          t('common.difference')]}
        />)
      },
      ...sortBuckets(barrierStepByStep?.current ?? []).map(bucket => {
        return {
          name: bucket.target === WithdrawPointTarget.GAME && bucket.target_id ? mapName(getGameName(bucket.target_id, games), `game_${bucket.target_id}`) : mapName(t(withdrawPointTargetName[bucket.target]), bucket.target),
          isNested: true,
          value: (
            <NestedCell computedLine info={[
              <PointCell point={bucket.delayed_withdraw_point} />,
              <PointCell point={bucket.withdraw_point} />,
              <PointCell point={bucket.barrier_sum} />,
              <PointCell point={bucket.gap} isColored />
            ]}/>
          )
        }
      })
    ]
  }, [barrierStepByStep, games, t, mapName])

  return (
    <Table size='medium' className={`${classes.fourBorder} ${classes.tableBackground}`}>
      <TableHead className={clsx(classes.head, commonClasses.pinkTableHead, commonClasses.bold)}>
        <TableRow className={commonClasses.tableRow}>
          {tablePayloads.map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellHead, commonClasses.tableCellHead) }}
                className={clsx(classes.fourPadding, classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnName`}
                style={{ minWidth: el.width ?? 'auto' }}
              >
                {el.name}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          className={commonClasses.tableRow}
        >
          {tablePayloads.map(el => el.value).map((el, index) => {
            return (
              <TableCell
                classes={{ head: clsx(classes.cellBody, commonClasses.pre) }}
                className={clsx((tablePayloads[index].isNested ? classes.noPadding : classes.fourPadding), classes.fourBorder)}
                align='center'
                key={`the${index}-rdColumnContent`}
                style={{ minWidth: tablePayloads[index].width ?? 'auto' }}
                ref={currentTableCellRef}
              >
                {el}
              </TableCell>
            )
          })}
        </TableRow>
      </TableBody>
    </Table>
  )
})

const CalculateEffectiveCashDialog: React.FC<{ open: boolean, handleOpen: (value: boolean) => void, barrierStepByStep: BarrierStepByStepRes | undefined }> = memo((props) => {
  const { open, handleOpen, barrierStepByStep } = props

  const { t } = useT()
  const commonClasses = useCommonStyles()
  const style = useStyles()
  const games = useGDKStore.platform.games()
  const currentTableCellRef = useRef<HTMLElement | null>(null)

  const [isShowTerm, setIsShowTerm] = useState(false)
  const mapName = useMemo(() => (name: string, term: string, termColor = '#fff', nameClassName = '') => (
    <Box>
      <Typography className={nameClassName}>{name}</Typography>
      {isShowTerm ? <Box color={termColor} fontSize={12}>{term}</Box> : ''}
    </Box>
  ), [isShowTerm])
  const generalPreviewWithdrawPoint = useMemo(() => barrierStepByStep?.original_previous_withdraw_points.find(item => item.target === WithdrawPointTarget.GENERAL), [barrierStepByStep?.original_previous_withdraw_points])
  const gamesPreviewWithdrawPoint = useMemo(() => sortBuckets(barrierStepByStep?.original_previous_withdraw_points.filter(item => item.target !== WithdrawPointTarget.GENERAL) ?? []), [barrierStepByStep?.original_previous_withdraw_points])
  const previewWithdrawPointIsExist = useMemo(() => (Array.isArray(generalPreviewWithdrawPoint) && generalPreviewWithdrawPoint.length > 0) || (Array.isArray(gamesPreviewWithdrawPoint) && gamesPreviewWithdrawPoint.length > 0), [generalPreviewWithdrawPoint, gamesPreviewWithdrawPoint])
  const generalWithdrawPoint = useMemo(() => barrierStepByStep?.original_withdraw_points.find(item => item.target === WithdrawPointTarget.GENERAL), [barrierStepByStep?.original_withdraw_points])
  const gamesWithdrawPoint = useMemo(() => barrierStepByStep?.original_withdraw_points.filter(item => item.target === WithdrawPointTarget.GAME), [barrierStepByStep?.original_withdraw_points])

  const notes = useMemo(() => ([
    ...(barrierStepByStep?.snapshot.some(item => item.is_removed) ? [t('dialog.EffectiveCashNote.1')] : []),
    t('dialog.EffectiveCashNote.2'),
    t('dialog.EffectiveCashNote.3'),
    t('dialog.EffectiveCashNote.4')
  ]), [barrierStepByStep?.snapshot, t])

  return (
    <Dialog open={open} fullWidth maxWidth='lg'>
      <Box
        padding={3}
        className={commonClasses.dialogPinkHeader}
      >
        {t('common.calculateEffectiveCash')}
        <Button
          onClick={() => { setIsShowTerm(prev => !prev) }}
          className={`${commonClasses.blueGradualOutlineButton} ${style.dialogHeaderButton}`}
        >
          {t('dialog.technicalTerm')}
        </Button>
      </Box>
      <Box margin={3} padding={3} className={clsx(style.baseBackground, style.baseColor)}>
        <Typography variant="h4" gutterBottom className={commonClasses.bold}>
          1. {t('dialog.revertEffectiveCash')}
        </Typography>
        <Grid container alignItems='flex-start' justifyContent='space-between' spacing={2} className={style.BoxPadding}>
          <Grid xs={4} item>
            <Typography variant="h5" gutterBottom className={commonClasses.bold}>
              { barrierStepByStep?.previous_reset_at ? t('dialog.originalRecord') : t('common.noDataInThisRange')}
            </Typography>
          </Grid>
          <Grid xs={8} item>
            {barrierStepByStep?.previous_reset_at
              ? (
              <>
                <OriginEffectiveCashTable barrierStepByStep={barrierStepByStep} mapName={mapName} />
                <Box className={style.dialogNoteBox}>
                  <Typography className={style.dialogNoteText}>
                    {t('dialog.outerLayerDifference')}
                  </Typography>
                </Box>
              </>
                )
              : null }
          </Grid>
          <Grid xs={4} item>
            <Typography variant='h5' gutterBottom className={commonClasses.bold}>
            {t('dialog.searchEffectiveCash')}
            </Typography>
            <Box className={style.spacePreLine}>
              {(barrierStepByStep?.previous_reset_at && barrierStepByStep?.last_reset_at)
                ? `${t('dialog.settledBeforeReturnBet', {
                  previous: `${formatDate(barrierStepByStep.previous_reset_at)} ${formatTime(barrierStepByStep.previous_reset_at)}`,
                  last: `${formatDate(barrierStepByStep.last_reset_at)} ${formatTime(barrierStepByStep.last_reset_at)}`
                })}`
                : ''}
            </Box>
            <Box marginTop={2}>
              {mapName(t('dialog.catchAgainEffectiveCash'), 'original_previous_withdraw_point', 'rgb(255, 95, 95)', commonClasses.bold)}
              {<ul className={style.ul}>
                {!previewWithdrawPointIsExist && <li>{t('common.none')}</li>}
                {generalPreviewWithdrawPoint
                  ? (
                  <li>{`${t('dialog.notMatchActivityRule')}：${generalPreviewWithdrawPoint.original_previous_withdraw_point}`}</li>
                    )
                  : null
                }
                {
                  gamesPreviewWithdrawPoint
                    ? gamesPreviewWithdrawPoint.map(game => (
                      <li key={game.target_id}>{`${getGameName(game.target_id as GameType, games)}：${game.original_previous_withdraw_point}`}</li>
                    ))
                    : null
                }
              </ul>}
            </Box>
          </Grid>
          <Grid xs={8} item>
            <DelayEffectiveCashTable
              barrierStepByStep={barrierStepByStep}
              mapName={mapName}
              isShowTerm={isShowTerm}
              currentTableCellRef={currentTableCellRef}
            />
            <Box className={style.dialogNoteBox}>
              {notes.map(note => <Typography className={style.dialogNoteText}>{note}</Typography>)}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box margin={3} padding={3} marginTop={0} marginBottom={1} className={clsx(style.baseBackground, style.baseColor)}>
        <Typography variant="h4" gutterBottom className={commonClasses.bold}>
          2. {t('dialog.currentInterval')}
        </Typography>
        <Grid container alignItems='flex-start' justifyContent='space-between' spacing={2} className={style.BoxPadding}>
          <Grid xs={4} item>
            <Typography variant="h5" gutterBottom className={commonClasses.bold}>
              {t('dialog.enterDelayEffectiveCash')}
            </Typography>
            <Box className={style.spacePreLine}>
              {t('dialog.delayEffectiveCashInfo')}
            </Box>
            <Box marginTop={2}>
              {mapName(t('dialog.hasReachedEffectiveCash'), 'original_withdraw_point', 'rgb(255, 95, 95)', commonClasses.bold)}
              <ul className={style.ul}>
                {generalWithdrawPoint && <li>{`${t('dialog.notMatchActivityRule')}：${generalWithdrawPoint.original_withdraw_point}`}</li> }
                {gamesWithdrawPoint?.length ? gamesWithdrawPoint.map(item => (<li key={item.target_id}>{`${getGameName(item.target_id as GameType, games)}：${item.original_withdraw_point}`}</li>)) : <li>{t('common.none')}</li>}
              </ul>
            </Box>
          </Grid>
          <Grid xs={8} item>
            <CurrentEffectiveCashTable
              barrierStepByStep={barrierStepByStep}
              mapName={mapName}
              currentTableCellRef={currentTableCellRef}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display='flex' justifyContent='center' marginBottom={1}>
        <Button
          onClick={() => { handleOpen(false) }}
          className={commonClasses.purpleGradualButton}
        >
          {t('common.close')}
        </Button>
      </Box>
    </Dialog>
  )
})

const CalculateEffectiveCashButton = memo(() => {
  const { t } = useT()
  const style = useStyles()
  const gdk = useGDK()
  const account = useRequestFromSearch({ searchToRequest })?.account ?? ''

  const [open, setOpen] = useState(false)
  const [barrierStepByStep, setBarrierStepByStep] = useState<BarrierStepByStepRes>()
  const handleOpen = useCallback((value: boolean) => { setOpen(value) }, [])
  const handleClick = useCallback(() => {
    gdk.withdraw.getWithdrawBarrierStepByStep({ account }).subscribe(res => {
      setBarrierStepByStep(res)
      handleOpen(true)
    })
  }, [gdk.withdraw, account, handleOpen])

  return (
    <>
      <Button
        className={style.buttonColor}
        onClick={handleClick}
      >
        {t('common.calculateEffectiveCash')}
      </Button>
      <CalculateEffectiveCashDialog open={open} handleOpen={handleOpen} barrierStepByStep={barrierStepByStep} />
    </>
  )
})

export default CalculateEffectiveCashButton
