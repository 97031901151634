import { Box, Button, Dialog, DialogActions, DialogContent, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import React, { memo, useCallback, useState } from 'react'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import { useGetData } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useDebouncedCallback } from 'use-debounce'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { GDKError, FinanceDepositMethodPriorities } from '@golden/gdk-admin'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  item: {
    border: '1px solid #e9e9e9',
    '&:not(:first-child)': {
      marginTop: '-1px'
    }
  }
}))

const Item: React.FC<{ id: number, index: number, method: string }> = memo((props) => {
  const { id, index, method } = props
  const classes = useStyles()

  return (
    <Draggable draggableId={`draggable-${id}`} index={index}>
      {
        (provided) => {
          const { innerRef, draggableProps, dragHandleProps } = provided

          return (
            <ListItem
              divider
              ref={innerRef}
              className={classes.item}
              {...draggableProps}
              {...dragHandleProps}
            >
              <ListItemIcon>
                <DragHandleIcon />
              </ListItemIcon>
              <ListItemText primary={method} />
            </ListItem>
          )
        }
      }
    </Draggable>
  )
})

interface PropTypes {
  open: boolean
  close: () => void
  reload: () => void
}

const FinanceDepositSortDialog: React.FC<PropTypes> = memo((props) => {
  const { open, close, reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const globalDialog = useGlobalDialog()

  const [data, setData] = useState<FinanceDepositMethodPriorities[]>([])

  useGetData({
    gdkFunc: () => gdk.finance.getDepositMethodPriorities(),
    gdkFuncDependencies: [gdk, open],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setData(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: open
  })

  const handleOnDragEnd = (dropResult: DropResult) => {
    const { source, destination } = dropResult

    if (!destination) return

    setData(data => {
      const [removeItem] = data.splice(source.index, 1)
      data.splice(destination.index, 0, removeItem)
      const len = data.length
      return data.map((val, index) => ({
        ...val,
        priority: len - index
      }))
    })
  }

  const [handleSetting] = useDebouncedCallback(useCallback(() => {
    gdk.finance.updateDepositMethodPriorities(data.map(({ id, priority }) => ({ id, priority }))).subscribe({
      error (error: GDKError) {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          showIcon: true,
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'sortPriorityError',
          value: false,
          isOK: false
        })
      },
      complete () {
        reload()
        close()
      }
    })
  }, [gdk.finance, data, globalDialog, reload, close]), 200)

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.depositSort')}
      </Box>
      <DialogContent>
        <Box padding={3}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='drop-id'>
              {
                (provided) => {
                  const { droppableProps, innerRef, placeholder } = provided

                  return <List { ...droppableProps } ref={innerRef}>
                    {
                      data.map((item, index) => (
                        <Item
                          id={item.id}
                          index={index}
                          key={item.id}
                          method={item.name}
                        />)
                      )
                    }
                    {placeholder}
                  </List>
                }
              }
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item>
            <Button
              className={commonClasses.greyButton}
              onClick={close}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={commonClasses.purpleGradualButton}
              onClick={handleSetting}
            >
              {t('common.saveSetting')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
})

export default FinanceDepositSortDialog
