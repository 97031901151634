import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import allRoute from '../route/route'

interface PropTypes {
  id: number
  isActive: boolean
  account: string
  name: string
  reload: () => void
}

const FinancePiggyAlipayButtons: React.FC<PropTypes> = (props) => {
  const { id, isActive, account, name, reload } = props
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const handleStart = useDialogHandleClick({
    dialogId: `startPiggyAlipay-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStartPiggyAlipay')}
          <span className={classes.purpleWord}>{account} {name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.startPiggyAlipaySuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: id,
    gdkFunc: (payload) => gdk.finance.togglePiggyAlipay(payload, { is_active: true }),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() }
  })
  const handleStop = useDialogHandleClick({
    dialogId: `stopPiggyAlipay-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmStopPiggyAlipay')}
          <span className={classes.purpleWord}>{account} {name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.stopPiggyAlipaySuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: id,
    gdkFunc: (payload) => gdk.finance.togglePiggyAlipay(payload, { is_active: false }),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() }
  })
  /* const handleDelete = useDialogHandleClick({
    dialogId: `deletePiggyAlipay-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmDeleteAlipay')}
          <span className={classes.purpleWord}>{account} {name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.deleteAlipaySuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    payload: id,
    gdkFunc: (payload) => gdk.finance.deletePiggyAlipay(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => reload()
  }) */
  return (
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Grid item>
        <Button
          component={Link}
          to={allRoute.financePiggyAlipayUpdate.encodePath({ param: { id }, search: {} })}
          className={classes.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      </Grid>
      {isActive
        ? (
        <Grid item>
          <Button
            className={classes.greyOutlineButton}
            onClick={handleStop}
          >
            {t('common.toggleOff')}
          </Button>
        </Grid>
          )
        : (
        <Grid item>
          <Button
            className={classes.greenGradualOutlineButton}
            onClick={handleStart}
          >
            {t('common.toggleOn')}
          </Button>
        </Grid>
          )}
      {/*
      <Grid item>
        <Button
          className={classes.pinkGradualOutlineButton}
          onClick={handleDelete}
        >
          {t('common.delete')}
        </Button>
      </Grid>
      */}
    </Grid>
  )
}

export default React.memo(FinancePiggyAlipayButtons)
