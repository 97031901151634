import {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  FormEvent,
  Dispatch,
  SetStateAction
} from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { useDebouncedCallback } from 'use-debounce'
import { FormError } from './FormHelper'
import { ValidateFunc, ValidationTimePoint } from './Validator'

const handleValidations = <FormType, Value>(
  validations: Array<{
    func: ValidateFunc<FormType>
    when: Array<ValidationTimePoint<FormType>>
  }>,
  value: Value,
  form: FormType,
  lastSubmitForm: FormType,
  setError: Dispatch<SetStateAction<FormError<FormType>>>
) => {
  validations.some((validation) => {
    const { stop } = validation.func(value, form, lastSubmitForm, null)
    setError((error) => {
      const { newError } = validation.func(value, form, lastSubmitForm, error)
      return { ...error, ...newError }
    })
    return stop
  })
}

export const useInternValue = <Value>(
  defaultValue: Value,
  value?: Value
): [Value, Dispatch<SetStateAction<Value>>] => {
  const [internValue, setInternValue] = useState<Value>(defaultValue)
  useEffect(() => {
    if (value !== undefined) {
      setInternValue(value)
    }
  }, [value])
  return [internValue, setInternValue]
}

export type ValueGetter<FormType> = {
  [key in keyof FormType]: (event: unknown) => FormType[key]
}

export interface ColumnValidation<FormType> {
  func: ValidateFunc<FormType>
  when: Array<ValidationTimePoint<FormType>>
}

export type FormValidation<FormType> = {
  [key in keyof FormType]: Array<ColumnValidation<FormType>>
}

export type ChangedFormGetter<FormType> = Partial<{
  [key in keyof FormType]: (value: FormType[key], form: FormType) => FormType
}>

export type DisableFieldGetter<FormType> = Partial<{
  [key in keyof FormType]: (value: FormType[key], form: FormType) => boolean
}>

export interface SetAction<FormType> {
  type: 'set'
  value: FormType
}

export interface ChangeAction<FormType, Value> {
  type: 'change'
  label: keyof FormType
  value: Value
}
export interface ChangeOtherAction<FormType, Value> {
  type: `change:${keyof FormType & string}`
  label: keyof FormType
  value: Value
}

export interface OtherEventAction<FormType> {
  type: 'blur' | 'focus'
  label: keyof FormType
}

export interface SubmitAction {
  type: 'submit'
}

export type DispatchFormValue<FormType> = Dispatch<SetAction<FormType> | ChangeAction<FormType, any> | OtherEventAction<FormType> | SubmitAction>

export interface FormState<FormType> {
  value: FormType
  dispatch: DispatchFormValue<FormType>
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  handleChange: (label: keyof FormType) => (event: unknown) => void
  handleOther: (type: 'blur' | 'focus', label: keyof FormType) => () => void
  error: FormError<FormType>
  setError: Dispatch<SetStateAction<FormError<FormType>>>
  disableSubmit: boolean
  disabled: { [key in keyof FormType]: boolean }
  lastSubmitForm: FormType
}

export const createDefaultFormState = <FormType>(defaultValue: FormType): FormState<FormType> => ({
  value: defaultValue,
  dispatch: () => {},
  handleSubmit: (form) => form,
  handleChange: () => () => {},
  handleOther: () => () => {},
  error: {} as FormError<FormType>,
  setError: () => {},
  disableSubmit: true,
  disabled: {} as { [key in keyof FormType]: boolean },
  lastSubmitForm: defaultValue
})

export const useForm = <FormType extends {}>(options: {
  defaultValue: FormType
  onSubmit: (form: FormType) => FormType
  beforeSubmit?: (form: FormType) => void
  getValueFromEvent: ValueGetter<FormType>
  formValidation: FormValidation<FormType>
  getChangedForm?: ChangedFormGetter<FormType>
  disableField?: DisableFieldGetter<FormType>
}): FormState<FormType> => {
  const {
    defaultValue,
    onSubmit,
    beforeSubmit,
    getValueFromEvent,
    formValidation,
    getChangedForm,
    disableField
  } = options

  const initError = useMemo(() => {
    return Object.keys(defaultValue)
      .map((key) => ({ [key]: null }))
      .reduce((object, entry) => Object.assign({}, object, entry), {}) as FormError<FormType>
  }, [defaultValue])

  const [error, setError] = useState<FormError<FormType>>(initError)
  const [lastSubmitForm, setLastSubmitForm] = useState<FormType>(defaultValue)

  function valueReducer (
    state: FormType,
    action: SetAction<FormType> | ChangeAction<FormType, any> | ChangeOtherAction<FormType, unknown> | OtherEventAction<FormType> | SubmitAction
  ) {
    const { type } = action
    if (type === 'set') {
      const { value } = action as SetAction<FormType>
      return value
    }
    if (type === 'blur') {
      const { label } = action as OtherEventAction<FormType>
      const validations = formValidation[label]
        .filter((item) => item.when.includes('blur'))
      if (validations.length === 0) return state
      handleValidations(validations, state[label], state, lastSubmitForm, setError)
      return state
    }
    if (type === 'focus') {
      const { label } = action as OtherEventAction<FormType>
      const validations = formValidation[label]
        .filter((item) => item.when.includes('focus'))
      if (validations.length === 0) return state
      handleValidations(validations, state[label], state, lastSubmitForm, setError)
      return state
    }
    if (type === 'change') {
      const { label, value } = action as ChangeAction<FormType, any>
      const validations = formValidation[label]
        .filter((item) => item.when.includes('change'))
      const getChangedFormItem = getChangedForm?.[label]
      if (getChangedFormItem) {
        const changed = getChangedFormItem(value, state)
        handleValidations(validations, changed[label], changed, lastSubmitForm, setError)

        const point = `change:${label as keyof FormType & string}` as ValidationTimePoint<FormType>
        (Object.entries(formValidation) as Array<[keyof FormType & string, Array<ColumnValidation<FormType>>]>)
          .forEach(([column, list]) => {
            const validations = list.filter((item) => item.when.includes(point))
            if (validations.length === 0) return
            handleValidations(validations, changed[column], changed, lastSubmitForm, setError)
          })
        return changed
      }
      const changed = { ...state, [label]: value }
      handleValidations(validations, changed[label], changed, lastSubmitForm, setError)

      const point = `change:${label as keyof FormType & string}` as ValidationTimePoint<FormType>
      (Object.entries(formValidation) as Array<[keyof FormType & string, Array<ColumnValidation<FormType>>]>)
        .forEach(([column, list]) => {
          const validations = list.filter((item) => item.when.includes(point))
          if (validations.length === 0) return
          handleValidations(validations, changed[column], changed, lastSubmitForm, setError)
        })
      return changed
    }
    if (type === 'submit') {
      const pass = Object.keys(state)
        .every((key) => {
          if (formValidation[key as keyof FormType] === undefined) return true
          return formValidation[key as keyof FormType]
            .filter((item) => item.when.includes('beforeSubmit'))
            .every((validation) => {
              const { isPass } = validation.func(state[key as keyof FormType], state, lastSubmitForm, null)
              if (isPass) {
                setError((error) => {
                  const { newError } = validation.func(state[key as keyof FormType], state, lastSubmitForm, error)
                  return { ...error, ...newError }
                })
                return true
              }
              setError((error) => {
                const { newError } = validation.func(state[key as keyof FormType], state, lastSubmitForm, error)
                return { ...error, ...newError }
              })
              return false
            })
        })
      if (pass) return onSubmit(state)
      return state
    }
    return state
  }

  const [value, dispatch] = useReducer(
    useCallback(valueReducer, [formValidation, getChangedForm, onSubmit, lastSubmitForm]),
    defaultValue
  )

  useEffect(() => { dispatch({ type: 'set', value: defaultValue }) }, [defaultValue])

  const disableSubmit = useMemo(() => {
    return !Object.keys(value)
      .every((key) => {
        if (formValidation[key as keyof FormType] === undefined) return true
        return formValidation[key as keyof FormType]
          .filter((item) => item.when.includes('beforeClickSubmit'))
          .every((validation) => {
            return validation.func(value[key as keyof FormType], value, lastSubmitForm, null).isPass &&
              error[key as keyof FormType] === null
          })
      })
  }, [value, error, formValidation, lastSubmitForm])

  useEffect(() => {
    Object.keys(value)
      .forEach((key) => {
        if (formValidation[key as keyof FormType] === undefined) return
        const validations = formValidation[key as keyof FormType]
          .filter((item) => item.when.includes('rerender'))
        if (validations.length === 0) return
        handleValidations(validations, value[key as keyof FormType], value, lastSubmitForm, setError)
      })
  }, [value, formValidation])

  const [handleDebouncedSubmit] = useDebouncedCallback(() => {
    setLastSubmitForm(value)
    if (beforeSubmit) beforeSubmit(value)
    dispatch({ type: 'submit' })
  }, 200)
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleDebouncedSubmit()
  }
  const handleChange = (label: keyof FormType) => (event: unknown) => {
    dispatch({
      type: 'change',
      label,
      value: getValueFromEvent[label](event)
    })
  }
  const handleOther = (type: 'blur' | 'focus', label: keyof FormType) => () => { dispatch({ type, label }) }

  const disabled = useMemo(() => {
    return Object.keys(value)
      .map((key) => {
        const field = disableField?.[key as keyof FormType]
        if (field) {
          return { [key as keyof FormType]: field(value[key as keyof FormType], value) }
        }
        return { [key as keyof FormType]: false }
      })
      .reduce((collection, item) => Object.assign({}, collection, item), {}) as { [key in keyof FormType]: boolean }
  }, [value, disableField])

  return {
    value,
    dispatch,
    handleSubmit,
    handleChange,
    handleOther,
    error,
    setError,
    disableSubmit,
    disabled,
    lastSubmitForm
  }
}

export const useCheckMDWidth = (): boolean => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return matches
}
