import React, { useContext } from 'react'
import useT from '../../../i18ns/admin/useT'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import { FormState } from '../../../utils/default/FormHook'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { FinanceExchangeRate } from '../../../views/admin/finance/FinanceParameterPage'
import { formatDateTime } from '../../../utils/default/TableHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { FinanceExchangeRateProviders } from '@golden/gdk-admin'
import { cloneDeep } from '@golden/utils'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  button: {
    color: theme.custom.palette.blue.main
  },
  list: {
    '&:not(:first-child)': {
      marginTop: '-1px'
    }
  },
  item: {
    border: '1px solid #e9e9e9',
    '&:not(:first-child)': {
      marginTop: '-1px'
    }
  }
}))

const FinanceParameterExchangeRateList: React.FC<{ context: React.Context<FormState<FinanceExchangeRate>>, disabled: boolean }> = (props) => {
  const { context, disabled } = props
  const { value, dispatch } = useContext(context)
  const classes = useStyles()

  const handleActive = (sourceId: number, active: boolean) => {
    const providers = cloneDeep(value.providers)
    const sourceIndex = providers.findIndex(({ id }) => id === sourceId)
    if (sourceIndex === -1) return

    const [target] = providers.splice(sourceIndex, 1)

    if (!active) {
      providers.push(target)
    } else {
      const targetIndex = providers.findIndex((provider) => !provider.is_active)
      providers.splice((targetIndex === -1 ? providers.length : targetIndex), 0, target)
    }

    target.is_active = active
    dispatch({ type: 'set', value: { ...value, providers: providers.map((provider, index) => ({ ...provider, order: index })) } })
  }

  const handleSorting = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    const { droppableId: sourceId, index: sourceIndex } = source
    const { droppableId: targetId, index: targetIndex } = destination
    if (sourceId === targetId && sourceIndex === targetIndex) return

    const providers = cloneDeep(value.providers)

    const [target] = providers.splice(sourceIndex, 1)
    providers.splice(targetIndex, 0, target)

    dispatch({ type: 'set', value: { ...value, providers: providers.map((provider, index) => ({ ...provider, order: index })) } })
  }

  return (
    <DragDropContext onDragEnd={handleSorting}>
      <Droppable droppableId='droppable-1'>
        {
          (provided) => {
            const { droppableProps, innerRef, placeholder } = provided
            return (
              <List
                disablePadding
                ref={innerRef}
                className={classes.list}
                {...droppableProps}
              >
                {
                  value.providers
                    .filter((item) => item.is_active)
                    .map((item, index) => (<FinanceParameterExchangeRateItem key={item.id} {...item} index={index} handleActive={handleActive} disabled={disabled} />)
                    )
                }
                {placeholder}
              </List>
            )
          }
        }
      </Droppable>
      <List
        disablePadding
        className={classes.list}
      >
        {
          value.providers
            .filter((item) => !item.is_active)
            .map((item, index) => (<FinanceParameterExchangeRateItem key={item.id} {...item} index={index} handleActive={handleActive} disabled={disabled} />)
            )
        }
      </List>
    </DragDropContext>
  )
}

const FinanceParameterExchangeRateItem: React.FC<FinanceExchangeRateProviders & { index: number, handleActive: (id: number, active: boolean) => void, disabled: boolean }> = React.memo((props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { index, id, name, is_active, last_buy, last_sell, last_fetched_at, handleActive, disabled } = props
  const { t } = useT()
  const classes = useStyles()
  const content = !(last_buy || last_sell || last_fetched_at) ? '-' : `〔${t('common.depositShort')}${last_buy || '-'}, ${t('common.withdrawShort')}${last_sell || '-'}〕${last_fetched_at ? formatDateTime(last_fetched_at) : '-'}`

  if (is_active) {
    return (
      <Draggable draggableId={`provider-${id}`} index={index}>
        {
          (provided) => {
            const { draggableProps, dragHandleProps, innerRef } = provided
            return (
              <ListItem
                className={classes.item}
                divider
                ref={innerRef}
                {...draggableProps}
              >
                <ListItemIcon {...dragHandleProps}>
                  {!disabled && <DragHandleIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  secondary={content}
                />
                <Button className={classes.button} onClick={() => { handleActive(id, false) }} disabled={disabled}>
                  {t('common.disable')}
                </Button>
              </ListItem>
            )
          }
        }
      </Draggable>
    )
  } else {
    return (
      <ListItem
        divider
        disabled
        className={classes.item}
      >
        <ListItemIcon />
        <ListItemText
          primary={name}
          secondary={content}
        />
        <Button className={classes.button} onClick={() => { handleActive(id, true) }} disabled={disabled}>
          {t('common.indisable')}
        </Button>
      </ListItem>
    )
  }
})

export default React.memo(FinanceParameterExchangeRateList)
