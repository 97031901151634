import React, { useMemo, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IpWhitelistQuery } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { getValueFromChangeEvent, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import allRoutes, { Path } from '../route/route'
import { createValidateIp } from '../../../utils/default/Validator'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const useStyles = makeStyles(() => ({
  textField: {
    paddingTop: 14,
    paddingBottom: 14
  }
}))

export interface SystemIpWhitelistFormType {
  ip: string
}

export const searchToRequest: SearchToRequestFunc<IpWhitelistQuery> = (search) => {
  const converted = {
    ...search
  } as IpWhitelistQuery
  return omitBy(converted, isUndefined) as IpWhitelistQuery
}

export const initialForm: InitialFormFunc<SystemIpWhitelistFormType> = (defaultForm) => ({
  ip: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<SystemIpWhitelistFormType> = {
  ip: getValueFromChangeEvent
}

const formToRequest = (form: SystemIpWhitelistFormType): IpWhitelistQuery => {
  const converted = {
    ip: convertEmptyToUndefined(form.ip)
  } as IpWhitelistQuery
  return omitBy(converted, isUndefined) as IpWhitelistQuery
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const SystemIpWhitelistForm: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const writable = useChecker()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(request)
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.systemIpWhitelist.encodePath
  })

  const validation = useMemo(() => ({
    ip: [
      {
        func: createValidateIp('ip', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<SystemIpWhitelistFormType>), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <FormField<SystemIpWhitelistFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            variant="outlined"
            InputProps={{ classes: { input: classes.textField } }}
            name="ip"
            placeholder={t('placeholder.inputIp')}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormSubmitButton
            component={Button}
            context={FormContext}
            type="submit"
            className={commonClasses.purpleGradualButton}
          >
            <Box paddingY={0.5}>
              {t('common.search')}
            </Box>
          </FormSubmitButton>
        </Grid>
        {writable && (
          <Grid item>
            <MuiButton
              component={Link}
              to={Path.SYSTEM_IP_WHITELIST_CREATE}
              className={commonClasses.purpleGradualButton}
            >
              <Box paddingY={0.5}>
                {t('page.createIpWhitelist')}
              </Box>
            </MuiButton>
          </Grid>
        )}
      </Grid>
    </FormStateProvider>
  )
}

export default React.memo(SystemIpWhitelistForm)
