import React, { useState, useMemo, useCallback } from 'react'
import { PaginationRes, AdminAnnouncement, AnnouncementListReq, PaginationReq } from '@golden/gdk-admin'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import AnnouncementTableContent from './AnnouncementTableContent'
import AnnouncementTableStatus from './AnnouncementTableStatus'
import AnnouncementTableButtons from './AnnouncementTableButtons'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import announcementTypeName from '../../../constants/default/announcementTypeName'
import {
  Cell,
  createTableData,
  createDefaultPaginationData
} from '../../../utils/default/TableHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import DateTime from '../../default/present/DateTime'
import { searchToRequest } from '../../../views/admin/forestage/AnnouncementPage'
import allRoute from '../route/route'
import { renderContent } from '@golden/tiptap-react'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

interface RowType extends Cell {
  created_at: React.ReactElement
  type: string
  content: React.ReactElement
  begin_at: React.ReactElement
  end_at: React.ReactElement
  status: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  buttons: React.ReactElement
}

const AnnouncementTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [report, setReport] = useState<PaginationRes<Array<AdminAnnouncement<Date>>>>(
    createDefaultPaginationData([] as Array<AdminAnnouncement<Date>>)
  )
  const [reloadFlag, setReloadFlag] = useState<boolean>(true)

  const payload = useMemo(() => {
    const payload: AnnouncementListReq & PaginationReq = {
      page: request?.page ?? 1
    }
    if (request?.status) {
      return { ...payload, status: request.status }
    }
    return payload
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.announcement.getAnnouncementList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Array<AdminAnnouncement<Date>>>) => {
      setReport(res)
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    }
  })

  const handleReload = useCallback(() => { setReloadFlag((reloadFlag) => !reloadFlag) }, [])

  const [rows, setRows] = useState<RowType[]>([])
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(report.data.map(async (entry) => {
      const content = await renderContent(entry.content)
      return {
        id: entry.id,
        created_at: (<DateTime time={entry.created_at} />),
        type: t(announcementTypeName[entry.type]),
        content: (<AnnouncementTableContent title={entry.title} isPinned={entry.is_pinned} content={content} />),
        begin_at: (<DateTime time={entry.begin_at} />),
        end_at: (<DateTime time={entry.end_at} />),
        updatedAt: (<DateTime time={entry.updated_at} />),
        updatedBy: entry.updated_by,
        buttons: (
          <AnnouncementTableButtons
            id={entry.id}
            reload={handleReload}
          />
        ),
        status: (
          <AnnouncementTableStatus
            id={entry.id}
            status={entry.status}
            endAt={entry.end_at}
            reload={handleReload}
          />
        )
      }
    })))
  }, [report.data, t, handleReload])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        created_at: {
          label: t('common.createdAt'),
          value: 'created_at',
          align: 'center',
          width: 120
        },
        type: {
          label: t('common.announcementType'),
          value: 'type',
          align: 'center',
          width: 120
        },
        content: {
          label: t('common.announcementContent'),
          value: 'content',
          align: 'left'
        },
        begin_at: {
          label: t('common.beginAt'),
          value: 'begin_at',
          align: 'center',
          width: 120
        },
        end_at: {
          label: t('common.endAt'),
          value: 'end_at',
          align: 'center',
          width: 120
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center',
          width: 120
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center',
          width: 120
        },
        buttons: {
          label: t('common.function'),
          value: 'buttons',
          align: 'center',
          width: 60
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 120
        }
      },
      [
        'created_at',
        'type',
        'content',
        'begin_at',
        'end_at',
        'updatedAt',
        'updatedBy',
        'buttons',
        'status'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.forestageAnnouncement.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <CoreTable
        classes={{
          head: classes.pinkTableHead,
          cellHead: classes.tableCellHead,
          row: classes.tableRow
        }}
        data={data}
        total={report.total}
        showPagination
        page={request?.page ?? 1}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(AnnouncementTable)
