import { PlatformType, GameType, Platform, GameStatus, GameCategoryType, Game, WalletType } from '@golden/gdk-campaign'
import { GameCategoryBase, GameCategoryFactory, GameBase, GameFactory, PlatformBase, PlatformFactory } from '@golden/game-china'
import { flatten } from '@golden/utils'
import { createContext } from 'react'
import TKeyType from '../../i18ns/admin/TKeyType'
import { TOptions } from 'i18next'

export type StoreGame = Game & { instance: GameBase, nameWithStatus: string }
export type StorePlatform = Platform<StoreGame> & { instance: PlatformBase, nameWithStatus: string }
export interface StoreGameCategory { instance: GameCategoryBase, games: StoreGame[] }
export interface PlatformGameContentType { platforms: StorePlatform[], games: StoreGame[], categories: StoreGameCategory[], aliveGames: StoreGame[], alivePlatforms: StorePlatform[] }
export const platformContextDefaultValue: PlatformGameContentType = { platforms: [], games: [], categories: [], aliveGames: [], alivePlatforms: [] }
export const PlatformContext = createContext<PlatformGameContentType>(platformContextDefaultValue)

export const getStorePlatform = (platforms: Platform[], t: (key: TKeyType, options?: TOptions) => string) => (platforms
  .map((platform) => {
    const instance = (() => { try { return PlatformFactory.create(platform.id) } catch { return null } })()
    if (instance === null) console.log(platform)
    const walletName = instance?.walletName ?? ''
    return {
      ...platform,
      instance,
      nameWithStatus: platform.is_active ? walletName : `${walletName} - ${t('operationState.notSale')}`,
      games: (platform.games
        .map((game) => {
          const instance = (() => { try { return GameFactory.create(game.id, null) } catch { return null } })()
          const gameName = instance?.name ?? ''
          return ({
            ...game,
            instance,
            nameWithStatus: game.status !== GameStatus.STOP_SALE ? gameName : `${gameName} - ${t('operationState.notSale')}`
          })
        })
        .filter((game) => !!game.instance) as StoreGame[])
        .sort((a, b) => a.instance.order - b.instance.order)
    }
  })
  .filter((platform) => !!platform.instance) as StorePlatform[])
  .sort((a, b) => a.instance.order - b.instance.order)

const getStoreGames = (platforms: StorePlatform[]) => {
  return flatten(platforms.map((el) => el.games)).sort((a, b) => a.instance.order - b.instance.order)
}
const getStoreCategories = (games: StoreGame[]) => {
  return GameCategoryFactory.createAll().map((instance) => ({
    instance,
    games: games.filter((game) => game.category === instance.id)
  }))
}
export const getPlatformContextValue = (res: Platform[], t: (key: TKeyType, options?: TOptions) => string): PlatformGameContentType => {
  const platforms = getStorePlatform(res, t)
  const games = getStoreGames(platforms)
  const categories = getStoreCategories(games)
  const aliveGames = games.filter((game) => (game.status !== GameStatus.REMOVED))
  const alivePlatforms = platforms.filter((platform) => (platform.games.some((game) => (game.status !== GameStatus.REMOVED))))

  return {
    categories,
    platforms,
    games,
    aliveGames,
    alivePlatforms
  }
}

export const findGame = (gameId: GameType, games: StoreGame[]): StoreGame | null => {
  return games.find((item) => item.id === gameId) ?? null
}
export const getGameName = (gameId: GameType, games: StoreGame[]): string => {
  return findGame(gameId, games)?.instance?.name ?? ''
}
export const getGameNameWithStatus = (gameId: GameType, games: StoreGame[]): string => {
  return findGame(gameId, games)?.nameWithStatus ?? ''
}

const findPlatform = (platform: PlatformType, platforms: StorePlatform[]): StorePlatform | null => {
  return platforms.find((item) => item.id === platform) ?? null
}

const findPlatformByWalletId = (walletId: WalletType, platforms: StorePlatform[]): StorePlatform | null => {
  return platforms.find((item) => item.wallet_id === walletId) ?? null
}

export const mainWalletName = PlatformFactory.create(PlatformType.GOLDEN).walletName
export const getWalletName = (platform: PlatformType, platforms: StorePlatform[]) => {
  return findPlatform(platform, platforms)?.instance?.walletName ?? ''
}
export const getWalletNameWithStatus = (platform: PlatformType, platforms: StorePlatform[]) => {
  return findPlatform(platform, platforms)?.nameWithStatus ?? ''
}

export const getWalletNameWithStatusByWalletId = (walletId: WalletType, platforms: StorePlatform[]) => {
  return findPlatformByWalletId(walletId, platforms)?.nameWithStatus ?? ''
}

export const getWalletNameByWalletId = (walletId: WalletType, platforms: StorePlatform[]) => {
  return findPlatformByWalletId(walletId, platforms)?.instance.walletName ?? ''
}

const findGameCategory = (category: GameCategoryType, categories: StoreGameCategory[]): StoreGameCategory | null => {
  return categories.find((item) => item.instance.id === category) ?? null
}
export const getCategoryName = (category: GameCategoryType, categories: StoreGameCategory[]) => {
  return findGameCategory(category, categories)?.instance.name ?? ''
}
export const getCategoryShortName = (category: GameCategoryType, categories: StoreGameCategory[]) => {
  return findGameCategory(category, categories)?.instance.shortName ?? ''
}
