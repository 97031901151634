import React, { useCallback, useContext } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { AdminWithdrawStatusType, PermissionType, WithdrawSlip } from '@golden/gdk-admin'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { DialogPayloadContext } from './WithdrawalFMSlipDetailDialog'
import { WithdrawSlipType } from './EditWithdrawalFMSlipForm'

interface PropTypes {
  item: WithdrawSlip
  reload: () => void
}

const LockButton: React.FC<{ id: number, reload: () => void, type: WithdrawSlipType, text: string }> = React.memo((props) => {
  const { id, type, text, reload } = props
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(DialogPayloadContext)
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    gdk.withdraw.reviewWithdrawSlip(id).subscribe({
      next: () => {
        reload()
        setPayload({
          id,
          type,
          open: true,
          readonly: false
        })
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'lockError',
          value: true,
          isOK: false
        })
      }
    })
  }, [gdk, id, type]), 200)
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleClick}
      fullWidth
      size="small"
    >
      {text}
    </Button>
  )
})

const UnlockButton: React.FC<{ id: number, reload: () => void }> = React.memo((props) => {
  const { id, reload } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    gdk.withdraw.unlockWithdrawSlip(id).subscribe({
      next: () => {
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'unlockError',
          value: true,
          isOK: false
        })
      }
    })
  }, [gdk, id]), 200)
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleClick}
      fullWidth
      size="small"
    >
      {t('common.unlock')}
    </Button>
  )
})

const StopButton: React.FC<{ id: number, reload: () => void }> = React.memo((props) => {
  const { id, reload } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    gdk.withdraw.stopThirdPartyWithdraw(id).subscribe({
      next: () => {
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: error.message,
          showCancel: false
        }))
        globalDialog.setOpen({
          id: 'stopError',
          value: true,
          isOK: false
        })
      }
    })
  }, [gdk, id]), 200)
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      className={commonClasses.blueGradualOutlineButton}
      onClick={handleClick}
      fullWidth
      size="small"
    >
      {t('common.stopThirdPartyWithdraw')}
    </Button>
  )
})

const ReadButton: React.FC<{ id: number }> = React.memo((props) => {
  const { id } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const [, setPayload] = useContext(DialogPayloadContext)
  const [handleDebouncedClick] = useDebouncedCallback(useCallback(() => {
    setPayload({
      id,
      type: WithdrawSlipType.PENDING,
      open: true,
      readonly: true
    })
  }, [id]), 200)
  const handleClick = useCallback(handleDebouncedClick, [])
  return (
    <Button
      onClick={handleClick}
      className={commonClasses.blueGradualOutlineButton}
      fullWidth
      size="small"
    >
      {t('common.lookDetail')}
    </Button>
  )
})

const WithdrawalFMSlipButtons: React.FC<PropTypes> = (props) => {
  const { item, reload } = props
  const writable = useChecker([PermissionType.WITHDRAW_FM_SLIP])
  const { t } = useT()
  return (
    <Box paddingY={1}>
      <Grid container direction="column" spacing={1}>
        {
          [
            AdminWithdrawStatusType.IN_PROGRESS,
            AdminWithdrawStatusType.THIRD_PARTY_WITHDRAW_FAIL
          ].includes(item.status) && writable && (
            <React.Fragment>
              <Grid item>
                <Box minWidth={130}>
                  <Grid item container direction="row" spacing={1}>
                    <Grid xs={12} sm={12} md={6} item><LockButton reload={reload} id={item.id} type={WithdrawSlipType.THIRD_PARTY_WITHDRAW} text={t('common.auto')} /></Grid>
                    <Grid xs={12} sm={12} md={6} item><LockButton reload={reload} id={item.id} type={WithdrawSlipType.GOLDEN_WITHDRAW} text={t('common.manual')} /></Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item><LockButton reload={reload} id={item.id} type={WithdrawSlipType.CANCEL} text={t('common.cancelWithdraw')} /></Grid>
            </React.Fragment>
          )
        }
        {
          item.status === AdminWithdrawStatusType.REVIEWING &&
          writable && (<Grid item><UnlockButton id={item.id} reload={reload} /></Grid>)
        }
        {item.status === AdminWithdrawStatusType.THIRD_PARTY_WITHDRAWING && writable && (<Grid item><StopButton id={item.id} reload={reload} /></Grid>)}
        <Grid item>
          <ReadButton id={item.id} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(WithdrawalFMSlipButtons)
