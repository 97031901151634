import React, { useState, useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, RCStatusType, WithdrawRCSlipQuery, WithdrawWayType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { addMonths, isBefore, subSeconds, isValid, isAfter, startOfDay, endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, getValueFromCheckboxEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getValueFromValue, getCashInputProps } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter, ChangedFormGetter } from '../../../utils/default/FormHook'
import { createValidateDate, createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import DateTimePicker from '../../default/form/DateTimePicker'
import NumberInput from '../../default/form/NumberInput'
import rcStatusName from '../../../constants/admin/rcStatusName'
import withdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import Store from '../../../providers/admin/gdk/useGDKStore'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'

export type SlipRequest = PaginationReq & WithdrawRCSlipQuery

export interface SlipFormType {
  account: string
  serial: string
  status: '--' | RCStatusType
  method: '--' | WithdrawWayType
  createdAtNull: boolean | null
  createdAtStart: Date | null
  createdAtEnd: Date | null
  finishedAtNull: boolean | null
  finishedAtStart: Date | null
  finishedAtEnd: Date | null
  rcApplyAtNull: boolean | null
  rcApplyAtStart: Date | null
  rcApplyAtEnd: Date | null
  rcUpdatedBy: string
  min: string
  max: string
  layer_id: '--' | number
  bankAccount: string
  realName: string
  realNameNull: boolean | null
}

export const searchToRequest: SearchToRequestFunc<SlipRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    status: acceptUndefined(search.rc_status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(rcStatusName))
    )),
    method: acceptUndefined(search.method, pipe(
      (value) => guaranteeBeKey(value, Object.keys(withdrawWayTypeName))
    )),
    created_at_null: acceptUndefined(search.created_at_null, pipe(
      parseInt
    )),
    created_at_start: acceptUndefined(search.created_at_start, pipe(
      parseInt
    )),
    created_at_end: acceptUndefined(search.created_at_end, pipe(
      parseInt
    )),
    finished_at_start: acceptUndefined(search.finished_at_start, pipe(
      parseInt
    )),
    finished_at_end: acceptUndefined(search.finished_at_end, pipe(
      parseInt
    )),
    rc_apply_at_start: acceptUndefined(search.rc_apply_at_start, pipe(
      parseInt
    )),
    rc_apply_at_end: acceptUndefined(search.rc_apply_at_end, pipe(
      parseInt
    )),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat),
    layer_id: acceptUndefined(search.layer_id, parseInt)
  } as SlipRequest
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.finished_at_start && converted.finished_at_end && converted.finished_at_end < converted.finished_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.rc_apply_at_start && converted.rc_apply_at_end && converted.rc_apply_at_end < converted.rc_apply_at_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as SlipRequest
}

export const initialForm: InitialFormFunc<SlipFormType> = (defaultForm) => ({
  account: '',
  serial: '',
  status: '--',
  method: '--',
  bankAccount: '',
  createdAtNull: false,
  createdAtStart: startOfDay(new Date()),
  createdAtEnd: endOfDay(new Date()),
  finishedAtNull: null,
  finishedAtStart: null,
  finishedAtEnd: null,
  rcApplyAtNull: null,
  rcApplyAtStart: null,
  rcApplyAtEnd: null,
  rcUpdatedBy: '',
  min: '',
  max: '',
  layer_id: '--',
  realName: '',
  realNameNull: null,
  ...defaultForm
})

const formToRequest = (form: SlipFormType): SlipRequest => {
  const converted = {
    player_account: convertEmptyToUndefined(form.account),
    order_number: convertEmptyToUndefined(form.serial),
    rc_status: form.status === '--' ? undefined : form.status,
    method: form.method === '--' ? undefined : form.method,
    bank_account: convertEmptyToUndefined(form.bankAccount),
    created_at_null: form.createdAtNull !== null ? Number(form.createdAtNull) : undefined,
    created_at_start: form.createdAtStart && isValid(form.createdAtStart) ? getTime(form.createdAtStart) : undefined,
    created_at_end: form.createdAtEnd && isValid(form.createdAtEnd) ? getTime(form.createdAtEnd) : undefined,
    finished_at_null: form.finishedAtNull !== null ? Number(form.finishedAtNull) : undefined,
    finished_at_start: form.finishedAtStart && isValid(form.finishedAtStart) ? getTime(form.finishedAtStart) : undefined,
    finished_at_end: form.finishedAtEnd && isValid(form.finishedAtEnd) ? getTime(form.finishedAtEnd) : undefined,
    rc_apply_at_null: form.rcApplyAtNull !== null ? Number(form.rcApplyAtNull) : undefined,
    rc_apply_at_start: form.rcApplyAtStart && isValid(form.rcApplyAtStart) ? getTime(form.rcApplyAtStart) : undefined,
    rc_apply_at_end: form.rcApplyAtEnd && isValid(form.rcApplyAtEnd) ? getTime(form.rcApplyAtEnd) : undefined,
    rc_updated_by: convertEmptyToUndefined(form.rcUpdatedBy),
    cash_start: convertEmptyToUndefined(form.min),
    cash_end: convertEmptyToUndefined(form.max),
    layer_id: form.layer_id === '--' ? undefined : form.layer_id,
    real_name: convertEmptyToUndefined(form.realName),
    real_name_null: form.realNameNull !== null ? Number(form.realNameNull) : undefined,
    page: 1
  } as SlipRequest
  return omitBy(converted, isUndefined) as SlipRequest
}

const getValueFromEvent: ValueGetter<SlipFormType> = {
  account: getValueFromChangeEvent,
  serial: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  createdAtNull: getValueFromCheckboxEvent,
  createdAtStart: getValueFromValue,
  createdAtEnd: getValueFromValue,
  finishedAtNull: getValueFromCheckboxEvent,
  finishedAtStart: getValueFromValue,
  finishedAtEnd: getValueFromValue,
  rcApplyAtNull: getValueFromCheckboxEvent,
  rcApplyAtStart: getValueFromValue,
  rcApplyAtEnd: getValueFromValue,
  rcUpdatedBy: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  layer_id: getValueFromChangeEvent,
  bankAccount: getValueFromChangeEvent,
  realName: getValueFromChangeEvent,
  realNameNull: getValueFromCheckboxEvent
}

const getChangedForm: ChangedFormGetter<SlipFormType> = {
  createdAtStart: (value, form) => {
    if (value && form.createdAtEnd && (isAfter(form.createdAtEnd, subSeconds(addMonths(value, 2), 1)) || isBefore(form.createdAtEnd, value))) {
      return { ...form, createdAtStart: value, createdAtEnd: subSeconds(addMonths(value, 2), 1) }
    }
    return { ...form, createdAtStart: value }
  },
  finishedAtStart: (value, form) => {
    if (value && form.finishedAtEnd && (isAfter(form.finishedAtEnd, subSeconds(addMonths(value, 2), 1)) || isBefore(form.finishedAtEnd, value))) {
      return { ...form, finishedAtStart: value, finishedAtEnd: subSeconds(addMonths(value, 2), 1) }
    }
    return { ...form, finishedAtStart: value }
  },
  rcApplyAtStart: (value, form) => {
    if (value && form.rcApplyAtEnd && (isAfter(form.rcApplyAtEnd, subSeconds(addMonths(value, 2), 1)) || isBefore(form.rcApplyAtEnd, value))) {
      return { ...form, rcApplyAtStart: value, rcApplyAtEnd: subSeconds(addMonths(value, 2), 1) }
    }
    return { ...form, rcApplyAtStart: value }
  }
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const cashInputProps = getCashInputProps()

type DateType = 'created' | 'finished' | 'rcApply'
const DateRow: React.FC = React.memo(() => {
  const { value, handleChange, dispatch } = useContext(FormContext)
  const { t } = useT()
  const [dateType, setDateType] = useState<DateType>('created')
  const startName = useMemo(() => `${dateType}AtStart` as const, [dateType])
  const endName = useMemo(() => `${dateType}AtEnd` as const, [dateType])
  const nullName = useMemo(() => `${dateType}AtNull` as const, [dateType])

  const time = useMemo(() => ({ start: value[startName], end: value[endName] }), [endName, startName, value])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const startTimeAddTwoMonths = time.start ? subSeconds(addMonths(time.start, 2), 1) : new Date()
    const shouldDisableStartDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (time.end === null) return false
      return isAfter(day, endOfDay(time.end))
    }
    const shouldDisableEndDate = (day: Date | null): boolean => {
      if (day === null) return true
      if (time.start === null) return false
      if (isAfter(day, endOfDay(startTimeAddTwoMonths))) return true
      return isBefore(day, startOfDay(time.start))
    }
    return {
      shouldDisableStartDate,
      shouldDisableEndDate
    }
  }, [time.start, time.end])

  const dateTypeOptions: Array<{ name: string, value: DateType }> = useMemo(() => ([
    {
      name: t('common.applyDate'),
      value: 'created'
    },
    {
      name: t('common.reviewDate'),
      value: 'rcApply'
    },
    {
      name: t('common.confirmDate'),
      value: 'finished'
    }
  ]), [t])
  const handleDateTypeChange = useCallback((event) => {
    const start = value[startName]
    const end = value[endName]
    const isEmpty = value[nullName]
    setDateType(event.target.value)

    dateTypeOptions.forEach(item => {
      const type = item.value
      dispatch({ type: 'change', label: nullName, value: event.target.value === type ? isEmpty : null })
      dispatch({ type: 'change', label: startName, value: event.target.value === type ? start : null })
      dispatch({ type: 'change', label: endName, value: event.target.value === type ? end : null })
    })
  }, [value, startName, endName, nullName, dateTypeOptions, dispatch])

  const dateIsEmpty = useMemo(() => value[nullName] ?? false, [value, nullName])
  const handleDateIsEmptyChange = useCallback((event) => {
    const checked = event.target.checked
    const start = startOfDay(new Date())
    const end = endOfDay(new Date())

    dispatch({ type: 'change', label: startName, value: checked ? null : start })
    dispatch({ type: 'change', label: endName, value: checked ? null : end })
    handleChange(nullName)(event)
  }, [dispatch, endName, handleChange, nullName, startName])
  return (
    <Grid item>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
          <DropDown
            value={dateType}
            fullWidth
            options={dateTypeOptions}
            onChange={handleDateTypeChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePicker
            disabled={dateIsEmpty}
            value={time.start}
            onChange={handleChange(startName)}
            label={t('common.beginAt')}
            shouldDisableDate={shouldDisableStartDate}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePicker
            disabled={dateIsEmpty}
            value={time.end}
            onChange={handleChange(endName)}
            label={t('common.endAt')}
            shouldDisableDate={shouldDisableEndDate}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OnOffCheckbox
            label={t('common.emptyDate')}
            value={dateIsEmpty}
            onChange={handleDateIsEmptyChange}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

const WithdrawalRCSlipForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })
  const layers = Store.player.layers()

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        account: request.player_account,
        serial: request.order_number,
        status: request.rc_status ?? '--',
        method: request.method ?? '--',
        createdAtNull: request.created_at_null !== undefined ? Boolean(Number(request.created_at_null)) : null,
        createdAtStart: request.created_at_start ? new Date(request.created_at_start) : null,
        createdAtEnd: request.created_at_end ? new Date(request.created_at_end) : null,
        finishedAtNull: request.finished_at_null !== undefined ? Boolean(Number(request.finished_at_null)) : null,
        finishedAtStart: request.finished_at_start ? new Date(request.finished_at_start) : null,
        finishedAtEnd: request.finished_at_end ? new Date(request.finished_at_end) : null,
        rcApplyAtNull: request.rc_apply_at_null !== undefined ? Boolean(Number(request.rc_apply_at_null)) : null,
        rcApplyAtStart: request.rc_apply_at_start ? new Date(request.rc_apply_at_start) : null,
        rcApplyAtEnd: request.rc_apply_at_end ? new Date(request.rc_apply_at_end) : null,
        rcUpdatedBy: request.rc_updated_by,
        min: request.cash_start?.toString() ?? '',
        max: request.cash_end?.toString() ?? '',
        bankAccount: request.bank_account,
        layer_id: request.layer_id ?? '--',
        realName: request.real_name,
        realNameNull: request.real_name_null !== undefined ? Boolean(Number(request.real_name_null)) : null
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.withdrawalRCSlip.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    serial: [],
    status: [],
    method: [],
    createdAtNull: [],
    createdAtStart: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { createdAtStart: null } }
          }
          return createValidateDate('createdAtStart', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.createdAtEnd === null) {
            return { isPass: true, stop: false, newError: { createdAtStart: null } }
          }
          if (isAfter(value as Date, form.createdAtEnd)) {
            return { isPass: false, stop: true, newError: { createdAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    createdAtEnd: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { createdAtEnd: null } }
          }
          return createValidateDate('createdAtEnd', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.createdAtStart === null) {
            return { isPass: true, stop: false, newError: { createdAtEnd: null } }
          }
          if (isAfter(form.createdAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { createdAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { createdAtStart: null, createdAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    finishedAtNull: [],
    finishedAtStart: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { finishedAtStart: null } }
          }
          return createValidateDate('finishedAtStart', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.finishedAtEnd === null) {
            return { isPass: true, stop: false, newError: { finishedAtStart: null } }
          }
          if (isAfter(value as Date, form.finishedAtEnd)) {
            return { isPass: false, stop: true, newError: { finishedAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { finishedAtStart: null, finishedAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    finishedAtEnd: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { finishedAtEnd: null } }
          }
          return createValidateDate('finishedAtEnd', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.finishedAtStart === null) {
            return { isPass: true, stop: false, newError: { finishedAtEnd: null } }
          }
          if (isAfter(form.finishedAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { finishedAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { finishedAtStart: null, finishedAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    rcApplyAtNull: [],
    rcApplyAtStart: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { rcApplyAtStart: null } }
          }
          return createValidateDate('rcApplyAtStart', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.rcApplyAtEnd === null) {
            return { isPass: true, stop: false, newError: { rcApplyAtStart: null } }
          }
          if (isAfter(value as Date, form.rcApplyAtEnd)) {
            return { isPass: false, stop: true, newError: { rcApplyAtStart: t('error.startMustBeforeEnd') } }
          }
          return { isPass: true, stop: false, newError: { rcApplyAtStart: null, rcApplyAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    rcApplyAtEnd: [
      {
        func: (value, form, lastSubmitForm) => {
          if (value === null || value === '') {
            return { isPass: true, stop: false, newError: { rcApplyAtEnd: null } }
          }
          return createValidateDate('rcApplyAtEnd', t)(value, form, lastSubmitForm, null)
        },
        when: ['change']
      },
      {
        func: (value, form) => {
          if (form.rcApplyAtStart === null) {
            return { isPass: true, stop: false, newError: { rcApplyAtEnd: null } }
          }
          if (isAfter(form.rcApplyAtStart, value as Date)) {
            return { isPass: false, stop: true, newError: { rcApplyAtEnd: t('error.endMustAfterStart') } }
          }
          return { isPass: true, stop: false, newError: { rcApplyAtStart: null, rcApplyAtEnd: null } }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    rcUpdatedBy: [],
    min: [
      {
        func: createValidateMin('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    max: [
      {
        func: createValidateMax('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    bankAccount: [],
    layer_id: [],
    realName: [],
    realNameNull: []
  } as FormValidation<SlipFormType>), [t])

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(rcStatusName)
      .map((key) => ({
        name: t(rcStatusName[key as RCStatusType]),
        value: key
      })) as any
  )

  const methodOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(withdrawWayTypeName)
      .map((key) => ({
        name: t(withdrawWayTypeName[key as WithdrawWayType]),
        value: key
      })) as any
  )

  const layerOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' }
    ]
      .concat(
        layers.map((item) => ({ name: item.name, value: item.id })) as any
      )
  }, [t, layers])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.withdrawRCManage')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial"
                      label={t('common.orderNumber')}
                      placeholder={t('placeholder.inputOrderNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="status"
                      options={statusOptions}
                      label={t('common.orderStatus2')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="method"
                      options={methodOptions}
                      label={t('common.chooseWithdrawWay')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <DateRow />
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="realName"
                      label={t('common.realName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, OnOffCheckboxProps>
                      context={FormContext}
                      component={OnOffCheckbox}
                      name="realNameNull"
                      label={t('common.emptyRealName')}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="rcUpdatedBy"
                      label={t('common.updateBy')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="min"
                      label={t('common.minOrderMoney')}
                      placeholder={t('placeholder.inputMinOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="max"
                      label={t('common.maxOrderMoney')}
                      placeholder={t('placeholder.inputMaxOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="bankAccount"
                      label={`${t('common.cardNo')}/${t('common.address')}`}
                      placeholder={`${t('placeholder.input')}${t('common.cardNo')}/${t('common.address')}`}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="layer_id"
                      label={t('common.playerLayer')}
                      options={layerOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(WithdrawalRCSlipForm)
