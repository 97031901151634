import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import StatusLabelBase from '../default/present/StatusLabel'
import CustomThemeType from '../../themes/admin/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  activeRoot: {
    border: `${theme.custom.palette.primary} solid 1px`,
    '&:hover': {
      boxShadow: `0 0 2px ${theme.custom.palette.primary} !important`
    }
  },
  activeText: {
    background: theme.custom.palette.button.blueGreen,
    color: theme.palette.common.white
  },
  activeIconContainer: {
    backgroundColor: theme.custom.palette.primary
  },
  root: {
    whiteSpace: 'nowrap',
    height: 48
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize,
    paddingLeft: theme.spacing(2),
    height: 48,
    width: 48
  },
  iconContainer: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface PropTypes {
  id: number
  onClick: (familyId: number) => void
  selected: boolean
  isActive: boolean
  text: string
}

const StatusLabel: React.FC<PropTypes> = (props) => {
  const { id, onClick, selected, isActive, text } = props
  const classes = useStyles()
  const theme: CustomThemeType = useTheme()
  return (
    <StatusLabelBase
      clickable
      onClick={() => { onClick(id) }}
      classes={{
        root: clsx(classes.root, { [classes.activeRoot]: selected }),
        text: clsx(classes.text, { [classes.activeText]: selected }),
        iconContainer: clsx(classes.text, classes.iconContainer, { [classes.activeIconContainer]: selected })
      }}
      onColor={theme.custom.palette.primary}
      offColor={theme.custom.palette.button.grey}
      text={text}
      value={isActive}
    />
  )
}

export default React.memo(StatusLabel)
