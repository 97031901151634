import React, { useMemo, useRef, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AdminLayerInfo, AgentType, LoginStatus } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MemoPopover from '../../default/memo/MemoPopover'
import MemoDialog from '../../default/memo/MemoDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { formatDateTime, createTableData, formatCount } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import { getTitleOfNextLayer } from '../../../utils/default/AgentAccountHelper'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useGetDataByParams, useReload } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../route/route'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  account: {
    color: theme.palette.common.black
  },
  button: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white
    },
    '&:active': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white
    }
  },
  head: {
    borderTop: '1px solid rgba(233, 233, 233, 1)',
    backgroundColor: theme.palette.primary.light
  },
  cellHead: {
    color: `${theme.palette.common.white} !important`,
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  row: {
    borderBottom: '2px solid rgba(233, 233, 233, 1)'
  }
}))

interface RowType {
  id: number
  registeredUser: string
  depositedUser: string
  depositCount: string
  depositMoney: React.ReactElement
  percentage: string
  children: string
}

const AccountEmperorLayerSummaryCard: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker()
  const memoButtonRef = useRef<HTMLButtonElement>(null)
  const [memoPopoverOpen, setMemoPopoverOpen] = useState<boolean>(false)
  const [memoDialogOpen, setMemoDialogOpen] = useState<boolean>(false)
  const { reload, reloadFlag } = useReload()
  const pageFlow = usePageFlow()
  const [data, setData] = useState<AdminLayerInfo | null>(null)

  useGetDataByParams({
    path: Path.ACCOUNT_EMPEROR_LAYER,
    gdkFunc: (param: { id: string }) => gdk.emperor.getLayerInfo(Number(param.id)),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setData(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const rows = useMemo(() => {
    return data
      ? [{
          id: data.id,
          registeredUser: formatCount(data.registered_user),
          depositedUser: formatCount(data.deposited_user),
          depositCount: formatCount(data.deposit_count),
          depositMoney: (<PointsCell points={data.cash_deposit} />),
          percentage: `${Number(data.percentage)}%`,
          children: formatCount(data.children_count)
        }]
      : []
  }, [data])

  const tableData = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        registeredUser: {
          label: t('common.registeredPlayerCount'),
          value: 'registeredUser',
          align: 'center'
        },
        depositedUser: {
          label: t('common.depositedPlayerCount'),
          value: 'depositedUser',
          align: 'center'
        },
        depositCount: {
          label: t('common.playerDepositCount'),
          value: 'depositCount',
          align: 'center'
        },
        depositMoney: {
          label: t('common.playerDepositMoney'),
          value: 'depositMoney',
          align: 'right'
        },
        percentage: {
          label: t('common.splitPercentage'),
          value: 'percentage',
          align: 'center'
        },
        children: {
          label: t(getTitleOfNextLayer(AgentType.EMPEROR)),
          value: 'children',
          align: 'center'
        }
      },
      [
        'registeredUser',
        'depositedUser',
        'depositCount',
        'depositMoney',
        'percentage',
        'children'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const tableClasses = useMemo(() => {
    return {
      head: classes.head,
      cellHead: classes.cellHead,
      row: classes.row
    }
  }, [classes])

  return (
    <ScrollablePaper marginX={6}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            marginBottom={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.emperorAccount')}
            </Typography>
          </Box>
          <Box
            marginBottom={2}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="h4" className={classes.account}>
                  {data?.account ?? ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  {`[${data?.nickname ?? ''}]`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  ref={memoButtonRef}
                  size="small"
                  className={classes.button}
                  onClick={useCallback(() => {
                    if (writable) setMemoDialogOpen(true)
                  }, [writable])}
                  onMouseEnter={useCallback(() => { setMemoPopoverOpen(true) }, [])}
                  onMouseLeave={useCallback(() => { setMemoPopoverOpen(false) }, [])}
                >
                  {t('common.memo')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            marginBottom={2}
          >
            <Typography variant="h6">
              {data ? `${t('common.createdAt')} : ${formatDateTime(data.created_at)}` : ''}
            </Typography>
          </Box>
          <Box
            marginBottom={2}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  {`${t('common.status')} : `}
                </Typography>
              </Grid>
              <Grid item>
                {data && (<span style={{ color: (data.is_loginable ? 'green' : 'red') }}>{((data.is_loginable && data.login_status === LoginStatus.NORMAL) ? t('common.normal') : t('common.isNotLoginable'))}</span>)}
              </Grid>
            </Grid>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={tableData}
            total={1}
          />
        </Box>
        <MemoPopover
          open={memoPopoverOpen}
          anchorEl={memoButtonRef?.current ?? null}
          memo={data?.memo ?? ''}
        />
        <MemoDialog
          gdk={gdk}
          globalDialog={globalDialog}
          open={memoDialogOpen}
          account={data?.account ?? ''}
          nickname={data?.nickname ?? ''}
          showIconInSuccess={true}
          type="agent"
          id={data?.id ?? 0}
          memo={data?.memo ?? ''}
          onOK={useCallback(() => {
            setMemoDialogOpen(false)
            reload()
          }, [reload])}
          onCancel={useCallback(() => { setMemoDialogOpen(false) }, [])}
        />
      </LoadingAndErrorFrame>
    </ScrollablePaper>
  )
}

export default React.memo(AccountEmperorLayerSummaryCard)
