import React, { useState, useMemo, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { AdminPlayerWalletBoundRelatedRecords } from '@golden/gdk-admin'
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import { createTableData, formatDate } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import { Link } from 'react-router-dom'
import allRoute from '../route/route'

const Button = React.memo(MuiButton)

interface PropTypes {
  relatedRecords: AdminPlayerWalletBoundRelatedRecords[]
}

interface RowType {
  id: string
  time: React.ReactElement
  account: React.ReactElement
}

const BoundRelateRecordButton: React.FC<PropTypes> = (props) => {
  const { relatedRecords } = props
  const [open, setOpen] = useState<boolean>(false)
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const { t } = useT()

  const rows = useMemo(() => {
    return relatedRecords.map((item, index) => {
      return {
        id: `${index}-${item.user_id}`,
        time: (<span>{formatDate(item.created_at)} ~ {item.deleted_at ? formatDate(item.deleted_at) : t('common.current')}</span>),
        account: (
          <Link
            to={allRoute.playerDetail.encodePath({ search: {}, param: { id: item.user_id } })}
            target="_blank"
          >
            { item.account }
          </Link>
        )
      } as RowType
    })
  }, [relatedRecords, commonClasses])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: t('common.boundTimeSpan'),
          value: 'time',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        }
      },
      [
        'time',
        'account'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => {
          setOpen(true)
        }, [])}
        className={commonClasses.greyOutlineButton}
      >
        {`${t('common.bind')}${t('common.record')}${relatedRecords.length ? `(${relatedRecords.length})` : ''}`}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {`${t('common.bind')}${t('common.record')}`}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={relatedRecords.length}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' justifyContent='center' spacing={2}>
            <Grid item xs={4}>
              <Button
                fullWidth
                className={commonClasses.blueGradualOutlineButton}
                onClick={() => { setOpen(false) }}
              >{t('common.close')}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(BoundRelateRecordButton)
