import React, { createContext, useState, useMemo, useContext } from 'react'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { ManualWithdrawType, ManualWithdrawForm, EffectiveCash, GDKError } from '@golden/gdk-admin'
import { InitialFormFunc, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getCashInputProps } from '../../../utils/default/FormHelper'
import { RequestContext } from '../../../views/admin/manual/ManualWithdrawCreatePage'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney } from '../../../utils/default/TableHelper'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createCorrectResult, createErrorResult, createValidateCash, createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../default/form/FormField'
import NumberInput from '../../default/form/NumberInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import manualWithdrawName from '../../../constants/default/manualWithdrawName'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'

export interface FormType {
  id: number
  account: string
  method: ManualWithdrawType
  cash: string
  effectiveCash: string
  memo: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  id: 0,
  account: '',
  method: ManualWithdrawType.EVENT,
  cash: '',
  effectiveCash: '',
  memo: '',
  ...defaultForm
})

export const formToRequest = (form: FormType): ManualWithdrawForm => ({
  user_id: form.id,
  method: form.method,
  cash: Number(form.cash),
  deduct_effective_cash_needed: form.effectiveCash,
  memo: form.memo
})

const getValueFromEvent: ValueGetter<FormType> = {
  id: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  cash: getValueFromChangeEvent,
  effectiveCash: getValueFromChangeEvent,
  memo: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<FormType> = {
  account: (value, form) => ({
    ...initialForm(),
    id: form.id,
    account: value
  })
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const cashBetInputProps = {
  ...getCashInputProps(),
  decimalScale: 2,
  maxLength: undefined
}

const memoFormHelperTextProps = { error: true }

interface PropTypes {
  handleSubmit: (form: FormType) => FormType
  handleCancel: () => void
  loading: boolean
}

const ManualWithdrawCreateForm: React.FC<PropTypes> = (props) => {
  const { handleSubmit, handleCancel, loading } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const [context] = useContext(RequestContext)
  const [result, setResult] = useState<EffectiveCash>({
    user_id: 0,
    account: '',
    cash: '0.0000',
    buckets: [],
    is_suspended: false
  })
  const defaultForm = useMemo(() => initialForm({
    account: context.account,
    id: result.user_id,
    effectiveCash: '0.0000'
  }), [context.account, result.user_id])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const payload = useMemo(() => ({ account: context.account }), [context])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.withdraw.getEffectiveCash(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: EffectiveCash) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: (error: GDKError) => {
      pageFlow.setGDKError(error)
    },
    canLoadData: !!payload.account
  })
  const validation = useMemo(() => {
    return {
      id: [],
      account: [],
      method: [
        {
          func: createValidateNotEmpty('method', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      cash: [
        {
          func: createValidateNotEmpty('cash', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateCash('cash', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value) => {
            if (Number(value) === 0) return createErrorResult('cash', t('error.mustNotZero'))
            return createCorrectResult('cash')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      effectiveCash: [
        {
          func: createValidateNotEmpty('effectiveCash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [
        {
          func: createValidateNotEmpty('memo', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const memoInputProps = useMemo(() => ({
    classes: { input: classes.memoField },
    inputProps: {
      placeholder: t('placeholder.inputPlayerMemo')
    }
  }), [classes.memoField, t])
  const options = useMemo(() => {
    return Object.keys(manualWithdrawName)
      .map((key) => key as ManualWithdrawType)
      .map((key) => ({ name: t(manualWithdrawName[key]), value: key }))
  }, [t])
  if (!payload.account || context.isBatch) return null
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={5}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.playerInformation')}
                  </Typography>
                </Box>
              </Grid>
              {result.account
                ? (
                <React.Fragment>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          label={t('common.playerAccount')}
                          value={result.account}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          label={t('common.mainAccount')}
                          value={formatMoney(result.cash)}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, DropDownProps>
                          context={FormContext}
                          component={DropDown}
                          name="method"
                          label={t('common.withdrawObject')}
                          options={options}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="cash"
                          label={t('common.withdrawCash')}
                          inputProps={cashBetInputProps}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormField<FormType, TextFieldProps>
                          context={FormContext}
                          component={NumberInput}
                          name="effectiveCash"
                          label={t('common.removeNeededEffectiveCash')}
                          placeholder={t('placeholder.inputRemoveNeededEffectiveCash')}
                          inputProps={ { ...cashBetInputProps, decimalScale: 4 } }
                          fullWidth
                          required
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>{`${t('common.playerMemo')}*`}</Typography>
                    <FormField<FormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="memo"
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="outlined"
                      InputProps={memoInputProps}
                      FormHelperTextProps={memoFormHelperTextProps}
                    />
                  </Grid>
                  <Grid item>
                    <RequiredText />
                  </Grid>
                </React.Fragment>
                  )
                : (
                <Box display="flex" justifyContent="center" padding={1} overflow="auto">
                  <Typography variant="h3">
                    {t('common.noData')}
                  </Typography>
                </Box>
                  )}
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
      <Box paddingTop={2}>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleCancel}
              className={classes.greyButton}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              disabled={loading}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {loading
                ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={24} />
                </Box>
                  )
                : t('common.confirmCreate')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(ManualWithdrawCreateForm)
