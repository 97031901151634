import React, { useState, useMemo } from 'react'
import { PaginationRes, FeedbackHistory } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import CoreTable from '../../../default/present/CoreTable'
import DateTime from '../../../default/present/DateTime'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../../utils/default/TableHelper'
import { searchToRequest } from './PlayerReportFeedbackForm'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../../utils/default/ComplexFlowHook'
import allRoutes from '../../route/route'
import FeedbackUpdateAtGrid from './FeedbackUpdateAtGrid'
import FeedbackStatusGrid from './FeedbackStatusGrid'
import { getGameName } from '../../../../utils/default/PlatformHelper'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'

interface RowType {
  id: number
  account: string
  createdAt: React.ReactElement
  serialNumber: string
  feedback: string
  status: React.ReactElement
  updatedAt: React.ReactElement
  platform: string
}

const PlayerReportFeedbackMemberTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const games = useGDKStore.platform.games()
  const [list, setList] = useState<PaginationRes<FeedbackHistory[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getFeedbackHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<FeedbackHistory[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.pinkTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        account: item.account,
        createdAt: (<DateTime time={item.created_at} />),
        serialNumber: item.serial_number,
        feedback: item.feedback,
        status: (<FeedbackStatusGrid data={item} />),
        updatedAt: (<FeedbackUpdateAtGrid data={item} />),
        platform: getGameName(item.game_id, games)
      }
    })
  }, [list.data, games])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        createdAt: {
          label: t('common.generatedAt'),
          value: 'createdAt',
          align: 'center'
        },
        serialNumber: {
          label: t('common.orderNumber'),
          value: 'serialNumber',
          align: 'center'
        },
        feedback: {
          label: t('common.feedback'),
          value: 'feedback',
          align: 'center'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center',
          width: 120
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center',
          width: 180
        },
        platform: {
          label: t('common.gamePlatform'),
          value: 'platform',
          align: 'center'
        }
      },
      [
        'account',
        'createdAt',
        'serialNumber',
        'platform',
        'feedback',
        'status',
        'updatedAt'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoutes.playerReportFeedbackHistory.encodePath
  })

  if (request === undefined) return null

  return (
    <>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.total}
              showPagination
              page={request.page}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </>
  )
}

export default React.memo(PlayerReportFeedbackMemberTable)
