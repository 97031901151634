import React, { useState, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { AdminTransferTransactionStatusType, InAndOutTransfer, TransferSearchTimeType, WalletType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { AdminReportInAndOutRequest, searchToRequest } from './AdminReportInAndOutForm'
import { AdminReportInAndOutReport } from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import allRoute from '../route/route'
import { useSnackbar } from 'notistack'
import { mergeArray, range } from '@golden/utils'

const useStyles = makeStyles(() => ({
  special: {
    color: '#01c0c8'
  }
}))

interface RowType {
  id: number
  name: string
  in: string
  out: string
  rollback: React.ReactElement
  failure: React.ReactElement
  error: React.ReactElement
}

const BlueText: React.FC<{
  request: AdminReportInAndOutRequest | undefined
  walletId: number
  in: string
  out: string
  total: string
  status: AdminTransferTransactionStatusType
}> = (props) => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { request, walletId, in: inCash, out, total, status } = props
  return (
    <Tooltip title={
      <React.Fragment>
        <p>{t('common.transferIn')}：{formatMoney(inCash)}</p>
        <p>{t('common.transferOut')}：{formatMoney(out)}</p>
      </React.Fragment>
    }>
      <Typography
        component={Link}
        to={allRoute.playerReportTransfer.encodePath({
          search: {
            search_type: TransferSearchTimeType.FINISHED_AT,
            page: 1,
            start: request?.start_at,
            end: request?.end_at,
            golden_wallet: WalletType.CENTER,
            game_wallet: walletId,
            transaction_status: status
          },
          param: {}
        })}
        className={classes.anchor}
        variant="body2"
        align="right"
      >
        {formatMoney(total)}
      </Typography>
    </Tooltip>
  )
}

const AdminReportInAndOutTransfer: React.FC = () => {
  const [res, setRes] = useState<InAndOutTransfer>([])
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const { enqueueSnackbar } = useSnackbar()
  const request = useRequestFromSearch({ searchToRequest })
  const hide = request?.reports.length !== 0 && !request?.reports.includes(AdminReportInAndOutReport.TRANSFER)
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const platforms = useGDKStore.platform.platforms()
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.greyTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getInAndOutTransfer(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: InAndOutTransfer) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && !hide
  })
  const rows = useMemo(() => {
    return mergeArray(platforms, res, (platform, item) => platform.wallet_id === item.wallet_id)
      .map(([platform, item]) => ({
        ...item,
        id: platform.id,
        name: platform.nameWithStatus,
        in: formatMoney(item.deposits),
        out: formatMoney(item.withdraws),
        rollback: (<BlueText request={request} status={AdminTransferTransactionStatusType.ROLLBACK} walletId={platform.wallet_id} in={item.deposit_rollback} out={item.withdraw_rollback} total={item.rollback} />),
        failure: (<BlueText request={request} status={AdminTransferTransactionStatusType.FAIL} walletId={platform.wallet_id} in={item.deposit_failure} out={item.withdraw_failure} total={item.failure} />),
        error: (<BlueText request={request} status={AdminTransferTransactionStatusType.ERROR} walletId={platform.wallet_id} in={item.deposit_error} out={item.withdraw_error} total={item.error} />)
      }))
  }, [res, platforms, request])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        name: {
          label: t('common.gamePlatform'),
          value: 'name',
          align: 'center'
        },
        in: {
          label: t('common.totalTransferIn'),
          value: 'in',
          align: 'center'
        },
        out: {
          label: t('common.totalTransferOut'),
          value: 'out',
          align: 'center'
        },
        rollback: {
          label: t('common.rollback'),
          value: 'rollback',
          align: 'center'
        },
        failure: {
          label: t('common.failure'),
          value: 'failure',
          align: 'center'
        },
        error: {
          label: t('common.abnormal'),
          value: 'error',
          align: 'center'
        }
      },
      [
        'name',
        'in',
        'out',
        'rollback',
        'failure',
        'error'
      ],
      rows,
      'id'
    )
  }, [t, rows])

  const divRef = useRef<HTMLDivElement | null>(null)
  const handleCopy = () => {
    if (!divRef.current) return
    const totalTransferIn = rows.reduce((pre, cur) => BcMath.base(pre).add(cur.deposits).get(), '0')
    const totalTransferOut = rows.reduce((pre, cur) => BcMath.base(pre).add(cur.withdraws).get(), '0')
    divRef.current.innerHTML = '<table>' +
      `<tr>${rows.map((el) => `<td colspan="2">${el.name}</td>`).join('')}<td colspan="2">${t('common.sum')}</td></tr>` +
      `<tr>${range(0, rows.length + 1).map(() => `<td>${t('common.totalTransferIn')}</td><td>${t('common.totalTransferOut')}</td>`).join('')}</tr>` +
      `<tr>${rows.map((el) => `<td>${el.deposits}</td><td>-${el.withdraws}</td>`).join('')}<td>${totalTransferIn}</td><td>-${totalTransferOut}</td></tr>` +
      '</table>'
    divRef.current.setAttribute('style', 'display: block; color: #000; text-align: center;')
    const docRange = document.createRange()
    const selection = window.getSelection()
    selection?.removeAllRanges()
    docRange.selectNodeContents(divRef.current)
    selection?.addRange(docRange)
    document.execCommand('copy')
    divRef.current.setAttribute('style', 'display: none;')
    enqueueSnackbar(t('common.oneClickCopyDone'), {
      autoHideDuration: 2 * 1000
    })
  }

  if (hide) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          marginBottom={3}
          className={commonClasses.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.transferStatistic')}
          </Typography>
        </Box>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <Box paddingBottom={2}>
            <Grid container justifyContent="space-between">
              <Typography className={clsx(classes.special, commonClasses.bold)} variant="h6">
                {t('common.totalStatistic')}
              </Typography>
              <Button
                onClick={handleCopy}
                className={commonClasses.purpleGradualButton}
              >
                {t('common.accountantCopy')}
              </Button>
              <div
                ref={divRef}
                style={{ display: 'none' }}
              />
            </Grid>
          </Box>
          <CoreTable
            classes={tableClasses}
            data={data}
            total={data.rows.length}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportInAndOutTransfer)
