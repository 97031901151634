import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { EffectiveCashHistorySum, WithdrawPointTarget } from '@golden/gdk-admin'
import { Button, Collapse, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import allRoute from '../route/route'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { getGameName } from '../../../utils/default/PlatformHelper'
import DateTime from '../../default/present/DateTime'
import { BcMath } from '@golden/bcmath'
import Tip from '../Tip'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import withdrawPointTargetName from '../../../constants/admin/withdrawPointTargetName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import EffectiveCashSummaryTable, { PropTypes as EffectiveCashSummaryTableProps, sortBuckets } from './EffectiveCashSummaryTable'
import CalculateEffectiveCashButton from './CalculateEffectiveCashButton'
import PointCell from '../../default/present/PointCell'
import NestedCell from '../../default/present/NestedCell'

export interface PropTypes {
  data: EffectiveCashHistorySum
  classes?: {
    head?: string
    body?: string
    cellHead?: string
    cellBody?: string
    cellFooter?: string
    row?: string
    rowSelect?: string
  }
}

const useStyles = makeStyles({
  rowSelect: {
    backgroundColor: '#f2f2f2'
  },
  shadow: {
    background: 'linear-gradient(to left, white 1%, rgba(255,255,255,0)), linear-gradient(to left, rgba(255,255,255,0), white 99%) 0 100%, radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 50%, radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right, left, right, left',
    backgroundSize: '100px 100%, 100px 100%, 14px 100%, 14px 100%',
    backgroundAttachment: 'local, local, scroll, scroll'
  },
  fixedHeightRow: {
    height: '60px'
  }
})

const InfoBlock: React.FC<{ open: boolean }> = (props) => {
  const { t } = useT()
  const tipsArr = [
    t('dialog.effectiveCashInfo.time'),
    t('dialog.effectiveCashInfo.effectiveCash'),
    t('dialog.effectiveCashInfo.activity'),
    t('dialog.effectiveCashInfo.general'),
    [
      t('dialog.effectiveCashInfo.example1'),
      t('dialog.effectiveCashInfo.example2')
    ]
  ]
  return (
    <Collapse in={props.open} unmountOnExit>
      <Grid
        container
        direction="row"
        spacing={3}
      >
        {tipsArr.map((el, index) => (
          <Grid item xs={6} md={6} lg={3} key={`effectiveCashInfo-${index}`}>
            {typeof el === 'string'
              ? <Tip text={el} color='#000' titleBold />
              : el.map((_el, _index) => <Tip text={_el} color='#000' titleBold key={`effectiveCashInfo-2-${index}`}/>)
            }
          </Grid>
        ))}
      </Grid>
    </Collapse>
  )
}

const EffectiveCashHistorySumTable: React.FC<PropTypes> = (props) => {
  const {
    data
  } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const games = useGDKStore.platform.games()
  const { t } = useT()
  const [open, setOpen] = useState<boolean>(false)
  const tablePayloads: EffectiveCashSummaryTableProps['tablePayloads'] = useMemo(() => {
    return [
      {
        name: t('common.playerAccount'),
        value: data.account
      },
      {
        name: t('common.previousResetTime'),
        width: 110,
        isNested: true,
        value: data.previous_reset_at === null ? '--' : (<DateTime time={data.previous_reset_at } />)
      },
      {
        name: t('common.lastResetTime'),
        width: 140,
        isNested: true,
        value: (
          <>
            <DateTime time={data.last_reset_at} />
            {data.previous_reset_at !== null && <CalculateEffectiveCashButton />}
          </>
        )
      },
      {
        name: t('common.type'),
        width: 80,
        isNested: true,
        value: (<NestedCell info={[t('common.delay'), t('common.hasReach'), t('common.stillRequire'), t('common.difference')]} />)
      },
      ...(sortBuckets(data.buckets).map((bucket) => {
        return {
          name: bucket.target === WithdrawPointTarget.GAME && bucket.target_id ? getGameName(bucket.target_id, games) : t(withdrawPointTargetName[bucket.target]),
          isNested: true,
          value: (<NestedCell info={[
            (<PointCell point={bucket.delayed_withdraw_point} />),
            (<PointCell point={bucket.withdraw_point} />),
            (<PointCell point={bucket.barrier_sum} />),
            (<PointCell point={BcMath.base(bucket.delayed_withdraw_point).add(bucket.withdraw_point).sub(bucket.barrier_sum).get()} showPlusOnPositive isColored />)
          ]} />)
        }
      })),
      {
        name: `${t('common.withdraw')}${t('common.withdrawStatus')}`,
        value: (
          <span className={commonClasses.chipText} style={{ backgroundColor: data.is_withdrawable ? '#72d476' : '#ff5f5f' }}>
            {data.is_withdrawable ? t('common.canWithdraw') : t('common.cannotWithdraw')}
          </span>
        )
      },
      {
        name: t('common.reason'),
        width: 120,
        value: (
          <p className={commonClasses.pre}>
            {data.can_not_withdraw_reasons.length ? data.can_not_withdraw_reasons.join('\n') : '--'}
          </p>
        )
      },
      {
        name: t('common.function'),
        value: (
          <Button
            component={Link}
            to={allRoute.manualEffectiveCashUpdate.encodePath({ param: { account: data.account }, search: {} })}
            className={commonClasses.blueGradualOutlineButton}
            classes={{ disabled: commonClasses.blueGradualOutlineDisabledButton }}
          >
            {t('common.update')}
          </Button>
        )
      }
    ]
  }, [commonClasses, data, t, games])

  if (!data) {
    return (
      <Box display="flex" justifyContent="center" padding={1} overflow="auto">
        <Typography variant="h3">
          {t('common.noData')}
        </Typography>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Box className={classes.shadow}>
        <EffectiveCashSummaryTable tablePayloads={tablePayloads} />
      </Box>
      <Box>
        <Button
          onClick={() => { setOpen((value) => (!value)) }}
          style={{ color: '#169BD5' }}
        >
          { t('common.effectiveCashInfo') }
          { open ? <KeyboardArrowUp /> : <KeyboardArrowDown /> }
        </Button>
        <InfoBlock open={open}/>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(EffectiveCashHistorySumTable)
