import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { DateAtQuery, GameCategoryType, NowTime, PaginationReq } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { setToday, setYesterday, setThisMonth, setLastMonth, setThisWeek, setLastWeek, createShouldDisableDate } from '../../../utils/default/TimeHelper'
import { endOfDay, getTime, isAfter, isBefore, startOfDay, startOfYear, subDays, subMonths, subYears } from 'date-fns'
import { getCategoryShortName } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface PropType {
  category: 0 | GameCategoryType
}

export interface FormType {
  time: DateInputValue
}

export type RequestType = DateAtQuery & PaginationReq

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt
    )(search.end_at)
  }
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as RequestType
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  time: {
    start: subDays(startOfDay(new Date()), 6),
    end: endOfDay(new Date())
  },
  gameId: 0,
  ...defaultForm
})

const formToRequest = (form: FormType): RequestType => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    page: 1
  }
}

const getValueFromEvent: ValueGetter<FormType> = {
  time: getTimeFromDateInputValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const leftBound = subMonths(startOfDay(NowTime.get()), 3)
    return createShouldDisableDate(value.time.start, value.time.end, leftBound)
  }, [value.time])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      },
      {
        label: t('common.thisWeek'),
        change: setThisWeek
      },
      {
        label: t('common.lastWeek'),
        change: setLastWeek
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.today'),
        change: setToday
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

const AdminReportPlayerLeaderboardDetailForm: React.FC<PropType> = (props) => {
  const { category } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const categories = useGDKStore.platform.categories()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        }
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: (option) => allRoute.adminReportPlayerLeaderboardDetail.encodePath({ ...option, param: { ...option.param, id: category } })
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<FormType>), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.leaderboardDetail', { prefix: category === 0 ? t('common.allPlateform') : getCategoryShortName(category, categories) })}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default AdminReportPlayerLeaderboardDetailForm
