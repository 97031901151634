import React, { useCallback } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

interface PropTypes {
  id: number
  serial: string
  reload: () => void
}

const ButtonsBase: React.FC<{
  handleConfirm: () => void
  handleCancel: () => void
}> = React.memo((props) => {
  const { handleConfirm, handleCancel } = props
  const classes = useCommonStyles()
  const { t } = useT()
  return (
    <Grid container direction="column" spacing={1} justifyContent="center">
      <Grid item>
        <Button
          onClick={handleConfirm}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.confirm')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleCancel}
          size="small"
          className={classes.greyOutlineButton}
        >
          {t('common.cancel')}
        </Button>
      </Grid>
    </Grid>
  )
})

const DepositSlipButtons: React.FC<PropTypes> = (props) => {
  const { id, serial, reload } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const handleConfirm = useDialogHandleClick({
    dialogId: `confirmSlip-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography align="center" className={dialogClasses.text}>{t('dialog.confirmDeposit')}</Typography>
          <Typography align="center" className={clsx(dialogClasses.text, classes.purpleWord)}>{`${t('common.orderNumber')}[${serial}]`}</Typography>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.depositSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    payload: id,
    gdkFunc: (payload) => gdk.deposit.confirmDepositSlip(payload, true),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })
  const handleCancel = useDialogHandleClick({
    dialogId: `cancelSlip-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography align="center" className={dialogClasses.text}>{t('dialog.confirmCancel')}</Typography>
          <Typography align="center" className={clsx(dialogClasses.text, classes.purpleWord)}>{`${t('common.orderNumber')}[${serial}]`}</Typography>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.cancelOrderSuccess')
    }),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    payload: id,
    gdkFunc: (payload) => gdk.deposit.confirmDepositSlip(payload, false),
    gdkFuncDependencies: [gdk],
    onSuccess: reload
  })
  return (<ButtonsBase handleConfirm={handleConfirm} handleCancel={handleCancel} />)
}

export default DepositSlipButtons
