import React, { useMemo, useState, useEffect } from 'react'
import clsx from 'clsx'
import { fromUnixTime, lightFormat } from 'date-fns'
import { LockPeriod, FormattedLockPeriod, PlayerReportUserWalletLocksRes, GDKError } from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import { useRequestFromSearch, useGetDataByPayload } from '../../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import CustomThemeType from '../../../../themes/default/CustomThemeType'
import NestedCell from '../../../default/present/NestedCell'
import { getWalletNameByWalletId } from '../../../../utils/default/PlatformHelper'
import { searchToRequest } from './PlayerReportFeedbackForm'

interface PropTypes {
  tablePayloads: Array<{ name: string | React.ReactElement, width?: number, isNested?: boolean, value: string | React.ReactElement }>
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  fourBorder: {
    border: '1px solid rgba(233, 233, 233, 1)'
  },
  fourPadding: {
    padding: theme.spacing(1)
  },
  noPadding: {
    padding: 0
  },
  head: {
    borderTop: '1px solid rgba(233, 233, 233, 1)'
  },
  cellHead: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary
  },
  cellBody: {
    color: theme.palette.text.secondary
  },
  tableBackground: {
    background: '#fff'
  }
}))
const getReportInitialState = () => ({
  user_id: 0,
  account: '',
  data: []
})

const PlayerReportFeedbackLockTable: React.FC = () => {
  const { t } = useT()
  const gdk = useGDK()
  const platforms = useGDKStore.platform.platforms()
  const [report, setReport] = useState<{
    user_id: number
    account: string
    data: Array<{
      wallet_id: number
      lock_periods: FormattedLockPeriod[]
    }>
  }>(getReportInitialState())
  const resetReport = () => {
    setReport(getReportInitialState())
  }
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const request = useRequestFromSearch({ searchToRequest })
  const payload = useMemo(() => ({
    account: request?.account ?? ''
  }), [request])
  const formatHelper = (time: LockPeriod): FormattedLockPeriod => {
    if (time === null) return '-'
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { start_at, end_at } = time
    return {
      start_at: start_at === null ? t('common.notUnlock') : lightFormat(fromUnixTime(start_at), 'yyyy-MM-dd HH:mm:ss'),
      end_at: end_at === null ? t('common.notUnlock') : lightFormat(fromUnixTime(end_at), 'yyyy-MM-dd HH:mm:ss')
    }
  }
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.history.getUserWalletLocks(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: PlayerReportUserWalletLocksRes) => {
      setReport({
        user_id: res.user_id,
        account: res.account,
        data: res.data.map((el) => ({
          wallet_id: el.wallet_id,
          lock_periods: el.lock_periods.map((period) => formatHelper(period))
        }))
      })
    },
    onError: () => {
      resetReport()
    },
    canLoadData: payload.account !== ''
  })
  useEffect(() => {
    if (payload.account === '') {
      resetReport()
    }
  }, [payload.account])
  const sortedReportData = useMemo(() => {
    return report.data.sort((a, b) => {
      const aIndex = platforms.findIndex((el) => el.wallet_id === a.wallet_id)
      const bIndex = platforms.findIndex((el) => el.wallet_id === b.wallet_id)
      return aIndex - bIndex
    })
  }, [report, platforms])
  const walletLockCells = useMemo<Array<{
    name: string
    value: React.ReactElement
    isNested: true
    width: number
  }>>(() => {
    return sortedReportData.map((el) => ({
      name: getWalletNameByWalletId(el.wallet_id, platforms),
      value: (<NestedCell info={el.lock_periods.map((period: FormattedLockPeriod) => {
        if (period === null) return (<Box>-</Box>)
        return (<Box>{typeof period === 'string' ? period : `${period.start_at} - ${period.end_at}`}</Box>)
      })} />),
      isNested: true,
      width: 180
    }))
  }, [report, platforms])
  const tablePayloads: PropTypes['tablePayloads'] = useMemo(() => {
    return [
      {
        name: t('common.playerAccount'),
        value: report.account
      },
      {
        name: t('common.lockPeriod'),
        value: <NestedCell info={[
          (<Box>{t('common.lockPeriodNum', { number: t('common.one') })}</Box>),
          (<Box>{t('common.lockPeriodNum', { number: t('common.two') })}</Box>),
          (<Box>{t('common.lockPeriodNum', { number: t('common.three') })}</Box>)
        ]} />,
        isNested: true
      },
      ...walletLockCells
    ]
  }, [report, walletLockCells, t])
  return (
    <>
      <ScrollablePaper marginX={5}>
        {sortedReportData.length > 0 && <Box padding={4} overflow="auto">
          <Box paddingBottom={4} overflow="auto">
            <Table size='medium' className={classes.fourBorder}>
              <TableHead className={clsx(classes.head, commonClasses.pinkTableHead)}>
                <TableRow className={commonClasses.tableRow}>
                  {tablePayloads.map((el, index) => {
                    return (
                      <TableCell
                        classes={{ head: clsx(classes.cellHead, commonClasses.tableCellHead) }}
                        className={clsx(classes.fourPadding, classes.fourBorder)}
                        align='center'
                        key={`the${index}-rdColumnName`}
                        style={{ minWidth: el.width ?? 'auto' }}
                      >
                        {el.name}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow
                className={commonClasses.tableRow}
              >
                {tablePayloads.map(el => el.value).map((el, index) => {
                  return (
                    <TableCell
                      classes={{ head: clsx(classes.cellBody, commonClasses.pre) }}
                      className={clsx((tablePayloads[index].isNested ? classes.noPadding : classes.fourPadding), classes.fourBorder)}
                      align='center'
                      key={`the${index}-rdColumnContent`}
                      style={{ minWidth: tablePayloads[index].width ?? 'auto' }}
                    >
                      {el}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
            </Table>
          </Box>
          <Box paddingX={4}>
            <Typography color="error">{t('common.lockPeriodDoesNotReturn')}</Typography>
            <Typography color="error">{t('common.afterLockPeriodDoesReturn')}</Typography>
            <Typography color="error">{t('common.returnErrorPleaseProvideId')}</Typography>
          </Box>
        </Box>}
      </ScrollablePaper>
    </>
  )
}

export default React.memo(PlayerReportFeedbackLockTable)
