import React, { createContext, useCallback, useState, useMemo } from 'react'
import { PlayerRankForm, PlayerRankItem, PlayerRankType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleSubmit, useRedirectHandleBack, useGetDataByParams, InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Path } from '../../../components/admin/route/route'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney, formatCount } from '../../../utils/default/TableHelper'
import playerRankName from '../../../constants/default/playerRankName'
import FormStateProvider from '../../../components/default/form/FormStateProvider'
import FormField from '../../../components/default/form/FormField'
import FormSubmitButton from '../../../components/default/form/FormSubmitButton'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import RequiredText from '../../../components/default/form/RequiredText'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import NumberInput from '../../../components/default/form/NumberInput'

export interface EditPlayerRankFormType {
  depositEffectiveCashMultiple: string
  feedbackBarrierMultiple: string
  cashMaxWithdraw: string
  withdrawTimes: string
  dailyCashMaxWithdraw: string
}

export const initialForm: InitialFormFunc<EditPlayerRankFormType> = (defaultForm) => ({
  depositEffectiveCashMultiple: '0',
  feedbackBarrierMultiple: '0',
  cashMaxWithdraw: '0',
  withdrawTimes: '0',
  dailyCashMaxWithdraw: '0',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditPlayerRankFormType> = {
  depositEffectiveCashMultiple: getValueFromChangeEvent,
  feedbackBarrierMultiple: getValueFromChangeEvent,
  cashMaxWithdraw: getValueFromChangeEvent,
  withdrawTimes: getValueFromChangeEvent,
  dailyCashMaxWithdraw: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const moneyInputProps = {
  thousandSeparator: true,
  decimalScale: 0,
  allowNegative: false,
  fixedDecimalScale: true
}

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const formToRequest: (form: EditPlayerRankFormType) => Partial<PlayerRankForm> = (form) => {
  return {
    effective_cash_deposit_multiple: Number(form.depositEffectiveCashMultiple),
    cash_max_withdraw: Number(form.cashMaxWithdraw),
    withdraw_times_daily: Number(form.withdrawTimes),
    cash_max_withdraw_daily: Number(form.dailyCashMaxWithdraw),
    feedback_barrier_multiple: Number(form.feedbackBarrierMultiple)
  }
}

const PlayerRankUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const [id, setId] = useState<number>(0)
  const [rank, setRank] = useState<PlayerRankType>(PlayerRankType.SILVER)
  const [defaultForm, setDefaulForm] = useState<EditPlayerRankFormType>(initialForm())
  const pageFlow = usePageFlow()

  const validation = useMemo(() => {
    return {
      depositEffectiveCashMultiple: [
        {
          func: createValidateNotEmpty('depositEffectiveCashMultiple', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      feedbackBarrierMultiple: [
        {
          func: createValidateNotEmpty('feedbackBarrierMultiple', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      cashMaxWithdraw: [
        {
          func: createValidateNotEmpty('cashMaxWithdraw', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      withdrawTimes: [
        {
          func: createValidateNotEmpty('withdrawTimes', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      dailyCashMaxWithdraw: [
        {
          func: createValidateNotEmpty('dailyCashMaxWithdraw', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<EditPlayerRankFormType>
  }, [t])

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.PLAYER_RANK
  })

  useGetDataByParams({
    path: Path.PLAYER_RANK_UPDATE,
    onBeforeFetch: pageFlow.setLoadingStart,
    gdkFunc: (param: { id: string }) => gdk.player.getPlayerRank(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: PlayerRankItem) => {
      setId(res.id)
      setRank(res.rank)
      setDefaulForm(initialForm({
        depositEffectiveCashMultiple: formatMoney(res.effective_cash_deposit_multiple),
        feedbackBarrierMultiple: formatMoney(res.feedback_barrier_multiple),
        cashMaxWithdraw: formatMoney(res.cash_max_withdraw),
        withdrawTimes: formatCount(res.withdraw_times_daily),
        dailyCashMaxWithdraw: formatMoney(res.cash_max_withdraw_daily)
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })

  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'updatePlayerRank',
    globalDialog,
    getChangeDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmUpdatePlayerRank')}
          <span className={classes.purpleWord}>
            {t(playerRankName[rank])}
          </span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord, rank]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updatePlayerRankSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.player.updatePlayerRank(id, payload),
    gdkFuncDependencies: [gdk, id],
    afterSuccessDialog: handleBack
  })

  return (
    <Box padding={5}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <FormStateProvider
          context={FormContext}
          onSubmit={handleSubmit}
          defaultValue={defaultForm}
          validation={validation}
          getValueFromEvent={getValueFromEvent}
        >
          <Paper>
            <Box padding={4}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Box
                    paddingY={1.25}
                    paddingX={2}
                    className={classes.pinkTitleBar}
                  >
                    <Typography variant="h5">
                      {t('page.updatePlayerRank')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2} wrap="wrap">
                    <Grid item xs={12} md={3}>
                      <TextField
                        label={t('common.rankName')}
                        value={t(playerRankName[rank])}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2} wrap="wrap">
                    <Grid item xs={12} md={3}>
                      <FormField<EditPlayerRankFormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="depositEffectiveCashMultiple"
                        label={t('common.depositEffectiveCashMultiple')}
                        fullWidth
                        required
                        inputProps={moneyInputProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField<EditPlayerRankFormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="feedbackBarrierMultiple"
                        label={t('common.feedbackBarrierMultiple')}
                        fullWidth
                        required
                        inputProps={moneyInputProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField<EditPlayerRankFormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="cashMaxWithdraw"
                        label={t('common.MaxWithdrawCashEachTime')}
                        fullWidth
                        required
                        inputProps={moneyInputProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2} wrap="wrap">
                    <Grid item xs={12} md={3}>
                      <FormField<EditPlayerRankFormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="withdrawTimes"
                        label={t('common.withdrawTimesDaily')}
                        fullWidth
                        required
                        inputProps={moneyInputProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField<EditPlayerRankFormType, TextFieldProps>
                        context={FormContext}
                        component={NumberInput}
                        name="dailyCashMaxWithdraw"
                        label={t('common.MaxWithdrawCashEachDay')}
                        fullWidth
                        required
                        inputProps={moneyInputProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <RequiredText />
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        className={classes.greyButton}
                        onClick={handleDebouncedBack}
                      >
                        {t('common.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <FormSubmitButton
                        context={FormContext}
                        component={Button}
                        type="submit"
                        className={classes.purpleGradualButton}
                      >
                        {t('common.confirmUpdate')}
                      </FormSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </FormStateProvider>
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(PlayerRankUpdatePage)
