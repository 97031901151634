import React, { useState, createContext, useContext, useMemo, useRef, useEffect, useCallback, Dispatch, SetStateAction } from 'react'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload, useReload, InitialFormFunc, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerCenterForm, AdminPlayerCenter, QuestionType, PermissionType } from '@golden/gdk-admin'
import { AdminDivisionsOfChina } from '@golden/pccode-china'
import { ValueGetter, FormValidation, createDefaultFormState, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent, getValueFromValue, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { createValidateBirthday, createValidatePassword } from '../../../utils/default/Validator'
import PlayerInputButton from './PlayerInputButton'
import TKeyType from '../../../i18ns/admin/TKeyType'
import DropDown from '../../default/form/DropDown'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { lightFormat, isAfter, isBefore, parse } from 'date-fns'
import DatePicker from '../../default/form/DatePicker'
interface PropTypes {
  id: number
  section: string
  loaded: Dispatch<SetStateAction<string>>
}

interface FormType {
  loginPassword: string
  isResetQuestion: boolean
  wechatAccount: string
  qqAccount: string
  birthday: Date | null
  provinceId: '--' | number
  cityId: '--' | number
  address: string
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  loginPassword: '******',
  isResetQuestion: false,
  wechatAccount: '',
  qqAccount: '',
  birthday: null,
  provinceId: '--',
  cityId: '--',
  address: '',
  ...defaultForm
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const getValueFromEvent: ValueGetter<FormType> = {
  loginPassword: getValueFromChangeEvent,
  isResetQuestion: getValueFromValue,
  wechatAccount: getValueFromChangeEvent,
  qqAccount: getValueFromChangeEvent,
  birthday: getValueFromValue,
  provinceId: getValueFromChangeEvent,
  cityId: getValueFromChangeEvent,
  address: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<FormType> = {
  provinceId: (value, form) => ({
    ...form,
    provinceId: value,
    cityId: '--'
  })
}

const formToRequest = (form: FormType): AdminPlayerCenterForm => {
  const loginPassword = convertEmptyToUndefined(form.loginPassword)
  const converted = {
    login_password: loginPassword === '******' ? undefined : loginPassword,
    is_reset_question: form.isResetQuestion,
    wechat_account: form.wechatAccount,
    qq_account: form.qqAccount,
    birthday: form.birthday !== null ? lightFormat(form.birthday, 'yyyy-MM-dd') : null,
    province: form.provinceId === '--' ? 0 : form.provinceId,
    city: form.cityId === '--' ? 0 : form.cityId,
    address: form.address
  } as AdminPlayerCenterForm
  return omitBy(converted, isUndefined) as AdminPlayerCenterForm
}

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const LoginPasswordInput: React.FC = React.memo(() => {
  const { value, handleChange, dispatch, error, lastSubmitForm } = useContext(FormContext)
  const { t } = useT()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const [disabled, setDisabled] = useState<boolean>(true)
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!disabled) {
      dispatch({ type: 'change', label: 'loginPassword', value: '' })
      ref.current?.focus()
    }
  }, [disabled])
  const onChange = useCallback(handleChange('loginPassword'), [])
  useEffect(() => {
    setDisabled(true)
  }, [lastSubmitForm])
  return (
    <TextField
      label={t('common.loginPassword')}
      InputProps={{
        inputRef: ref,
        endAdornment: writable && (
          <PlayerInputButton
            title={t('common.update')}
            onClick={handleUpdate}
          />
        )
      }}
      type="password"
      value={value.loginPassword}
      onChange={onChange}
      helperText={error.loginPassword ?? ''}
      error={error.loginPassword !== null}
      disabled={disabled}
      fullWidth
    />
  )
})

const QuestionInput: React.FC<{
  id: number
  isAllowModify: boolean
}> = React.memo((props) => {
  const { id, isAllowModify } = props
  const { t } = useT()
  const { value } = useContext(FormContext)
  return (
    <TextField
      label={t('common.secretQuestion')}
      value={!isAllowModify ? t('common.cantModifyDueNoBind') : (value.isResetQuestion ? t('questionList.0') : t(`questionList.${id}` as TKeyType))}
      disabled
      fullWidth
    />
  )
})

const AnswerInput: React.FC<{
  answer: string
  isAllowModify: boolean
}> = React.memo((props) => {
  const { answer, isAllowModify } = props
  const { t } = useT()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const { value, dispatch } = useContext(FormContext)
  const handleReset = useCallback(() => {
    dispatch({ type: 'change', label: 'isResetQuestion', value: true })
  }, [])
  return (
    <TextField
      InputProps={{
        endAdornment: writable && (
          <PlayerInputButton
            title={t('common.reset')}
            onClick={handleReset}
            disabled={!isAllowModify}
          />
        )
      }}
      value={value.isResetQuestion ? '' : answer}
      disabled
      fullWidth
    />
  )
})

const WechatInput: React.FC<{
  isAllowModify: boolean
}> = React.memo((props) => {
  const { isAllowModify } = props
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const [disabled, setDisabled] = useState<boolean>(true)
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  const onChange = useCallback(handleChange('wechatAccount'), [])
  return (
    <TextField
      label={t('common.wechatAccount')}
      InputProps={{
        inputRef: ref,
        endAdornment: writable && (
          <PlayerInputButton
            title={t('common.update')}
            onClick={handleUpdate}
            disabled={!isAllowModify}
          />
        )
      }}
      InputLabelProps={{ shrink: true }}
      placeholder={!isAllowModify ? t('common.cantModifyDueNoBind') : ''}
      value={value.wechatAccount}
      onChange={onChange}
      disabled={disabled}
      fullWidth
    />
  )
})

const QQInput: React.FC<{
  isAllowModify: boolean
}> = React.memo((props) => {
  const { isAllowModify } = props
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const [disabled, setDisabled] = useState<boolean>(true)
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  const onChange = useCallback(handleChange('qqAccount'), [])
  return (
    <TextField
      label={t('common.qqAccount')}
      InputProps={{
        inputRef: ref,
        endAdornment: writable && (
          <PlayerInputButton
            title={t('common.update')}
            onClick={handleUpdate}
            disabled={!isAllowModify}
          />
        )
      }}
      value={value.qqAccount}
      onChange={onChange}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      placeholder={!isAllowModify ? t('common.cantModifyDueNoBind') : ''}
      fullWidth
    />
  )
})

const BirthdayInput: React.FC<{
  isAllowModify: boolean
}> = React.memo((props) => {
  const { value, handleChange, error } = useContext(FormContext)
  const { t } = useT()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const { isAllowModify } = props
  const [disabled, setDisabled] = useState<boolean>(true)
  const onChange = useCallback(handleChange('birthday'), [])
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const shouldDisableDate = (day: Date | null) => {
    return !day || isBefore(day, new Date(1950, 0, 1)) || isAfter(day, new Date())
  }

  return (
    <DatePicker
      error={error.birthday !== null}
      helperText={error.birthday ?? ''}
      value={value.birthday}
      label={t('common.birthday')}
      onChange={onChange}
      shouldDisableDate={shouldDisableDate}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      initialFocusedDate={new Date(2000, 0, 1)}
      InputProps={{
        endAdornment: writable && (
          <PlayerInputButton
            title={t('common.update')}
            onClick={handleUpdate}
            disabled={!isAllowModify}
          />
        )
      }}
      InputAdornmentProps={{
        position: 'start'
      }}
    />
  )
})

const AddressInput: React.FC<{
  isAllowModify: boolean
}> = React.memo((props) => {
  const { isAllowModify } = props
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const [disabled, setDisabled] = useState<boolean>(true)
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const provinceOptions = useMemo(() => {
    return [
      { name: !isAllowModify ? t('common.cantModifyDueNoBind') : '--', value: '--' } as any
    ].concat(AdminDivisionsOfChina.getProvinces().map((item) => ({ name: item.name, value: item.code })))
  }, [isAllowModify, t])
  const cityOptions = useMemo(() => {
    const defaultOptions = [{ name: '--', value: '--' }]
    if (value.provinceId === '--') {
      return defaultOptions.concat(
        AdminDivisionsOfChina.getCities().map((item) => ({ name: item.name, value: item.code })) as any
      )
    }
    return defaultOptions.concat(
      AdminDivisionsOfChina.getProvinceCities(Number(value.provinceId))
        .map((item) => ({ name: item.name, value: item.code })) as any
    )
  }, [value.provinceId])
  const onProvinceChaneg = useCallback(handleChange('provinceId'), [])
  const onCityChange = useCallback(handleChange('cityId'), [])
  const onAddresssChange = useCallback(handleChange('address'), [])
  return (
    <Grid container direction="row" spacing={2} alignItems="flex-end">
      <Grid item xs={12} md={6} lg={3}>
        <DropDown
          label={t('common.receiveAddress')}
          value={value.provinceId}
          onChange={onProvinceChaneg}
          options={provinceOptions}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DropDown
          label=""
          value={value.cityId}
          onChange={onCityChange}
          options={cityOptions}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          InputProps={{
            endAdornment: writable && (
              <PlayerInputButton
                title={t('common.update')}
                onClick={handleUpdate}
                disabled={!isAllowModify}
              />
            )
          }}
          value={value.address}
          onChange={onAddresssChange}
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </Grid>
  )
})

const PlayerDetailCenterSection: React.FC<PropTypes> = (props) => {
  const { id, section, loaded } = props
  const gdk = useGDK()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CENTER])
  const globalDialog = useGlobalDialog()
  const { reload, reloadFlag } = useReload()
  const pageFlow = usePageFlow()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [isAllowModify, setIsAllowModify] = useState<boolean>(false)
  const [question, setQuestion] = useState<{ id: QuestionType | 0, answer: string }>({
    id: 0,
    answer: ''
  })
  const [defaultForm, setDefaultForm] = useState<FormType>(initialForm())
  const validation = useMemo(() => {
    return {
      loginPassword: [
        {
          func: createValidatePassword('loginPassword', t),
          when: ['change']
        }
      ],
      isResetQuestion: [],
      wechatAccount: [],
      qqAccount: [],
      birthday: [
        {
          func: createValidateBirthday('birthday', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      provinceId: [],
      cityId: [],
      address: []
    } as FormValidation<FormType>
  }, [t])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerCenter(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerCenter) => {
      setIsAllowModify(res.is_allow_modify)
      setQuestion({
        id: res.question_id,
        answer: res.answer
      })
      setDefaultForm(initialForm({
        wechatAccount: res.wechat_account,
        qqAccount: res.qq_account,
        birthday: res.birthday ? parse(res.birthday, 'yyyy-MM-dd', new Date()) : null,
        provinceId: res.province === 0 ? '--' : res.province,
        cityId: res.city === 0 ? '--' : res.city,
        address: res.address
      }))
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'updatePlayerCenter',
    gdkFunc: (payload) => gdk.player.updatePlayerCenter(id, payload),
    gdkFuncDependencies: [gdk, id],
    getChangeDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmPlayerCenter')
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.playerCenterSuccess')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    onSuccess: reload
  })
  return (
    <div id={section}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
        getChangedForm={getChangedForm}
      >
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.playerCenterSetting')}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <LoadingAndErrorFrame { ...pageFlow.status }>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <LoginPasswordInput />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid container direction="row" spacing={2} alignItems="flex-end">
                      <Grid item xs={12} md={6}>
                        <QuestionInput id={question.id} isAllowModify={isAllowModify} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <AnswerInput answer={question.answer} isAllowModify={isAllowModify} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <WechatInput isAllowModify={isAllowModify} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <QQInput isAllowModify={isAllowModify} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <BirthdayInput isAllowModify={isAllowModify} />
                  </Grid>
                </Grid>
                <Box paddingTop={2}>
                  <AddressInput isAllowModify={isAllowModify} />
                </Box>
              </LoadingAndErrorFrame>
            </Box>
            {writable && (
              <Box display="flex" justifyContent="flex-end">
                <FormSubmitButton
                  context={FormContext}
                  component={Button}
                  className={commonClasses.purpleGradualButton}
                  type="submit"
                >
                  {t('common.saveSetting')}
                </FormSubmitButton>
              </Box>
            )}
          </Box>
        </Paper>
      </FormStateProvider>
    </div>
  )
}

export default React.memo(PlayerDetailCenterSection)
