import React, { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { MemoPopoverPayload } from './MemoPopover'
import MemoText from './MemoText'

interface PropTypes {
  memoPopoverContext: React.Context<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>
  memo: string
  children?: any
  classes?: Record<'text', string>
}

const MemoTextWithContext: React.FC<PropTypes> = (props) => {
  const {
    memoPopoverContext: MemoPopoverContext,
    memo,
    children,
    classes
  } = props

  const [, setMemoPopoverPayload] = useContext(MemoPopoverContext)

  const handleMouseEnter = useCallback((event) => {
    setMemoPopoverPayload({
      open: true,
      anchorEl: event.currentTarget,
      memo
    })
  }, [setMemoPopoverPayload, memo])

  const handleMouseLeave = useCallback(
    () => { setMemoPopoverPayload((memoPopoverPayload) => ({ ...memoPopoverPayload, open: false })) },
    [setMemoPopoverPayload]
  )

  return (
    <MemoText
      classes={classes}
      memo={children ?? memo}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

export default React.memo(MemoTextWithContext)
