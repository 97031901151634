import React, { useCallback, useContext } from 'react'
import { FormState } from '../../../utils/default/FormHook'

interface PropTypes<FormType> {
  component: React.ElementType
  name: keyof FormType
  context: React.Context<FormState<FormType>>
  helperText?: string
  preventUndefined?: any
}

const FormField = function<FormType, T> (props: PropTypes<FormType> & T) {
  const { component: Component, name, context, helperText, preventUndefined, ...restProps } = props

  const { value, handleChange, handleOther, error, disabled } = useContext(context)
  const onChange = useCallback(handleChange(name), [])
  const onBlur = useCallback(handleOther('blur', name), [])
  const onFocus = useCallback(handleOther('focus', name), [])

  return (
    <Component
      value={value[name] ?? preventUndefined}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      helperText={error[name] ?? helperText ?? ''}
      error={error[name] !== null}
      disabled={disabled[name]}
      {...restProps}
    />
  )
}

export default FormField
