import React, { useState, useContext, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { AdminPlayerTransaction, NowTime } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { formatDateTime, formatMoney, formatCount } from '../../../utils/default/TableHelper'
import allRoute from '../route/route'
import PointsCell from '../../default/present/PointsCell'
import { InfoContext } from '../../../views/admin/player/PlayerDetailPage'
import PayoutTip from '../PayoutTip'
import Tip from '../Tip'
import { endOfDay, startOfDay, subDays } from 'date-fns'

interface PropTypes {
  id: number
  section: string
  loaded: Dispatch<SetStateAction<string>>
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1)
  },
  head: {
    width: '50%'
  },
  cell: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'right',
    padding: theme.spacing(2)
  }
}))

const PlayerDetailFinanceSection: React.FC<PropTypes> = (props) => {
  const { id, section, loaded } = props
  const [info] = useContext(InfoContext)
  const [res, setRes] = useState<AdminPlayerTransaction>({
    deposit: {
      count: 0,
      cash_sum: '0.0000',
      max: '0.0000',
      last_at: new Date(),
      last_cash: '0.0000'
    },
    withdraw: {
      count: 0,
      cash_sum: '0.0000',
      max: '0.0000',
      last_at: new Date(),
      last_cash: '0.0000',
      withdraw_point: '0.0000',
      barrier_sum: '0.0000'
    },
    bet: {
      effective_cash: '0.0000',
      bet_cash_sum: '0.0000',
      user_revenue: '0.0000'
    }
  })
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const headClassName = clsx(detailClasses.greyHead, classes.head)
  const cellClassName = clsx(detailClasses.cell, classes.cell)
  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerTransaction(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerTransaction) => {
      setRes(res)
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })
  const page = 1
  const account = info.account
  const startAt = subDays(startOfDay(NowTime.get()), 30).getTime()
  const endAt = endOfDay(NowTime.get()).getTime()
  return (
    <div id={section}>
      <Paper>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.depositWithdrawStatistic')}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className={classes.title}>{t('common.depositData')}</Typography>
                  <table className={detailClasses.table}>
                    <tbody>
                      <tr>
                        <td className={headClassName}>{t('common.depositCountSum')}</td>
                        <td className={cellClassName}>{formatCount(res.deposit.count)}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.depositCashSum')}</td>
                        <td className={cellClassName}>{res.deposit.last_at ? formatMoney(res.deposit.cash_sum) : 0}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.depositMaxCash')}</td>
                        <td className={cellClassName}>{res.deposit.last_at && res.deposit.max ? formatMoney(res.deposit.max) : 0}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.lastDepositDate')}</td>
                        <td className={cellClassName}>{res.deposit.last_at ? formatDateTime(res.deposit.last_at) : '--'}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.lastDepositCash')}</td>
                        <td className={cellClassName}>{res.deposit.last_at && res.deposit.last_cash ? formatMoney(res.deposit.last_cash) : 0}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Box paddingTop={2}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.manualDeposit.encodePath({ param: {}, search: { page, account, start_at: startAt, end_at: endAt } })}
                        >
                          {t('page.manualDeposit')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.depositSlip.encodePath({ param: {}, search: { page, user_account: account, created_at_start: startAt, created_at_end: endAt } })}
                        >
                          {t('page.depositSlip')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.depositThirdPartySlip.encodePath({ param: {}, search: { page, account, created_at_start: startAt, created_at_end: endAt } })}
                        >
                          {t('page.thirdPartyDepositSlip')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.agentWalletHistory.encodePath({ param: {}, search: { page, account, start_at: startAt, end_at: endAt } })}
                        >
                          {t('page.agentWallet')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingTop={2}>
                    <PayoutTip color="#808080" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className={classes.title}>{t('common.withdrawData')}</Typography>
                  <table className={detailClasses.table}>
                    <tbody>
                      <tr>
                        <td className={headClassName}>{t('common.withdrawCountSum')}</td>
                        <td className={cellClassName}>{formatCount(res.withdraw.count)}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.withdrawCashSum')}</td>
                        <td className={cellClassName}>{res.withdraw.last_at ? formatMoney(res.withdraw.cash_sum) : 0}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.withdrawMaxCash')}</td>
                        <td className={cellClassName}>{res.withdraw.last_at && res.withdraw.max ? formatMoney(res.withdraw.max) : 0}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.lastWithdrawDate')}</td>
                        <td className={cellClassName}>{res.withdraw.last_at ? formatDateTime(res.withdraw.last_at) : '--'}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.lastWithdrawCash')}</td>
                        <td className={cellClassName}>{res.withdraw.last_at && res.withdraw.last_cash ? formatMoney(res.withdraw.last_cash) : 0}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Box paddingTop={2}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.manualWithdraw.encodePath({ param: {}, search: { page, account, start_at: startAt, end_at: endAt } })}
                        >
                          {t('page.manualWithdraw')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.withdrawalFMSlip.encodePath({ param: {}, search: { page, player_account: account, created_at_start: startAt, created_at_end: endAt } })}
                        >
                          {t('page.withdrawFMManage')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          className={commonClasses.purpleGradualButton}
                          to={allRoute.withdrawalRCSlip.encodePath({ param: {}, search: { page, player_account: account, created_at_start: startAt, created_at_end: endAt } })}
                        >
                          {t('page.withdrawRCManage')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className={classes.title}>{t('common.betData')}</Typography>
                  <table className={detailClasses.table}>
                    <tbody>
                      <tr>
                        <td className={headClassName}>{t('common.allBetCash')}</td>
                        <td className={cellClassName}>{formatMoney(res.bet.bet_cash_sum)}</td>
                      </tr>
                      <tr>
                        <td className={headClassName}>{t('common.playerRevenue')}</td>
                        <td className={cellClassName}>
                          <PointsCell points={res.bet.user_revenue} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Tip text={t('common,betHistoyTip')} />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}

export default React.memo(PlayerDetailFinanceSection)
