import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Autocomplete, { PropTypes as AutocompleteProps } from '../../../components/default/form/Autocomplete'
import BlacklistFormStateProvider, {
  PropTypes as BlacklistFormStateProviderProps,
  initialForm,
  EditBlacklistFormType
} from '../../../components/admin/finance/BlacklistFormStateProvider'
import FormField from '../../../components/default/form/FormField'
import NumberInput from '../../../components/default/form/NumberInput'
import DropDown, { PropTypes as DropDownProps } from '../../../components/default/form/DropDown'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { FormState, createDefaultFormState } from '../../../utils/default/FormHook'
import { useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import EditBlacklistFormSubmitButton from '../../../components/admin/finance/EditBlacklistFormSubmitButton'
import StateProvider from '../../../providers/default/StateProvider'
import useGDK from '../../../providers/admin/gdk/useGDK'
import blacklistMemoOptions from '../../../constants/admin/blacklistMemoOptions'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import { getBankAccountInputProps } from '../../../utils/default/FormHelper'

const defaultFirstForm = initialForm()
const defaultRows = [{
  id: 0,
  context: createContext(createDefaultFormState(defaultFirstForm)),
  defaultValue: defaultFirstForm
}]

const bankAccountInputProps = getBankAccountInputProps()

const realNameInputProps = {
  maxLength: 20
}

interface SubmitButtonContextType { id: number, disabled: boolean, value: EditBlacklistFormType }

const SubmitButtonContext = React.createContext<[
  SubmitButtonContextType[],
  Dispatch<SetStateAction<SubmitButtonContextType[]>>
]>([
  [],
  () => {}
])

const TextField = React.memo(MuiTextField)

const AccountNumberInput: React.FC<{
  context: React.Context<FormState<EditBlacklistFormType>>
}> = React.memo((props) => {
  const { context } = props
  const gdk = useGDK()
  const { value, error, handleChange, dispatch, setError } = useContext(context)
  const [payloads] = useContext(SubmitButtonContext)
  const { t } = useT()

  useEffect(() => {
    if (value.bankAccount !== '' && value.bankAccount.length >= 16) {
      const validationSubscription = gdk.finance.validateBlackListDebitCard({ bank_account: value.bankAccount })
        .subscribe({
          next: (res) => {
            if (!res.result) {
              setError((error) => ({ ...error, bankAccount: t('error.cardMustNotRepeat') }))
            }
          }
        })
      const infoSubscription = gdk.finance.getDebitCardInfo({ bank_account: value.bankAccount })
        .subscribe({
          next: (res) => {
            if (res.bank === 0) {
              dispatch({ type: 'set', value: { ...value, bank: '--', province: '--', city: '--' } })
              return
            }
            dispatch({ type: 'set', value: { ...value, bank: res.bank, province: res.province, city: res.city } })
          },
          error: () => {
            dispatch({ type: 'set', value: { ...value, bank: '--', province: '--', city: '--' } })
          }
        })

      if (payloads.some((item) => item.value.bankAccount === value.bankAccount)) {
        setError((error) => ({ ...error, bankAccount: t('error.cardMustNotRepeat') }))
      }

      return () => {
        validationSubscription.unsubscribe()
        infoSubscription.unsubscribe()
      }
    }
  }, [value.bankAccount, gdk])

  return (
    <NumberInput
      variant="outlined"
      margin="dense"
      fullWidth
      label=""
      placeholder={t('placeholder.inputBankAccount')}
      inputProps={bankAccountInputProps}
      value={value.bankAccount}
      onChange={handleChange('bankAccount')}
      error={error.bankAccount !== null}
      helperText={error.bankAccount ?? ''}
    />
  )
})

const FormContextParser: React.FC<{
  id: number
  context: React.Context<FormState<EditBlacklistFormType>>
  setDisableds: Dispatch<SetStateAction<SubmitButtonContextType[]>>
}> = React.memo((props) => {
  const { id, context, setDisableds } = props
  const { disableSubmit, value } = useContext(context)
  useEffect(() => {
    setDisableds((disableds) => {
      return disableds
        .filter((item) => item.id !== id)
        .concat({ id, disabled: disableSubmit, value })
    })
  }, [disableSubmit, value, id])
  return null
})

const FinanceBlacklistCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const supportBanks = useGDKStore.finance.supportBanks()
  const [rows, setRows] = useState<Array<{ id: number } & BlacklistFormStateProviderProps>>(defaultRows)

  const columns = useMemo(() => {
    return [
      { name: t('common.realName'), width: 200 },
      { name: `${t('common.bankAccount')}*`, width: 'auto' },
      { name: `${t('common.bank')}*`, width: 'auto' },
      { name: `${t('common.memo')}*`, width: 'auto' },
      { name: t('common.function'), width: 'auto' }
    ]
  }, [t])

  const [handleDebouncedBatchCreate] = useDebouncedCallback(() => {
    setRows((rows) => {
      const defaultForm = initialForm()
      return rows.concat({
        id: rows[rows.length - 1].id + 1,
        context: createContext(createDefaultFormState(defaultForm)),
        defaultValue: defaultForm
      })
    })
  }, 500)

  const handleBatchCreate = useCallback(handleDebouncedBatchCreate, [])

  const bankOptions = useMemo(
    () => [{ name: '--', value: '--' }].concat(supportBanks.map((bank) => ({ name: bank.name, value: bank.id })) as any),
    [supportBanks]
  )

  const memoAutocompleteProps = useMemo(() => {
    return {
      freeSolo: true,
      disableClearable: true,
      disableListWrap: true,
      options: blacklistMemoOptions.map((item) => t(item)),
      getOptionDisabled: (option: string) => option === t(blacklistMemoOptions[5])
    }
  }, [t])

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.FINANCE_BLACKLIST
  })

  return (
    <StateProvider
      context={SubmitButtonContext}
      defaultValue={[]}
    >
      <Box paddingY={5}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
              <Button
                className={classes.purpleGradualButton}
                onClick={handleBatchCreate}
              >
                {t('common.batchCreate')}
              </Button>
            </Box>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.createDebitCardBlacklist')}
              </Typography>
            </Box>
            <Box paddingY={3} overflow="auto">
              <Table size="small">
                <TableHead className={classes.blackTableHead}>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        className={clsx(classes.tableCellHead, classes.nowrap)}
                        style={{ width: column.width }}
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <BlacklistFormStateProvider
                      key={row.id}
                      context={row.context}
                      defaultValue={row.defaultValue}
                    >
                      <TableRow className={classes.greyTableRow}>
                        <TableCell align="center">
                          <FormField<EditBlacklistFormType, TextFieldProps>
                            component={TextField}
                            context={row.context}
                            name="realName"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoFocus
                            label=""
                            placeholder={t('placeholder.inputRealName')}
                            inputProps={realNameInputProps}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <AccountNumberInput context={row.context} />
                        </TableCell>
                        <TableCell>
                          <FormField<EditBlacklistFormType, DropDownProps>
                            component={DropDown}
                            context={row.context}
                            name="bank"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label=""
                            options={bankOptions}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormField<EditBlacklistFormType, AutocompleteProps>
                            component={Autocomplete}
                            context={row.context}
                            name="memo"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label=""
                            placeholder={t('placeholder.inputMemo')}
                            autocompleteProps={memoAutocompleteProps}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <SubmitButtonContext.Consumer>
                            {([, setDisaleds]) => (
                              <Button
                                className={classes.pinkGradualOutlineButton}
                                onClick={() => {
                                  setRows((rows) => rows.filter((item) => item.id !== row.id))
                                  setDisaleds((disableds) => disableds.filter((disabled) => disabled.id !== row.id))
                                }}
                                disabled={rows.length === 1}
                              >
                                {t('common.delete')}
                              </Button>
                            )}
                          </SubmitButtonContext.Consumer>
                        </TableCell>
                      </TableRow>
                      <SubmitButtonContext.Consumer>
                        {([, setDisableds]) => (
                          <FormContextParser
                            id={row.id}
                            context={row.context}
                            setDisableds={setDisableds}
                          />
                        )}
                      </SubmitButtonContext.Consumer>
                    </BlacklistFormStateProvider>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </ScrollablePaper>
        <Box paddingY={3} paddingX={5} display="flex" flexDirection="row" justifyContent="flex-end">
          <Box paddingX={2}>
            <Button
              className={classes.greyButton}
              onClick={handleDebouncedBack}
            >
              {t('common.cancel')}
            </Button>
          </Box>
          <SubmitButtonContext.Consumer>
            {([payload]) => {
              const disabled = payload.some((item) => item.disabled)
              const values = payload.map((item) => item.value)
              return (
                <EditBlacklistFormSubmitButton
                  onBack={handleBack}
                  disabled={disabled}
                  values={values}
                />
              )
            }}
          </SubmitButtonContext.Consumer>
        </Box>
      </Box>
    </StateProvider>
  )
}

export default React.memo(FinanceBlacklistCreatePage)
