import React, { useEffect, useMemo, useState } from 'react'
import useGDK from './gdk/useGDK'
import tipAudio from '../../assets/admin/audios/slip-alert.mp3'
import useGDKStore from './gdk/useGDKStore'
import { Branch } from '@golden/gdk-admin'
import { getTime } from 'date-fns'

const TipAudio = new Audio(tipAudio)

const FinanceAlertHandler: React.FC = () => {
  const gdk = useGDK()
  const isLogined = useGDKStore.auth.isLoggedIn()
  const me = useGDKStore.admin.me()
  const [, setCaches] = useState<{
    withdrawsFM: number[]
    withdrawsRC: number[]
  }>({
    withdrawsFM: [],
    withdrawsRC: []
  })

  useEffect(() => {
    gdk.trunk.next([{ branch: Branch.ADMIN_FINANCE_ALERT, openedAt: getTime(new Date()) }])
  }, [isLogined, gdk])

  const hasPermission = useMemo(() => {
    return me &&
      !me?.is_need_authenticator &&
      !me?.is_need_update_password
  }, [me])

  useEffect(() => {
    if (hasPermission) {
      const subscription = gdk.finance.alert.subscribe({
        next: (res) => {
          setCaches((caches) => {
            const withdrawsFM = res.withdraw_ids?.filter((id) => {
              if (caches.withdrawsFM.includes(id)) return false
              return true
            }) ?? []
            const withdrawsRC = res.rc_withdraw_ids?.filter((id) => {
              if (caches.withdrawsRC.includes(id)) return false
              return true
            }) ?? []
            if (withdrawsFM.length + withdrawsRC.length > 0) {
              TipAudio.play()?.catch(() => {})
            }
            return {
              withdrawsFM: caches.withdrawsFM.concat(withdrawsFM),
              withdrawsRC: caches.withdrawsRC.concat(withdrawsRC)
            }
          })
        }
      })
      return () => { subscription.unsubscribe() }
    }
  }, [gdk, hasPermission])

  return null
}

export default React.memo(FinanceAlertHandler)
