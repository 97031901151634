import { useContext } from 'react'
import {
  AuthIsLoggedInContext,
  AuthIsWrongContext,
  AdminMeContext,
  SupportBankContext,
  ForwarderContext,
  PlayerLayerContext,
  ActivityContext,
  WithdrawSlipAccountingContext
} from './GDKStoreProvider'
import { PlatformContext } from '../../../utils/default/PlatformHelper'
import { GameStatus } from '@golden/gdk-admin'

const isLoggedIn = () => useContext(AuthIsLoggedInContext)
const isWrong = () => useContext(AuthIsWrongContext)
const me = () => useContext(AdminMeContext)
const supportBanks = () => useContext(SupportBankContext)
const forwarders = () => useContext(ForwarderContext)
const platformGame = () => useContext(PlatformContext)
const layers = () => useContext(PlayerLayerContext)
const activities = () => useContext(ActivityContext)
const withdrawSlipAccounting = () => useContext(WithdrawSlipAccountingContext)

const useGDKStore = {
  auth: {
    isLoggedIn,
    isWrong
  },
  admin: {
    me
  },
  finance: {
    supportBanks,
    forwarders
  },
  platform: {
    categories: () => platformGame().categories,
    platforms: () => platformGame().platforms,
    alivePlatforms: () => platformGame().alivePlatforms,
    games: () => platformGame().games,
    aliveGames: () => platformGame().aliveGames
  },
  player: {
    layers
  },
  activity: {
    activities
  },
  withdraw: {
    withdrawSlipAccounting
  }
}

export default useGDKStore
