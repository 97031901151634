import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useInternValue } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import adminPermissionGroup, { PermissionItem } from '../../../constants/admin/adminPermissionGroup'
import TKeyType from '../../../i18ns/admin/TKeyType'
import useT from '../../../i18ns/admin/useT'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { makeStyles } from '@material-ui/core/styles'
import { PermissionType, Role } from '@golden/gdk-admin'
import clsx from 'clsx'
import { useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { FormContext } from './EditRoleForm'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'

const useStyles = makeStyles((theme) => ({
  white: {
    color: theme.palette.common.white
  }
}))

export interface PropTypes {
  defaultValue?: number[]
  value?: number[]
  onChange?: (value: number[]) => void
}

interface RowType {
  id: number
  title: string
  readable: React.ReactElement
  writable: React.ReactElement
  children: Array<{
    title: string
    readable: React.ReactElement
    writable: React.ReactElement
  }>
}

const Section: React.FC<{
  name: TKeyType
  items: PermissionItem[]
  onChange: (value: number[]) => void
  permissions: PermissionType[]
  selectablePermissions: PermissionType[]
}> = React.memo((props) => {
  const { name, items, onChange, permissions, selectablePermissions } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const { t } = useT()

  useEffect(() => {
    onChange(permissions.filter((permission) => selectablePermissions.some((selectable) => selectable === permission)))
  }, [selectablePermissions])

  const selectableItems = useMemo(() => items
    .filter((item) => selectablePermissions.includes(item.readable))
    .map((item) => ({
      ...item,
      writable: item.writable.filter((el) => selectablePermissions.includes(el.value)),
      children: item.children
        ? item.children
          .filter((child) => selectablePermissions.includes(child.readable))
          .map((child) => ({ ...child, writable: child.writable.filter((el) => selectablePermissions.includes(el.value)) }))
        : undefined
    })
    ), [items, selectablePermissions])

  const isWritableAll = useMemo(() => selectableItems.every((item) => {
    return item.writable.every(({ value }) => permissions.includes(value)) &&
      (item.children ?? []).every((item) => item.writable.every(({ value }) => permissions.includes(value)))
  }), [selectableItems, permissions])
  const isReadableAll = useMemo(() => selectableItems.every((item) => {
    return permissions.includes(item.readable) &&
      (item.children ?? []).every((child) => permissions.includes(child.readable))
  }), [selectableItems, permissions])
  const allWritablePermissions = useMemo(() => items.map((item) => [
    ...(item.children ?? []).map((child) => child.writable.map((el) => el.value)).reduce((pre, cur) => [...pre, ...cur], []),
    ...item.writable.map((el) => el.value)
  ]).reduce((pre, cur) => [...pre, ...cur], []), [items])
  const allReadablePermissions = useMemo(() => items.map((item) => [
    item.readable,
    ...(item.children ?? []).map((child) => child.readable)
  ]).reduce((pre, cur) => [...pre, ...cur], []), [items])
  const allReadablePermissionsWithWritable = useMemo(() => items.map((item) => [
    ...item.writable.length ? [item.readable] : [],
    ...(item.children ?? []).filter((child) => child.writable.length).map((child) => child.readable)
  ]).reduce((pre, cur) => [...pre, ...cur], []), [items])

  const rows: RowType[] = useMemo(() => selectableItems.map((item, index) => {
    return ({
      id: index,
      title: t(item.name),
      readable: (
        <OnOffCheckbox
          label={t('common.look')}
          value={permissions.includes(item.readable)}
          onChange={(event) => {
            const value = event.target.checked
            if (value) {
              onChange([...permissions, item.readable])
              return
            }
            const writable = item.writable.map((el) => el.value)
            onChange(permissions.filter((permission) => permission !== item.readable && !writable.includes(permission)))
          }}
        />
      ),
      writable: (
        <Grid container spacing={1}>
          {item.writable.map((el) => (
            <Grid item key={el.value}>
              <OnOffCheckbox
                key={el.value}
                label={t(el.name)}
                value={permissions.includes(el.value)}
                onChange={(event) => {
                  const value = event.target.checked
                  if (value) {
                    onChange([...new Set([...permissions, item.readable, el.value])])
                    return
                  }
                  onChange(permissions.filter((permission) => permission !== el.value))
                }}
              />
            </Grid>
          ))}
        </Grid>
      ),
      children: item.children
        ? item.children.map((child) => ({
          title: t(child.name),
          readable: (
            <OnOffCheckbox
              label={t('common.look')}
              value={permissions.includes(child.readable)}
              onChange={(event) => {
                const value = event.target.checked
                if (value) {
                  onChange([...permissions, child.readable])
                  return
                }
                const writable = child.writable.map((el) => el.value)
                onChange(permissions.filter((permission) => permission !== child.readable && !writable.includes(permission)))
              }}
            />
          ),
          writable: (
            <Grid container spacing={1}>
              {child.writable.map((el) => (
                <Grid item key={el.value}>
                  <OnOffCheckbox
                    key={el.value}
                    label={t(el.name)}
                    value={permissions.includes(el.value)}
                    onChange={(event) => {
                      const value = event.target.checked
                      if (value) {
                        onChange([...new Set([...permissions, child.readable, el.value])])
                        return
                      }
                      onChange(permissions.filter((permission) => permission !== el.value))
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )
        }))
        : []
    })
  }), [selectableItems, onChange, permissions, t])

  if (!selectableItems.length) return null
  return (
    <Box paddingY={2}>
      <Grid container>
        <Grid item container spacing={1} style={{ color: '#fff', backgroundColor: '#4c4658', marginBottom: 8 }}>
          <Grid item container lg={3} md={3} xs={3} alignItems="center">
            <Box
              paddingX={1}
              paddingY={1.5}
              className={clsx(commonClasses.bold, classes.white)}
            >
              {t(name)}
            </Box>
          </Grid>
          <Grid item lg={2} md={2} xs={2}>
            <OnOffCheckbox
              color="default"
              classes={{
                root: classes.white,
                checked: classes.white
              }}
              label={t('common.selectAll')}
              value={isReadableAll}
              onChange={(event) => {
                const value = event.target.checked
                if (value) {
                  onChange([...new Set([...permissions, ...allReadablePermissions])])
                  return
                }
                onChange(permissions.filter((permission) => !allReadablePermissions.includes(permission) && !allWritablePermissions.includes(permission)))
              }}
            />
          </Grid>
          { selectableItems.some((el) => el.writable.length > 0) && (<Grid item lg={7} md={7} xs={7}>
            <OnOffCheckbox
              color="default"
              classes={{
                root: classes.white,
                checked: classes.white
              }}
              label={t('common.selectAll')}
              value={isWritableAll}
              onChange={(event) => {
                const value = event.target.checked
                if (value) {
                  onChange([...new Set([...permissions, ...allReadablePermissionsWithWritable, ...allWritablePermissions])])
                  return
                }
                onChange(permissions.filter((permission) => !allWritablePermissions.includes(permission)))
              }}
            />
          </Grid>) }
        </Grid>
        { rows.map((row) => (
          <>
            <Grid item container spacing={1} key={row.id}>
              <Grid item lg={3} md={3} xs={3}>
                <Box paddingX={1} paddingY={2}>
                  {row.title}
                </Box>
              </Grid>
              <Grid item lg={2} md={2} xs={2}>
                {row.readable}
              </Grid>
              <Grid item lg={7} md={7} xs={7}>
                {row.writable}
              </Grid>
            </Grid>
            { row.children.map((child, i) => (
              <Grid item container spacing={1} key={i}>
                <Grid item lg={3} md={3} xs={3}>
                  <Box paddingX={1} paddingY={2} style={{ textIndent: '1.5em' }}>
                    {child.title}
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} xs={2}>
                  {child.readable}
                </Grid>
                <Grid item lg={7} md={7} xs={7}>
                  {child.writable}
                </Grid>
              </Grid>
            )) }
          </>
        )) }
      </Grid>
    </Box>
  )
})

const ManagerPermissionEditTable: React.FC<PropTypes> = (props) => {
  const { defaultValue, value, onChange } = props
  const { value: form } = useContext(FormContext)
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const [internValue, setInternValue] = useInternValue(defaultValue ?? value ?? [], value)
  const handleChange = useCallback((value) => {
    setInternValue(value)
    if (onChange) onChange(value)
  }, [onChange, setInternValue])

  const [selectablePermissions, setSelectablePermissions] = useState<PermissionType[]>([])
  useGetDataByPayload({
    payload: form.parentId,
    gdkFunc: (payload) => gdk.permissionRole.getRole(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: Role) => {
      setSelectablePermissions(res.permissions)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  return (
    <React.Fragment>
      {
        adminPermissionGroup
          .map((section, i) => (
            <Section
              key={i}
              name={section.name}
              items={section.items}
              onChange={handleChange}
              permissions={internValue}
              selectablePermissions={selectablePermissions}
            />
          ))
      }
    </React.Fragment>
  )
}

export default React.memo(ManagerPermissionEditTable)
