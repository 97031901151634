import React, { useState, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import CoreDialog from '../../default/dialogs/CoreDialog'
import Autocomplete from '../../default/form/Autocomplete'
import blacklistMemoOptions from '../../../constants/admin/blacklistMemoOptions'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const memoInputProps = {
  maxLength: 10
}

const Button = React.memo(MuiButton)

interface PropTypes {
  id: number
  cardId: number
  account: string
  isBlock: boolean
  reload: () => void
}

const DebitCardBlockButton: React.FC<PropTypes> = (props) => {
  const { id, cardId, account, isBlock, reload } = props
  const [open, setOpen] = useState<boolean>(false)
  const [reason, setReason] = useState<string>('')
  const globalDialog = useGlobalDialog()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const memoAutocompleteProps = useMemo(() => {
    return {
      freeSolo: true,
      disableClearable: true,
      disableListWrap: true,
      options: blacklistMemoOptions.map((item) => t(item)),
      getOptionDisabled: (option: string) => option === t(blacklistMemoOptions[5])
    }
  }, [t])
  const handleChange = useCallback((value: any) => {
    setReason(value)
  }, [])
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    setOpen(false)
    gdk.player.setPlayerDebitCardBlacklist(id, cardId, reason)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.addBlacklistSuccess')
          }))
          globalDialog.setOpen({
            id: `addBlacklistSuccess-${cardId}`,
            value: true,
            isOK: false
          })
          reload()
        },
        error: (error) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: `addBlacklistFail-${cardId}`,
            value: true,
            isOK: false
          })
        }
      })
  }, [gdk, id, cardId, reason]), 200)
  const onOK = useCallback(handleDebouncedOK, [])
  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => { setOpen(true) }, [])}
        disabled={isBlock}
        className={commonClasses.greenGradualOutlineButton}
      >
        {t('common.addBlacklist')}
      </Button>
      <CoreDialog
        open={open}
        notUseTypo
        message={(
          <React.Fragment>
            <Typography className={dialogClasses.text}>{t('dialog.confirmAddBlacklist')}</Typography>
            <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>[{account}]</Typography>
            <Box marginTop={3} width={400}>
              <Autocomplete
                label={t('common.inputAddBlacklistReason')}
                placeholder={t('placeholder.chooseReasonOrInput')}
                autocompleteProps={memoAutocompleteProps}
                inputProps={memoInputProps}
                value={reason}
                onChange={handleChange}
                fullWidth
              />
            </Box>
          </React.Fragment>
        )}
        showOK
        showCancel
        disabledOK={reason === ''}
        onOK={onOK}
        onCancel={useCallback(() => { setOpen(false) }, [])}
        okButtonClasses={{ root: dialogClasses.okButton }}
        cancelButtonClasses={{ root: dialogClasses.cancelButton }}
      />
    </React.Fragment>
  )
}

export default React.memo(DebitCardBlockButton)
