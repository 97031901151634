import React, { useMemo } from 'react'
import clsx from 'clsx'
import { GameCategoryType, GameStatus } from '@golden/gdk-admin'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiTextField from '@material-ui/core/TextField'
import publisherStatusName from '../../../constants/admin/publisherStatusName'
import CustomThemeType from '../../../themes/admin/CustomThemeType'
import useT from '../../../i18ns/admin/useT'
import { getCategoryName } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  root: (props: { selected: boolean, disabled?: boolean }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.custom.palette.primary} solid 1px`,
    borderRadius: theme.shape.borderRadius,
    background: props.selected ? theme.custom.palette.button.blueGreen : theme.palette.common.white,
    color: props.selected ? theme.palette.common.white : theme.custom.palette.primary,
    cursor: props.disabled ? 'auto' : 'pointer',
    '&:hover': props.disabled
      ? {}
      : {
          boxShadow: `0 0 2px ${theme.custom.palette.primary}`
        },
    minWidth: 300
  }),
  text: {
    display: 'flex',
    flexGrow: 1,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1)
  },
  buttons: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  button: {
    padding: theme.spacing(0.25),
    maxWidth: theme.spacing(6),
    minWidth: theme.spacing(6),
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 0
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  white: {
    color: `${theme.palette.common.white} !important`
  },
  whiteUnderline: {
    color: `${theme.palette.common.white} !important`,
    borderBottom: '2px solid #fff',
    '&:before': {
      borderBottom: 'none !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }
  },
  memo: {
    '& > *': {
      color: `${theme.palette.common.white} !important`,
      borderBottom: '2px solid #fff',
      '&:before': {
        borderBottom: 'none !important'
      },
      '&:after': {
        borderBottom: 'none !important'
      }
    }
  },
  memoInput: {
    '&::placeholder': {
      color: '#154647'
    }
  }
}))

interface PropTypes {
  gameId: number
  text: string
  selected: boolean
  state: GameStatus
  category: GameCategoryType
  memo: string
  forbidTransfer: boolean
  onClick: (gameId: number) => void
  onStateChange: (gameId: number, state: GameStatus) => void
  onMemoChange: (gameId: number, memo: string) => void
  disabled?: boolean
}

const TextField = React.memo(MuiTextField)

const GameButton: React.FC<PropTypes> = (props) => {
  const classes = useStyles(props)
  const theme = useTheme()
  const { t } = useT()
  const categories = useGDKStore.platform.categories()
  const { gameId, selected, text, state, category, memo, forbidTransfer, onClick, onStateChange, onMemoChange, disabled } = props
  const colorMap: { [key in GameStatus]: string } = {
    [GameStatus.POPULAR]: '#fb9678',
    [GameStatus.ONLINE]: '#057fdf',
    [GameStatus.MAINTENANCE]: '#b79657',
    [GameStatus.STOP_SALE]: '#979797',
    [GameStatus.REMOVED]: ''
  }

  const forbidEnterGame = useMemo(() => [GameStatus.MAINTENANCE, GameStatus.STOP_SALE].includes(state), [state])
  const forbidBetHistory = useMemo(() => state === GameStatus.STOP_SALE, [state])

  return (<>
    <div className={classes.root} onClick={() => {
      if (!disabled) onClick(gameId)
    }}>
      <div className={classes.row}>
        <div className={classes.text}>
          {text}
        </div>
        <div className={classes.buttons}>
          {Object.keys(publisherStatusName)
            .map((key) => Number(key) as GameStatus)
            .filter((key) => (key !== GameStatus.REMOVED))
            .map((key) => (
              <Button
                disabled={disabled}
                key={key}
                className={classes.button}
                style={{
                  background: key === state ? colorMap[key] : 'rgba(256, 256, 256, 0.4)',
                  border: key === state || selected ? 'none' : `${colorMap[key]} solid 1px`,
                  color: key === state || selected ? theme.palette.common.white : colorMap[key]
                }}
                onClick={() => { onStateChange(gameId, key) }}
              >
                {t(publisherStatusName[key])}
              </Button>
            ))
          }
        </div>
      </div>
      {
        state === GameStatus.MAINTENANCE && (
          <Box display='flex' flexGrow={1} flexDirection='row' alignItems='center' marginTop={1.5}>
            <TextField
              disabled={disabled}
              className={clsx({ [classes.memo]: selected })}
              label=""
              placeholder={t('placeholder.inputGameMemo')}
              fullWidth
              value={memo}
              onChange={(event) => { onMemoChange(gameId, event.target.value) }}
              inputProps={{
                className: clsx({ [classes.memoInput]: selected })
              }}
            />
          </Box>
        )
      }
      <Box paddingX={1} marginTop={2} textAlign="center">
        { getCategoryName(category, categories) }
      </Box>
    </div>
    {(forbidEnterGame || forbidBetHistory || forbidTransfer) && (<Box marginTop={1}>
      <Typography color="error">{forbidEnterGame && (t('common.forbidEnterGame'))} {forbidBetHistory && (t('common.forbidBetHistory'))} {forbidTransfer && (t('common.forbidTransfer'))}</Typography>
    </Box>)}
  </>)
}

export default React.memo(GameButton)
