import React, { useState, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import DropDown from '../../default/form/DropDown'
import RequiredText from '../../default/form/RequiredText'
import PlayerLayerAccountInput from './PlayerLayerAccountInput'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

interface PropTypes {
  layers: Array<{ id: number, name: string }>
  reload: () => void
}

const PlayerLayerSetButton: React.FC<PropTypes> = (props) => {
  const { layers, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const globalDialog = useGlobalDialog()
  const [open, setOpen] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<Array<{ name: string, value: number }>>([])
  const [layerId, setLayerId] = useState<number>(-2)
  const layerOptions = useMemo(() => {
    return layers.map((item) => ({
      name: item.name,
      value: item.id
    })).concat({ name: t('common.systemAuto'), value: -1 })
  }, [layers, t])
  const handleAccountChange = useCallback((value: Array<{ name: string, value: number }>) => { setAccounts(value) }, [])
  const handleLayerIdChange = useCallback((event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => { setLayerId(event.target.value as number) }, [])
  const handleClose = useCallback(() => {
    setAccounts([])
    setLayerId(-2)
    setOpen(false)
  }, [])
  const payload = useMemo(() => {
    return {
      player_ids: accounts.map(({ value }) => value),
      layer_id: layerId
    }
  }, [accounts, layerId])
  const handleSubmit = useDialogHandleClick({
    dialogId: 'setLayer',
    globalDialog,
    payload,
    gdkFunc: (payload) => {
      setOpen(false)
      if (payload.layer_id === -1) {
        return gdk.player.unlockPlayerLayer(payload)
      } else {
        return gdk.player.lockPlayerLayer(payload)
      }
    },
    gdkFuncDependencies: [gdk],
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.title, commonClasses.pre)}>
                {t('dialog.confirmSetPlayerLayer')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={clsx(dialogClasses.text, commonClasses.pre)}>
                {t('dialog.playerLayerContent', { layer: layerOptions.find((layer) => layer.value === layerId)?.name ?? '' })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.setPlayerLayerSuccess')
    }),
    getFailDialogConfig: (error) => {
      handleClose()
      return createGlobalDialogConfig({
        variant: 'error',
        showIcon: true,
        showCancel: false,
        message: error
      })
    },
    afterSuccessDialog: () => {
      reload()
      handleClose()
    }
  })
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        className={commonClasses.purpleGradualButton}
        onClick={useCallback(() => { setOpen(true) }, [])}
      >
        {t('common.setPlayerLayer')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('common.setPlayerLayer')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <PlayerLayerAccountInput
                  value={accounts}
                  onChange={handleAccountChange}
                />
              </Grid>
              <Grid item>
                <DropDown
                  label={t('common.playerLayer')}
                  options={layerOptions}
                  value={layerId}
                  onChange={handleLayerIdChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={handleClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                disabled={accounts.length === 0 || layerId === -2}
                onClick={handleSubmit}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default React.memo(PlayerLayerSetButton)
