import React, { useState, useMemo } from 'react'
import { CashAccountingUserStatistic } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { searchToRequest } from './PlayerReportCashAccountingForm'
import CoreTable from '../../../default/present/CoreTable'
import PointsCell from '../../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles, useDialogStyles } from '../../../../utils/admin/StyleHook'
import { makeStyles } from '@material-ui/core'
import CustomThemeType from '../../../../themes/default/CustomThemeType'

const useStyles = makeStyles((theme: CustomThemeType) => ({
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  }
}))

interface UserStatisticRowType {
  id: number
  account: string
  cashWinning: string
  buybackAmount: string
  feedback: string
  activity: React.ReactElement | string
  riskAdjustment: string
  deposit: string
  agentTransfer: string
  transfer: string
  other: string
  betCash: string
  withdraw: string
  donate: string
  cashAccounting: React.ReactElement
}

const ActivityDetailDialog: React.FC<{
  open: boolean
  list: Array<{
    title: string
    cash: string
  }>
  handleOk: () => void
}> = (props) => {
  const { open, list, handleOk } = props
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows = useMemo(() => list.map((item, index) => ({
    id: index,
    title: item.title,
    cash: (<PointsCell showSign points={item.cash} />)
  })), [list])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        title: {
          label: t('common.activityName'),
          value: 'title',
          align: 'center',
          width: 250
        },
        cash: {
          label: t('common.totalCash'),
          value: 'cash',
          align: 'center',
          width: 150
        }
      },
      [
        'title',
        'cash'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle className={classes.title}>{t('common.allActivityCash')}</DialogTitle>
      <DialogContent dividers>
        <CoreTable
          data={data}
          total={rows.length}
          classes={tableClasses}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button onClick={handleOk} classes={{ root: dialogClasses.okButton }}>
            {t('common.confirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const UserStatisticTable: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const [res, setRes] = useState<CashAccountingUserStatistic>({
    account: '',
    cash_winning: '0.0000',
    buyback_amount: '0.0000',
    feedback: '0.0000',
    activity: '0.0000',
    activity_details: [],
    risk_adjustment: '0.0000',
    deposit: '0.0000',
    agent_transfer: '0.0000',
    transfer_log: '0.0000',
    other: '0.0000',
    bet_cash: '0.0000',
    withdraw: '0.0000',
    donate: '0.0000',
    cash_accounting: '0.0000'
  })
  const pageFlow = usePageFlow()
  const payload = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: payload!,
    gdkFunc: (payload) => gdk.history.getCashAccountingUserStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: CashAccountingUserStatistic) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: payload !== undefined
  })

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const rows: UserStatisticRowType[] = useMemo(() => [res].map((item) => ({
    id: 0,
    account: item.account,
    cashWinning: formatMoney(item.cash_winning),
    buybackAmount: formatMoney(item.buyback_amount),
    feedback: formatMoney(item.feedback),
    activity: item.activity_details.length > 0
      ? (<span
        className={commonClasses.anchor}
        onClick={() => { setIsDialogOpen(true) }}
      >
        {formatMoney(item.activity)}
      </span>)
      : formatMoney(item.activity),
    riskAdjustment: formatMoney(item.risk_adjustment),
    deposit: formatMoney(item.deposit),
    agentTransfer: formatMoney(item.agent_transfer),
    transfer: formatMoney(item.transfer_log),
    other: formatMoney(item.other),
    betCash: formatMoney(item.bet_cash),
    withdraw: formatMoney(item.withdraw),
    donate: formatMoney(item.donate),
    cashAccounting: (<PointsCell points={item.cash_accounting} />)
  })), [res, commonClasses.anchor])
  const data = useMemo(() => {
    return createTableData<UserStatisticRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        cashWinning: {
          label: t('common.winningCash'),
          value: 'cashWinning',
          align: 'center'
        },
        buybackAmount: {
          label: t('common.totalBuyback'),
          value: 'buybackAmount',
          align: 'center'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'center'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'center'
        },
        riskAdjustment: {
          label: t('common.riskAdjustment'),
          value: 'riskAdjustment',
          align: 'center'
        },
        deposit: {
          label: t('common.allDepositCash'),
          value: 'deposit',
          align: 'center'
        },
        agentTransfer: {
          label: t('common.transferPoint'),
          value: 'agentTransfer',
          align: 'center'
        },
        transfer: {
          label: t('common.transfer'),
          value: 'transfer',
          align: 'center'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'center'
        },
        betCash: {
          label: t('common.allBetCash'),
          value: 'betCash',
          align: 'center'
        },
        withdraw: {
          label: t('common.allWithdrawalCash'),
          value: 'withdraw',
          align: 'center'
        },
        donate: {
          label: t('common.donate'),
          value: 'donate',
          align: 'center'
        },
        cashAccounting: {
          label: t('common.cashAccount'),
          value: 'cashAccounting',
          align: 'center'
        }
      },
      [
        'account',
        'cashWinning',
        'buybackAmount',
        'feedback',
        'activity',
        'riskAdjustment',
        'deposit',
        'agentTransfer',
        'transfer',
        'other',
        'betCash',
        'withdraw',
        'donate',
        'cashAccounting'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  if (payload === undefined) return null
  return (
    <>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={rows.length}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <ActivityDetailDialog
        list={res.activity_details}
        open={isDialogOpen}
        handleOk={() => {
          setIsDialogOpen(false)
        }}
      />
    </>
  )
})

export default React.memo(UserStatisticTable)
