import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../components/admin/route/route'
import useGDK from '../../providers/admin/gdk/useGDK'

const LogoutPage: React.FC = () => {
  const gdk = useGDK()
  const navigate = useNavigate()
  const handleRedirect = useCallback(() => {
    navigate(Path.LOGIN)
  }, [navigate])
  useEffect(() => {
    const subscription = gdk.auth.logout().subscribe({ next: handleRedirect, error: handleRedirect })
    return () => { subscription.unsubscribe() }
  }, [gdk, handleRedirect])
  return null
}

export default React.memo(LogoutPage)
