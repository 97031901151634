import React, { useRef, useState, useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import PlayerInputButton from './PlayerInputButton'
import { useInternValue } from '../../../utils/default/FormHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogStyles } from '../../../utils/admin/StyleHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { PermissionType } from '@golden/gdk-admin'

interface PropTypes {
  id: number
  email: string
  reload: () => void
  disabled?: boolean
}

const PlayerEmailInput: React.FC<PropTypes> = (props) => {
  const { id, email, reload, disabled: disabledButton } = props
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_INFO_EMAIL])
  const [value, setValue] = useInternValue<string>(email, email)
  const [disabled, setDisabled] = useState<boolean>(true)
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const handleUpdate = useCallback(() => { setDisabled(false) }, [])
  const handleValidate = useCallback(() => {
    gdk.player.updateEmail(id, value).subscribe({
      next: () => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          notUseTypo: true,
          showCancel: false,
          message: (
            <Box whiteSpace="pre">
              <Typography className={dialogClasses.text} align="center">
                {t('dialog.emailValidate')}
              </Typography>
            </Box>
          )
        }))
        globalDialog.setOpen({
          id: 'validateEmail',
          value: true,
          isOK: false
        })
        setDisabled(true)
        reload()
      },
      error: (error) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          variant: 'error',
          message: error.message
        }))
        globalDialog.setOpen({
          id: 'setEmailFail',
          value: true,
          isOK: false
        })
      }
    })
  }, [gdk, id, value])
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!disabled) ref.current?.focus()
  }, [disabled])
  return (
    <TextField
      label={t('common.email')}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputRef: ref,
        endAdornment: writable && (
          <PlayerInputButton
            title={disabled ? t('common.update') : t('common.validate')}
            onClick={disabled ? handleUpdate : handleValidate}
            disabled={disabledButton}
          />
        )
      }}
      placeholder={disabledButton ? t('common.cantModifyDueNoBind') : ''}
      value={disabled ? value || '-' : value}
      onChange={useCallback((event) => {
        setValue(event.target.value)
      }, [])}
      disabled={disabled}
      fullWidth
    />
  )
}

export default React.memo(PlayerEmailInput)
