import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { CompanyStatistic, PlayerStatistic } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import AdminReportCompanyStatisticForm, { searchToRequest } from '../../../components/admin/adminReport/AdminReportCompanyStatisticForm'
import AdminReportCompanyStatisticCash from '../../../components/admin/adminReport/AdminReportCompanyStatisticCash'
import AdminReportCompanyStatisticCount from '../../../components/admin/adminReport/AdminReportCompanyStatisticCount'
import AdminReportCompanyStatisticPlayerCash from '../../../components/admin/adminReport/AdminReportCompanyStatisticPlayerCash'

const AdminReportCompanyStatisticPage: React.FC = () => {
  const gdk = useGDK()
  const request = useRequestFromSearch({ searchToRequest })
  const [result, setResult] = useState<CompanyStatistic | PlayerStatistic>({
    overview: [],
    people: []
  })
  const pageFlow = usePageFlow()
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getCompanyStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: CompanyStatistic | PlayerStatistic) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportCompanyStatisticForm request={request} />
      </Box>
      <LoadingAndErrorFrame error={null} loading={false} showContent={!pageFlow.status.showContent}>
        <Box paddingX={5} paddingY={3}>
          <Paper>
            <Box padding={5}>
              <LoadingAndErrorFrame { ...pageFlow.status }></LoadingAndErrorFrame>
            </Box>
          </Paper>
        </Box>
      </LoadingAndErrorFrame>
      {request && (
        <LoadingAndErrorFrame { ...pageFlow.status } error={null} loading={false}>
          {request && !request.account && result && (result as CompanyStatistic).overview && (
            <Box marginTop={3}>
              <AdminReportCompanyStatisticCash request={request} result={result as CompanyStatistic} />
            </Box>
          )}
          {request && !request.account && result && (result as CompanyStatistic).overview && (result as CompanyStatistic).people && (
            <Box marginTop={3}>
              <AdminReportCompanyStatisticCount request={request} result={result as CompanyStatistic} />
            </Box>
          )}
          {request?.account && result && (result as PlayerStatistic).overview && (
            <Box marginTop={3}>
              <AdminReportCompanyStatisticPlayerCash request={request} result={result as PlayerStatistic} />
            </Box>
          )}
        </LoadingAndErrorFrame>
      )}
    </Box>
  )
}

export default React.memo(AdminReportCompanyStatisticPage)
