import React, { useState, useCallback, useContext, Dispatch, SetStateAction, useMemo } from 'react'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import RequiredText from '../../default/form/RequiredText'
import { ContextType } from '../../../views/admin/manual/ManualDepositCreatePage'
import FileInputButton from '../FileInputButton'

interface PropTypes {
  context: React.Context<[ContextType, Dispatch<SetStateAction<ContextType>>]>
  loading: boolean
}

const PlayerSearchForm: React.FC<PropTypes> = (props) => {
  const { context, loading } = props
  const [state, setState] = useContext(context)
  const [account, setAccount] = useState<string>('')
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const csvTemplate = useMemo(() => {
    const titles = [t('common.account'), t('common.cash'), `${t('common.memo')}(${t('common.stringMaximum', { amount: 255 })})`]
    return `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(titles.join(','))}`
  }, [t])

  return (
    <Paper>
      <Box padding={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid>
                  <Typography variant="h5">
                    {t('common.playerSearch')}
                  </Typography>
                </Grid>
                <Grid>
                  <a
                    href={csvTemplate}
                    download={`${t('common.template')}.csv`}
                    className={commonClasses.anchor}
                  >
                    {t('common.downloadCsvTemplate')}
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label={t('common.playerAccount')}
                  placeholder={t('placeholder.inputPlayerAccount')}
                  value={account}
                  onChange={useCallback((event) => { setAccount(event.target.value) }, [])}
                  fullWidth
                  required
                />
              </Grid>
              <Grid container item xs={12} md={6} lg={3} justifyContent="flex-end" alignItems="flex-end">
                <FileInputButton
                  loading={loading}
                  onChange={useCallback((file) => {
                    setState({ ...state, file, isBatch: true })
                  }, [setState, state])}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <RequiredText />
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={useCallback(() => {
                  setState({ ...state, account, isBatch: false })
                }, [account])}
                disabled={account === '' || loading}
              >
                {t('common.search')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(PlayerSearchForm)
