import React, { useState, useMemo, createContext, Dispatch, SetStateAction, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { omitBy, isUndefined } from '@golden/utils'
import { PaginationReq, PaginationRes, PlayerRankType, RankCategoryType, RankEffectiveBetRecord, RankEffectiveBetRecordReq, RankEffectiveBetRecordType, RankEffectiveBetStatusType, RankEffectiveBetSummary, RankLogRecordReq, RankLogRecordRes } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { searchToRequest } from './PlayerRankEffectiveBetForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useDialogHandleClick, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import playerRankName from '../../../constants/default/playerRankName'
import rankEffectiveBetStatusColor from '../../../constants/admin/rankEffectiveBetStatusColor'
import rankEffectiveBetRecordTypeName from '../../../constants/admin/rankEffectiveBetRecordTypeName'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import DateTime from '../../default/present/DateTime'
import { Link } from 'react-router-dom'
import allRoute from '../../admin/route/route'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import rankCategoryTypeName from '../../../constants/admin/rankCategoryTypeName'

interface SummaryRowType {
  id: number
  account: string
  rank: string
  accumulatedRankEffectiveBet: string
  status: React.ReactElement
  recoverAmount: string
  keepAmount: String
  function: React.ReactElement
}

interface EffectiveBetRowType {
  id: number
  type: string
  before: string
  adjustment: string
  after: string
  note: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
  function: React.ReactElement | string | null
}

interface RankRowType {
  id: number
  type: React.ReactElement
  before: string
  after: string
  memo: React.ReactElement
  updatedAt: React.ReactElement
  updatedBy: string
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const RevokeButton: React.FC<{
  id: number
  reload: () => void
}> = React.memo((props) => {
  const { id, reload } = props
  const gdk = useGDK()
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()

  const handleClick = useDialogHandleClick({
    dialogId: `revokeRankEffectiveBet-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmRevoke')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.revokeSuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.player.revokeDeductedRankEffectiveBet(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })
  return (
    <Button
      onClick={handleClick}
      className={commonClasses.blueGradualOutlineButton}
      classes={{ disabled: commonClasses.blueGradualOutlineDisabledButton }}
    >
      {t('common.revoke')}
    </Button>
  )
})

const PlayerRankEffectiveBetTable: React.FC = () => {
  const { reload, reloadFlag } = useReload()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const writable = useChecker()

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])

  const [res, setRes] = useState<
  (PaginationRes<RankEffectiveBetRecord[]> & RankEffectiveBetSummary) |
  (PaginationRes<RankLogRecordRes[]> & RankEffectiveBetSummary)
  >({
      ...createDefaultPaginationData([]),
      user_id: 0,
      account: '',
      rank: PlayerRankType.SILVER,
      rank_effective_bet: '0.0000',
      rank_status: {
        status: RankEffectiveBetStatusType.SUCCESS,
        content: ''
      },
      reached_recover_amount: '0.0000',
      required_recover_amount: '0.0000',
      keep_amount: '0.0000',
      keep_threshold: '0.0000'
    })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const effectiveBetPayload = useMemo(() => {
    const data = {
      account: request?.account,
      types: request?.effective_bet_types,
      start_at: request?.start_at,
      end_at: request?.end_at,
      operator: request?.effective_bet_operator,
      note: request?.effective_bet_memo,
      page: request?.page
    } as PaginationReq & RankEffectiveBetRecordReq
    return omitBy(data, isUndefined) as PaginationReq & RankEffectiveBetRecordReq
  }, [request])
  useGetDataByPayload({
    payload: effectiveBetPayload,
    gdkFunc: (payload) => gdk.player.getRankEffectiveBetRecords(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<RankEffectiveBetRecord[]> & RankEffectiveBetSummary) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && request.tab === 2
  })

  const rankPayload = useMemo(() => {
    const data = {
      account: request?.account,
      types: request?.rank_types,
      start_at: request?.start_at,
      end_at: request?.end_at,
      operator: request?.rank_operator,
      memo: request?.rank_memo,
      page: request?.page
    } as PaginationReq & RankLogRecordReq
    return omitBy(data, isUndefined) as PaginationReq & RankLogRecordReq
  }, [request])
  useGetDataByPayload({
    payload: rankPayload,
    gdkFunc: (payload) => gdk.player.getRankLog(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<RankLogRecordRes[]> & RankEffectiveBetSummary) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined && request.tab === 1
  })

  const summaryRows: SummaryRowType[] = useMemo(() => [res].map((item) => ({
    id: 0,
    account: item.account,
    rank: t(playerRankName[res.rank]),
    accumulatedRankEffectiveBet: formatMoney(item.rank_effective_bet),
    status: (<span style={{ color: rankEffectiveBetStatusColor[item.rank_status.status] }}>
      {item.rank_status.content}
    </span>),
    recoverAmount: `${formatMoney(item.reached_recover_amount)} / ${formatMoney(item.required_recover_amount)}`,
    keepAmount: `${formatMoney(item.keep_amount)} / ${formatMoney(item.keep_threshold)}`,
    function: (
      <Button
        component={Link}
        to={allRoute.playerRankEffectiveBetUpdate.encodePath({ param: { account: item.account }, search: { ...request } })}
        className={commonClasses.blueGradualOutlineButton}
        classes={{ disabled: commonClasses.blueGradualOutlineDisabledButton }}
      >
        {t('common.update')}
      </Button>
    )
  })), [res, t, request, commonClasses.blueGradualOutlineButton, commonClasses.blueGradualOutlineDisabledButton])
  const summaryData = useMemo(() => {
    return createTableData<SummaryRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        rank: {
          label: t('common.currentPlayerRank'),
          value: 'rank',
          align: 'center'
        },
        accumulatedRankEffectiveBet: {
          label: t('common.accumulatedRankEffectiveBet'),
          value: 'accumulatedRankEffectiveBet',
          align: 'center'
        },
        recoverAmount: {
          label: t('common.reachedAndRequiredRankEffectiveBet'),
          value: 'recoverAmount',
          align: 'center'
        },
        status: {
          label: t('common.upgradeStatus'),
          value: 'status',
          align: 'center'
        },
        keepAmount: {
          label: t('common.keepAmountAndThreshold'),
          value: 'keepAmount',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        }
      },
      writable
        ? [
            'account',
            'rank',
            'accumulatedRankEffectiveBet',
            'recoverAmount',
            'status',
            'keepAmount',
            'function'
          ]
        : [
            'account',
            'rank',
            'accumulatedRankEffectiveBet',
            'recoverAmount',
            'status',
            'keepAmount'
          ],
      summaryRows,
      'id'
    )
  }, [summaryRows, t, writable])

  const rankRows: RankRowType[] = useMemo(() => (res.data as RankLogRecordRes[]).map((item) => ({
    id: item.id,
    type: (<span style={{ color: item.type === RankCategoryType.UPGRADE ? 'green' : 'red' }}>{t(rankCategoryTypeName[item.type])}</span>),
    before: t(playerRankName[item.before_rank]),
    after: t(playerRankName[item.after_rank]),
    memo: (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />),
    updatedAt: (<DateTime time={item.updated_at} />),
    updatedBy: item.updated_by
  } as RankRowType)), [res, t])
  const rankData = useMemo(() => {
    return createTableData<RankRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: t('common.upgradeAndDowngrade'),
          value: 'type',
          align: 'center'
        },
        before: {
          label: t('common.beforeRank'),
          value: 'before',
          align: 'center'
        },
        after: {
          label: t('common.afterRank'),
          value: 'after',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        }
      },
      [
        'type',
        'before',
        'after',
        'memo',
        'updatedAt',
        'updatedBy'
      ],
      rankRows,
      'id'
    )
  }, [rankRows, t])

  const effectiveBetRows: EffectiveBetRowType[] = useMemo(() => (res.data as RankEffectiveBetRecord[]).map((item) => ({
    id: item.id,
    type: t(rankEffectiveBetRecordTypeName[item.type]),
    before: formatMoney(item.before_amount),
    adjustment: formatMoney(item.adjustment),
    after: formatMoney(item.after_amount),
    note: (<MemoTextWithTitleAndContext context={MemoContext} title={item.note} memo={item.note} />),
    updatedAt: (<DateTime time={item.updated_at} />),
    updatedBy: item.updated_by,
    function: item.type === RankEffectiveBetRecordType.DEDUCT
      ? item.is_revoked
        ? t('common.revoked')
        : (<RevokeButton id={item.id} reload={reload} />)
      : null
  })), [res, t, reload])
  const effectiveBetData = useMemo(() => {
    return createTableData<EffectiveBetRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: t('common.operationType'),
          value: 'type',
          align: 'center'
        },
        before: {
          label: t('common.beforeAdjustment'),
          value: 'before',
          align: 'center'
        },
        adjustment: {
          label: t('common.adjustment'),
          value: 'adjustment',
          align: 'center'
        },
        after: {
          label: t('common.afterAdjustment'),
          value: 'after',
          align: 'center'
        },
        note: {
          label: t('common.memo'),
          value: 'note',
          align: 'center'
        },
        updatedAt: {
          label: t('common.updateAt'),
          value: 'updatedAt',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center'
        }
      },
      writable
        ? [
            'type',
            'before',
            'adjustment',
            'after',
            'note',
            'updatedAt',
            'updatedBy',
            'function'
          ]
        : [
            'type',
            'before',
            'adjustment',
            'after',
            'note',
            'updatedAt',
            'updatedBy'
          ],
      effectiveBetRows,
      'id'
    )
  }, [effectiveBetRows, t, writable])

  const navigate = useNavigate()

  const handleTabChange = useCallback((_: any, tab: number) => {
    if (pageFlow) pageFlow.setLoadingStart()
    navigate(allRoute.playerRankEffectiveBet.encodePath({ search: { ...request, page: 1, tab: tab + 1 }, param: {} }))
  }, [navigate, request])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.playerRankEffectiveBet.encodePath
  })

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box marginBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={summaryData}
                total={summaryRows.length}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <MemoPopoverWithContext memoPopoverContext={MemoContext}>
        <ScrollablePaper marginX={5}>
          <Tabs value={request.tab - 1} onChange={handleTabChange}>
            <Tab label={t('common.upgradeAndDowngradeRecord')} />
            <Tab label={t('common.effectiveBetRecord')} />
          </Tabs>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              {request.tab === 1 && (
                <CoreTable
                  showPagination
                  page={request.page}
                  onChangePage={handlePagination}
                  classes={tableClasses}
                  data={rankData}
                  total={res.total}
                />
              )}
              {request.tab === 2 && (
                <CoreTable
                  showPagination
                  page={request.page}
                  onChangePage={handlePagination}
                  classes={tableClasses}
                  data={effectiveBetData}
                  total={res.total}
                />
              )}
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </MemoPopoverWithContext>
    </React.Fragment>
  )
}

export default React.memo(PlayerRankEffectiveBetTable)
