import React, { useMemo, createContext } from 'react'
import { PaginationReq, ActivityManagementListQuery, ActivityManagementStatus, ActivityModuleType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getValueFromChangeEvent, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import activityManagementStatusName from '../../../constants/admin/activityManagementStatusName'
import { isUndefined, omitBy } from '@golden/utils'

export type ActivityManagementListRequest = PaginationReq & ActivityManagementListQuery

export interface ActivityManagementFormType {
  title: string
  activity_type: string
  layout_setting_type: ActivityModuleType | '--'
  status: ActivityManagementStatus | '--'
  is_carousel_active: boolean | '--'
  is_guest_active: boolean | '--'
  is_my_active: boolean | '--'
}

export const searchToRequest: SearchToRequestFunc<ActivityManagementListRequest> = (search) => {
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page)
  } as ActivityManagementListRequest
  return omitBy(converted, isUndefined) as ActivityManagementListRequest
}

export const initialForm: InitialFormFunc<ActivityManagementFormType> = (defaultForm) => ({
  title: '',
  activity_type: '--',
  layout_setting_type: '--',
  status: '--',
  is_carousel_active: '--',
  is_guest_active: '--',
  is_my_active: '--',
  ...defaultForm
})

const formToRequest = (form: ActivityManagementFormType): ActivityManagementListRequest => {
  const converted = {
    title: convertEmptyToUndefined(form.title),
    activity_type: form.activity_type === '--' ? undefined : form.activity_type,
    layout_setting_type: form.layout_setting_type === '--' ? undefined : form.layout_setting_type,
    status: form.status === '--' ? undefined : form.status,
    is_carousel_active: form.is_carousel_active === '--' ? undefined : form.is_carousel_active,
    is_guest_active: form.is_guest_active === '--' ? undefined : form.is_guest_active,
    is_my_active: form.is_my_active === '--' ? undefined : form.is_my_active,
    page: 1
  } as ActivityManagementListRequest
  return omitBy(converted, isUndefined) as ActivityManagementListRequest
}

const getValueFromEvent: ValueGetter<ActivityManagementFormType> = {
  title: getValueFromChangeEvent,
  activity_type: getValueFromChangeEvent,
  layout_setting_type: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  is_carousel_active: getValueFromChangeEvent,
  is_guest_active: getValueFromChangeEvent,
  is_my_active: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const ActivityManagementForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        title: request.title,
        activity_type: request.activity_type,
        layout_setting_type: request.layout_setting_type,
        status: request.status,
        is_carousel_active: request.is_carousel_active,
        is_guest_active: request.is_guest_active,
        is_my_active: request.is_my_active
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.activityManagement.encodePath
  })

  const validation = useMemo(() => ({
    title: [],
    activity_type: [],
    layout_setting_type: [],
    status: [],
    is_carousel_active: [],
    is_guest_active: [],
    is_my_active: []
  } as FormValidation<ActivityManagementFormType>), [])

  const activityTypeOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('activity.activity'), value: 'activity' },
      { name: t('activity.banner'), value: 'banner' }
    ]
  }, [t])
  const moduleOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: `${t('common.none')}${t('common.applyModule')}`, value: ActivityModuleType.NONE },
      { name: t('common.singleActivity'), value: ActivityModuleType.SINGLE },
      { name: t('common.game2'), value: ActivityModuleType.GAME }
    ]
  }, [t])
  const statusOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' }
    ].concat(
      Object.keys(activityManagementStatusName)
        .map((key) => ({ name: t(activityManagementStatusName[key as ActivityManagementStatus]), value: key }))
    )
  }, [t])
  const isActiveOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('common.toggleOn'), value: 1 },
      { name: t('common.toggleOff'), value: 0 }
    ]
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={2}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction-xs-column justifyContent="space-between" spacing={2}>
              <Grid item xs={12} md={10} >
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="title"
                      label={t('common.title')}
                      placeholder={t('placeholder.inputTitle')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="activity_type"
                      options={activityTypeOptions}
                      label={t('common.activityType')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="layout_setting_type"
                      options={moduleOptions}
                      label={t('common.applyModule')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="status"
                      options={statusOptions}
                      label={t('common.orderStatus2')}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="is_carousel_active"
                      options={isActiveOptions}
                      label={t('common.homeCarousel')}
                      fullWidth
                    />
                  </Grid><Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="is_guest_active"
                      options={isActiveOptions}
                      label={t('common.showBeforeLogin')}
                      fullWidth
                    />
                  </Grid><Grid item xs={12} md={3}>
                    <FormField<ActivityManagementFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="is_my_active"
                      options={isActiveOptions}
                      label={t('common.my')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ActivityManagementForm)
