import React, { useMemo, useState, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { useGetData, useDialogHandleClick } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AnnouncementPersonalContent } from '@golden/gdk-admin'
import { createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { renderContent } from '@golden/tiptap-react'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import AnnouncementPersonalTemplateDialog from './AnnouncementPersonalTemplateDialog'
import { useAsyncEffect } from '../../../utils/default/useAsyncEffect'

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    width: 360,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  content: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: 'solid 1px #e7e7e7',
    width: '500px'
  }
}))

interface RowType {
  id: number
  content: React.ReactElement
  title: string
  operation: React.ReactElement
}

const DeleteButton: React.FC<{ id: number, reload: () => void }> = (props) => {
  const { id, reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteAnnouncementPersonalTemplate-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.confirmDelete')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.notification.deletePersonalNotificationTemplate(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  return (
    <Button
      className={commonClasses.pinkGradualOutlineButton}
      onClick={handleDelete}
    >
      {t('common.delete')}
    </Button>
  )
}

const EditButton: React.FC<{ id: number, reload: () => void }> = (props) => {
  const { id, reload } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const [open, setOpen] = useState(false)
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <React.Fragment>
      <Button
        className={commonClasses.blueGradualOutlineButton}
        onClick={useCallback(() => { setOpen(true) }, [setOpen])}
      >
        {t('common.edit')}
      </Button>
      <AnnouncementPersonalTemplateDialog title={t('common.editTemplate')} isEdit id={id} open={open} close={close} reload={reload} />
    </React.Fragment>
  )
}

const Buttons: React.FC<{ id: number, reload: () => void, isEdit: boolean }> = ({ id, reload, isEdit }) => {
  const commonClasses = useCommonStyles()
  const { t } = useT()

  if (isEdit) {
    return (
      <Grid container direction="row" spacing={1} justifyContent="center">
        <Grid item>
          <DeleteButton id={id} reload={reload} />
        </Grid>
        <Grid item>
          <EditButton id={id} reload={reload} />
        </Grid>
      </Grid>
    )
  }

  return (
    <Button
      component={Link}
      to={allRoute.forestagePersonalCreateByTemplate.encodePath({ search: {}, param: { id } })}
      size="small"
      className={commonClasses.blueGradualOutlineButton}
    >
      {t('common.select')}
    </Button>
  )
}

const AnnouncementPersonalTemplateTable: React.FC<{ isEdit: boolean, reload: () => void, reloadFlag: boolean }> = ({ isEdit, reload, reloadFlag }) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const { t } = useT()
  const [list, setList] = useState<Array<AnnouncementPersonalContent & { id: number }>>([])

  useGetData({
    gdkFunc: () => gdk.notification.getPersonalNotificationTemplateList(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const [rows, setRows] = useState<RowType[]>([])
  useAsyncEffect(async (mounted) => {
    if (!mounted) return
    setRows(await Promise.all(list.map(async (entry) => {
      const content = await renderContent(entry.content)
      return {
        id: entry.id,
        title: entry.title,
        content: (
          <Box
            className={clsx(classes.ellipsis)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ),
        operation: (<Buttons isEdit={isEdit} id={entry.id} reload={reload} />)
      } as RowType
    })))
  }, [list, classes.ellipsis, isEdit, reload])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        title: {
          label: t('common.title'),
          value: 'title',
          align: 'left'
        },
        content: {
          label: t('common.content'),
          value: 'content',
          align: 'left',
          width: 360
        },
        operation: {
          label: t('common.operation'),
          value: 'operation',
          align: 'center'
        }
      },
      [
        'title',
        'content',
        'operation'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      cellHead: commonClasses.tableCellHead,
      row: commonClasses.tableRow
    }
  }, [commonClasses.pinkTableHead, commonClasses.tableCellHead, commonClasses.tableRow])

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={4}>
        <Box paddingY={2}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={list.length}
              page={1}
              loading={pageFlow.status.loading}
            />
          </LoadingAndErrorFrame>
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AnnouncementPersonalTemplateTable)
