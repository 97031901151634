import React, { createContext, useMemo, useCallback, useContext } from 'react'
import { getTime, startOfDay, endOfDay, subYears } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { searchToRequest, Request } from '../../../views/admin/forestage/AnnouncementPersonalPage'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { convertEmptyToUndefined, getValueFromChangeEvent, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { setToday, setYesterday, setThisMonth, setLastMonth, createShouldDisableDate } from '../../../utils/default/TimeHelper'

export interface AnnouncementPersonalFormType {
  time: DateInputValue
  account: string
  title: string
}

const initialForm: InitialFormFunc<AnnouncementPersonalFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  account: '',
  title: '',
  ...defaultForm
})

const formToRequest = (form: AnnouncementPersonalFormType): Request => {
  const converted: Request = {
    start_at: form.time.start === null ? getTime(startOfDay(new Date())) : getTime(form.time.start),
    end_at: form.time.end === null ? getTime(endOfDay(new Date())) : getTime(form.time.end),
    account: convertEmptyToUndefined(form.account),
    title: convertEmptyToUndefined(form.title),
    page: 1
  }
  return omitBy(converted, isUndefined) as Request
}

const getValueFromEvent: ValueGetter<AnnouncementPersonalFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  title: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const tenYearsAgo = startOfDay(subYears(value.time.start ?? new Date(), 10))
    return createShouldDisableDate(value.time.start, value.time.end, tenYearsAgo)
  }, [value.time])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AnnouncementPersonalForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultValue = useMemo(() => {
    if (request !== undefined) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.forestagePersonal.encodePath
  })
  const formValidation: FormValidation<AnnouncementPersonalFormType> = useMemo(() => {
    return {
      time: [],
      type: [],
      account: [],
      title: []
    }
  }, [])

  return (
    <Paper>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('page.announcementPersonal')}
          </Typography>
        </Box>
        <FormStateProvider
          context={FormContext}
          defaultValue={defaultValue}
          onSubmit={handleSubmit}
          getValueFromEvent={getValueFromEvent}
          validation={formValidation}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<AnnouncementPersonalFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<AnnouncementPersonalFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="title"
                    label={t('common.title')}
                    placeholder={t('placeholder.inputTitle')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end">
                <FormSubmitButton
                  className={classes.purpleGradualButton}
                  context={FormContext}
                  type="submit"
                  component={Button}
                >
                  {t('common.search')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </FormStateProvider>
      </Box>
    </Paper>
  )
}

export default React.memo(AnnouncementPersonalForm)
