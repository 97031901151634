import { lightFormat } from 'date-fns'
import { Column, Row, Align } from '../../components/default/present/CoreTable'

export type Cell = Record<string, any>

export type ColumnCollection<T extends Cell> = { [key in keyof T]: Omit<Column<keyof T>, 'groupItems'> & { groupItems?: Array<keyof T> } }

export function createTableData<T extends Cell> (
  columnData: ColumnCollection<T>,
  columnPosition: Array<keyof T>,
  rowData: T[],
  rowKey: string
): {
    columns: Array<Column<string>>
    rows: Array<Row<string>>
  } {
  const columns = columnPosition.map((key) => ({
    label: columnData[key].label,
    value: key as string,
    align: columnData[key].align as Align,
    columnAlign: columnData[key].columnAlign as Align,
    width: columnData[key].width,
    canSort: columnData[key].canSort,
    groupItems: columnData[key].groupItems?.map((key) => columnData[key])
  })) as Array<Column<string>>

  const rows = rowData.map((row, index) => {
    const items = new Map(
      Object.keys(row).map((key) => ([key, row[key as keyof T]]))
    )
    return {
      key: (row[rowKey] as string | number) || index,
      items
    }
  })

  return {
    columns,
    rows
  }
}

export function getPerPage () {
  return 25
}

export function createDefaultPaginationData<T> (defaultData: T): {
  current_page: number
  data: T
  first_page_url: string
  from: number
  last_page: number
  last_page_url: string
  next_page_url: string
  path: string
  per_page: number
  prev_page_url: string
  to: number
  total: number
} {
  return {
    current_page: 0,
    data: defaultData,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: getPerPage(),
    prev_page_url: '',
    to: 0,
    total: 0
  }
}

export function formatMoney (value: string | number): string {
  const result = new Intl.NumberFormat('en', { minimumFractionDigits: 4 }).format(Number(value))
  return result
}

export function formatCount (value: number): string {
  const roundedValue = Number(value).toFixed(0)
  const result = new Intl.NumberFormat().format(Number(roundedValue))
  return result
}

export function formatDateTime (value: Date): string {
  return lightFormat(value, 'yyyy-MM-dd HH:mm:ss')
}

export function formatTime (value: Date): string {
  return lightFormat(value, 'HH:mm:ss')
}

export function formatSimpleTime (value: Date): string {
  return lightFormat(value, 'HH:mm')
}

export function formatDate (value: Date): string {
  return lightFormat(value, 'yyyy-MM-dd')
}
