import React, { useContext, Dispatch, SetStateAction, useCallback } from 'react'
import MemoButton from './MemoButton'
import { MemoPopoverPayload } from './MemoPopover'

interface PropTypes {
  context: React.Context<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>
  title: string
  memo: string
}

const MemoTextWithTitleAndContext: React.FC<PropTypes> = (props) => {
  const { context, title, memo } = props
  const [, setPayload] = useContext(context)
  return (
    <MemoButton
      onlyRead
      memo={title}
      onMouseEnter={useCallback((event) => {
        setPayload({
          open: true,
          anchorEl: event.currentTarget,
          memo
        })
      }, [memo])}
      onMouseLeave={useCallback(() => {
        setPayload({
          open: false,
          anchorEl: null,
          memo: ''
        })
      }, [])}
    />
  )
}

export default React.memo(MemoTextWithTitleAndContext)
