import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IpWhitelistFormStateProvider, {
  PropTypes as IpWhitelistFormStateProviderProps,
  initialForm,
  EditIpWhitelistFormType
} from '../../../components/admin/system/IpWhitelistFormStateProvider'
import FormField from '../../../components/default/form/FormField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormState, createDefaultFormState } from '../../../utils/default/FormHook'
import { useRedirectHandleBack } from '../../../utils/default/ComplexFlowHook'
import { Path } from '../../../components/admin/route/route'
import StateProvider from '../../../providers/default/StateProvider'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import EditIpWhitelistFormSubmitButton from '../../../components/admin/system/EditIpWhitelistFormSubmitButton'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'

const defaultFirstForm = initialForm()
const defaultRows = [{
  id: 0,
  context: createContext(createDefaultFormState(defaultFirstForm)),
  defaultValue: defaultFirstForm
}]

interface SubmitButtonContextType { id: number, disabled: boolean, value: EditIpWhitelistFormType }

const SubmitButtonContext = React.createContext<[
  SubmitButtonContextType[],
  Dispatch<SetStateAction<SubmitButtonContextType[]>>
]>([
  [],
  () => {}
])

const TextField = React.memo(MuiTextField)

const FormContextParser: React.FC<{
  id: number
  context: React.Context<FormState<EditIpWhitelistFormType>>
  setDisableds: Dispatch<SetStateAction<SubmitButtonContextType[]>>
}> = React.memo((props) => {
  const { id, context, setDisableds } = props
  const { disableSubmit, value } = useContext(context)
  useEffect(() => {
    setDisableds((disableds) => {
      return disableds
        .filter((item) => item.id !== id)
        .concat({ id, disabled: disableSubmit, value })
    })
  }, [disableSubmit, value, id])
  return null
})

const RemoveButton: React.FC<{
  rows: Array<{ id: number } & IpWhitelistFormStateProviderProps>
  row: { id: number } & IpWhitelistFormStateProviderProps
  setRows: Dispatch<SetStateAction<Array<{ id: number } & IpWhitelistFormStateProviderProps>>>
}> = React.memo((props) => {
  const { rows, row, setRows } = props
  const dialogId = `deleteIpWhitelist-${row.id}`
  const { value } = useContext(row.context)
  const [, setDisableds] = useContext(SubmitButtonContext)
  const { t } = useT()
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  useDialogFlow(globalDialog, dialogId, () => {
    setRows((rows) => rows.filter((item) => item.id !== row.id))
    setDisableds((disableds) => disableds.filter((disabled) => disabled.id !== row.id))
    globalDialog.clearState()
  }, [row])
  return (
    <Button
      className={classes.pinkGradualOutlineButton}
      onClick={useCallback(() => {
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          message: (
            <React.Fragment>
              {t('dialog.confirmRemoveIpWhitelist')}
              <span className={classes.purpleWord}>[{value.ip}]</span>
            </React.Fragment>
          )
        }))
        globalDialog.setOpen({ id: dialogId, value: true, isOK: false })
      }, [dialogId, value])}
      disabled={rows.length === 1}
    >
      {t('common.delete')}
    </Button>
  )
})

const SystemIpWhitelistCreatePage: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()

  const [rows, setRows] = useState<Array<{ id: number } & IpWhitelistFormStateProviderProps>>(defaultRows)

  const columns = useMemo(() => {
    return [
      { name: `${t('common.ipAddress')}(${t('common.required')})`, width: 'auto' },
      { name: `${t('common.memo')}(${t('common.required')})`, width: 'auto' },
      { name: t('common.function'), width: 'auto' }
    ]
  }, [t])

  const [handleDebouncedBatchCreate] = useDebouncedCallback(() => {
    setRows((rows) => {
      const defaultForm = initialForm()
      return rows.concat({
        id: rows[rows.length - 1].id + 1,
        context: createContext(createDefaultFormState(defaultForm)),
        defaultValue: defaultForm
      })
    })
  }, 500)

  const handleBatchCreate = useCallback(handleDebouncedBatchCreate, [])

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.SYSTEM_IP_WHITELIST
  })

  return (
    <StateProvider
      context={SubmitButtonContext}
      defaultValue={[]}
    >
      <Box paddingY={5}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('page.createIpWhitelist')}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" paddingY={2}>
              <Button
                className={classes.blueGradualOutlineButton}
                onClick={handleBatchCreate}
              >
                {t('common.batchCreate')}
              </Button>
            </Box>
            <Box paddingBottom={3} overflow="auto">
              <Table size="small">
                <TableHead className={classes.blackTableHead}>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        className={clsx(classes.tableCellHead, classes.nowrap)}
                        style={{ width: column.width }}
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <IpWhitelistFormStateProvider
                      key={row.id}
                      context={row.context}
                      defaultValue={row.defaultValue}
                    >
                      <TableRow className={classes.greyTableRow}>
                        <TableCell align="center">
                          <FormField<EditIpWhitelistFormType, TextFieldProps>
                            component={TextField}
                            context={row.context}
                            name="ip"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoFocus
                            label=""
                            placeholder={t('placeholder.inputIp')}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormField<EditIpWhitelistFormType, TextFieldProps>
                            component={TextField}
                            context={row.context}
                            name="memo"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label=""
                            placeholder={t('placeholder.inputMemo')}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RemoveButton rows={rows} setRows={setRows} row={row} />
                        </TableCell>
                      </TableRow>
                      <SubmitButtonContext.Consumer>
                        {([, setDisableds]) => (
                          <FormContextParser
                            id={row.id}
                            context={row.context}
                            setDisableds={setDisableds}
                          />
                        )}
                      </SubmitButtonContext.Consumer>
                    </IpWhitelistFormStateProvider>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box>
              <Typography color="error">{t('common.ipTip')}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Box paddingX={2}>
                <Button
                  className={classes.greyButton}
                  onClick={handleDebouncedBack}
                >
                  {t('common.cancel')}
                </Button>
              </Box>
              <SubmitButtonContext.Consumer>
                {([payload]) => {
                  const disabled = payload.some((item) => item.disabled)
                  const values = payload.map((item) => item.value)
                  return (
                    <EditIpWhitelistFormSubmitButton
                      onBack={handleBack}
                      disabled={disabled}
                      values={values}
                    />
                  )
                }}
              </SubmitButtonContext.Consumer>
            </Box>
          </Box>
        </ScrollablePaper>
      </Box>
    </StateProvider>
  )
}

export default React.memo(SystemIpWhitelistCreatePage)
