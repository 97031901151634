import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import allRoute from '../route/route'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogHandleClick, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { searchToRequest } from '../../../views/admin/forestage/AnnouncementPage'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

interface PropTypes {
  id: number
  reload: () => void
}

const AnnouncementTableButtonsBase: React.FC<{
  id: number
  handleDelete: () => void
}> = React.memo((props) => {
  const {
    id,
    handleDelete
  } = props
  const writable = useChecker()
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()

  return (
    <Grid container direction="column" spacing={1} justifyContent="center">
      <Grid item>
        <Button
          component={Link}
          to={allRoute.forestageAnnouncementLook.encodePath({ search: { ...request }, param: { id } })}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.look')}
        </Button>
      </Grid>
      { writable && (<Grid item>
        <Button
          component={Link}
          to={allRoute.forestageAnnouncementUpdate.encodePath({ search: { ...request }, param: { id } })}
          size="small"
          className={classes.blueGradualOutlineButton}
        >
          {t('common.update')}
        </Button>
      </Grid>) }
      { writable && (<>
        <Grid item>
          <Button
            onClick={handleDelete}
            size="small"
            className={classes.pinkGradualOutlineButton}
          >
            {t('common.delete')}
          </Button>
        </Grid>
      </>) }
    </Grid>
  )
})

const AnnouncementTableButtons: React.FC<PropTypes> = (props) => {
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const { id, reload } = props

  const handleDelete = useDialogHandleClick({
    dialogId: `deleteAnnouncement-${id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteAnnouncementQuestion')
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: t('dialog.deleteAnnouncementFinish'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload: id,
    gdkFunc: (payload) => gdk.announcement.deleteAnnouncement(payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => { reload() },
    onSuccessDependencies: [reload]
  })

  return (
    <AnnouncementTableButtonsBase
      id={id}
      handleDelete={handleDelete}
    />
  )
}

export default React.memo(AnnouncementTableButtons)
