import React, { createContext, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import allRoutes from '../../route/route'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../../utils/default/ComplexFlowHook'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import RequiredText from '../../../../components/default/form/RequiredText'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../../utils/default/FormHook'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import FormField from '../../../default/form/FormField'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import FormStateProvider from '../../../default/form/FormStateProvider'
import { omitBy, isUndefined } from '@golden/utils'
import { convertEmptyToUndefined, getValueFromChangeEvent } from '../../../../utils/default/FormHelper'
import { createValidateNotEmpty } from '../../../../utils/default/Validator'
import { PlayerReportWinlossAnalyzeReq } from '@golden/gdk-admin'

interface PlayerReportWinlossAnalyzeType {
  account: string
}

export const initialForm: InitialFormFunc<PlayerReportWinlossAnalyzeType> = (defaultForm) => ({
  account: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerReportWinlossAnalyzeType> = {
  account: getValueFromChangeEvent
}

export const searchToRequest: SearchToRequestFunc<PlayerReportWinlossAnalyzeReq> = (search) => {
  const converted = {
    ...search
  } as PlayerReportWinlossAnalyzeReq
  return omitBy(converted, isUndefined) as PlayerReportWinlossAnalyzeReq
}

const formToRequest = (form: PlayerReportWinlossAnalyzeType): PlayerReportWinlossAnalyzeReq => {
  const converted = {
    account: convertEmptyToUndefined(form.account)
  } as PlayerReportWinlossAnalyzeReq
  return omitBy(converted, isUndefined) as PlayerReportWinlossAnalyzeReq
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const PlayerReportWinlossAnalyzeForm: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        ...request
      })
    }
    return initialForm()
  }, [request])

  const validation = useMemo(() => ({
    account: [
      {
        func: createValidateNotEmpty<PlayerReportWinlossAnalyzeType>('account', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<PlayerReportWinlossAnalyzeType>), [t])

  const handleSubmit = useChangeUrlSubmit({
    formToRequest,
    encodePath: allRoutes.playerReportWinlossAnalyze.encodePath
  })

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">{t('page.winlossAnalyze')}</Typography>
              </Box>
            </Grid>
              <Grid item xs={12} md={3}>
                <FormField<PlayerReportWinlossAnalyzeType, TextFieldProps>
                  context={FormContext}
                  component={TextField}
                  name="account"
                  label={t('common.playerAccount') + ' *'}
                  placeholder={t('placeholder.inputPlayerAccount')}
                  fullWidth
                />
              </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <FormSubmitButton
                  component={Button}
                  context={FormContext}
                  type="submit"
                  className={commonClasses.purpleGradualButton}
                >
                  {t('common.search')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerReportWinlossAnalyzeForm)
