import React, { useState, useMemo, useContext, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useGDK from '../../../providers/admin/gdk/useGDK'
import useT from '../../../i18ns/admin/useT'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { AnnouncementPersonalContent } from '@golden/gdk-admin'
import { useGetDataByPayload, useGDKFuncHandleSubmit, InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { getValueFromValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { createValidateNotEmpty, createValidateEditorNotEmpty } from '../../../utils/default/Validator'
import { TextEditor } from '../../default/form/textEditor/TextEditor'
import { defaultEditorState, EditorState } from '@golden/tiptap-react'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import CustomThemeType from '../../../themes/admin/CustomThemeType'

interface FormType {
  title: string
  content: EditorState
  id?: number
}

const formToRequest = (data: FormType): AnnouncementPersonalContent => {
  return {
    title: data.title,
    content: JSON.stringify(data.content.content)
  }
}

const getValueFromEvent: ValueGetter<FormType> = {
  title: getValueFromChangeEvent,
  content: getValueFromValue
}

const initialForm: InitialFormFunc<FormType> = (form) => ({
  title: '',
  content: defaultEditorState,
  ...form
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const Editor: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, error } = useContext(FormContext)
  return (
    <TextEditor
      value={value.content}
      onChange={handleChange('content')}
      controls={['textColor', 'clearFormat', 'link']}
      error={error.content !== null}
      helperText={error.content ?? ''}
      placeholder={`${t('placeholder.inputContent')}*`}
    />
  )
})

const titleInputProps = {
  maxLength: 20
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    color: theme.custom.palette.text.dialog,
    fontSize: theme.custom.typography.dialog
  }
}))

const AnnouncementPersonalTemplateDialog: React.FC<{ title: string, isEdit: boolean, open: boolean, close: () => void, reload: () => void, id?: number }> = ({ title, isEdit, open, close, reload, id }) => {
  const gdk = useGDK()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const [defaultForm, setDefaultForm] = useState<FormType>(initialForm())

  useGetDataByPayload({
    payload: id,
    gdkFunc: () => gdk.notification.getPersonalNotificationTemplate(id as number),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setDefaultForm(initialForm({ ...res, content: { ...defaultEditorState, content: JSON.parse(res.content) } }))
      pageFlow.setContentShow()
    },
    onError: (error) => {
      pageFlow.setError(error.message)
    },
    canLoadData: isEdit && open
  })
  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => isEdit ? gdk.notification.updatePersonalNotificationTemplate(payload, id as number) : gdk.notification.createPersonalNotificationTemplate(payload),
    onSuccess: () => {
      reload()
      close()
    },
    gdkFuncDependencies: [gdk]
  })

  const validation = useMemo(() => {
    return {
      title: [
        {
          func: createValidateNotEmpty('title', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      content: [
        {
          func: createValidateEditorNotEmpty('content', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <FormStateProvider<FormType>
        context={FormContext}
        defaultValue={defaultForm}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
        onSubmit={handleSubmit}
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent>
          <Box padding={3} paddingTop={1}>
            <LoadingAndErrorFrame {...pageFlow.status}>
              <Grid container direction="column" spacing={3}>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <FormField<FormType, TextFieldProps>
                      component={TextField}
                      context={FormContext}
                      name="title"
                      label={t('common.title')}
                      placeholder={t('placeholder.inputTitle')}
                      inputProps={titleInputProps}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <Editor />
                  </Grid>
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={close}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                component={Button}
                context={FormContext}
                type="submit"
                className={commonClasses.purpleGradualButton}
              >
                {t('common.save2')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(AnnouncementPersonalTemplateDialog)
