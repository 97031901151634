import React, { useState, createContext, useMemo, useContext, useEffect, Dispatch, SetStateAction, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import MuiTextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useDialogHandleSubmit, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { WithdrawRCSlipDetail, WithdrawWayType, CryptoProtocolType, RCStatusType, AccountWarnedType } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { formatDateTime, formatMoney } from '../../../utils/default/TableHelper'
import rcStatusColor from '../../../constants/admin/rcStatusColor'
import rcStatusName from '../../../constants/admin/rcStatusName'
import EditWithdrawalRCSlipForm, { WithdrawRCSlipFormType, initialForm } from './EditWithdrawalRCSlipForm'
import { createDefaultFormState, FormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import { getValueFromChangeEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import { createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import PlayerIsWarnedIcon from '../PlayerIsWarnedIcon'
import { uniq } from '@golden/utils'
import allRoutes from '../route/route'
import { startOfDay, endOfDay, subDays, getTime } from 'date-fns'
import { Link } from 'react-router-dom'
import { protocolTypeName } from '../../../constants/default/protocolTypeName'
import WithdrawalCashAccountingTable from './WithdrawalCashAccountingTable'
import { WithdrawSlipAccountingContext } from '../../../providers/admin/gdk/GDKStoreProvider'

const TextField = React.memo(MuiTextField)

const useStyles = makeStyles((theme) => ({
  cell: {
    width: '25%',
    padding: theme.spacing(1.5)
  },
  log: {
    background: theme.palette.grey[200]
  },
  tableFont: {
    fontSize: 12
  }
}))

const getValueFromEvent: ValueGetter<WithdrawRCSlipFormType> = {
  status: getValueFromValue,
  memo: getValueFromChangeEvent
}

const formToRequest = (form: WithdrawRCSlipFormType): WithdrawRCSlipFormType => form

export const FormContext = createContext<FormState<WithdrawRCSlipFormType>>(createDefaultFormState(initialForm()))

const FormSubmitButton: React.FC<{ loading: boolean }> = React.memo((props) => {
  const { loading } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { disableSubmit } = useContext(FormContext)

  return (
    <Button
      disabled={disableSubmit || loading}
      className={commonClasses.purpleGradualButton}
      type="submit"
    >
      {loading
        ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
          )
        : t('common.confirm')}
    </Button>
  )
})

export interface Payload {
  id: number
  open: boolean
  readonly: boolean
}

export const defaultPayload: Payload = {
  id: 0,
  open: false,
  readonly: true
}

export const DialogPayloadContext = createContext<[
  Payload,
  Dispatch<SetStateAction<Payload>>
]>([
  defaultPayload,
  () => {}
])

interface PropTypes {
  reload: () => void
}

const ButtonLinkToEffectiveCash: React.FC<{ detail: WithdrawRCSlipDetail | null }> = (props) => {
  const { detail } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { start_at: startAt, end_at: endAt } = useContext(WithdrawSlipAccountingContext)

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={allRoutes.manualEffectiveCashHistory.encodePath({
        search: {
          start_at: getTime(startAt),
          end_at: getTime(endAt),
          account: detail?.player_account ?? '',
          page: 1,
          now_timestamp: getTime(new Date())
        },
        param: {}
      })}
      target="_blank"
    >
      <Button
        className={commonClasses.blueGradualOutlineButton}
      >
        {t('common.effectiveCashRecord')}
      </Button>
    </Link>
  )
}

const ButtonLinkToIpMonitoring: React.FC<{ account: string }> = (props) => {
  const { account } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const past = getTime(startOfDay(subDays(new Date(), 30)))
  const endOfToday = getTime(endOfDay(new Date()))

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={allRoutes.playerIpMonitoring.encodePath({ search: { start_at: past, end_at: endOfToday, account, page: 1 }, param: {} })}
      target="_blank"
    >
      <Button
        className={commonClasses.blueGradualOutlineButton}
      >
        {t('page.ipMonitoring')}
      </Button>
    </Link>
  )
}

const ButtonLinkToWalletHistory: React.FC<{ id: number, account: string }> = (props) => {
  const { id, account } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  useEffect(() => {
    gdk.withdraw.withdrawSlipId = id
  }, [gdk, id])
  const res = useGDKStore.withdraw.withdrawSlipAccounting()

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={allRoutes.playerReportCash.encodePath({ search: { start_at: getTime((res.start_at)), end_at: getTime((res.end_at)), account, page: 1 }, param: {} })}
      target="_blank"
    >
      <Button
        className={commonClasses.blueGradualOutlineButton}
      >
        {t('page.cashLog')}
      </Button>
    </Link>
  )
}

const WithdrawalRCSlipDetailDialog: React.FC<PropTypes> = React.memo((props) => {
  const { reload } = props
  const [payload, setPayload] = useContext(DialogPayloadContext)
  const { id, open, readonly } = payload
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const writable = useChecker()
  const detailPageFlow = usePageFlow()
  const [detail, setDetail] = useState<WithdrawRCSlipDetail | null>(null)

  const defaultForm = useMemo(() => ({
    ...initialForm(),
    memo: detail?.memo_risk ?? '',
    status: detail?.rc_status as Exclude<RCStatusType, RCStatusType.REVIEWING>
  }), [detail])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.withdraw.getWithdrawRCSlipDetail(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: detailPageFlow.setLoadingStart,
    onSuccess: (res: WithdrawRCSlipDetail) => {
      setDetail(res)
      detailPageFlow.setContentShow()
    },
    onError: detailPageFlow.setGDKError,
    canLoadData: id !== 0
  })
  const isMobileRealnameMatch = detail?.is_mobile_realname_match
  const hasDuplicateBankAccount = detail?.has_duplicate_bank_account ?? false
  const isFirst = detail?.is_first
  const isFirstUseDebitCard = detail?.first_use_debit_card
  const isSelf = detail?.locked_by === me?.id
  const isNoMatchRealName = useMemo(() => detail?.real_name_unmatched_by_forwarder && detail.real_name_unmatched_by_forwarder.length > 0, [detail])
  const headCellClass = clsx(detailClasses.greyHead, classes.cell, commonClasses.pre, classes.tableFont)
  const bodyCellClass = clsx(detailClasses.cell, classes.cell, commonClasses.pre, classes.tableFont)

  const validation = useMemo(() => {
    return {
      status: [
        {
          func: (value: RCStatusType) => {
            if ([RCStatusType.SUCCESSFUL, RCStatusType.IRREGULAR, RCStatusType.CANCELED].includes(value)) return createCorrectResult('status')
            return createErrorResult('status', '')
          },
          when: ['beforeClickSubmit']
        }
      ],
      memo: [
        {
          func: (value, form) => {
            if (form.status === RCStatusType.IRREGULAR || form.status === RCStatusType.CANCELED) {
              if (value === detail?.memo_risk) return createErrorResult('memo', '')
            }
            return createCorrectResult('memo')
          },
          when: ['beforeClickSubmit']
        }
      ]
    } as FormValidation<WithdrawRCSlipFormType>
  }, [detail])
  const handleClose = useCallback(() => {
    setPayload({
      id: 0,
      open: false,
      readonly: true
    })
  }, [])

  const { handleSubmit, loading } = useDialogHandleSubmit({
    dialogId: `reviewRCSlip${id}`,
    formToRequest,
    globalDialog,
    gdkFunc: (form) => {
      const id = detail?.id ?? 0
      return gdk.withdraw.applyWithdrawRCSlip(id, form as { memo: string, status: Exclude<RCStatusType, RCStatusType.IN_PROGRESS | RCStatusType.REVIEWING> })
    },
    gdkFuncDependencies: [gdk, detail],
    getChangeDialogConfig: (form) => createGlobalDialogConfig({
      showIcon: false,
      message: (<div>{ t('common.confirm') } {(<span style={{ color: rcStatusColor[form.status] }}>{`${t(rcStatusName[form.status])}`}</span>)} ?</div>),
      showCancel: true
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.playerCenterSuccess')
    }),
    getFailDialogConfig: (_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }),
    onSuccess: () => {
      handleClose()
      reload()
      globalDialog.clearState()
    }
  })

  const bankAndProtocolMap = {
    [WithdrawWayType.CURRENCY_DEBIT_CARD]: detail?.bank_name ?? '',
    [WithdrawWayType.CURRENCY_GOUBAO]: t('withdraw.goubao'),
    [WithdrawWayType.CURRENCY_CRYPTO_ERC]: t(protocolTypeName[CryptoProtocolType.ERC20]),
    [WithdrawWayType.CURRENCY_CRYPTO_TRC]: t(protocolTypeName[CryptoProtocolType.TRC20]),
    [WithdrawWayType.CURRENCY_TOPAY]: t(protocolTypeName[CryptoProtocolType.TOPAY]),
    [WithdrawWayType.CURRENCY_EBPAY]: t(protocolTypeName[CryptoProtocolType.EBPAY]),
    [WithdrawWayType.CURRENCY_CGPAY]: t(protocolTypeName[CryptoProtocolType.CGPAY]),
    [WithdrawWayType.CURRENCY_CGPAY_USDT]: t(protocolTypeName[CryptoProtocolType.CGPAY_USDT]),
    [WithdrawWayType.CURRENCY_KOIPAY]: t(protocolTypeName[CryptoProtocolType.KOIPAY]),
    [WithdrawWayType.CURRENCY_PAY988]: t(protocolTypeName[CryptoProtocolType.PAY988]),
    [WithdrawWayType.CURRENCY_PAY808]: t(protocolTypeName[CryptoProtocolType.PAY808]),
    [WithdrawWayType.CURRENCY_KDPAY]: t(protocolTypeName[CryptoProtocolType.KDPAY]),
    [WithdrawWayType.AGENT_TRANSFER_WITHDRAW]: t('withdraw.agent_transfer_withdraw')
  }

  return (
    <Dialog open={open} scroll="paper" maxWidth="md" fullWidth onClose={handleClose}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        validation={validation}
        getValueFromEvent={getValueFromEvent}
        onSubmit={handleSubmit}
      >
        <LoadingAndErrorFrame {...detailPageFlow.status}>
          <Box padding={4}>
            <Box overflow="auto">
              <Box minWidth={800}>
                <Box
                  paddingY={1}
                  paddingX={1.5}
                  className={clsx(commonClasses.blackTitleBar, commonClasses.pre)}
                >
                  <Typography variant="body1">
                    {detail?.order_number}
                  </Typography>
                </Box>
                <table className={detailClasses.table}>
                  <tbody>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.playerAccount')}</td>
                      <td className={bodyCellClass}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                          <Grid item>
                            {// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              (detail?.fm_warned || detail?.rc_warned) &&
                              <Grid container item direction="column" wrap="nowrap" alignItems="center" justifyContent="center" >
                                <PlayerIsWarnedIcon isWarned={detail.fm_warned} />
                                <PlayerIsWarnedIcon isWarned={detail.rc_warned} warnedType={AccountWarnedType.RISK_CONTROL} />
                              </Grid>
                            }
                          </Grid>
                          <Grid item>{detail?.player_account
                            ? (<Link
                            style={{ textDecoration: 'none' }}
                            to={allRoutes.playerDetail.encodePath({ search: {}, param: { id: detail?.user_id || 0 } })}
                            target="_blank"
                          >
                            {detail?.player_account}
                          </Link>)
                            : ''}</Grid>
                        </Grid>
                      </td>
                      <td className={headCellClass} align="center">{t('common.status')}</td>
                      <td className={bodyCellClass}>
                        {detail?.rc_status
                          ? (
                          <span className={commonClasses.chipText} style={{ backgroundColor: rcStatusColor[detail.rc_status] }}>
                            {t(rcStatusName[detail.rc_status])}
                          </span>
                            )
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{`${t('common.cardNo')}/${t('common.address')}`}</td>
                      <td className={bodyCellClass}>{detail?.bank_account ?? '--'}{detail?.method === WithdrawWayType.AGENT_TRANSFER_WITHDRAW && '*'}</td>
                      <td className={headCellClass} align="center">{t('common.cash')}</td>
                      <td className={bodyCellClass}>{detail?.cash ? formatMoney(detail.cash) : ''}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.realName')}</td>
                      <td className={bodyCellClass}>{detail?.real_name ?? '-'}</td>
                      <td colSpan={2} rowSpan={4} className={bodyCellClass}>
                        {
                          (!!isFirstUseDebitCard) &&
                          <Typography color="secondary">
                            * {t('common.isFirstWithdrawWithDebitCard')}
                          </Typography>
                        }
                        {
                          (isFirst && !isFirstUseDebitCard) &&
                          <Typography color="secondary">
                            * {t('common.isFirstWithdraw')}
                          </Typography>
                        }
                        {
                          !isMobileRealnameMatch &&
                          <Typography color="secondary">
                              * {t('common.playerCardRealnameNotMatch')}
                          </Typography>
                        }
                        {
                          hasDuplicateBankAccount &&
                          <Typography color="secondary">
                            * {t('common.duplicateBindPre')}
                            {detail?.duplicate_bank_accounts.map((item) => (
                              <Tooltip title={
                                item.deleted_at ? t('common.bindPeriod', { startAt: formatDateTime(item.created_at), endAt: formatDateTime(item.deleted_at) }) : t('common.bindPeriod2', { startAt: formatDateTime(item.created_at) })
                              }>
                                <span>
                                  【<a target="_blank" href={allRoutes.player.encodePath({ param: {}, search: { account: item.account, page: 1 } })} rel="noreferrer">{item.account}</a>】
                                </span>
                              </Tooltip>
                            ))}
                            {t('common.duplicateBind')}
                          </Typography>
                        }
                        {
                          isNoMatchRealName &&
                          <Typography color="secondary">
                            * {t('common.thirdPartyNoMatch', { name: detail?.real_name_unmatched_by_forwarder.map(item => t(`thirdPartName.${item}`)).join('、') })}
                          </Typography>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.bank')}</td>
                      <td className={bodyCellClass}>{detail?.method ? bankAndProtocolMap[detail?.method] : ''}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.playerLayer')}</td>
                      <td className={bodyCellClass}>{detail?.layer_name ?? t('common.none')}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.registeredAt')}</td>
                      <td className={bodyCellClass}>{detail?.register_at ? formatDateTime(detail.register_at) : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
            <Box marginTop={3}>
              <Box
                paddingY={1}
                paddingX={1.5}
                className={clsx(commonClasses.blackTitleBar, commonClasses.pre)}
              >
                <Typography variant="body1">
                  {uniq((detail?.agent_chain ?? [])).concat(detail?.player_account ?? '').join(' > ')}
                </Typography>
              </Box>
              <Box paddingY={2}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography>{t('common.financeMemo')}</Typography>
                    <TextField
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="filled"
                      disabled
                      value={detail?.memo_finance ?? ''}
                      inputProps={{
                        classes: commonClasses.memoField
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>{t('common.battleFieldMemo')}</Typography>
                    <TextField
                      multiline
                      fullWidth
                      type="text"
                      margin="normal"
                      variant="filled"
                      disabled
                      value={detail?.memo_battle_field ?? ''}
                      inputProps={{
                        classes: commonClasses.memoField
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <ButtonLinkToEffectiveCash detail={detail} />
                  </Grid>
                  <Grid item>
                    <ButtonLinkToIpMonitoring account={detail?.player_account ?? ''} />
                  </Grid>
                  <Grid item>
                    <ButtonLinkToWalletHistory id={id} account={detail?.player_account ?? ''} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <WithdrawalCashAccountingTable id={id} account={detail?.player_account ?? ''} />
            <Box marginBottom={3}>
              <Box
                paddingY={1}
                paddingX={1.5}
                marginBottom={2}
                className={commonClasses.blackTitleBar}
              >
                <Typography variant="body1">
                  {t('common.review')}
                </Typography>
              </Box>
              <EditWithdrawalRCSlipForm
                writable={detail?.rc_status === RCStatusType.REVIEWING && isSelf && writable && !readonly}
                hideIrregularButton={detail?.method === WithdrawWayType.AGENT_TRANSFER_WITHDRAW}
              />
            </Box>
            <LoadingAndErrorFrame error={null} loading={false} showContent={detailPageFlow.status.showContent}>
              {
                detail?.rc_change_log && (
                  <Box className={classes.log} whiteSpace="pre" padding={2} overflow="auto">
                    {detail.rc_change_log}
                  </Box>
                )
              }
            </LoadingAndErrorFrame>
            <Box marginTop={3}>
              <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    onClick={handleClose}
                    className={commonClasses.greyButton}
                  >
                    {t('common.close')}
                  </Button>
                </Grid>
                {detail?.rc_status === RCStatusType.REVIEWING && !readonly && isSelf && writable && (
                  <Grid item>
                    <FormSubmitButton loading={loading} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </LoadingAndErrorFrame>
      </FormStateProvider>
    </Dialog>
  )
})

export default React.memo(WithdrawalRCSlipDetailDialog)
