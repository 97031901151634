import React, { useMemo, useState, useCallback, ChangeEvent } from 'react'
import { isEqual, lightFormat } from 'date-fns'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { DeviceStatistic, DeviceStatisticQuery, FrequencyType } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LineChart, { PropTypes as LineChartProps } from '../../default/present/LineChart'
import { getDayArray, getWeekArray, getMonthArray } from '../../../utils/default/TimeHelper'
import NumberInput from '../../default/form/NumberInput'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import { useDebouncedCallback } from 'use-debounce/lib'

interface PropTypes {
  request: DeviceStatisticQuery
  result: DeviceStatistic
}

interface ChartType {
  time: string
  total?: number
  pc?: number
  h5?: number
  app?: number
  pwa?: number
  other?: number
}

interface FormType {
  interval: string
  showTotal: boolean
  showDesktop: boolean
  showH5: boolean
  showApp: boolean
  showPWA: boolean
  showOther: boolean
}

const AdminReportDeviceStatisticLoginCount: React.FC<PropTypes> = (props) => {
  const { request, result } = props
  const { t } = useT()
  const classes = useCommonStyles()
  const [form, setForm] = useState<FormType>({
    interval: '500',
    showTotal: true,
    showDesktop: true,
    showH5: true,
    showApp: true,
    showPWA: true,
    showOther: true
  })
  const data: ChartType[] = useMemo(() => {
    if (!request) return []
    const mappingData = (time: Date, formatDateString: string) => {
      const target = result.login_count.find((item) => isEqual(item.time, time))
      const displayTime = lightFormat(time, formatDateString)
      if (target) {
        return { time: displayTime, total: target.total, pc: target.pc, h5: target.h5, app: target.app, pwa: target.pwa, other: target.other }
      }
      return { time: displayTime, total: 0, pc: 0, h5: 0, app: 0, pwa: 0, other: 0 }
    }
    if (request.period === FrequencyType.DAY) {
      return getDayArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.WEEK) {
      return getWeekArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'MM/dd'))
    }
    if (request.period === FrequencyType.MONTH) {
      return getMonthArray(new Date(request.start_at), new Date(request.end_at))
        .map((item) => mappingData(item, 'yyyy/MM'))
    }
    return []
  }, [request, result.login_count])
  const lines = useMemo(() => {
    const lines = []
    if (form.showTotal) lines.push({ name: t('common.totalLoginCount'), dataKey: 'total', stroke: '#6236ff' } as LineChartProps<ChartType>['lines'][0])
    if (form.showDesktop) lines.push({ name: t('common.desktopLoginCount'), dataKey: 'pc', stroke: '#fb9678' } as LineChartProps<ChartType>['lines'][0])
    if (form.showH5) lines.push({ name: t('common.h5LoginCount'), dataKey: 'h5', stroke: '#70d506' } as LineChartProps<ChartType>['lines'][0])
    if (form.showApp) lines.push({ name: t('common.appLoginCount'), dataKey: 'app', stroke: '#36C0FF' } as LineChartProps<ChartType>['lines'][0])
    if (form.showPWA) lines.push({ name: t('common.pwaLoginCount'), dataKey: 'pwa', stroke: '#FC5625' } as LineChartProps<ChartType>['lines'][0])
    if (form.showOther) lines.push({ name: t('common.otherLoginCount'), dataKey: 'other', stroke: '#5cd2c6' } as LineChartProps<ChartType>['lines'][0])
    return lines
  }, [form, t])
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.loginCount')}
          </Typography>
        </Box>
        <Box paddingTop={2} display="flex" alignItems="center" flexDirection="row">
          <Box display="flex" marginRight={2}>
            <NumberInput
              label={t('common.loginCountInterval')}
              value={form.interval}
              onChange={useDebouncedCallback(useCallback((event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setForm((form) => ({ ...form, interval: value }))
              }, []), 200)[0]}
              helperText={t('helperText.interval')}
              FormHelperTextProps={{ error: true }}
              inputProps={{
                decimalScale: 0,
                fixedDecimalScale: true,
                allowNegative: false,
                maxLength: 6
              }}
            />
          </Box>
          <OnOffCheckbox
            label={t('common.totalLoginCount')}
            value={form.showTotal}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showTotal: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.desktopLoginCount')}
            value={form.showDesktop}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showDesktop: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.h5LoginCount')}
            value={form.showH5}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showH5: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.appLoginCount')}
            value={form.showApp}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showApp: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.pwaLoginCount')}
            value={form.showPWA}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showPWA: value }))
            }, [])}
          />
          <OnOffCheckbox
            label={t('common.otherLoginCount')}
            value={form.showOther}
            onChange={useCallback((event) => {
              const value = event.target.checked
              setForm((form) => ({ ...form, showOther: value }))
            }, [])}
          />
        </Box>
        <Box paddingY={2}>
          <LineChart<ChartType>
            height={600}
            chartMarginRight={48}
            interval={Number(form.interval)}
            data={data}
            dataKey="time"
            xLabel={t('common.date')}
            yLabel={t('common.loginCount')}
            lines={lines}
          />
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportDeviceStatisticLoginCount)
