import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { timer } from 'rxjs'
import Typography from '@material-ui/core/Typography'
import useT from '../../i18ns/admin/useT'
import { useReload } from '../../utils/default/ComplexFlowHook'
import DropDown from '../default/form/DropDown'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dropDown: {
    ...theme.typography.button,
    lineHeight: 1.4,
    padding: 10
  }
}))

interface PropTypes {
  reload: () => void
  reloadFlag?: boolean
}

const Refresh: React.FC<{ bottleneck: number, reload: () => void, reloadFlag: boolean }> = React.memo((props) => {
  const { reload, reloadFlag, bottleneck } = props
  const { t } = useT()
  const { reload: reloadTimer, reloadFlag: reloadTimerFlag } = useReload()
  const [count, setCount] = useState<number>(0)
  useEffect(() => {
    const subscription = timer(0, 1000).subscribe((count) => { setCount(count + 1) })
    return () => { subscription.unsubscribe() }
  }, [reloadTimerFlag, bottleneck])

  useEffect(() => {
    setCount(0)
    reloadTimer()
  }, [reloadFlag, reloadTimer])

  useEffect(() => {
    if (count >= bottleneck) {
      setCount(0)
      reload()
      reloadTimer()
    }
  }, [bottleneck, count, reload, reloadTimer])

  const diff = bottleneck - count
  const time = diff < 0 ? 0 : diff

  return (
    <Typography>
      {t('common.refreshSecond', { time })}
    </Typography>
  )
})

const RefreshCount: React.FC<PropTypes> = (props) => {
  const { reload, reloadFlag = false } = props
  const { t } = useT()
  const classes = useStyles()

  const options = useMemo(() => {
    return [10, 30, 60]
      .map((item) => ({ name: `${item}${t('common.timeSecond')}`, value: item }))
      .concat({ name: t('common.noRefresh'), value: '--' } as any)
  }, [t])

  const [bottleneck, setBottleneck] = useState<number | '--'>('--')
  const handleBottleneckChange = useCallback((event) => {
    setBottleneck(event.target.value)
  }, [])

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
      <DropDown
        label=""
        variant="outlined"
        options={options}
        value={bottleneck}
        className={classes.dropDown}
        onChange={handleBottleneckChange}
      />
      {bottleneck !== '--' && (
        <Box paddingLeft={2}>
          <Refresh bottleneck={bottleneck} reload={reload} reloadFlag={reloadFlag}/>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(RefreshCount)
