import React, { createContext, useMemo, useContext, useCallback, useState } from 'react'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { ManualWithdrawCsvPreviewRes, ManualWithdrawType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import { InitialFormFunc, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { RequestContext } from '../../../views/admin/manual/ManualWithdrawCreatePage'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import FormField from '../../default/form/FormField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import manualWithdrawName from '../../../constants/default/manualWithdrawName'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import ManualWithdrawFilePreviewTable from './ManualWithdrawFilePreviewTable'
import { formatMoney } from '../../../utils/default/TableHelper'

interface RequestType { file: File, withdrawType: ManualWithdrawType }

export interface FormType {
  file: File
  withdrawType: ManualWithdrawType
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  file: new File([], ''),
  withdrawType: ManualWithdrawType.EVENT,
  ...defaultForm
})

export const formToRequest = (form: FormType): RequestType => {
  return form
}

const getValueFromEvent: ValueGetter<FormType> = {
  file: getValueFromChangeEvent,
  withdrawType: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<FormType> = {
  file: (value, form) => ({
    ...initialForm(),
    withdrawType: form.withdrawType,
    file: value
  })
}

const FormContext = createContext(createDefaultFormState(initialForm()))

interface PropTypes {
  loading: boolean
  setLoading: (value: boolean) => void
  handleBack: () => void
}

const ManualWithdrawFileCreateForm: React.FC<PropTypes> = (props) => {
  const { loading, setLoading, handleBack } = props
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const [context] = useContext(RequestContext)
  const defaultForm = useMemo(() => initialForm({ file: context.file }), [context.file])
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const validation = useMemo(() => {
    return {
      file: [
        {
          func: createValidateNotEmpty('file', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      withdrawType: [
        {
          func: createValidateNotEmpty('withdrawType', t),
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<FormType>
  }, [t])
  const options = useMemo(() => {
    return Object.keys(manualWithdrawName)
      .map((key) => ({ name: t(manualWithdrawName[key as ManualWithdrawType]), value: key }))
  }, [t])

  const [res, setRes] = useState<ManualWithdrawCsvPreviewRes>({
    account_duplicated: 0,
    minus_cash: 0,
    data: []
  })

  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'createManualFileWithdraw',
    globalDialog,
    getChangeDialogConfig: useCallback(() => {
      return createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <React.Fragment>
            <Typography
              className={dialogClasses.text}
              align="center"
            >
              {t('dialog.confirmUploadManualWithdraw', {
                total: res.data.length,
                cash: formatMoney(res.data.reduce((pre, cur) => BcMath.base(pre).add(cur.withdraw_amount).get(), '0'))
              })}
            </Typography>
          </React.Fragment>
        )
      })
    }, [t, dialogClasses, res]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.createManualWithdrawSuccess')
    }), [t]),
    onSuccess: () => {
      setLoading(false)
    },
    getFailDialogConfig: useCallback((_, error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error.message
    }), []),
    onError: () => {
      setLoading(false)
    },
    formToRequest,
    gdkFunc: (payload) => {
      setLoading(true)
      return gdk.manual.createWithdrawByCsv(payload)
    },
    gdkFuncDependencies: [gdk],
    afterSuccessDialog: handleBack
  })
  if (!context.file.size || !context.isBatch) return null
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={5}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <ManualWithdrawFilePreviewTable
                  res={res}
                  setRes={setRes}
                  pageFlow={pageFlow}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<FormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="withdrawType"
                      label={t('common.withdrawObject')}
                      options={options}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
                <Typography color="error">{ t('common.memoDisplayAtForestage') }</Typography>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
      <Box paddingTop={2}>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleBack}
              className={classes.greyButton}
            >
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <FormSubmitButton
              context={FormContext}
              component={Button}
              disabled={loading}
              type="submit"
              className={classes.purpleGradualButton}
            >
              {loading
                ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={24} />
                </Box>
                  )
                : t('common.confirmCreate')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Box>
    </FormStateProvider>
  )
}

export default React.memo(ManualWithdrawFileCreateForm)
